import { NgModule } from '@angular/core';
import { BreadcrumbComponent } from './breadcrumb.component';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';


@NgModule({
    declarations: [
        BreadcrumbComponent
    ],
    imports: [
        TranslateModule,
        CommonModule,
        RouterModule
    ],
    providers: [],
    exports: [
        BreadcrumbComponent
    ]
})
export class BreadcrumbModule {
}
