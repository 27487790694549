import { NgModule } from '@angular/core';

import { ClinicsModalComponent } from './clinics-modal.component';

import { BaseModule } from '../../shared/base.module';
import { ImageUploadModule } from 'angular2-image-upload';
import { RouterModule } from '@angular/router';

import { MATERIALS } from '../../app.materials';
import { SharedPopoverModule } from '../../shared/popover/popover.module';

@NgModule({
    imports: [
        MATERIALS,
        BaseModule,
        RouterModule,
        SharedPopoverModule,
        ImageUploadModule.forRoot()
    ],
    exports: [
        ClinicsModalComponent
    ],
    declarations: [
        ClinicsModalComponent
    ]
})
export class ClinicsModalModule { }
