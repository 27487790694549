import { map, filter, mergeMap, switchMap, catchError } from 'rxjs/operators';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { SecureApi } from './core/secure.api';
import { AuthService } from './core/auth.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationItemModel } from './shared/notification/notification-item/notification-item.model';
import { saveAs } from 'file-saver';
import { ValidatorFn, AbstractControl } from '@angular/forms';
import Echo from "laravel-echo";
import * as Pusher from "pusher-js";
import * as _ from 'lodash';
declare var Weglot;
import { environment } from '../environments/environment';
import { ENV } from './app.env';
import { HttpClient } from '@angular/common/http';
import { storyblokInit } from "@storyblok/js";

declare var window;
// declare ga as a function to set and sent the events
declare var gtag: Function;

@Injectable()

export class AppService {
    returned = new Subject();
    notificationChange = new Subject();

    isLoading = false;
    isMobile = false;
    isSE = false;
    isMePage = false;
    isPortrait = false;
    deviceInnerHeight: number;
    steps = {
        'FEELING_SMOKE_DETECTOR': 1,
        'FEELING_SELECTION': 2,
        'FEELING_RATING': 3,
        'ANAMESTIC_SELECTION': 4,
        'FINAL': 5
    };
    repeatSteps = {
        'SELECTED_SMOKE_DETECTOR': 1,
        'NOT_SELECTED_SMOKE_DETECTOR': 2,
        'FEELING_SELECTION': 3,
        'FEELING_RATING': 4,
        'ANAMESTIC_SELECTION': 5,
        'FINAL': 6
    };
    languages =  [{
        "id": 1,
        "locale": "de_CH",
        "code": "DE",
        "name": "German"
    },
    {
        "id": 2,
        "locale": "fr_FR",
        "code": "FR",
        "name": "French"
    },
    {
        "id": 3,
        "locale": "it_IT",
        "code": "IT",
        "name": "Italian"
    },
    {
        "id": 4,
        "locale": "en_GB",
        "code": "EN",
        "name": "English"
    }];
    // Don't know why languages is override at some point
    meLanguages =  [{
        "id": 1,
        "locale": "de_CH",
        "code": "DE",
        "name": "German"
    },
    {
        "id": 2,
        "locale": "fr_FR",
        "code": "FR",
        "name": "French"
    },
    {
        "id": 3,
        "locale": "it_IT",
        "code": "IT",
        "name": "Italian"
    },
    {
        "id": 4,
        "locale": "en_GB",
        "code": "EN",
        "name": "English"
    }];
    defaultSystemLanguage: any;
    SEStep: BehaviorSubject<number> = new BehaviorSubject<number>(-1);
    SEType: string;
    SEData: any = {};
    activeLanguage: {
        code : any
        id : any
        locale : any,
        name: any
    };
    notificationTimeout: any;
    dowloadFileTimeout: any;
    notifications: any[] = [];
    unreadNotifications: any[] = [];
    disableNotifications: any[] = [];
    notificationLoaded = false;
    firstTabNotification: any[] = [];
    secondTabNotification: any[] = [];
    multiClinics: any[] = [];

    // Time for reload notification
    timeoutToCheckNotification = 10e3;

    // Check Latest Version released
    isNewVersionAvailable: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    releasedVersion = ENV.buildVersion;

    private publishSubscribeSubject: Subject<any> = new Subject();
    private emitter: Observable<any>;

    echo: any;
    patientsList: any;
    // Load patients list balance with 3 step mapping with 3 API of patients
    loadedProgressOfPatientList = 0;
    patientLoadingProgess = {
        'load_first_50_active' : 1,
        'load_all_active'      : 2,
        'load_all_patients'    : 3
    };
    waitingUpdatedPatients = [];
    totalPatientsType: any = {};
    isRepeatedSE = false;
    isDefaultClinic = false;
    waitingTranslation = false;
    hideFooterPages = [
        'login',
        'patient-registration',
        'documents-dashboard',
        'change-clinic',
        'klenico-me'
    ];
    meClinicFolderChanged: BehaviorSubject<string> = new BehaviorSubject<string>('');

    // Google Analytics Events
    GAEvents = {
        PATIENTS_LIST: {
            name: 'Patient List',
            event: {
                filterBy: 'Filter by ',
                searchBy: 'Search Patient',
                viewProfile: 'View Profile',
                create: 'Create new patient',
                menu: 'Action menu',
                openSR: 'Open SR',
                openDI: 'Open DI',
                sendReminder: 'Send Reminder',
                transferPatient: 'Transfer patient',
                deletePatient: 'Delete Patient',
                archivePatient: 'Archive patient',
                editPatient: 'Edit Patient',
                exportMap: 'Export Map',
                showMore: 'Show more patients',
                coordinateSendEmail: 'Coordinator send email',
                contactPatient: 'Therapist contact patient'
            }
        },
        DASHBOARD: {
            name: 'Dashboard',
            event: {
                filterBy: 'Filter by ',
                searchBy: 'Search Paient',
                viewProfile: 'View Profile',
                create: 'Create new patient',
                menu: 'Action menu',
            }
        },
        HEADER_MENU: {
            name: 'Header Menu',
            event: {
                support: 'Open Support ',
                notification: 'Open Notification',
                activityDashboard: 'Open Activity Dashboard',
                feedback: 'Open Feedback',
            }
        },
        LOGIN: {
            name: 'Access Login',
            event: {
                deeplink: 'Deeplink',
                normal: 'Directly',
            }
        },
        CLINICAL_VALIDATION: {
            name: 'Symptom Map',
            event: {
                symptomClick:   'Click on Symptom',
                symptomRating:  'Change Symptom Rating',
                fullfillTask:   'Fullfilled Task',
                diagnosisProposal: {
                    0: 'Review Diagnosis Proposal',
                    1: 'Accept Diagnosis Proposal',
                    2: 'Denied Diagnosis Proposal',
                    3:  'Click on Diagnosis Proposal',
                }
            }
        }
    };

    constructor(public secureApi: SecureApi,
                public http: HttpClient,
                public router: Router,
                public translateService: TranslateService,
                @Inject('baseSocketUrl') public baseSocketUrl: string,
                @Inject('baseSocketKey') public baseSocketKey: string,
                @Inject('googleId') private googleId,
                @Inject('mouseFlow') private mouseFlow,
                @Inject('weglotKey') public weglotKey: string,
                @Inject('storyBlokToken') public storyBlokToken: string,
                public authService: AuthService) {

        if (this.authService.isLoggedIn) {
            this.getUnreadNotifications();
            this.echo = this.initEcho();
            this.getPushNotification();
            this.getFileDownload();
            if (this.authService.isSuperAdmin) {
                this.checkFileDownload();
            }
            this.initPatientListObserver();
            this.initSessionLogoutObserver();
            if (environment.production) {
                this.initMouseflow();
            }
        }

        // device detection
        if (/Mobi|Android/i.test(navigator.userAgent) && window.screen.width < 640) {
            this.isMobile = true;
            this.deviceInnerHeight = window.innerHeight;
        }
        if (window.orientation == 0 || window.orientation == 180) {
            this.isPortrait = true;
        }

        this.authService.onLogoutComplete().subscribe(() => {
            clearTimeout(this.notificationTimeout);
            clearTimeout(this.dowloadFileTimeout);
            this.disableNotifications = [];
            this.patientsList = null;
            if (this.echo) {
                this.echo.disconnect();
            }
        });

        this.authService.onLoginComplete().subscribe(() => {
            this.getUnreadNotifications();
            this.echo = this.initEcho();
            this.getPushNotification();
            if (this.authService.isSuperAdmin) {
                this.getFileDownload();
                this.checkFileDownload();
            }
            this.initPatientListObserver();
            this.initSessionLogoutObserver();
        });

        this.defaultSystemLanguage = this.languages[0];
        this.activeLanguage = this.getCurrentSystemLanguage();
        this.emitter = this.publishSubscribeSubject.asObservable();

        storyblokInit({});
    }

    fetchUnreadNotification() {
        return this.secureApi.get('notifications/unread')
            .subscribe(response => {
                this.notifications = response['notifications'] || [];

                this.notifications = this.notifications.map(
                    notification => new NotificationItemModel(notification)
                );

                const filterUnreadNotifications = this.notifications.filter((notification) => {
                    return !notification.hasRead;
                });

                this.unreadNotifications = filterUnreadNotifications || [];

                // Handle legacy Dashboard for Admin and Super Admin
                this.firstTabNotification = response['first_tab_notifications'] || [];
                this.secondTabNotification = response['second_tab_notifications'] || [];
                this.notificationLoaded = true;

                this.notificationChange.next(true);
            });
    }

    //New
    getUnreadNotifications() {
        if (!this.authService.isLoggedIn) {
            return false;
        }
        return this.fetchUnreadNotification();
    }

    getFileDownload() {
        if (this.authService.isSuperAdmin) {
            this.echo.disconnect();
            this.echo.private('User.File.' + this.authService.user.id).listen('FinishedDownloadFile', (notification) => {
                this.checkFileDownload();
            });
        }
    }

    checkFileDownload() {
        this.secureApi.get('retrieve-data/check-download')
                    .subscribe(response => {
                        let items = response['items'] || [];
                        items.forEach(
                            item => {
                                this.secureApi.downloadFile('retrieve-data/download/' + item['id'])
                                    .subscribe(data => {
                                        setTimeout(() => {
                                            saveAs(data['body'], item['file_name']);
                                        }, 2000);
                                    });
                        });
                });
    }

    getPushNotification() {
        setTimeout(() => {
            this.echo.private('User.Notification.' + this.authService.user.id).notification((notification) => {
                this.getUnreadNotifications();
            });
        }, 500);
    }

    initPatientListObserver() {
        if (this.authService.isLoggedIn && !this.authService.isSuperAdmin && !this.authService.isClinicAdmin) {
            this.echo.private('Patient.List.' + this.authService.user.id).listen('PatientListChanged', (response) => {
                if (response.patient.clinic_id !== this.authService.user.clinic_id) {
                    return;
                }
                this.checkUpdatePatientList(response);
            });
        }
    }

    initSessionLogoutObserver() {
        if (this.authService.isLoggedIn) {
            this.echo.private('User.Logout.' + this.authService.user.id).listen('SessionLogout', (response) => {
                if (response.time !== this.authService.user.last_seen_at) {
                    this.authService.logout();
                    this.router.navigate(['login']);
                }
            });
        }
    }

    setPatientsListData(patientType, patients, patientsTotal = 0){
        this.patientsList[patientType] = patients;
        this.totalPatientsType[patientType] = patientsTotal;
    }

    checkUpdatePatientList(response) {
        if (this.loadedProgressOfPatientList !== this.patientLoadingProgess.load_all_patients) {
            this.waitingUpdatedPatients.push(response);
            return;
        }
        this.updatePatientList(response.action, response.patient);
        this.publish('updatePatient', { patient: response.patient, action: response.action });
    }

    updateWatingPatientList() {
        this.waitingUpdatedPatients.forEach(patientReponse => {
            this.updatePatientList(patientReponse.action, patientReponse.patient);
            this.publish('updatePatient', { patient: patientReponse.patient, action: patientReponse.action });
        });
        this.waitingUpdatedPatients = [];
    }

    updatePatientList(action, patient) {

        patient = this.formatPatientList(patient);

        if (!this.patientsList || !this.patientsList.activePatients || !patient.assignee_id) {
            return false;
        }

        let activePatients = patient.assignee_id == this.authService.user.id ? 'activePatients' : 'sharedPatients';

        switch (action) {
            case 'created' : {
                this.patientsList.activePatients.unshift(patient);
                return ;
            }

            case 'updated' : {
                return this.addPatientToList(activePatients, patient);
            }

            case 'shared' : {
                return this.addPatientToList('sharedPatients', patient);
            }

            case 'unshared' : {
                return this.removePatientFromList('sharedPatients', patient);
            }

            case 'deleted' : {
                if (patient.archived) {
                    return this.movePatientToAnotherList('archivedPatients', 'deletedPatients', patient);
                }
                return this.movePatientToAnotherList(activePatients, 'deletedPatients' , patient);
            }

            case 'restored' : {
                if (patient.archived) {
                    return this.movePatientToAnotherList('deletedPatients' , 'archivedPatients', patient);
                }
                return this.movePatientToAnotherList('deletedPatients' , activePatients, patient);
            }

            case 'archived' : {
                return this.movePatientToAnotherList(activePatients, 'archivedPatients', patient);
            }

            case 'dearchived' : {
                return this.movePatientToAnotherList('archivedPatients', activePatients, patient);
            }

            case 'transfered' : {
                this.removePatientFromList('activePatients', patient);
                this.removePatientFromList('sharedPatients', patient);
                break;
            }

            default : {
                return ;
            }
        }
    }

    removePatientFromList(list, patient) {

        this.patientsList[list] = this.patientsList[list].filter(item => {
            return  item.id != patient.id
        });

        this.totalPatientsType[list]--;
    }

    addPatientToList(list, patient) {

        //Remove then add to top of array
        this.patientsList[list] = this.patientsList[list].filter(item => {
            return  item.id != patient.id
        });
        this.patientsList[list].unshift(patient);
        this.totalPatientsType[list]++;
    }

    movePatientToAnotherList(oldList, newList, patient) {

        //Remove from old list and add to new list
        this.patientsList[oldList] = this.patientsList[oldList].filter(item => {
            return  item.id != patient.id
        });

        //We need to check if latest item is duplicated or not
        if (this.patientsList[newList].length > 0 && this.patientsList[newList][0].id == patient.id) {
            return;
        }

        this.patientsList[newList].unshift(patient);

        this.totalPatientsType[oldList]--;
        this.totalPatientsType[newList]++;
    }

    initEcho() {
        window.Pusher = Pusher;
        return new Echo({
            broadcaster: 'pusher',
            key: this.baseSocketKey,
            wsHost: this.baseSocketUrl,
            authEndpoint: '//' + this.baseSocketUrl + '/broadcasting/auth',
            wsPort: 6001,
            wssPort: 443,
            disableStats: true,
            cluster: 'mt1',
            enabledTransports: ['ws', 'wss'],
            auth: {
                headers: {
                    Authorization: 'Bearer ' + this.authService.getToken()
                },
            },
        });
    }

    updateNotifications(action: string) {
        const body = 'action=' + action;
        return this.secureApi.put('notifications', body, true);
    }

    onNotificationChange(): Observable<any> {
        return this.notificationChange.asObservable();
    }

    setLanguage(locale) {
        this.translateService.use(locale);
        this.activeLanguage = _.find(this.languages, {locale: locale});
        localStorage.setItem('language', locale);
    }

    getLanguage() {
        return localStorage.getItem('language');
    }

    markNotificationAsRead(notificationId: string) {
        const body = 'id=' + notificationId;
        return this.secureApi.post('notifications/mark-as-read', body, true)
            .subscribe(response => {
                this.fetchUnreadNotification();
            });
    }

    removeNotification(notification: any) {
        this.markNotificationAsRead(notification.id);
    }

    markNotificationAsDisable(notificationId: string) {
        this.notifications.forEach(
            notification => {
                if (notification.id === notificationId) {
                    notification['_disabled'] = true;
                }
            }
        )
    }

    /**
     * Parse error return from backend
     *
     * @param error
     */
    parseErrors(error) {
        const errors = error.error.error;
        const errorMessage = [];

        if (typeof errors === 'object') {
            for (const key in errors) {
                if (errors.hasOwnProperty(key)) {
                    errorMessage.push(errors[key][0]);
                }
            }
        } else if (typeof errors === 'string') {
            errorMessage.push(errors);
        }

        return errorMessage;
    }

    publish(channel: string, data: any): void {
        this.publishSubscribeSubject.next({
            channel: channel,
            data: data
        });
    }

    subscribe(channel: string, handler: ((value: any) => void)): Subscription {
        return this.emitter.pipe(
            filter(emission => emission.channel === channel),
            map(emission => emission.data),)
            .subscribe(handler);
    }

    setPatientsFilter(filterObject: Object) {
        return sessionStorage.setItem('patient-filter', JSON.stringify(filterObject));
    }

    getPatientsFilter() {
        return JSON.parse(sessionStorage.getItem('patient-filter'));
    }

    removePatientsFilter() {
        return sessionStorage.removeItem('patient-filter');
    }

    setCurrentSeStep(step) {
        this.SEStep.next(step);
    }

    getCurrentSeStep(){
        return this.SEStep.value;
    }

    regexpValidator(regexp, fieldName): ValidatorFn {
        return (control: AbstractControl): { [key: string]: boolean } | null => {
            if (control.value && !RegExp(regexp).test(control.value)) {
                return {[fieldName]: true};
            }
            return null;
        };
    }

    setIsNewVersionAvailable(isNewVersion: boolean) {
        this.isNewVersionAvailable.next(isNewVersion);
    }

    formatPatientList(patient) {

        if (patient.assignee) {
            patient['user_assignee_id'] = patient.assignee.id;
            patient['user_assignee_role'] = patient.assignee.role_id;
            patient['user_assignee_official_name'] = patient.assignee.official_name;
        }

        return patient;
    }

    // Send Event for Google Analytics
    sendGA($eCategory, $eAction, $eLabel = null, $eValue = null) {
        gtag('config', this.googleId);
        gtag('event', $eAction, {
            'event_category': $eCategory,
            'event_label': $eLabel,
            'value': $eValue
        });
    }

    initMouseflow(){
        window._mfq = window._mfq || [];
        let mouseFlowURL = this.mouseFlow;
        (function() {
            var mf = document.createElement("script");
            mf.type = "text/javascript"; mf.defer = true;
            mf.src = mouseFlowURL;
            document.getElementsByTagName("head")[0].appendChild(mf);
        })();
    }

    getLanguageCode(lang){
        const currentLang = _.find(this.languages, {locale: lang});
        return currentLang.code;
    }

    changeLanguage(lang){
        this.waitingTranslation = true;
        const currentSystemLanguage = this.getCurrentSystemLanguage();
        if(_.find(this.languages, {locale: lang.locale}) && lang.locale !== currentSystemLanguage.locale){
            return this.switchSystemLang(this.activeLanguage, lang);
        }
    }
    changeSRLanguage(data) {
        let route = `patient-questionnaire/change-language`
        return this.secureApi.post(route, data, false);
    }

    getCurrentSystemLanguage(){
        const locale = localStorage.getItem('language');
        let systemLanguage = _.find(this.languages, {locale : locale});
        if(!systemLanguage){
            systemLanguage = this.defaultSystemLanguage;
        }
        return systemLanguage;
    }

    switchSystemLang(origin, destination){
        let _self = this;
        this.isLoading = true;
        localStorage.setItem('language', destination.locale);
        this.activeLanguage = destination;

        const data = {
            'questionnaire_id': this.SEData?.patient_questionnaire_id,
            'language': destination.locale
        };
        this.changeSRLanguage(data).subscribe(value => {
            localStorage.removeItem('defaultLanguage');
            _self.isLoading = false;
            this.translateService.use(destination.locale);
            location.reload();
        });
    }

    setMeClinicFlatform(value) {
        localStorage.setItem('meClinicFlatform', value);
    }

    getMeClinicFlatform() {
        return localStorage.getItem('meClinicFlatform');
    }

    setMeClinicVoucher(value) {
        localStorage.setItem('meClinicVoucher', value);
    }

    getMeClinicVoucher() {
        return localStorage.getItem('meClinicVoucher');
    }

    setMeClinicId(value) {
        localStorage.setItem('meClinicId', value);
    }

    getMeClinicId() {
        if (localStorage.getItem('meClinicId')) {
            return localStorage.getItem('meClinicId');
        }
        return 0;
    }

    setMeClinicFolder(slug) {
        localStorage.setItem('meClinicFolder', slug);
        this.meClinicFolderChanged.next(slug);
    }



    setMeClinicFolderViaClinicId(clinicId) {
        this.callApiGetClinicConfig(clinicId).subscribe(res => {
            this.storeMeClinicFolder(res);
        });
    }

    callApiGetClinicConfig(clinicId) {
        let url = 'https://api.storyblok.com/v2/cdn/stories/?token=' + this.storyBlokToken + '&filter_query[clinicId][in]=' + clinicId;
        return this.http.get(url);
    }

    storeMeClinicFolder(res) {
        if (res['stories'].length > 0) {
            let fullSlug = res['stories'][0]['full_slug'].split('/');
            localStorage.setItem('meClinicConfig', JSON.stringify(res['stories'][0]['content']));

            let folder = fullSlug[0] == 'main' ? 'core' : fullSlug[0];
            this.setMeClinicFolder(folder);
        }
    }

    setMeClinicConfig(value) {
        localStorage.setItem('meClinicConfig', value);
    }

    getMeClinicConfig() {
        return localStorage.getItem('meClinicConfig');
    }

    getMeClinicFolder() {
        return localStorage.getItem('meClinicFolder');
    }

    getStoryBlokDataForClinic(url) {
        // Get follow language
        let storyBlokLang = '';
        let lang = this.translateService.currentLang;
        let languages = this.meLanguages;
        let storyBlokClinic =  this.getMeClinicFolder() ?? 'core';

        languages.forEach(language => {
            if (language.locale == lang) {
                storyBlokLang = language.code.toLocaleLowerCase();
            }
        });

        return this.getStoryBlokSlugByUrl(storyBlokClinic, url, storyBlokLang).pipe(
            catchError(error => this.getStoryBlokSlugByUrl('core', url, storyBlokLang)),
        );
    }

    getStoryBlokSlugByUrl(path, url, language) {
        url = 'https://api.storyblok.com/v2/cdn/stories/' + path + '/' + url + '?token=' + this.storyBlokToken + '&language=' + language + '&resolve_links=url';
        return this.http.get(url);
    }

    getStoryBlokSlugByUuid(uid) {
        // Get follow language
        let storyBlokLang = '';
        let lang = this.translateService.currentLang;
        let languages = this.meLanguages;

        languages.forEach(language => {
            if (language.locale == lang) {
                storyBlokLang = language.code.toLocaleLowerCase();
            }
        });

        let url = 'https://api.storyblok.com/v2/cdn/stories/' + uid + '/?find_by=uuid' + '&token=' + this.storyBlokToken + '&language=' + storyBlokLang;
        return this.http.get(url);
    }
}
