
<div bsModal #modal="bs-modal" class="modal fade modal--continue-later" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-md">
        <div class="modal-content">
            <i class="fas fa-times close-btn" (click)="hideModal()"></i>
            <div class="modal-body">
                <div class="row" *ngIf="!isIntroducePage">
                    <div class="col-xs-12 modal--continue-later__header">
                        {{ 'Confirmation' | translate }}
                    </div>
                    <div class="col-xs-12 modal-content">
                        {{ (isStudy ? 'STUDY continue later default' : 'Are you sure you want to continue later?') | translate }}
                    </div>
                    <div class="col-xs-12 modal-description">

                    </div>
                </div>
                <div class="row" *ngIf="isIntroducePage">
                    <div class="col-xs-12 modal--continue-later__header">
                        {{'Confirmation' | translate }}
                    </div>
                    <div class="col-xs-12 modal-content" *ngIf="step != 1">
                        {{ (isStudy ? 'STUDY Are you sure you want to continue later?' : 'Are you sure you want to continue later?') + ' - step' + step | translate }}
                    </div>
                    <div class="col-xs-12 modal-content" *ngIf="step == 1">
                        {{ (isStudy ? 'STUDY Are you sure you want to continue later?' : 'Are you sure you want to continue later?') | translate }}
                    </div>
                    <div class="col-xs-12 modal-description">
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-12">
                        <button type="button" class="btn btn-secondary mr-r--sm" (click)="hideModal()">
                            {{ 'Cancel' | translate }}
                        </button>

                        <button  type="button" class="btn btn-primary" (click)="confirm()">
                            {{ 'OK' | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>