
import {finalize} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SwitchClinicService } from './switch-clinic.service';
import { AuthService } from '../../core/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthComponent } from '../../auth/auth.component';
import { AppService } from '../../app.service';

@Component({
    selector: 'app-switch-clinic',
    templateUrl: './switch-clinic.component.html',
    styleUrls: ['../../auth/auth.component.scss'],
    providers: []
})

export class SwitchClinicComponent extends AuthComponent implements OnInit {
    successMessage = '';
    errorMessage = '';
    infoMessage = '';
    checkMaintenance: 0;
    userId: 0;
    lastURL = '';
    lastParams = {};
    loginData: any;
    user: any;
    clinicId: number;

    get isLoading() {
        return this.appService.isLoading;
    }

    set isLoading(isLoading) {
        this.appService.isLoading = isLoading;
    }

    constructor(
        public router: Router,
        public authService: AuthService,
        public translate: TranslateService,
        public appService: AppService,
        public route: ActivatedRoute,
        public service: SwitchClinicService,
        public fb: FormBuilder
    ) {
        super(router, authService, appService, translate);
    }

    ngOnInit() {
        super.ngOnInit();
        this.user = JSON.parse(localStorage.getItem('user'));
        if(this.user.clinics && this.user.clinics.length > 1){
            this.appService.multiClinics = this.user.clinics;
        }
        this.clinicId = this.authService.clinic['id'] || 0;

        //  Integrate DEEPLINK after login successfully
        this.route.params.subscribe(params => {
            if (params['action'] && params['id']) {
                this.lastURL = params['action'] + '/' + params['id'];
            }
            if (params['page']) {
                this.lastURL = params['page'];
            }
        });

        this.isLoading = false;
    }

    loginToClinic(){
        if(!this.clinicId){
            return false;
        }
        this.appService.isLoading = true;
        if(this.clinicId === this.authService.clinic['id']){
            return this.router.navigate([this.lastURL],
                {
                    queryParams: this.lastParams
                });
        }
        this.service.switchClinic(this.clinicId).subscribe(response => {
            this.authService.saveToLocalStorage(this.user['email'], response);
            return this.router.navigate([this.lastURL],
                {
                    queryParams: this.lastParams
                }).then(() => {
                window.location.reload();
            });
        });
    }
}
