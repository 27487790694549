import { NgModule } from '@angular/core';
import { TinymceModule } from 'angular2-tinymce';
import {MaintenanceComponent} from './maintenance.component';
import {BaseModule} from '../shared/base.module';

@NgModule({
    imports: [
        BaseModule,
        TinymceModule.withConfig({
            menubar: false,
            elementpath: false,
            toolbar: 'bold italic alignleft numlist bullist link formatselect'
        })
    ],
    declarations: [
        MaintenanceComponent
    ]
})

export class MaintenanceModule {
}
