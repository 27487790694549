
import {finalize} from 'rxjs/operators';
import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ChangePasswordService } from './change-password.service';
import { AuthService } from '../../core/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthComponent } from '../../auth/auth.component';
import { AppService } from '../../app.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['../auth.component.scss']
})

/**
 * New account activation page / Reset password page
 */
export class ChangePasswordComponent extends AuthComponent implements OnInit, AfterViewInit {
    // success;
    params: any;
    token: string;
    email: string;
    password: string;
    passwordConfirmation: string;
    errorMessage: string;
    successMessage: string;
    infoMessage: string;
    userPhoneNumber: string;
    errorMessagePasswordLength: string;
    errorMessagePasswordConfirmation: string;
    isVerifySMSStep = false;
    isResetPasswordStep = false;
    isCorrectPassword = false;
    isVerifyByEmail = false;
    regexSpecialCharacter = '[\x20-\x2F\x3A-\x40\x5B-\x60\x7b-\x7e\xA0-\xA3\xA5\xA7\xA9-\xB3\xB5-\xB7\xB9-\xBB\xBF-\xFF\u20AC\u0160\u0161\u017D\u017E\u0152\u0153\u0178]'; // ISO 8859-15
    @ViewChild('passwordField', { static: false }) passwordField: ElementRef;
    form: FormGroup;

    get isLoading() {
        return this.appService.isLoading;
    }

    set isLoading(isLoading) {
        this.appService.isLoading = isLoading;
    }

    constructor(
        public router: Router,
        public authService: AuthService,
        public appService: AppService,
        public translate: TranslateService,
        public changePasswordService: ChangePasswordService,
        public activatedRouter: ActivatedRoute,
        public fb: FormBuilder
        ) {
            super(router, authService, appService, translate);
        }

    ngOnInit() {
        localStorage.clear();
        this.appService.isLoading = false;
        super.ngOnInit();

        this.activatedRouter.params.subscribe(params => {
            this.params = params;
            if (params['token']) {
                this.token = params['token'];
            }
        });

        this.changePasswordService
        .verifyToken(this.token)
        .subscribe(response => {
            this.email = response['email'];
            this.isVerifyByEmail = response['is_verify_by_email']
        },
        error => {
            this.errorMessage = error.error.error;
            this.router.navigate(['/login'], {
                queryParams: {
                    infoMessage: error.error.error
                }
            });
        });

        this.form = this.fb.group({
            'password': ['', [Validators.minLength(8),this.appService.regexpValidator('\\d','haveNumber'),this.appService.regexpValidator(this.regexSpecialCharacter,'haveSpecialCharacter')]],
            'confirm_password': ['']
            }
        )
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.passwordField.nativeElement.focus();
        }, 20);
    }

    changePassword() {
        this.errorMessage = '';
        this.errorMessagePasswordLength = '';
        this.errorMessagePasswordConfirmation = '';

        if (this.form.invalid) {
            return;
        }

        if (this.password !== this.passwordConfirmation) {
            this.errorMessagePasswordConfirmation = 'The password confirmation does not match.';
            return;
        }

        this.isLoading = true;
        this.changePasswordService.changePassword(this.token, this.password, this.passwordConfirmation).pipe(
            finalize(() => this.isLoading = false))
            .subscribe(response => {
                this.successMessage = response['message'];
                this.isVerifySMSStep = response['isVerifySMS'];
                this.isResetPasswordStep = response['isResetPassword'];
                this.userPhoneNumber = response['userPhoneNumber'];
                this.infoMessage = response['message'];

                localStorage.setItem('email', this.email);
            },
            error => {
                this.errorMessage = error.error.error.password || error.error.error;
            });
    }
}
