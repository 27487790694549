
import {finalize} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PasswordService } from './password.service';
import { AuthService } from '../../core/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthComponent } from '../../auth/auth.component';
import { AppService } from '../../app.service';

@Component({
    selector: 'app-password',
    templateUrl: './password.component.html',
    styleUrls: ['../auth.component.scss']
})

/**
 * Forgot password page
 */
export class PasswordComponent extends AuthComponent implements OnInit {
    email: string;
    errorMessage: string;
    successMessage: string;

    get isLoading() {
        return this.appService.isLoading;
    }

    set isLoading(isLoading) {
        this.appService.isLoading = isLoading;
    }

    constructor(
        public router: Router,
        public authService: AuthService,
        public translate: TranslateService,
        public appService: AppService,
        public passwordService: PasswordService
    ) {
        super(router, authService, appService, translate);
    }

    ngOnInit() {
        super.ngOnInit();
    }

    onClickResetPassword() {
        this.errorMessage = '';

        if (!this.email) {
            return;
        }

        this.isLoading = true;

        this.passwordService
            .password(this.email).pipe(
            finalize(
                () => this.isLoading = false))
            .subscribe(
                response => {
                    this.successMessage = response['message'];

                    setTimeout(() => {
                        this.router.navigate(['login']);
                    }, this.NAVIGATE_TIMEOUT);

                    this.email = '';
                },
                error => {
                    this.errorMessage = error.error.error ||
                        'Your search did not return any results. Please try again with other information.';
                });
    }

    onInputFocused(event) {
        event.target.scrollIntoView();
        document.body.scrollBy(0, -40);
    }
}
