<div *ngIf="notificationItem" class="media notification-box__media"
        [class.has-read]="notificationItem.hasRead"
        (click)="onClickNotification(notificationItem)" [class.disabled]="notificationItem.isDisabled">
    <div class="media-left notification-box__media-left">
        <i aria-hidden="true" [ngClass]="notificationItem.cssClass"></i>
    </div>
    <div class="media-body notification-box__media-body">
        <div class="row">
            <div class="col-xs-12 notification-item__title ellipsis" [innerHTML]="notificationItem.title | translate: notificationItem.params">
            </div>
            <div class="col-xs-7 notification-item__name ellipsis">
                <ng-container *ngIf="!notificationItem.isWelcomeNotification; else klenicoTeamNotification">
                    {{ notificationItem.userName | translate }}
                </ng-container>
                <ng-template #klenicoTeamNotification>
                    {{ 'Klenico Team' | translate }}
                </ng-template>
            </div>
            <div class="col-xs-5 notification-item__date ellipsis">
                {{ notificationItem.displayTime | dateDiffTranslate }}
            </div>
        </div>
    </div>
</div>
