<div bsModal #modal="bs-modal" [class]="'modal fade modal-confirmation ' + params['class']" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-confirmation-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-body text-xs-center">
                <div class="row">
                    <div class="col-xs-12">
                        <div class="modal-icon">
                            <i class="{{params.icon}}" ng-class="icon" aria-hidden="true"></i>

                        </div>
                    </div>
                </div>
                <confirm-modal-body [responseMessage]="alertMessage" [params]="params"></confirm-modal-body>
                <div class="row">
                    <div class="col-xs-12 text-xs-right">
                            <button *ngIf="!alertMessage" type="button" class="btn btn-secondary " (click)="cancel(); $event.stopPropagation()">
                                <i class="fas fa-ban"></i>
                                {{ (params['cancelText'] || 'Cancel') | translate }}
                            </button>
                            <button *ngIf="alertMessage" type="button" class="btn btn-secondary " (click)="cancel(); $event.stopPropagation()">
                                <i class="fas fa-times"></i>
                                {{ 'Close' | translate }}
                            </button>
                            <button *ngIf="!alertMessage" type="button" class="btn btn-primary " (click)="submit(); $event.stopPropagation()">
                                <i class="fas fa-check"></i>
                                {{ (params['submitText'] || 'OK') | translate }}
                            </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>