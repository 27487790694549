import { Inject, Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { ENV } from '../../app.env';

@Injectable()
export class VerifyLoginTokenService {

    /**
     * Use for reset password and new account activation
     *
     * @param {Http} http
     * @param {string} baseUrl
     */
    constructor(public http: HttpClient, @Inject('baseUrl') public baseUrl: string) {
    }

    /**
     * @param {string} token
     * @returns {Observable<Response>}
     */
     verifyLoginToken(token, id) {
        let url = this.baseUrl + 'oauth/verify-token';

        let httpParams = new HttpParams()
            .append('token', token)
            .append('user_id', id)
            .append('version', ENV.buildVersion);

        let body = httpParams.toString();

        let headerOptions = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        };

        const httpOptions = {
            headers: new HttpHeaders(headerOptions)
        }

        return this.http.post(url, body, httpOptions);
    }
}
