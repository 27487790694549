import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterText',
    pure: true
})

export class FilterTextPipe implements PipeTransform {
    transform(items: any[], searchText: string, fieldName?: string): any[] {
        if (!items || !searchText) {
            return items;
        }

        const fieldNames = fieldName.split(',');

        searchText = searchText.toLowerCase();
        return items.filter(item => {
            let matched = false;

            fieldNames.forEach((field) => {
                let text = field ? item[field] : item;
                if (text == null) {
                    text = '';
                }
                if (text.toString().toLowerCase().indexOf(searchText.toLocaleLowerCase()) !== -1) {
                    matched = true;
                }
            });

            return matched;
        });
    }
}
