export const HELP_PATIENT_LIST                  = 'Patient List Help';
export const HELP_PATIENTS_ACTION               = 'Patient List Action Help';
export const HELP_DIAGNOSIS                     = 'Diagnosis Help';
export const HELP_SE_MAP                        = 'Self-evaluation Symptom Map Help';
export const HELP_DI_MAP                        = 'Diagnosis Interview Symptom Map Help';
export const HELP_QUESTIONNAIRE                 = 'Questionnaire Help';
export const HELP_DI_OVERVIEW                   = 'DI Overview Help';
export const HELP_DI_ACUTE                      = 'DI Acute Help';
export const HELP_DI_ACUTE_SE_LIST_HELP         = 'DI Acute SE list Help';
export const HELP_DI_ACUTE_EXPERT_LIST_HELP     = 'DI Acute Expert list Help';
export const HELP_DI_ACUTE_CHECK_BUTTON_HELP    = 'DI Acute Check button Help';
export const HELP_DI_AMNESTIC                   = 'DI Amnestic Help';
export const HELP_DI_AMNESTIC_SE_LIST_HELP      = 'DI Amnestic SE list Help';
export const HELP_DI_AMNESTIC_EXPERT_LIST_HELP  = 'DI Amnestic Expert list Help';
export const HELP_DI_AMNESTIC_CHECK_BUTTON_HELP = 'DI Amnestic Check button Help';
export const HELP_HOME_DOCTOR                   = 'Doctor Home Page Help';
export const HELP_DI_SYMPTOM                    = 'DI Symptom Help';
export const HELP_DI_RATING                     = 'DI Symptom Rating Help';
export const HELP_TEMPLATE_LIST                 = 'Template List Help';
export const HELP_CLINIC_PROFILE                = 'Clinic Profile Help';
export const TYPE_HELP_NOTIFICATION_DOCTOR      = 'Notification for Doctor Help';
export const TYPE_HELP_NOTIFICATION_ADMIN       = 'Notification for Admin Help';
export const TYPE_HELP_ASSIGNMENT_PATIENT       = 'Assignment Patient Help';
export const TYPE_HELP_SHARE_PATIENT            = 'Share Patient Help';
export const HELP_ACTIVITY_DASHBOARD       = 'Activity Dashboard Help';
