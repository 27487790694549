<section *ngIf="params && !params['action']" class="auth__form">
    <div class="row auth__header">
        <div class="col-xs-6">
            <a [routerLink]="['/login']" class="sprite auth__header__brand"></a>
        </div>
        <div class="col-xs-6 text-right">
            <a href="https://klenico.com" target="_blank" rel="noopener" class="auth__header__link">
                {{ 'Go to website' | translate}}
                <i class="fas fa-arrow-right mr-l--xs"></i>
            </a>
        </div>
    </div>

    <div class="auth__slider auth__slider--mobile hidden-sm-up">
        <img src="/assets/img/pages/login{{getBackground()}}.webp" width="100%" height="100%" class="auth__slider__image" />
    </div>

    <div class="auth__wrapper">
        <div class="auth__inner">
            <div class="card auth__form__card">
                <div class="card-header auth__form__card__header">
                    <div class="row">
                        <div class="col-xs-12">
                            <h2 class="page-title">
                                {{ 'Set password' | translate}}
                            </h2>
                        </div>
                    </div>
                </div>

                <div class="card-block mat-form" *ngIf="!isVerifySMSStep">
                    <div *ngIf="errorMessage" class="alert alert-danger" role="alert">
                        <span class="fas fa-exclamation-circle" aria-hidden="true"></span> {{ errorMessage | translate }}
                    </div>
                    <div [formGroup]="form" >
                        <div *ngIf="successMessage" class="alert alert-success" role="alert">
                            <span class="fas fa-exclamation-circle" aria-hidden="true"></span> {{ successMessage | translate }}
                        </div>
                        <div class="row form-group">
                            <div class="col-xs-12 font-se-paragraph">
                                <b>{{ 'Please set your password.' | translate }}</b>
                                {{'It should contain at least 6 characters, one of which should be a special character.' | translate}}
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-xs-12">
                                <p>{{ 'Email Address' | translate }}</p>
                                <p>
                                    <b>{{ email }}</b>
                                </p>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-xs-12">
                                <mat-form-field class="ps-content">
                                    <input [(ngModel)]="password" #passwordField (blur)="isCorrectPassword = !form.controls.password.errors" (focus)="isCorrectPassword = false" (keyDown.Enter)="changePassword()" type="password" matInput [placeholder]="'New Password' | translate"
                                        autocomplete="off" formControlName="password"/>
                                    <div *ngIf="!isCorrectPassword" class="form-text auth__password">
                                        <small  [ngClass]="!form.controls.password.value || form.controls.password.hasError('minlength') ? 'text-danger' : 'text-success' " >
                                            {{ 'At least 8 characters long' | translate }}
                                        </small>
                                        <small  [ngClass]="!form.controls.password.value || form.controls.password.hasError('haveNumber') ? 'text-danger' : 'text-success' " >
                                            {{ 'At least 1 number' | translate }}
                                        </small>
                                        <small [ngClass]="!form.controls.password.value || form.controls.password.hasError('haveSpecialCharacter') ? 'text-danger' : 'text-success' " >
                                            {{ 'At least 1 special character' | translate }}
                                        </small>
                                    </div>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-xs-12">
                                <mat-form-field class="ps-content">
                                    <input [(ngModel)]="passwordConfirmation" (keyDown.Enter)="changePassword()" type="password" matInput [placeholder]="'Confirm Password' | translate"
                                        autocomplete="off" formControlName="confirm_password"/>
                                    <small [hidden]="!errorMessagePasswordConfirmation" class="form-text text-danger">
                                        {{ errorMessagePasswordConfirmation | translate }}
                                    </small>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="form-group text-xs-center">
                            <button (click)="changePassword()" class="btn btn-primary btn-radius btn-dark" type="button">
                                {{ 'Change Password' | translate }}
                            </button>
                        </div>
                    </div>
                </div>
                <div *ngIf="isVerifySMSStep">
                    <mobile-verification-component [userMobileNumber]="userPhoneNumber" [enteredPassword]="password" [userEmail]="email" [isResetPasswordStep]="isResetPasswordStep"
                        [isVerifyByEmail]="isVerifyByEmail" [resetPasswordToken]="token"></mobile-verification-component>
                </div>

            </div>
        </div>
    </div>

    <div class="row auth__footer">
        <div class="col-xs-8">
            <p class="copyright exclude-translate">
                {{ appService.releasedVersion }} &copy; {{ thisYear }} Klenico Health AG
            </p>
        </div>
        <div class="col-xs-4">
            <div class="footer__language">
                <div class="form-group mat-select-pill">
                    <mat-select class="mat-select-pill__select"
                            [value]="appService.activeLanguage.locale"
                            (selectionChange)="appService.setLanguage($event.value)">
                            <mat-option *ngFor="let language of appService.languages"
                                        [value]="language.locale">
                                {{language.name | translate}}
                            </mat-option>
                    </mat-select>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="auth__slider hidden-xs-down">
    <img src="/assets/img/pages/login{{getBackground()}}.webp" width="100%" height="100%" class="auth__slider__image" />
</section>