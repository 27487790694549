import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../core/auth.service';
import { BaseService } from '../../base.service';
import { AppService } from '../../../app.service';
import { NotificationItemModel } from './notification-item.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'notification-item',
    templateUrl: 'notification-item.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['../notification.component.scss']
})

export class NotificationItemComponent {
    successMessage: string = '';
    isSelecting: boolean = false;

    @Input() notificationItem: NotificationItemModel;
    @Input() last = false;
    @Input() selectedNotificationId = '';

    @Output() notificationClicked = new EventEmitter<NotificationItemModel>();

    constructor(public authService: AuthService,
        public router: Router,
        public baseService: BaseService,
        public appService: AppService,
        public translateService: TranslateService,
        public route: ActivatedRoute
    ) {
    }

    ngOnInit() {
        if (!this.notificationItem) {
            return;
        }
        this.notificationItem.disabled  = this.appService.disableNotifications.indexOf(this.notificationItem.id) > -1;
    }

    /**
     * @param {NotificationItemModel} notification
     */
    onClickNotification(notification: NotificationItemModel) {
        this.isSelecting = true;

        this.notificationClicked.emit(notification);
    }

}
