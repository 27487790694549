<div  class="" tabindex="-1" role="dialog" aria-hidden="false">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body">
                <div class="sprite header__brand">
                </div>
                <div class="row">
                    <div class="form-group col-xs-12">
                        <div class="maintenance-text" [innerHTML]="infoMsg"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
