
import {throwError as observableThrowError,  BehaviorSubject ,  Observable } from 'rxjs';

import {tap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';

@Injectable()
export class RequestInterceptorService implements HttpInterceptor {

    isRefreshingToken: boolean = false;
    tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    constructor(private authService: AuthService,
        private router: Router) { }

    addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
        return req.clone({ setHeaders: { Authorization: 'Bearer ' + token } })
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(tap(
            (event: HttpEvent<any>) => {
                this.addToken(req, this.authService.getToken());
            },
            (error: any) => {
                if (error instanceof HttpErrorResponse) {
                    switch ((<HttpErrorResponse>error).status) {
                        case 401:
                            return this.handle401Error(req, next);
                        case 403:
                            return this.handle403Error(error);
                        case 400:
                            return this.handle400Error(error);
                        case 404:
                            return this.handle404Error(error);
                    }
                } else {
                    return observableThrowError(error);
                }
            }
        ));
    }

    private handle401Error(req: HttpRequest<any>, next: HttpHandler) {
        return this.authService.logout();
        // Temporary disable
        // if (!this.isRefreshingToken) {
        //     this.isRefreshingToken = true;
        //
        //     // Reset here so that the following requests wait until the token
        //     // comes back from the refreshToken call.
        //     this.tokenSubject.next(null);
        //
        //     return this.authService.refreshToken().pipe(
        //         finalize(() => {
        //             this.isRefreshingToken = false;
        //         }))
        //         .subscribe(
        //             response => {
        //                 const newToken = response['token'];
        //                 if (newToken) {
        //                     const user = JSON.parse(localStorage.getItem('user'));
        //                     if (user) {
        //                         user.token = newToken;
        //                         localStorage.setItem('user', JSON.stringify(user));
        //                     }
        //
        //                     this.tokenSubject.next(newToken);
        //                     return next.handle(this.addToken(req, newToken));
        //                 }
        //
        //                 // If we don't get a new token, we are in trouble so logout.
        //                 return this.authService.logout();
        //             },
        //             error => {
        //                 // If there is an exception calling 'refreshToken', bad news so logout.
        //                 return this.authService.logout();
        //             }
        //         );
        // } else {
        //     return this.tokenSubject.pipe(
        //         filter(token => token != null))
        //         .pipe(take(1))
        //         .pipe(switchMap(token => {
        //             return next.handle(this.addToken(req, token));
        //         }));
        // }
    }

    private handle403Error(error: HttpErrorResponse) {
        this.router.navigate(['errors'], {
            queryParams: {
                message: error['error']
            },
            skipLocationChange: true
        });
    }

    private handle400Error(error: HttpErrorResponse) {
        if (error.error && (error.error.error == 'token_not_provided' || error.error.error == 'token_invalid')) {
            localStorage.removeItem('user');
            this.router.navigate(['login']);
        }
    }

    private handle404Error(error: HttpErrorResponse) {
        if (error.error && error.error.error == 'user_not_found') {
            localStorage.removeItem('user');
            this.router.navigate(['login']);
        }
    }
}
