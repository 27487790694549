import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthService } from './auth.service';
import { MaintenanceService } from '../maintenance/maintenance.service';

@Injectable()
export class AuthMaintenance implements CanActivate {
    constructor(
        public authService: AuthService,
        public maintenanceService: MaintenanceService,
        public router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.authService.isSuperAdmin) {
            return true;
        }
        if (this.checkMaintenanceMode()) {
            return false;
        }
        return true;
    }

    checkMaintenanceMode() {
        if (this.authService.isSuperAdmin) {
            return false;
        }
        this.maintenanceService.read().subscribe(
            response => {
                if (response['maintain'].value) {
                    this.authService.maintenanceMode = true;
                    this.authService.maintenanceMessage = response['maintain'].text;
                    localStorage.clear();
                    this.router.navigate([''], {
                        queryParams: {
                        },
                        skipLocationChange: false
                    });
                    return true;
                }
                return false;
            }
        );
    }
}
