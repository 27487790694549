<div *ngIf="!isLoading" class="auth__form">
    <div class="row auth__header">
        <div class="col-xs-6">
            <span class="sprite auth__header__brand"></span>
        </div>
        <div class="col-xs-6 text-right">
            <a href="https://klenico.com" target="_blank" rel="noopener" class="auth__header__link">
                {{ 'Go to website' | translate}}
                <i class="fas fa-arrow-right mr-l--xs"></i>
            </a>
        </div>
    </div>


    <div class="auth__slider auth__slider--mobile hidden-sm-up">
        <img src="/assets/img/pages/login{{getBackground()}}.webp" width="100%" height="100%" class="auth__slider__image" />
    </div>

    <div class="auth__wrapper">
        <div class="auth__inner">
            <div class="card auth__form__card">
                <div class="card-block mat-form">
                    <div *ngIf="errorMessage" class="alert alert-danger" role="alert">
                        <span class="fas fa-exclamation-circle" aria-hidden="true"></span> {{ errorMessage | translate }}
                    </div>
                    <div>
                        <div *ngIf="successMessage" class="alert alert-success" role="alert">
                            <span class="fas fa-exclamation-circle" aria-hidden="true"></span> {{ successMessage | translate }}
                        </div>
                        <div class="row form-group">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row auth__footer">
        <div class="col-xs-8">
            <p class="copyright exclude-translate">
                {{ appService.releasedVersion }} &copy; {{ thisYear }} Klenico Health AG
            </p>
        </div>
        <div class="col-xs-4">
            <div class="form-group header--self-evaluation__language">
                <mat-select class="header--self-evaluation__language__select"
                            [value]="appService.activeLanguage.locale"
                            (selectionChange)="appService.setLanguage($event.value)">
                    <mat-option *ngFor="let language of appService.languages"
                                [value]="language.locale">
                        {{language.name | translate}}
                    </mat-option>
                </mat-select>
            </div>
        </div>
    </div>
</div>


<section class="auth__slider hidden-xs-down">
    <img src="/assets/img/pages/login{{getBackground()}}.webp" width="100%" height="100%" class="auth__slider__image" />
</section>