import { NgModule } from '@angular/core';
import { BaseModule } from '../shared/base.module';
import { AuthRoutesModule } from './auth.routes';
import { AuthComponent } from './auth.component';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { PasswordComponent } from './forgot-password/password.component';
import { LoginService } from './login/login.service';
import { PasswordService } from './forgot-password/password.service';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ChangePasswordService } from './change-password/change-password.service';
import { FormsModule } from '@angular/forms';
import { MobileVerificationComponent } from './change-password/mobile-verification/mobile-verification.component';
import { SmsVerificationComponent } from './login/sms-verification/sms-verification.component';
import { SmsService } from './sms.service';

import { MATERIALS } from '../app.materials';
import { AuthSelfEvaluationComponent } from './self-evaluation/auth-self-evaluation.component';
import { RequestActionService } from './request-action/request-action.service';
import { RequestActionComponent } from './request-action/request-action.component';
import { DialogModule } from '../dialog/dialog.module';
import { VerifyLoginTokenService } from './verify-login-token/verify-login-token.service';
import { VerifyLoginTokenComponent } from './verify-login-token/verify-login-token.component';

@NgModule({
    imports: [
        MATERIALS,
        BaseModule, 
        AuthRoutesModule, 
        FormsModule,
        DialogModule
    ],
    declarations: [
        AuthComponent,
        LoginComponent,
        LogoutComponent,
        PasswordComponent,
        RequestActionComponent,
        VerifyLoginTokenComponent,
        ChangePasswordComponent,
        MobileVerificationComponent,
        SmsVerificationComponent,
        AuthSelfEvaluationComponent
    ],
    providers: [
        LoginService,
        PasswordService,
        RequestActionService,
        VerifyLoginTokenService,
        ChangePasswordService,
        SmsService
    ],
})

export class AuthModule {
}
