import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ENV } from '../app.env';

@Injectable()
export class SmsService {

    constructor(public http: HttpClient, @Inject('baseUrl') public baseUrl: string) {
    }

    /**
     * @param url
     * @param urlSearchParams
     * @returns {Observable<Response>}
     */
    private sendRequest(url, urlSearchParams) {
        let headerOptions = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        };

        let body = urlSearchParams.toString();

        const httpOptions = {
            headers: new HttpHeaders(headerOptions)
        };

        return this.http.post(url, body, httpOptions);
    }

    /**
     * Request to send sms on new account activation form
     *
     * @param formData
     * @returns {Observable<Response>}
     */
    requestToSendSms(formData) {
        let url = this.baseUrl + 'oauth/sms';

        let paramBuilder = new URLSearchParams();
        paramBuilder.append('phone_number', formData.mobile);
        let body = paramBuilder.toString();

        return this.sendRequest(url, body);
    }

    /**
     * Handle resend sms on all form
     * @param userIdOrEmail
     * @returns {Observable<Response>}
     */
    resendLoginSms(userIdOrEmail) {
        if (!userIdOrEmail) {
            return;
        }

        let url = this.baseUrl + 'oauth/resend-sms';
        let urlSearchParams = new URLSearchParams(); // handle the + sign

        urlSearchParams.append('userIdOrEmail', userIdOrEmail);

        return this.sendRequest(url, urlSearchParams);
    }

    /**
     * @param formData
     * @param phoneNumber
     * @param password
     * @param token
     * @returns {Observable<Response>}
     */
    verifySmsCode(formData, phoneNumber, password, token) {
        let endPoint = this.baseUrl + 'oauth/verify-sms';
        let urlSearchParams = new URLSearchParams();

        urlSearchParams.append('smsCode', formData.smsCode);

        /**
         * @todo: Check for special char in the password and plus sign on form number
         */
        urlSearchParams.append('enteredPassword', password.replace(/\+/gi, '%2B'));
        urlSearchParams.append('passwordResetToken', token);
        urlSearchParams.append('phoneNumber', phoneNumber);

        return this.sendRequest(endPoint, urlSearchParams);
    }

    /**
     * Verify sms code login
     */
    verifyLoginSmsCode(formData) {
        if (!formData.userId || !formData.smsCode) {
            return;
        }

        let endPoint = this.baseUrl + 'oauth/verify-sms-login';

        let urlSearchParams = new URLSearchParams();
        urlSearchParams.append('userId', formData.userId);
        urlSearchParams.append('smsCode', formData.smsCode);
        urlSearchParams.append('version', ENV.buildVersion);

        return this.sendRequest(endPoint, urlSearchParams);
    }
}
