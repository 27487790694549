import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'rating'
})

export class RatingPipe implements PipeTransform {
    selectionList = {
        mild: {
            name: 'mild',
            rating: 16.66,
            class: 'rating-low'
        },
        moderate: {
            name: 'moderate',
            rating: 50,
            class: 'rating-middle'
        },
        severe: {
            name: 'severe',
            rating: 83.33,
            class: 'rating-high'
        },
        none: {
            name: 'none',
            rating: 1,
            class: 'rating-none'
        },
        anamnestic: {
            name: 'anamnestic',
            rating: 1,
            class: 'anamnestic'
        }
    };

  transform(value: string, args: string[]): string {
    if(!value){
      return '';
    }
      switch (value) {
          case this.selectionList.none.class :
              return this.selectionList.none.name;
          case this.selectionList.anamnestic.class :
              return 'anamnestic-only';
          case this.selectionList.moderate.class :
              return this.selectionList.moderate.name;
          case this.selectionList.mild.class :
              return this.selectionList.mild.name;
          case this.selectionList.severe.class :
              return this.selectionList.severe.name;

          default :
              return this.selectionList.none.name;
      }
  }
}
