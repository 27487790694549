import { Component, OnInit, DoCheck, ViewChild } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { Location } from '@angular/common';
import { FeedbackService } from '../feedback.service';
import { BaseService } from '../../shared/base.service';
import { AuthService } from '../../core/auth.service';
import { AppService } from '../../app.service';
import { AuthComponent } from '../../auth/auth.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-feedback',
  templateUrl: './self-evaluation-feedback.component.html',
  styleUrls: ['../../self-evaluation/self-evaluation.component.scss'],
  providers: [FeedbackService, BaseService]
})
export class SelfEvaluationFeedbackComponent extends AuthComponent implements OnInit {

    scored : number = 0;
    type : number = 3;
    comment : string = '';
    userId : number = 0;
    showMessage = false;
    scoredList = [1,2,3,4,5,6,7,8,9,10];

    constructor(
        public authService: AuthService,
        public router: Router,
        public baseService: BaseService,
        public appService: AppService,
        public feedbackService: FeedbackService,
        public fb: FormBuilder,
        public location: Location,
        public translateService : TranslateService,
        public route: ActivatedRoute,
        ) { 
        super(router, authService, appService, translateService);
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.route.queryParams.subscribe(params => {
            if (params && params.score && params.user_id && params.type && params.locale) {
                this.scored = params.score;
                this.type = params.type;
                this.userId = params.user_id;
                this.translateService.use(params.locale);
            }
        });
    }

  

    submit() {
        let body = {
            'comment' : this.comment,
            'rated' : this.scored,
            'type' : this.type,
            'user_id' : this.userId
        }

        this.appService.isLoading = true;
        this.feedbackService.sendSRFeedback(body).subscribe( response => {
            this.appService.isLoading = false;
            this.showMessage = true;
        });
        
    }

}