import {Component} from '@angular/core';
import {BaseDialogComponent} from '../base.dialog.component';
import {DialogInterface} from '../dialog.interface';

@Component({
    templateUrl: './confirm.component.html'
})
export class ConfirmComponent extends BaseDialogComponent implements DialogInterface {

}
