<nav *ngIf="pages.length" class="text-xs-center">
    <ul class="pagination">
        <li class="page-item">
            <button class="btn btn-link page-link" [disabled]="!hasPrevious()" (click)="goto(current - 1)">
                <i class="fas fa-angle-left" aria-hidden="true"></i>
                {{ previousText | translate }}
            </button>
        </li>
        <li class="page-item" *ngFor="let page of pages; trackBy:pageTrackBy"
            [ngClass]="{ 'active' : page.number === current }">
            <button class="btn btn-link page-link" (click)="goto(page.number)" [disabled]="page.disabled"
                    [ngClass]="{'small-padding': isSmallPadding}">
                {{ page.number }}
            </button>
        </li>
        <li class="page-item">
            <button class="btn btn-link page-link" [disabled]="!hasNext()" (click)="goto(current + 1)">
                {{ nextText | translate }}
                <i class="fas fa-angle-right" aria-hidden="true"></i>
            </button>
        </li>
    </ul>
</nav>
