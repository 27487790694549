<ng-template #popoverTemplate>
    <div class="popover-container" [innerHtml]="popoverContent || popoverService.getHelpTemplateTranslation(templateType)"></div>
    <button aria-label="Close" class="btn btn-link close" type="button" (click)="pop.hide();$event.stopPropagation();">
    </button>
</ng-template>
<i [class]="'far fa-question-circle popover-icon ' + iconClass" 
    [class.flex-center]="isFlex"
    [style.font-size]="fontSize"
    [style.margin-left]="margin"
    [ngStyle]="styles"
    [container]="container"
    (click)="$event.stopPropagation()"
    [containerClass]="containerClass + ' help-btn-all'" [popover]="popoverTemplate"
    [popoverTitle]="'Help' | translate"
    [outsideClick]="true" [placement]="placement" #pop="bs-popover">
</i>
