import {Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import { FormGroup, NgForm, Validators } from '@angular/forms';
import { AuthService } from '../../core/auth.service';
import { HELP_CLINIC_PROFILE } from '../../shared/constants/help-buttons';
import { PopoverService } from '../../shared/popover/popover.service';

@Component({
    selector: 'clinics-modal-component',
    templateUrl: './clinics-modal.component.html',
    styleUrls: ['./clinics-modal.component.scss'],
})
export class ClinicsModalComponent implements OnChanges {
    @Input() params: Object;
    @Input() formLoading: boolean;
    @Input() isUploading: boolean;
    @Input() form: FormGroup;
    @Input() errorMessage: string[];
    @Input() responseMessage: string;
    @Input() formSubmitted: boolean;
    @Input() fullPath: string;
    @Input() languages: Object[];
    @Input() clinicUsers: Object[];
    @Input() countries: Object[];
    @Input() authService: AuthService;
    @Input() token: string;
    @Input() accessControl: Object[];
    @Input() helpRawData = '';

    @Output() hideModal = new EventEmitter<any>();
    @Output() submit = new EventEmitter<any>();
    @Output() avatarUploading = new EventEmitter<any>();
    @Output() logoUploaded = new EventEmitter<any>();
    @Output() openFormModal = new EventEmitter<any>();
    @Output() activateConfirmationModal = new EventEmitter<any>();
    @Output() delete = new EventEmitter<any>();
    @Output() public deleteLogoEmit: EventEmitter<any> = new EventEmitter<any>();

    categories = [
        {
            name : 'Key customer',
            id : 2
        },
        {
            name : 'Customer',
            id : 1
        },
        {
            name : 'Research partner',
            id : 3
        },
        {
            name : 'Test account',
            id : 4
        },
        {
            name : 'Internal',
            id : 5
        },
        {
            name : 'Archive',
            id : 0
        },
        {
            name : 'B2C',
            id : 6
        },
        {
            name : 'B2B2C',
            id : 7
        },
    ];
    registrationTemplates = [
        {
            name : 'Default',
            value : '' 
        },
        {
            name : 'Sanitas',
            value : 'Sanitas' 
        },
        {
            name : 'Concordia',
            value : 'Concordia' 
        },
    ];
    HELP_CLINIC_PROFILE = HELP_CLINIC_PROFILE;

    @ViewChild('formDirective') private formDirective: NgForm;

    constructor(public popoverService: PopoverService) { }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['fullPath'] && this.fullPath) {
            this.form.controls.logo.setValue(this.fullPath);
        }

        if (changes['params'] && this.formDirective) {
            this.formDirective.resetForm();
        }

        // Check required sr success page url
        this.changeSRMethod();  

        // set helpTemplates
        this.popoverService.setHelpContent(this.helpRawData);
    }

    removeLogo() {
        this.deleteLogoEmit.emit();
    }

    changeSRMethod() {
        if (!this.form) {
            return;
        }

        if (this.form.controls.show_different_sr_success_page.value) {
            this.form.controls['sr_success_url'].setValidators([<any>Validators.required]);
        } else {
            this.form.controls['sr_success_url'].clearValidators();
        }
        this.form.controls['sr_success_url'].updateValueAndValidity();
    }

    onInputFocused(event, formGroup: any, isBottomField?: boolean) {
        let uagent = navigator.userAgent.toLowerCase();
        if (!(/safari/.test(uagent) && !/chrome/.test(uagent))) {
            // wait for the virtual keyboard to scroll up so that the scroll height can be updated correctly
            setTimeout(() => {
                if (isBottomField) {
                    document.body.scroll({
                        top: document.body.scrollHeight,
                        left: 0,
                        behavior: 'smooth'
                    });
                } else {
                    document.body.scroll({
                        top: 0,
                        left: 0,
                        behavior: 'smooth'
                    });
                }

                let modalEle = document.getElementsByClassName('modal')[0] as HTMLElement;
                modalEle.scroll({
                    top: formGroup.offsetTop,
                    left: 0,
                    behavior: 'smooth'
                });
            }, 2000);
        }
    }

    hideClinicEditModal() {
        this.fullPath = '';
        this.responseMessage = '';
        this.hideModal.emit();
    }
}
