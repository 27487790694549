import {Injectable} from '@angular/core';
import {SecureApi} from '../core/secure.api';

@Injectable()
export class MaintenanceService {
    constructor(public secureApi: SecureApi) {
    }

    read(id = 1) {
        return this.secureApi.get('settings/check-maintenance/');
    }
}