<div class="card-block sms_form">
    <div *ngIf="errorMessage" class="alert alert-danger" role="alert">
        <span class="fas fa-exclamation-circle" aria-hidden="true"></span>{{ errorMessage | translate }}
        <br>
        <div class="sms_form__resend" *ngIf="!isValidSmsCode">
            <button class="btn btn-secondary btn-sm " type="submit"
                    (click)="onResendSmsClick()"
                    [disabled]="isDisableResendSmsButton">
                <i class="fas fa-undo"></i>{{ 'Verification number' | translate }}
            </button>
        </div>
    </div>
    <div *ngIf="infoMessage" class="alert alert-info" role="alert">
        <span class="fas fa-exclamation-circle" aria-hidden="true"></span>{{ infoMessage | translate }}
    </div>
    <div *ngIf="successMessage" class="alert alert-success" role="alert">
        <span class="fas fa-exclamation-circle" aria-hidden="true"></span>{{ successMessage | translate }}
    </div>
    <p class="form-group">
        {{ smsMessage | translate }} {{ hiddenPhoneNumber }}
    </p>
    <form [formGroup]="smsCodeForm" (ngSubmit)="onSubmitSmsCode(smsCodeForm.value, smsCodeForm.valid)" novalidate class="mat-form">
        <div class="row form-group">
            <div class="col-xs-12">
                <mat-form-field class="ps-content">
                    <input formControlName="smsCode" type="text" #mTanCode matInput [placeholder]="'Verification number' | translate" autocomplete="off"/>
                    <small class="form-text text-danger" [hidden]="smsCodeForm.controls.smsCode.valid
                    || !smsCodeForm.controls.smsCode.touched
                    || (smsCodeForm.controls.smsCode.pristine && !submitted)">
                        {{ 'Verification number is required.' | translate }}
                    </small>
                </mat-form-field>
            </div>
        </div>
        <div class="row form-group">
            <div class="col-xs-6 offset-xs-3">
                <button class="btn btn-primary btn-radius btn-dark" type="submit" [disabled]="isDisableResendSmsButton">
                    {{ 'Verify' | translate }}
                </button>
            </div>
        </div>
    </form>
</div>
