import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import {HttpResponse} from '@angular/common/http';
import { AuthService } from './core/auth.service';

@Injectable()
export class CustomErrorHandle implements ErrorHandler {

    constructor(public injector: Injector) {
    }

    get router(): Router {
        return this.injector.get(Router);
    }

    get authService(): AuthService {
        return this.injector.get(AuthService);
    }

    navigate(route) {
        return this.router.navigate([route]);
    }

    handleError(error) {
        if (error instanceof HttpResponse) {
             if (error.status === 503) {
                this.authService.maintenanceMessage = error['message'];
                this.authService.maintenanceMode = true;
                localStorage.clear();
            } else {
                this.router.navigate(['errors'], {
                    queryParams: {
                        message: error['error']
                    },
                    skipLocationChange: true
                });
            }
        }
        console.error(error);
    }
}
