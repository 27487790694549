import { Routes } from '@angular/router';
import { AuthGuard } from './core/auth.guard';
import { ErrorsComponent } from './errors';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { ProfileComponent } from './profile/profile.component';
import { AuthMaintenance } from './core/auth.maintenance';
import { UsersComponent } from './users/users.component';
import { SwitchClinicComponent } from './home/switch-clinic/switch-clinic.component';
import { AuthUnguard } from './core/auth.unguard';

export const ROUTES: Routes = [
    {
        path: '',
        redirectTo: '/home',
        pathMatch: 'full',
    },
    {
        path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'users',
        component: UsersComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'Users management'
        }
    },
    {
        path: 'templates',
        loadChildren: () => import('./templates/templates.module').then(m => m.TemplatesModule),
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'Text Templates'
        }
    },
    {
        path: 'notifications',
        loadChildren: () => import('./notifications/notifications.module').then(m => m.NotificationsModule),
        canActivate: [AuthGuard],
        data: {
            // breadcrumb: 'Notifications'
        }
    },
    {
        path: 'translations',
        loadChildren: () => import('./translations/translations.module').then(m => m.TranslationsModule),
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'Translations Management'
        }
    },
    {
        path: 'b2c-patients',
        loadChildren: () => import('./home/b2c-admin/b2c-admin.module').then(m => m.B2CAdminModule),
        canActivate: [AuthGuard],
        data: {
            name: 'b2c-patients.admin-index',
            breadcrumb: 'B2C Admin Dashboard',
        }
    },
    {
        path: 'clinics',
        loadChildren: () => import('./clinics/clinics.module').then(m => m.ClinicsModule),
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'Clinic Management'
        }
    },
    {
        path: 'self-report-setting',
        loadChildren: () => import('./self-report-setting/self-report-setting.module').then(m => m.SelfReportSettingModule),
        canActivate: [AuthGuard],
        data: {
        }
    },
    {
        path: 'service-provider-setting',
        loadChildren: () => import('./service-provider-setting/service-provider-setting.module').then(m => m.ServiveProviderSettingModule),
        canActivate: [AuthGuard],
        data: {
        }
    },
    {
        path: 'patients',
        loadChildren: () => import('./patients/patients.module').then(m => m.PatientsModule),
        canActivate: [AuthGuard],
        data: {
            name: 'patients.index',
            breadcrumb: 'Patient list',
        }
    },
    {
        path: 'patient',
        loadChildren: () => import('./patient/patient.module').then(m => m.PatientModule),
        canActivate: [AuthGuard],
        data: {
            name: 'patient.index',
            breadcrumb: 'Patient list'
        }
    },
    {
        path: 'profile',
        component: ProfileComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'Profile'
        },
        outlet: 'popup'
    },
    {
        path: 'self-evaluation/:token',
        loadChildren: () => import('./self-evaluation/self-evaluation.module').then(m => m.SelfEvaluationModule),
        data: {
            breadcrumb: 'Self Evaluation',
            page: 'se'
        }
    },
    {
        path: 'repeat-self-evaluation/:token',
        loadChildren: () => import('./self-evaluation/repeat-self-evaluation.module').then(m => m.RepeatSelfEvaluationModule),
        data: {
            breadcrumb: 'Self Evaluation',
            page: 'repeatedSE'
        }
    },
    {
        path: 'support',
        loadChildren: () => import('./support/support.module').then(m => m.SupportModule),
        canActivate: [AuthGuard, AuthMaintenance],
        data: {
            breadcrumb: 'Support-Center',
            breadcrumbSpecific: 'Support-Center',
        }
    },
    {
        path: 'privacy-statement',
        loadChildren: () => import('./privacy-statement/privacy-statement.module').then(m => m.PrivacyStatementModule),
        canActivate: [AuthMaintenance],
        data: {
            breadcrumb: 'Privacy Statement'
        }
    },
    {
        path: 'terms-condition',
        loadChildren: () => import('./terms-condition/terms-condition.module').then(m => m.TermsConditionModule),
        canActivate: [AuthMaintenance],
        data: {
            breadcrumb: 'General terms and conditions'
        }
    },
    {
        path: 'legal-notice',
        loadChildren: () => import('./legal-notice/legal-notice.module').then(m => m.LegalNoticeModule),
        canActivate: [AuthMaintenance],
        data: {
            breadcrumb: 'Legal Notice'
        }
    },
    {
        path: 'device-label',
        loadChildren: () => import('./device-label/device-label.module').then(m => m.DeviceLabelModule),
        canActivate: [AuthMaintenance],
        data: {
            breadcrumb: 'Device Label'
        }
    },
    {
        path: 'errors',
        component: ErrorsComponent
    },
    {
        path: 'maintenance',
        component: MaintenanceComponent
    },
    {
        path: 'settings',
        component: MaintenanceComponent
    },
    {
        path: 'live-questionnaire',
        loadChildren: () => import('./live-questionnaire/live-questionnaire.module').then(m => m.LiveQuestionnaireModule),
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'Live-Questionnaires',
            page: 'live-questionnaire'
        }
    },
    {
        path: 'activity-dashboard',
        loadChildren: () => import('./activity-dashboard/activity-dashboard.module').then(m => m.ActivityDashboardModule),
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'Activities',
            page: 'activity-dashboard'
        }
    },
    {
        path: 'patient-registration',
        loadChildren: () => import('./patient-registration/patient-registration.module').then(m => m.PatientRegistrationModule),
        data: {
            breadcrumb: 'Patient registration',
            page : 'patient-registration'
        }
    },
    {
        path: 'verify-patient-registration/:token',
        loadChildren: () => import('./verify-patient-registration/verify-patient-registration.module').then(m => m.VerifyPatientRegistrationModule),
        data: {
            breadcrumb: 'Verify Patient registration',
            page : 'patient-registration'
        }
    },
    {
        path: 'retrieve-data/download/:downloadId/:token/:fileName',
        loadChildren: () => import('./verify-export-download/verify-export-download.module').then(m => m.VerifyExportDownloadModule),
        data: {
            breadcrumb: 'Verify Export Download',
            page : 'super-admin-export-download'
        }
    },
    {
        path: 'clinic-sr-config',
        loadChildren: () => import('./clinic-self-report-config/clinic-self-report-config.module').then(m => m.ClinicSelfReportConfigModule),
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'Clinic SR config'
        }
    },
    {
        path: 'clinic-service-provider',
        loadChildren: () => import('./clinic-service-provider/clinic-service-provider.module').then(m => m.ClinicServiceProviderModule),
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'Clinic Service provider'
        }
    },
    {
        path: 'switch-clinic',
        component: SwitchClinicComponent,
        data: {
            page: 'change-clinic'
        }
    },
    {
        path: 'insurance-dashboard',
        loadChildren: () => import('./home/insurance-parner/tokens/tokens.module').then(m => m.TokensModule),
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'Tokens Management'
        }
    },
    {
        path: 'me', 
        loadChildren: () => import('./me/me.module').then(m => m.MeModule),
        data: {
            page : 'klenico-me'
        }
    },
    {
        path: '**',
        redirectTo: '/errors',
        pathMatch: 'full'
    }
];
