// angular
import { Component, ElementRef, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// services
import { AuthSelfEvaluationService } from './auth-self-evaluation.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../core/auth.service';
import { AppService } from '../../app.service';


@Component({
    selector: 'auth-self-evaluation-component',
    templateUrl: './auth-self-evaluation.component.html',
    styleUrls: ['./auth-self-evaluation.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        AuthSelfEvaluationService,
    ]
})
export class AuthSelfEvaluationComponent implements OnInit {
    code: string;
    errorMessage: string;
    successMessage: string;
    lang = 'de_CH';

    constructor(
        public elementRef: ElementRef,
        public router: Router,
        public route: ActivatedRoute,
        public authSelfEvaluationService: AuthSelfEvaluationService,
        public translate: TranslateService,
        public authService: AuthService,
        public appService : AppService
    ) {
    }

    ngOnInit() {
        this.authService.logout();
        this.appService.isLoading = false;
        this.route.queryParams.subscribe(params => {
           this.code = params['code'];
           if (params['lang']) {
                // Wait language data load 
                setTimeout(() => {
                    this.appService.setLanguage(params['lang']);
                }, 1000);
           }
        });
    }

    authenticate() {
        let language = this.getLanguage();
        this.authSelfEvaluationService.login(this.code, language)
            .subscribe(response => {
                if (response['success']) {
                    let url = 'self-evaluation/';
                    if (response['is_repeated']) {
                        url = 'repeat-self-evaluation/';
                    }
                    return this.router.navigate([url + response['token']]);
                }
            },
            error => {
                this.errorMessage = 'Access code not found. Please recheck your input. If you have any problems, please contact your health partner.';
        });
    }


    addSpace(event) {

        if (event.keyCode === 8) { //Backspace
            return;
        }

        let value = event.target.value;
        if (value.length === 4) {
            event.target.value = value + ' ';
        }

        if (value.length > 4 && value.indexOf(' ') < 0) {

            if (value.length >= 9) { // Handle case copy code with length >= 9
                value = value.slice(0,8);
            }
            event.target.value = [value.slice(0, 4),' ', value.slice(4)].join('');
        }
    }

    getLanguage() {
        const selectedLang = this.appService.getLanguage();
        const languages = JSON.parse(localStorage.getItem('languages'));
        const language = languages.find(item => item.locale === selectedLang);

        if (!language) {
            return 1;
        }
        return language.id;
    }
}
