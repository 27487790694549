import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { DynamicViewComponent } from './dynamic-view.component';
import { SlideDashboardInformationModule } from '../../dashboard-information/slides/slide-dashboard-information.module';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        SlideDashboardInformationModule
    ],
    declarations: [
        DynamicViewComponent
    ],
    exports: [
        DynamicViewComponent
    ]
})

export class DynamicViewModule { }