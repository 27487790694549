import {Injectable} from '@angular/core';
import {SecureApi} from '../core/secure.api';

@Injectable()
export class LegalNoticeService {
    constructor(public secureApi: SecureApi) {
    }

    getContent() {
        return this.secureApi.get('legal-notice');
    }
}
