import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { PopoverComponent } from './popover.component';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { PopoverService } from './popover.service';

@NgModule({
    declarations: [PopoverComponent],
    exports: [
        PopoverComponent
    ],
    imports: [
        TranslateModule,
        CommonModule,
        PopoverModule.forRoot()
    ],
    providers: [
        PopoverService
    ]
})
export class SharedPopoverModule { }
