import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../core/auth.service';
import { BaseService } from '../base.service';
import { AppService } from '../../app.service';
import { NotificationItemModel } from './notification-item/notification-item.model';

@Component({
    selector: 'notification-list',
    templateUrl: 'notification.component.html',
    styleUrls: ['notification.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class NotificationComponent {
    notificationsList: NotificationItemModel[] = [];
    notificationListCount = 0;

    readonly MIN_NO_OF_NOTIFICATIONS = 4;
    readonly MAX_NO_OF_NOTIFICATIONS = 10;

    constructor(public authService: AuthService,
                public router: Router,
                public baseService: BaseService,
                public appService: AppService,
                public route: ActivatedRoute) {
    }

    /**
     * Handle notification after clicked on child component
     * @param notification
     */
    handleClinkedNotification(notification: NotificationItemModel) {
        this.router.navigate(['/notifications/', notification.id]);
    }
}
