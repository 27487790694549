
import {of as observableOf,  Observable, Subscription } from 'rxjs';
import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, NgForm, Validators } from '@angular/forms';
import { UsersService } from './users.service';
import { AuthService } from '../core/auth.service';
import { BaseService } from '../shared/base.service';
import { ClinicsService } from '../clinics/clinics.service';
import { BaseComponent } from '../shared/base.component';
import { RoleModel, UserModel } from './user.model';
import { AppService } from '../app.service';
import { DialogComponent } from '../dialog/dialog.component';
import { PasswordService } from '../auth/forgot-password/password.service';
import { ClinicsComponent } from '../clinics/clinics.component';
import * as _ from 'lodash';
import { add } from 'ngx-bootstrap/chronos';

@Component({
    selector: 'users-component',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss'],
    providers: [UsersService, ClinicsService, PasswordService ,BaseService]
})

export class UsersComponent extends ClinicsComponent implements OnInit, AfterViewInit {
    @ViewChild('dialogLogo', { static: false }) dialogLogo: DialogComponent;
    helpClinicProfile: any;
    @Input() isHome = false;
    @Input() url = '/users';
    ALLROLES: Object[] = [];
    roles: Object[] = [];
    clinics: Object[] = [];
    B2CClinics: Object[] = [];
    clinicUsers: Object[] = [];
    clinicRoles: Object[] = [];
    clinicId: any = '';
    isClinicUpdatable: boolean;
    isClinicActivatable: boolean;
    isClinicDeletable: boolean;
    isClinicIndexable: boolean;
    isClinicPool = 0;
    isFocusMobileInput: boolean = false;
    userRoleId: number;
    isDisableLoginTypeSwitcher: boolean = false;
    showSelectRole : boolean = false;
    isResendEmailable : boolean = false;
    isEditable: boolean = false;
    subAdminRight: Subscription;
    clinic: Object = null;

    activeListUser: number = 1;

    userList = {
        superAdmins: [],
        admins: [],
        doctors: [],
        assistants: []
    };

    ROLES_UNAVAILABLE_IN_CLINIC = [this.authService.ROLES.SUPER_ADMIN,this.authService.ROLES.ASSISTANT_PLUS];

    // define what table columns to display [column key, label, css class]
    readonly tableColumns = [
        ['surname', 'Last Name', 'col-xs-3'],
        ['given_name', 'Given Name', 'col-xs-3'],
        ['email', 'Email Address', 'col-xs-4 col-sm-4'],
        ['created_at', 'Addition Date', 'col-xs-2 hidden-sm-down']
    ];

    @ViewChild('formDirective') private formDirective: NgForm;

    constructor(public authService: AuthService,
                public router: Router,
                public baseService: BaseService,
                public appService: AppService,
                public route: ActivatedRoute,
                public fb: FormBuilder,
                public usersService: UsersService,
                public passwordService: PasswordService,
                public clinicsService: ClinicsService) {
        super(authService, router, baseService, appService, route, fb, clinicsService);
    }

    ngOnInit() {
        this.searchInfo = new UserModel();
        this.sortedColumn = 'surname';
        this.url = 'users';
        this.token = this.authService.user['token'];

        // sort
        this.sortedColumn = 'created_at_sort';
        this.order = 'desc';
        this.orderField = ['-created_at_sort'];
        this.sortIsActivated = true;

        this.isCreatable = this.authService.isAuthorised('users.create');
        this.isUpdatable = this.authService.isAuthorised('users.update');
        this.isActivatable = this.authService.isAuthorised('users.activate');
        this.isDeletable = this.authService.isAuthorised('users.delete');

        this.isClinicUpdatable = this.authService.isAuthorised('clinics.update');
        this.isClinicActivatable = this.authService.isAuthorised('clinics.activate');
        this.isClinicDeletable = this.authService.isAuthorised('clinics.delete');
        this.isClinicIndexable = this.authService.isAuthorised('clinics.index');

        this.userRoleId = this.authService.user['role_id'];

        // process url query parameters e.g.
        this.route.queryParams.subscribe(params => {
            if (params['page'] != null) {
                this.currentPage = +params['page'];
            }

            if (params['items'] != null) {
                this.itemsPerPage = +params['items'];
            }

            if (params['clinic_id'] != null) {
                this.clinicId = +params['clinic_id'] || 0;
            }

            // process filters like search keyword, role/ clinic id, sort column, sort order
            if (params['filters'] != null) {
                this.filters = [];
                const filters = params['filters'].split('--');
                for (const filter of filters) {
                    const splitFilter = filter.split('-');
                    if (splitFilter[0] === 'sort') {
                        this.sortedColumn = splitFilter[1];
                    } else if (splitFilter[0] === 'order') {
                        this.order = splitFilter[1];
                    } else {
                        this.searchInfo[splitFilter[0]] = splitFilter[1];

                        // assign the search filters
                        if (splitFilter[0] !== 'keyword') {
                            this.filters.push({key: splitFilter[0]});
                        }
                    }

                    if (splitFilter[0] === 'clinic_id') {
                        this.clinicId = splitFilter[1];
                    }
                }
            }

            for (const param in params) {
                if (params.hasOwnProperty(param) && param !== 'page' && param !== 'items' && param !== 'filters') {
                    this.params[param] = params[param];
                }
            }

            this.activeListUser = (this.authService.isSuperAdmin && !this.clinicId) ?  this.authService.ROLES.SUPER_ADMIN : this.authService.ROLES.CLINIC_ADMIN;
            this.getItems();
        });

    }

    ngAfterViewInit() {
        switch (this.params['action']) {
            case 'create': {
                this.openFormModal({action: 'create', title: 'Add User', url: this.url});
                break;
            }
            case 'update': {
                if (this.params['component'] !== 'clinics') {
                    this.openFormModal({action: 'update', id: this.params['id'], title: 'Edit User', url: this.url});
                } else {
                    this.openClinicFormModal({
                        action: 'update',
                        id: this.params['id'],
                        title: 'Update Clinic',
                        url: 'users',
                        component: 'clinics'
                    });
                }
                break;
            }
            case 'read': {
                this.openFormModal({
                    action: 'read',
                    id: this.params['id'],
                    title: 'User Details',
                    activated: this.params['activated'],
                    content: this.params['content'],
                    description: this.params['description'],
                    'role-id': this.params['role-id'],
                    url: this.url
                });
                break;
            }
            case 'activate':
            case 'delete': {
                this.params['url'] = this.url;
                this.openConfirmModal(this.params);
                break;
            }
            default: {
                break;
            }
        }
    }

    clearSearch() {
        this.searchInfo.keyword = '';
    }

    get isClinicView() {
        return this.clinicId;
    }

    get isAdminView() {
        return (this.clinicName == null && this.clinicCity == null);
    }

    /**
     * @param params
     */
    openFormModal(params: Object) {
        this.initForm(params);

        this.usersService.read(params['id']).subscribe(response => {
            let editingUser = response['user'];
            this.ALLROLES = response['roles'];
            this.languages = JSON.parse(localStorage.getItem('languages'));
            this.clinicRoles = _.orderBy(editingUser?.roles, 'clinic_id') || this.clinicRoles;
            let form = {};
            form['roles'] = this.clinicRoles;
            let userComponent = this;
            /**
             * 1. Bind value
             * Display only the roles that the logged-in user can select
             */
            this.isEditable = !_.find(this.authService.user?.related_user, { id : params['id']});

            if (params['action'] === 'create') {
                if (this.roles != null) {
                    this.roles = [];
                }

                if (this.clinics != null) {
                    this.clinics = [];
                }

                let clinicId: number;
                if (this.authService.isSuperAdmin) {
                    this.roles = response['roles'];
                    if (this.clinicId > 0 || this.clinicId > '0') {
                        clinicId = +this.clinicId;
                        this.roles = this.roles.filter( item => {
                            return this.ROLES_UNAVAILABLE_IN_CLINIC.indexOf(item['id']) < 0
                        });
                    } else {
                        this.clinics = response['clinics'];
                    }
                } else if (this.authService.isClinicAdmin) {
                    this.roles = response['roles'];
                    clinicId = this.authService.user['clinic_id'];
                }

                for (let clinic of response['clinics']) {
                    if (clinic['id'] === clinicId) {
                        this.clinics.push(clinic);
                        if (clinic['b2c_register'] && this.authService.user['role_id'] === this.authService.ROLES.SUPER_ADMIN) {
                            this.roles.push(response['roles'][7]);
                        }
                        break;
                    }
                }
                // Sort role by id before render to UI
                this.roles.sort((role1, role2) => role1['id'] - role2['id']);
            } else {
                const _self = this;
                this.roles = (editingUser.role.id === userComponent.authService.ROLES.SUPER_ADMIN || this.haveSuperAdminRole()) ? response['roles'] : response['roles'].filter(function (role) {
                    return (role.id !== userComponent.authService.ROLES.SUPER_ADMIN)
                        && !(editingUser?.is_patient_transfer_host && role.id === userComponent.authService.ROLES.CLINIC_ADMIN)
                });
                this.clinics = response['clinics'];
            }

            form['logo'] = null;
            form['have_admin_role'] = editingUser?.have_admin_role;

            let disabled = params['action'] === 'read' ? true : false;
            this.isDisableLoginTypeSwitcher = disabled || editingUser['role_id'] === 1 ? true : false;

            for (let column in editingUser) {
                if (editingUser.hasOwnProperty(column)) {
                    form[column] = {value: editingUser[column], disabled: disabled};
                }
            }

            //1544
            this.isResendEmailable = !editingUser.is_activated_by_email;

            if (this.form != null) {
                this.form.reset(form);
            } else {
                this.form = this.fb.group(form);
                this.form.controls['email'].setValidators([<any>Validators.required,
                    <any>Validators.pattern('[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}')]);
                this.form.controls['official_name'].setValidators([<any>Validators.minLength(3)]);
                this.form.controls['given_name'].setValidators([<any>Validators.required, <any>Validators.minLength(3)]);
                this.form.controls['surname'].setValidators([<any>Validators.required, <any>Validators.minLength(3)]);
            }

            /**
             * 2. Bind validator
             */
            // reset mobile validator required on update only
            this.form.controls['mobile'].clearValidators();
            // reset clinic validator required
            this.form.controls['clinic_id'].clearValidators();
            // If role is admin, the Admin rights will be disabled.
            this.enableSelectAdminRole(this.form.controls['role_id'].value, editingUser?.have_admin_role);
            this.B2CClinics = _.filter(this.clinics, {
                b2c_register : 1,
                is_default : 0
            });

            //Watch when change role
            this.sub = this.form.controls['role_id'].valueChanges.subscribe(selectedRole => {
                if (this.authService.isSuperAdmin) {
                    if (parseInt(selectedRole) !== this.authService.ROLES.SUPER_ADMIN) {
                        this.form.controls['clinic_id'].enable();
                        this.form.controls['clinic_id'].setValidators([<any>Validators.required, <any>Validators.min(1)]);
                    }

                    // If role is super admin, the clinic selection will be disabled.
                    if (parseInt(selectedRole) === this.authService.ROLES.SUPER_ADMIN) {
                        this.form.controls['clinic_id'].disable();
                        this.form.controls['clinic_id'].clearValidators();
                        this.form.controls['clinic_id'].setValue(0);
                    }

                }

                //Watch when change role
                this.enableSelectAdminRole(selectedRole, editingUser?.have_admin_role);
            });

            //Watch when change Admin Rights
            if (this.authService.isClinicAdmin) {
                this.subAdminRight = this.form.controls['have_admin_role'].valueChanges.subscribe(haveAdminRights => {
                    this.addClinicRole(this.form.controls['clinic_id'].value, this.form.controls['role_id'].value, haveAdminRights)
                });
            }

            // Add new user
            if (params['action'] === 'create') {
                this.form.controls['login_type'].setValue(1);

                if (this.authService.isSuperAdmin) {
                    if (parseInt(this.clinicId) > 0) {
                        this.form.controls['clinic_id'].setValue(this.clinicId);
                        this.form.controls['role_id'].setValue(3);
                    } else {
                        this.form.controls['role_id'].setValue(3);
                    }
                } else if (this.authService.isClinicAdmin) {
                    this.form.controls['role_id'].setValue(3);
                    this.form.controls['clinic_id'].setValue(this.authService.user['clinic_id']);
                    this.form.controls['clinic_id'].disable();

                    // Change logic in #6588
                    // if (this.authService.isExtendedLoginClinic()) {
                    //     this.form.controls['login_type'].setValue(1);
                    //     this.form.controls['login_type'].disable();
                    //     this.isDisableLoginTypeSwitcher = true;
                    // }
                }

                this.enableSelectAdminRole(this.form.controls['role_id'].value,false);
                // Reset validator for mobile
                this.form.controls['mobile'].setValidators([<any>Validators.pattern('\\+{1}[0-9\\s]+')]);
            } else if (params['action'] === 'update') {
                this.form.controls['mobile'].setValidators([<any>Validators.pattern('\\+{1}[0-9\\s]+')]);
                if (editingUser.is_activated_by_email) {
                    this.form.controls['mobile'].addValidators([Validators.required]);
                }

                // Change logic in #6588
                if (this.haveSuperAdminRole()) {
                    this.form.controls['login_type'].setValue(1);
                    this.form.controls['login_type'].disable();
                    this.isDisableLoginTypeSwitcher = true;
                }

                if(this.authService.isClinicAdmin){
                    this.form.controls['clinic_id'].disable();
                }
            }

            this.form.controls['mobile'].updateValueAndValidity();

            // hide 'select clinic' in clinic field
            if (params['action'] === 'update' || params['action'] === 'read') {
                if (this.form.controls['clinic_id'].value === null) {
                    this.form.controls['clinic_id'].setValue(0);
                }

                if (this.form.controls['role_id'].value === this.authService.ROLES.CLINIC_ADMIN || this.form.controls['role_id'].value === this.authService.ROLES.SUPER_ADMIN) {
                    this.form.controls['have_admin_role'].setValue(true);
                }
            }

            this.showModal();
        });
    }

    /**
     * Handle on select clinic event
     *
     * @param selectedClinic
     */
    onSelectClinic(selectedClinic) {
        // Handle login switcher enable/disable depend on login type
    }

    // handle add button clicks
    openClinicFormModal(params: Object) {
        this.initForm(params);
        this.clinicsService.read(params['id'])
            .subscribe(
                response => {
                    let clinic = response['clinic'];
                    this.countries = response['countries'];
                    this.languages = response['languages'];
                    this.clinicUsers = response['clinic'].assignees;
                    this.helpClinicProfile = response['helpTemplates'];

                    let form = {};
                    let disabled = params['action'] === 'read' ? true : false;

                    for (let column in clinic) {
                        if (clinic.hasOwnProperty(column)) {
                            form[column] = {value: clinic[column], disabled: disabled};
                        }
                    }

                    if (this.externalForm != null) {
                        this.externalForm.reset(form);
                    } else {
                        this.externalForm = this.fb.group(form);
                        this.externalForm.controls['name'].setValidators([<any>Validators.required, <any>Validators.minLength(3)]);
                        this.externalForm.controls['language_id'].setValidators([<any>Validators.required]);
                        this.externalForm.controls['city'].setValidators([<any>Validators.required]);
                        this.externalForm.controls['country_id'].setValidators([<any>Validators.required]);
                        this.externalForm.controls['customer_id'].setValidators([<any>Validators.required, <any>Validators.minLength(3), <any>Validators.pattern(/^-?([0-9]\d*)?$/)]); ///^-?(0|[1-9]\d*)?$/
                    }

                    if (params['action'] === 'create') {
                        this.externalForm.controls['login_type'].setValue(0);
                        this.externalForm.controls['security'].setValue(0);
                        this.externalForm.controls['patient_transfer_host_id'].disable();
                    } else if (params['action'] === 'update') {
                        this.fullPath = clinic['logo'];
                    } else if (params['action'] === 'read') {
                        this.fullPath = clinic['logo'];
                        this.externalForm.controls['patient_transfer_host_id'].setValue(
                            this.externalForm.controls['patient_transfer_host_id'] != null
                                ? this.externalForm.controls['patient_transfer_host_id']
                                : null
                        );
                    }

                    this.showModal();
                },
                error => {
                    this.errorMessage = this.appService.parseErrors(error);
                }
            );
    }

    /**
     * Open email template management page
     */
    openEmailTemplate() {
        this.router.navigate(['templates'])
    }

    /**
     * @param e
     */
    exportUser(e) {
        this.token = this.authService.user['token'];
        e.target.blur();
        e.target.parentElement.blur();
        window.location.href = this.authService.baseUrl + 'retrieve-data/users/' + this.clinicId + '?token=' + this.token;
    }

    /**
     * Handle on focus mobile
     */
    focusInMobileInput() {
        this.isFocusMobileInput = true;
    }

    /**
     * Handle on focus mobile
     */
    focusOutMobileInput() {
        this.isFocusMobileInput = false;
    }

    getItems() {
        this.router.navigate(['./'], {
            queryParams: {
                clinic_id: this.clinicId,
            },
            relativeTo: this.route
        });

        let url = this.clinicId ? `users/all/${this.clinicId}` :  'users/all'
        this.usersService.getAll(url)
            .subscribe(data => {
                this.setData(data);
            });
    }

    setData(data: any): void {
        this.items = data['users'] || [];
        if (data.filters != null) {
            this.filters = data.filters;
            this.asyncFilters = observableOf(data.filters);
        }
        let clinic = this.clinicId ? data['clinic'] : this.authService.clinic;
        if (clinic) {
            this.clinicName = clinic.name;
            this.clinicCity = clinic.city;
            this.clinicActivation = clinic.activated;
        }

        // if users are empty, hide the page numbers below the users list to prevent page redirection
        this.paginationHidden = this.items.length === 0;
        this.isLoading = false;
        this.clinic = clinic;
    }

    navigateRoute() {
        this.router.navigate(['./'], {
            relativeTo: this.route
        });
    }

    initForm(params: Object): void {
        this.params = params;
        // store the current page to be used later since this.currentPage will be set to 1 when opening the modal
        this.page = this.currentPage;
        let queryParams = {};

        for (const param in this.params) {
            if (param !== 'url' && this.params.hasOwnProperty(param)) {
                queryParams[param] = this.params[param];
            }
        }

        // reset errors
        this.resetErrors();
        this.formSubmitted = false;

        if (this.formDirective) {
            this.formDirective.resetForm();
        }

        // Notice: For popup navigation from the search on StartPage
        // Avoid to check multiple api called
        // this.router.navigate(['./'], {queryParams: queryParams, relativeTo: this.route});

        // display app loading icon
        this.isLoading = true;
    }

    sortUsers(column) {
        if (column === 'created_at') {
            column = 'created_at_sort';
        }

        this.sort(column, false);
    }

    /**
     * Handle switch tab action
     * @param userType
     */
    switchUserTypeTab(userType) {
        this.activeListUser = userType;

        this.totalItems = this.items.length || 0;
    }

    enableSelectAdminRole(roleId, value = false, disabled = true) {
        if (this.authService.EMPLOYEE_ROLES.indexOf(roleId) >= 0 || this.authService.ROLES.B2C_ADMIN === roleId || this.authService.ROLES.INSURANCE_PARTNER === roleId || this.authService.ROLES.ASSISTANT_PLUS === roleId) {
            disabled = false;
            if (this.authService.ROLES.COORDINATOR === roleId){
                value = false;
            }
            if(this.authService.ROLES.INSURANCE_PARTNER === roleId
                || this.authService.ROLES.B2C_ADMIN === roleId
                || this.authService.ROLES.ASSISTANT_PLUS === roleId){
                value = false;
                disabled = true;
            }
        } else {
            value = true;
        }

        this.form.controls['have_admin_role'].setValue(value);
        if(disabled){
            this.form.controls['have_admin_role'].disable();
        }
        else {
            this.form.controls['have_admin_role'].enable();
        }
    }

    openResendEmailModal(params: any) {
        this.initForm(params);

        // close the modal opened before the confirmation modal
        if (this.modal && this.modal.isShown) {
            this.hideModal();
        }

        const dialog = this.dialog.openConfirm(params);
        this.isLoading = false;

        dialog.onSubmit(() => {
            this.isLoading = true;
            this.passwordService.password(params.description)
                .subscribe(
                    response => {
                        this.isUpdated = true;
                        this.getItems();
                        dialog.showAlert('Resend activation email successfully');
                    },
                    error => {
                        dialog.showAlert(error.error.error);
                        this.isLoading = false;
                    }
                );
        });

        dialog.onCancel(() => {
            if (this.params['redirectUrl']) {
                return this.router.navigate([this.params['redirectUrl']]);
            }

            if (this.modal && this.modal.isShown) {
                this.hideModal();
            }

            this.getItems();
        });
    }

    hideModal() {
        if (this.sub) {
            this.form.controls['clinic_id'].clearValidators();
            this.sub.unsubscribe();
        }
        if (this.subAdminRight) {
            this.subAdminRight.unsubscribe();
        }
        this.clinicRoles = [];
        this.modal.hide();
    }

    addClinicRole(clinicId, roleId, isAdmin = false){
        if((!clinicId && roleId !== this.authService.ROLES.SUPER_ADMIN)
            || !roleId
            || _.find(this.clinicRoles, { role_id: this.authService.ROLES.SUPER_ADMIN})){
            return false;
        }

        let roles = [];
        // Check Clinic Id exist => replace all roles for this clinic
        if(_.find(this.clinicRoles, { clinic_id: clinicId})){
            this.replaceClinicRole(clinicId, roleId);
        } else{
            roles.push({
                clinic_id: clinicId,
                role_id: roleId
            });
        }

        const adminRole = {
            clinic_id: clinicId,
            role_id: this.authService.ROLES.CLINIC_ADMIN
        };
        if(!isAdmin){
            _.remove(this.clinicRoles, adminRole);
        }
        if(isAdmin && !this.haveAdminRole(adminRole) && roleId !== this.authService.ROLES.CLINIC_ADMIN){
            roles.push(adminRole);
        }
        if(this.isSuperAdmin(roleId)){
            this.form.controls['login_type'].disable();
            this.form.controls['login_type'].setValue(1);
            this.isDisableLoginTypeSwitcher = true;
            this.clinicRoles = roles;
        }else {
            this.form.controls['login_type'].enable();
            this.isDisableLoginTypeSwitcher = false;
            this.clinicRoles = _.orderBy(this.clinicRoles.concat(roles), 'clinic_id');
        }
    }

    allowMultiRoles(roleId){
        return  !this.isSuperAdmin(roleId) &&
                !this.isCoordinator(roleId) &&
                !this.isB2CAdmin(roleId);
    }

    haveCoordinatorRole(){
        return _.find(this.clinicRoles, { role_id: this.authService.ROLES.COORDINATOR});
    }

    haveSuperAdminRole(){
        return _.find(this.clinicRoles, { role_id: this.authService.ROLES.SUPER_ADMIN});
    }

    haveAdminRole(role){
        return _.find(this.clinicRoles, {
            clinic_id: role.clinic_id,
            role_id: this.authService.ROLES.CLINIC_ADMIN
        });
    }

    have2ndRole(role){
        const roles = _.filter(this.clinicRoles, {
            clinic_id: role.clinic_id
        });
        return roles && roles.length > 1;
    }

    clinicById(clinicId, roleId){
        if(this.isSuperAdmin(roleId)){
            return {
                name : '-'
            }
        }
        return _.find(this.clinics, {
            id: clinicId
        });
    }

    isAdmin(roleId){
        return roleId === this.authService.ROLES.CLINIC_ADMIN;
    }

    isSuperAdmin(roleId){
        return roleId === this.authService.ROLES.SUPER_ADMIN;
    }

    isCoordinator(roleId){
        return roleId === this.authService.ROLES.COORDINATOR;
    }

    isB2CAdmin(roleId){
        return roleId === this.authService.ROLES.B2C_ADMIN;
    }

    roleById(roleId){
        return _.find(this.ALLROLES, {
            id: roleId
        });
    }

    removeClinicRole(clinicId, roleId = null){
        let params = {
            clinic_id: clinicId,
        };
        if(roleId){
            params['role_id'] = roleId;
        }
        return _.remove(this.clinicRoles, params);
    }

    isExistClinicRole(clinicId, roleId){
        return _.find(this.clinicRoles, {'clinic_id': clinicId , 'role_id': roleId});
    }

    replaceClinicRole(clinicId, newRole){
        const _self = this;
        let params = {
            clinic_id: clinicId,
        };
        if(newRole){
            params['role_id'] = newRole;
        }
        _.remove(this.clinicRoles, function(role){
            return role.clinic_id === clinicId && !_self.isAdmin(role.role_id);
        });
        const clinicRoles = !this.isExistClinicRole(clinicId, newRole) ? _.orderBy(this.clinicRoles.concat(params), 'clinic_id') : _.orderBy(this.clinicRoles, 'clinic_id');
        this.clinicRoles = clinicRoles;
        return;
    }

    submit(value, valid, form) {
        if (form === 'clinics') {
            return super.submit(value, valid, form);
        } else {
            this.form.controls['roles'].setValue(this.clinicRoles);
            return super.submit(this.form.value, this.form.valid);
        }
    }

}
