import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { BlockTypes, MarkTypes, richTextResolver } from '@storyblok/richtext';


@Pipe({
  name: 'storyblokRichtext'
})

export class StoryBlokRichTextPipe implements PipeTransform {

  constructor(
    private _sanitizer: DomSanitizer,
    private translate: TranslateService
  ) {
      
  }

  transform(value: any, replaces): SafeHtml {
    if (!value || !value.content) {
      return '';
    }

    let text = richTextResolver({
      resolvers: {
        [MarkTypes.LINK]: (node) => this.linkResolver(node),
        [BlockTypes.BR]: (node) => this.brResolver(),
        [BlockTypes.COMPONENT]: (node) => this.componentResolver(node, replaces),
      },
    }).render(value);

    if (replaces) {
      for (const [key, value] of Object.entries(replaces)) {
        if (value) {
          text = text.toString().replace('{{' + key + '}}', value.toString());
        }
      }
    }

    return this._sanitizer.bypassSecurityTrustHtml(text.toString());
  }

  linkResolver(node) {
    const { linktype, href, target } = node.attrs;
  
    if (linktype === "email") {
      return `<a href="mailto:${href}" target="${target}">${node.text}</a>`;
    }
    if (linktype === "story") {
      return "<a href=" + this.removeFullSlugPrefix(href) + " target=" + target + ">" + node.text + "</a>";
    }
  
    return `<a href="${href}" target="${target}">${node.text}</a>`;
  }

  brResolver() {
    return `<br>`;
  }

  componentResolver(node, replaces) {
    let data = node.attrs.body[0];

    if (data.component == 'buttonComponent') {
      return this.renderButtonComponent(data, replaces);
    }

    if (data.component == 'sliderComponent') {
      return this.renderSliderComponent(data, replaces);
    }

  }

  renderButtonComponent(data, replaces) {

    // Maybe have bug here
    if (data.link.linktype === "story") {
      return "<a href=" + this.removeFullSlugPrefix(data.link.cached_url) + " target=" + data.target + " title=" + data.link.title + ">" + data.link.title + "</a>";
    }

    if (data.renderAs === "button") {
      return "<a class='btn btn-primary-me' href=" + this.removeFullSlugPrefix(data.link.cached_url) + " target=" + data.target + " title=" + data.link.title + ">" + data.link.title + "</a>";
    } 
  }

  renderSliderComponent(data, replaces) {
    let value = 55;
    if (replaces.value) {
      value = replaces.value;
    }

    return `<div class='slider-component'>
      <div class='slider-component--label'>${data.leftLabel}</div>
      <div class='slider-component--slider'>
        <div class='slider-component--slider--inner'>
          <div class='slider-component--slider--inner--left'></div>
          <div class='slider-component--slider--inner--center'></div>
          <div class='slider-component--slider--inner--right'></div>
        </div>
        <div class='slider-component--slider--percent' style="left:${value}%"></div>
      </div>
      <div class='slider-component--label slider-component--label__right'>${data.rightLabel}</div>
    </div>`;
  }

  removeFullSlugPrefix(href) {
    let locale = this.translate.currentLang.split('_')[0];
    const withoutLocale = href.replace(`${locale}/`, "");
    const removeN = withoutLocale.startsWith("/") ? 2 : 1;
    const segments = withoutLocale.split("/").slice(removeN);
    return `/me/${segments.join("/")}`;
  }
}
