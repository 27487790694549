<div class="slide-visualization">
    <div class="slide-container">
        <div class="block">
            <i class="fas fa-comment"></i>
            <i class="fas fa-arrow-right"></i>
            <img src="assets/img/dashboard-informations/slide3-img1.png">
        </div>
        <div class="block">
            <i class="fas fa-comments"></i>
            <i class="fas fa-arrow-right"></i>
            <img src="assets/img/dashboard-informations/slide3-img2.png">
        </div>
    </div>
</div>