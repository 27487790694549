import { Inject, Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';

@Injectable()
export class ChangePasswordService {

    /**
     * Use for reset password and new account activation
     *
     * @param {Http} http
     * @param {string} baseUrl
     */
    constructor(public http: HttpClient, @Inject('baseUrl') public baseUrl: string) {
    }

    /**
     * @param {string} token
     * @returns {Observable<Response>}
     */
    verifyToken(token: string) {
        const url = this.baseUrl + 'oauth/password/verify';
        const body = 'token=' + token;
        const headerOptions = {
            'Content-Type': 'application/x-www-form-urlencoded'
        };

        const httpOptions = {
            headers: new HttpHeaders(headerOptions)
        };
        return this.http.post(url, body, httpOptions);
    }

    /**
     * @param {string} token
     * @param {string} password
     * @param {string} passwordConfirmation
     * @returns {Observable<Response>}
     */
    changePassword(token: string, password: string, passwordConfirmation: string) {
        const url = this.baseUrl + 'oauth/password/change';

        let httpParams = new HttpParams()
                                .append('token', token)
                                .append('password', password)
                                .append('password_confirmation', passwordConfirmation);

        const body = httpParams.toString();

        const headerOptions = {
            'Content-Type': 'application/x-www-form-urlencoded'
        };

        const httpOptions = {
            headers: new HttpHeaders(headerOptions)
        };

        return this.http.post(url, body, httpOptions);
    }
}
