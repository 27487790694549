
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { VerifyLoginTokenService } from './verify-login-token.service';
import { AuthService } from '../../core/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthComponent } from '../auth.component';
import { AppService } from '../../app.service';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
    selector: 'app-verify-login-token',
    templateUrl: './verify-login-token.component.html',
    styleUrls: ['../auth.component.scss']
})

/**
 * New account activation page / Reset password page
 */
export class VerifyLoginTokenComponent extends AuthComponent implements OnInit {
    params: any;
    token: string;
    email: string;
    errorMessage: string;
    successMessage: string;
    infoMessage: string;
    form: FormGroup;

    get isLoading() {
        return this.appService.isLoading;
    }

    set isLoading(isLoading) {
        this.appService.isLoading = isLoading;
    }

    constructor(
        public router: Router,
        public authService: AuthService,
        public appService: AppService,
        public translate: TranslateService,
        public verifyLoginTokenService: VerifyLoginTokenService,
        public activatedRouter: ActivatedRoute,
        public fb: FormBuilder
        ) {
            super(router, authService, appService, translate);
        }

    ngOnInit() {
        localStorage.removeItem('user');
        super.ngOnInit();

        this.activatedRouter.params.subscribe(params => {

            if(params['token'] && params['id']){
                this.appService.isLoading = true;
                this.verifyLoginTokenService
                    .verifyLoginToken(params['token'], params['id'])
                    .subscribe(response => {
                            this.appService.isLoading = false;
                            this.successMessage = response['message'];
                            this.doLogin(response);
                        },
                        error => {
                            this.appService.isLoading = false;
                            this.errorMessage = error.error.error;
                        });
            }
        });

    }
}
