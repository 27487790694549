export class TemplateContent {
    content: string;
    title: string;
    constructor(params: {
        content?: string,
        title?: string
    } = {}) {
        this.content = params['content'] || '';
        this.title = params['title'] || '';
    }
}