// angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// 3rd parties
import { TranslateModule } from '@ngx-translate/core';
import { ModalModule } from 'ngx-bootstrap/modal';
import { DateDiffTranslatePipe } from './pipe/date-diff-translate.pipe';
import { BaseService } from './base.service';

// custom pipe
import { OrderByPipe } from './pipe/order-by.pipe';
import { FormatTextPipe } from './pipe/format-text.pipe';
import { FilterTextPipe } from './pipe/filter-text.pipe';
import { BoldSpanPipe } from './pipe/replace-pipe';
import { DatexPipe } from './pipe/datex.pipe';
import { ChunkPipe } from './pipe/chunk.pipe';
import { ReplacePipe } from './pipe/replace-reg-pipe';
import { SafeHtmlPipe } from './pipe/safe-html.pipe';
import { FilterRolePipe } from './pipe/filter-role.pipe';
import { StepperWizardModule } from './stepper/stepper.module';
import { SlideCustomModule } from './slide/slide.module';
import { DashboardInformationComponent } from '../dashboard-information/dashboard-information.component';
import { TermsConditionModalComponent } from '../terms-condition/modal/modal.component';
import { PrivacyStatementModalComponent } from '../privacy-statement/modal/modal.component';
import { LegalNoticeModalComponent } from '../legal-notice/modal/modal.component';
import { RatingPipe } from './pipe/rating.pipe';
import { TruncatePipe } from './pipe/truncate.pipe';
import { MultiClinicsComponent } from './multi-clinics/multi-clinics.component';
import { SearchArrayPipe } from './pipe/search.pipe';
import { DeviceLabelModalComponent } from '../device-label/modal/modal.component';
import { StoryBlokRichTextPipe } from './pipe/storyblok-richtext.pipe';
import { StoryBlokButtonPipe } from './pipe/storyblok-button.pipe';
import { StoryBlokImagePipe } from './pipe/storyblok-image.pipe';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        StepperWizardModule,
        SlideCustomModule,
        ModalModule.forRoot()
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        DateDiffTranslatePipe,
        OrderByPipe,
        FormatTextPipe,
        FilterTextPipe,
        BoldSpanPipe,
        ReplacePipe,
        FilterRolePipe,
        DatexPipe,
        ChunkPipe,
        RatingPipe,
        TruncatePipe,
        SafeHtmlPipe,
        SearchArrayPipe,
        StoryBlokRichTextPipe,
        StoryBlokButtonPipe,
        StoryBlokImagePipe,
        DashboardInformationComponent,
        TermsConditionModalComponent,
        PrivacyStatementModalComponent,
        LegalNoticeModalComponent,
        DeviceLabelModalComponent,
        MultiClinicsComponent
    ],
    declarations: [
        DateDiffTranslatePipe,
        OrderByPipe,
        FormatTextPipe,
        FilterTextPipe,
        BoldSpanPipe,
        ReplacePipe,
        FilterRolePipe,
        DatexPipe,
        ChunkPipe,
        RatingPipe,
        TruncatePipe,
        SafeHtmlPipe,
        SearchArrayPipe,
        StoryBlokRichTextPipe,
        StoryBlokButtonPipe,
        StoryBlokImagePipe,
        DashboardInformationComponent,
        TermsConditionModalComponent,
        PrivacyStatementModalComponent,
        LegalNoticeModalComponent,
        DeviceLabelModalComponent,
        MultiClinicsComponent
    ],
    providers: [BaseService]

})
export class BaseModule {
}
