<div class="bg-circle"></div>
<div class="bg-circle-corner"></div>
<section class="auth-self-evalution">
    <div class="row">
        <div class="card col-xs-12 col-sm-8">
            <div class="card-block mat-form">
                <div class="card--header">
                    {{ 'Welcome SE Auth' | translate }}
                </div>
                <div class="form-group auth-self-evalution__content">
                   {{ 'To enable your doctor to best prepare for your treatment, we kindly ask you to complete the online survey. Data is encrypted.' | translate }}
                </div>
                <div *ngIf="errorMessage" class="alert alert-danger" role="alert">
                    <span class="fas fa-exclamation-circle" aria-hidden="true"></span>{{ errorMessage | translate }}
                </div>
                <div *ngIf="successMessage" class="alert alert-success" role="alert">
                    <span class="fas fa-exclamation-circle" aria-hidden="true"></span>{{ successMessage | translate }}
                </div>
                <div class="form-group">
                    <div class="">
                        <input class="card--box__search__input" [(ngModel)]="code" placeholder="{{ 'Enter code' | translate }}" (keyup)="addSpace($event)" [maxlength]="9">
                    </div>
                </div>
                <div class="form-group">
                    <button (click)="authenticate()" class="btn btn-primary badge-pill" type="button">
                        {{ 'Start survey' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</section>
