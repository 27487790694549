import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../core/auth.service';
import { ClinicsService } from './clinics.service';
import { BaseService } from '../shared/base.service';
import { BaseComponent } from '../shared/base.component';
import { ClinicModel } from './clinic.model';
import { AppService } from '../app.service';
import { DialogComponent } from '../dialog/dialog.component';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
    selector: 'clinics-component',
    templateUrl: './clinics.component.html',
    styleUrls: ['./clinics.component.scss'],
    providers: [ClinicsService, BaseService]
})

export class ClinicsComponent extends BaseComponent implements OnInit, AfterViewInit {
    @ViewChild('dialogLogo', { static: false }) dialogLogo: DialogComponent;
    @ViewChild('modalDeleteClinic', {static: false}) modalDeleteClinic: ModalDirective;

    helpClinicProfile: any;
    readonly tableColumns = [
        ['customer_id', 'Customer ID', 'col-xs-2' , 'customer_id'],
        ['customer_type_text', 'Customer type', 'col-xs-2', 'customer_type'],
        ['name', 'Clinic Name', 'col-xs-4', 'name'],
        ['city', 'City', 'col-xs-2', 'city'],
        ['country_name', 'Country', 'col-xs-2', 'country_name']
    ];

    clinicUsers = [];
    smsCodeForm = this.fb.group({
        smsCode: ['', [<any>Validators.required, Validators.pattern('^[0-9]*$')]],
        clinicId : ['', [<any>Validators.required]]
    });
    smsCodeSubmitted: boolean;

    deletingName: string;

    constructor(public authService: AuthService,
                public router: Router,
                public baseService: BaseService,
                public appService: AppService,
                public route: ActivatedRoute,
                public fb: FormBuilder,
                public clinicsService: ClinicsService) {
        super(authService, router, baseService, appService);
    }

    ngOnInit() {
        super.ngOnInit();
        this.sortedColumn = 'name';
        this.searchInfo = new ClinicModel();
        this.url = 'clinics';
        this.deletingName = '';
        this.token = this.authService.user['token'];

        this.isIndexable = this.authService.isAuthorised('clinics.index');
        this.isCreatable = this.authService.isAuthorised('clinics.create');
        this.isUpdatable = this.authService.isAuthorised('clinics.update');
        this.isDeletable = this.authService.isAuthorised('clinics.delete');
        this.isActivatable = this.authService.isAuthorised('clinics.activate');
    }

    ngAfterViewInit(): void {
        switch (this.params['action']) {
            case 'create': {
                this.openFormModal({action: 'create', title: 'Add Clinic', url: this.url});
                break;
            }
            case 'update': {
                this.openFormModal({action: 'update', id: this.params['id'], title: 'Edit Clinic', url: this.url});
                break;
            }
            case 'read': {
                this.openFormModal({
                    action: 'read', id: this.params['id'], title: 'Clinic Details', activated: this.params['activated'],
                    content: this.params['content'], description: this.params['description'], url: this.url
                });
                break;
            }
            case 'activate':
            case 'delete' : {
                this.params['url'] = this.url;
                this.openConfirmModal(this.params);
                break;
            }
            default: {
                this.getItems();
                break;
            }
        }
    }

    // handle add buton clicks
    openFormModal(params: Object) {
        this.initForm(params);
        this.clinicsService.read(params['id'])
            .subscribe(response => {
                let clinic = response['clinic'];
                this.params['clinic'] = clinic;
                this.countries = response['countries'];
                this.languages = response['languages'];
                this.clinicUsers = response['clinic'].assignees;
                this.helpClinicProfile = response['helpTemplates'];

                let form = {};
                let disabled = params['action'] === 'read' ? true : false;

                for (let column in clinic) {
                    if (clinic.hasOwnProperty(column)) {
                        form[column] = {value: clinic[column], disabled: disabled};
                    }
                }

                if (this.form != null) {
                    this.form.reset(form);
                } else {
                    this.form = this.fb.group(form);
                    this.form.controls['name'].setValidators([<any>Validators.required, <any>Validators.minLength(3)]);
                    this.form.controls['language_id'].setValidators([<any>Validators.required]);
                    this.form.controls['city'].setValidators([<any>Validators.required]);
                    this.form.controls['country_id'].setValidators([<any>Validators.required]);
                    this.form.controls['customer_id'].setValidators([<any>Validators.required, <any>Validators.minLength(3), <any>Validators.pattern(/^-?([0-9]\d*)?$/)]);
                }

                if (params['action'] === 'create') {
                    this.form.controls['login_type'].setValue(0);
                    this.form.controls['show_se_pdf'].setValue(0);
                    this.form.controls['show_proposal_diagnosis'].setValue(1);
                    this.form.controls['security'].setValue(0);
                    this.form.controls['show_different_sr_success_page'].setValue(0);
                    this.form.controls['customer_type'].setValue(4);
                    this.form.controls['patient_transfer_host_id'].disable();
                    this.form.controls['b2c_register'].setValue(0);
                    this.form.controls['show_patient_document'].setValue(0);
                    this.form.controls['show_patient_service_provider'].setValue(0);
                    this.form.controls['show_patient_video_call'].setValue(0);
                    this.form.controls['auto_assign_to_transfer_host'].setValue(0);
                } else if (params['action'] === 'update') {
                    this.fullPath = clinic['logo'];
                } else if (params['action'] === 'read') {
                    this.fullPath = clinic['logo'];
                    this.form.controls['patient_transfer_host_id'].setValue(
                        this.form.controls['patient_transfer_host_id'] != null
                            ? this.form.controls['patient_transfer_host_id']
                            : null
                    );
                }

                this.showModal();
            });
    }

    // handle search result item clicks
    typeaheadOnSelect(event) {
        let item = event.item;

        if (item.hasOwnProperty('keyword')) {
            this.searchInfo.keyword = item.keyword;
            this.searchItems();
        } else {
            this.searchInfo.keyword = '';

            this.openFormModal({
                action: 'read',
                id: item.id,
                title: 'Clinic Details',
                activated: item.activated,
                content: item.activated ? 'Are you sure you want to deactivate this clinic?' : 'Are you sure you want to activate this clinic?',
                description: item['name'],
                url: '/clinics'
            });
        }
    }

    initForm(params: Object) {
        this.formSubmitted = false;
        super.initForm(params);
    }

    avatarUploading($event) {
        if ($event) {
            this.isUploading = true;
        }
    }

    avatarUploaded($event) {
        let response = JSON.parse($event.serverResponse);
        this.fullPath = response.fullPath;
        this.form.controls['logo'].setValue(response.filePath);
        this.isUploading = false;
    }

    exportClinic(e) {
        this.token = this.authService.user['token'];
        e.target.blur();
        e.target.parentElement.blur();
        window.location.href = this.authService.baseUrl + 'retrieve-data/clinics' + '?token=' + this.token;
    }

    deleteLogo($event) {
        let clinicId = this.params['id'];

        let params = {
            url: 'clinics/deleteLogo',
            id: clinicId,
            action: 'delete',
            title: 'Remove image',
            content: 'Are you sure you want to remove this image?',
            icon: 'far fa-trash-alt'
        };

        const dialog = this.dialogLogo.openConfirm(params);
        this.isLoading = false;

        dialog.onSubmit(() => {
            this.isLoading = true;

            const url = (params['component'] ? params['component'] : params['url']) + '/' + params['id'];

            this.baseService.changeStatus(url, params['action'])
                .subscribe(
                    response => {
                        const message = response['message'];
                        this.isLoading = false;
                        this.fullPath = '';
                        this.form.controls['logo'].setValue('');
                        if (message) {
                            dialog.showAlert(message);
                        } else {
                            dialog.hideModal();
                        }
                    },
                    error => {
                        dialog.showAlert(error.error.error);
                        this.isLoading = false;
                    }
                );
        });

        dialog.onCancel(() => {
            dialog.hideModal();
        });
    }

    deleteClinic(clinic) {
        this.deletingName = clinic.name;
        this.clinicsService.sendDeleteClinicCode({
            clinic_id: clinic.id
        }).subscribe(res => {
            this.modalDeleteClinic.show();
            this.smsCodeForm.reset();
            this.smsCodeForm.controls['clinicId'].setValue(clinic.id);
            this.smsCodeSubmitted = false;
        });
    }

    submitDeleteClinic() {
        this.smsCodeSubmitted = true;

        if (this.smsCodeForm.invalid) {
            return;
        }

        let params = this.smsCodeForm.getRawValue();

        this.clinicsService.deleteClinic(params).subscribe(res => {
            const message = res['message'];
            if (res['success']) {
                this.smsCodeSubmitted = false;
                this.modalDeleteClinic.hide();
                this.modal.hide();
                this.isUpdated = true;
                this.getItems();
;
                if (message) {
                    const alert = this.dialog.openAlert({icon: 'far fa-trash-alt'});
                    alert.showAlert(message);
                }
            } else {
                if (message) {
                    const alert = this.dialog.openAlert({icon: 'fas fa-exclamation-triangle'});
                    alert.showAlert(message);
                }
            }
        });
    }

    hideModal() {
        this.fullPath = '';
        this.responseMessage = '';
        this.modal.hide();
    }
}
