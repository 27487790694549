<div class="modal-content">
    <div class="modal-header">
        <h4 class="modal-title help-btn">
            {{ params['title'] | translate }}
            <app-popover [templateType]="HELP_CLINIC_PROFILE" 
                    iconClass="popover-icon--white" containerClass="clinic-profile" 
                    placement="bottom" [attachBody]="true"></app-popover>
        </h4>
    </div>
    <div class="modal-body">
        <button type="button" class="btn btn-link close" (click)="hideClinicEditModal()" aria-label="Close">

        </button>
        <!--action buttons-->
        <div *ngIf="params['action'] === 'read'">
            <ul class="list-inline">
                <li class="list-inline-item" *ngIf="accessControl['isUpdatable']">
                    <button class="btn btn-link" (click)="openFormModal.emit();">
                        <i class="fas fa-pencil-alt text-primary" aria-hidden="true"></i>
                        <span>{{ 'Edit' | translate }}</span>
                    </button>
                </li>
                <li class="list-inline-item" *ngIf="accessControl['isIndexable']">
                    <a class="btn btn-link" [routerLink]="['/users']" [queryParams]="{ clinic_id: params['id'] }">
                        <i class="far fa-user text-primary"></i>
                        <span>{{ 'List Users' | translate }}</span>
                    </a>
                </li>
                <li class="list-inline-item" *ngIf="accessControl['isIndexable']">
                    <a class="btn btn-link" [routerLink]="['/activity-dashboard']" [queryParams]="{ clinic_id: params['id'] }">
                        <i class="far fa-chart-bar text-primary"></i>
                        <span>{{ 'Activity Dashboard' | translate }}</span>
                    </a>
                </li>
                <li class="list-inline-item" *ngIf="accessControl['isIndexable']">
                    <a class="btn btn-link" [routerLink]="['/templates']" [queryParams]="{ clinic_id: params['id'] }">
                        <i class="far fa-file-alt text-primary"></i>
                        <span>{{ 'Edit template' | translate }}</span>
                    </a>
                </li>
                <li class="list-inline-item" *ngIf="accessControl['isActivatable']">
                    <button class="btn btn-link" (click)="activateConfirmationModal.emit()">
                        <i [ngClass]="(params['activated'] == 1) ? 'fas fa-ban text-primary': 'far fa-check-circle text-primary'"
                        ></i>
                        <span>{{ params['activated'] == 1 ? ('Deactivate' | translate) : ('Activate' | translate) }}</span>
                    </button>
                </li>
                <li class="list-inline-item hidden" *ngIf="accessControl['isActivatable']">
                    <a class="btn btn-link" [routerLink]="['/clinic-sr-config']" [queryParams]="{ clinic_id: params['id'] }">
                        <i class="far fa-clone text-primary"></i>
                        <span>{{ 'Choose Self Report' | translate }}</span>
                    </a>
                </li>
                <li class="list-inline-item hidden" *ngIf="accessControl['isActivatable']">
                    <a class="btn btn-link" [routerLink]="['/clinic-service-provider']" [queryParams]="{ clinic_id: params['id'] }">
                        <i class="far fa-clone text-primary"></i>
                        <span>{{ 'Choose Service Provider' | translate }}</span>
                    </a>
                </li>
                <li class="list-inline-item" *ngIf="accessControl['isDeletable']">
                    <button class="btn btn-link" (click)="delete.emit();">
                        <i class="far fa-trash-alt text-primary" aria-hidden="true"></i>
                        <span>{{ 'Delete' | translate }}</span>
                    </button>
                </li>
            </ul>
            <hr/>
        </div>
        <ng-template [ngIf]="!formLoading">
            <form [formGroup]="form" novalidate class="mat-form" #formDirective="ngForm">
                <div *ngIf="errorMessage.length > 0" class="row form-group" role="alert">
                    <div class="col-sm-10">
                        <div class="alert alert-danger">
                            <div *ngFor="let error of errorMessage">
                                <i class="fas fa-exclamation-circle" aria-hidden="true"></i> {{ error | translate}}
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="responseMessage" class="row form-group" role="alert">
                    <div class="col-sm-10">
                        <div class="alert-success alert">
                            <i class="fas fa-check" aria-hidden="true"></i> {{ responseMessage | translate }}
                        </div>
                    </div>
                </div>

                <div class="row" id="image-upload-wrapper">
                    <div class="form-group col-xs-3 preview">
                        <div class="logo {{isUploading ? 'loading-overlay' : ''}}">
                            <i class="btn btn-link nav-link sprite icons--profile" *ngIf="!fullPath"></i>
                            <div class="spinning-circle">
                            </div>
                            <img *ngIf="fullPath" src="{{ fullPath }}" class="icons--profile" />
                            <input class="hidden-xs-up" formControlName="logo" value="{{logo}}" />
                            <i (click)="removeLogo()" class="logo__remove far fa-times-circle" *ngIf="fullPath"></i>
                        </div>
                    </div>
                    <div class="form-group col-xs-9">
                        <image-upload
                                *ngIf="params['action'] !== 'read'"
                                class="image-upload-extension"
                                [headers]="{ 'Authorization': 'Bearer ' + token }"
                                [max]="10"
                                [extensions]="['jpg','jpeg','png']"
                                [url]="authService.baseUrl + 'clinics/upload/' + (params['id'] ? params['id'] : 0)"
                                [buttonCaption]="('Select a picture to upload' | translate)"
                                (uploadFinished)="logoUploaded.emit($event)"
                                (uploadStateChanged)="avatarUploading.emit($event)"
                                [preview]="false"
                                dropBoxMessage="">
                        </image-upload>
                        {{ 'Max file size: 2MB. Recommend format: jpg, jpeg and png' | translate }}
                    </div>
                </div>
                <div class="row">
                    <div #nameFG class="form-group col-xs-8">
                        <mat-form-field class="ps-content">
                            <input type="text" matInput [placeholder]="'Clinic Name' | translate"
                                   formControlName="name"
                                   (focus)="onInputFocused($event, nameFG)"/>
                            <small class="form-text text-danger" [hidden]="form.controls['name'].valid
                                                                            || (form.controls['name'].pristine
                                                                            && !formSubmitted)">
                                {{ 'Require at least 3 characters.' | translate }}
                            </small>
                        </mat-form-field>
                    </div>
                    <div #klnicoNameFG class="form-group col-xs-4">
                        <mat-form-field class="ps-content" *ngIf="this.authService.isSuperAdmin">
                            <mat-select [placeholder]="'Customer type' | translate" formControlName="customer_type">
                                <mat-option disabled value="null">
                                    {{ 'Select customer type' | translate }}
                                </mat-option>
                                <mat-option *ngFor="let category of categories" [value]="category['id']">
                                    {{ category['name'] | translate }}
                                </mat-option>
                            </mat-select>
                            <small class="form-text text-danger" [hidden]="form.controls.customer_type.valid || (form.controls.customer_type.pristine && !formSubmitted)">
                                {{ 'Category is required.' | translate }}
                            </small>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-xs-8">
                        <mat-form-field class="ps-content">
                            <mat-select [placeholder]="'Patient Transfer Host' | translate"
                                        formControlName="patient_transfer_host_id">
                                <mat-option disabled value="null">
                                    {{ 'Select Patient Transfer Host' | translate }}
                                </mat-option>
                                <mat-option *ngFor="let assisgnee of clinicUsers" [value]="assisgnee['id']">
                                    {{ assisgnee['official_name'] }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div #customerIdFG class="form-group col-xs-4">
                        <mat-form-field class="ps-content">
                            <input type="text" matInput [placeholder]="'Customer ID' | translate"
                                   formControlName="customer_id"
                                   (focus)="onInputFocused($event, customerIdFG)"/>
                            <small class="form-text text-danger" [hidden]="form.controls.customer_id.valid || (form.controls.customer_id.pristine && !formSubmitted)">
                                <ng-template [ngIf]="form.controls.customer_id.hasError('required') || form.controls.customer_id.hasError('minlength')">
                                    {{ 'Require at least 3 characters.' | translate }}
                                </ng-template>
                                <ng-template [ngIf]="form.controls.customer_id.hasError('pattern')">
                                    {{ 'Please insert numbers only.' | translate }}
                                </ng-template>
                            </small>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div #emailFG class="form-group col-xs-8">
                        <mat-form-field class="ps-content">
                            <input type="email" matInput [placeholder]="'Admin Contact Email' | translate" formControlName="admin_email" (focus)="onInputFocused($event, emailFG)"/>
                        </mat-form-field>
                    </div>
                    <div #phoneNumFG class="form-group col-xs-4">
                        <mat-form-field class="ps-content">
                            <input type="tel" matInput [placeholder]="'Phone Number' | translate" formControlName="phone" (focus)="onInputFocused($event, phoneNumFG, true)"/>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div #addressFG class="form-group col-xs-8">
                        <mat-form-field class="ps-content">
                            <input type="text" matInput [placeholder]="'Address' | translate" formControlName="address" (focus)="onInputFocused($event, addressFG, true)"/>
                        </mat-form-field>
                    </div>
                    <div class="form-group col-xs-4">
                        <mat-form-field class="ps-content">
                            <mat-select [placeholder]="'Clinic Default Language' | translate" required="required" formControlName="language_id">
                                <mat-option disabled value="null">
                                    {{ 'Select Default Language' | translate }}
                                </mat-option>
                                <mat-option *ngFor="let language of languages" [value]="language['id']">
                                    {{ language['name'] | translate }}
                                </mat-option>
                            </mat-select>
                            <small class="form-text text-danger" [hidden]="form.controls.language_id.valid || (form.controls.language_id.pristine && !formSubmitted)">
                                {{ 'Default language is required.' | translate }}
                            </small>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div #postalCodeFG class="form-group col-xs-4">
                        <mat-form-field class="ps-content">
                            <input type="text" matInput [placeholder]="'Postal Code' | translate" formControlName="postal_code" (focus)="onInputFocused($event, postalCodeFG, true)"/>
                            <small class="form-text text-danger" [hidden]="form.controls.postal_code.valid || (form.controls.postal_code.pristine && !formSubmitted)">
                                {{ 'Postal code must be numbers.' | translate }}
                            </small>
                        </mat-form-field>
                    </div>
                    <div #cityFG class="form-group col-xs-4">
                        <mat-form-field class="ps-content">
                            <input type="text" matInput [placeholder]="'City' | translate" formControlName="city" (focus)="onInputFocused($event, cityFG, true)"/>
                            <small class="form-text text-danger" [hidden]="form.controls.city.valid || (form.controls.city.pristine && !formSubmitted)">
                                {{ 'City is required.' | translate }}
                            </small>
                        </mat-form-field>
                    </div>
                    <div class="form-group col-xs-4">
                        <mat-form-field class="ps-content">
                            <mat-select [placeholder]="'Country' | translate" required="required" formControlName="country_id">
                                <mat-option disabled value="null">
                                    {{ 'Select Country' | translate }}
                                </mat-option>
                                <mat-option *ngFor="let country of countries" [value]="country['id']">
                                    {{ country['name'] | translate }}
                                </mat-option>
                            </mat-select>
                            <small class="form-text text-danger" [hidden]="form.controls.country_id.valid || (form.controls.country_id.pristine && !formSubmitted)">
                                {{ 'Country is required.' | translate }}
                            </small>
                        </mat-form-field>
                    </div>
                </div>
                <div *ngIf="this.authService.isSuperAdmin && params['action'] !== 'read'" class="row">
                    <div class="form-group col-xs-6">
                        <div class="row">
                            <div class="label col-xs-12">{{ 'SR invitation by letter' | translate }}</div>
                            <div class="col-xs-12">
                                <div class="row">
                                    <div class="col-xs-12">
                                        <input type="range" min="0" max="1" step="1" formControlName="show_se_pdf"/>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xs-6" [class.label--bold]="form.controls.show_se_pdf.value === 0">
                                        {{ 'Not allowed' | translate }}
                                    </div>
                                    <div class="col-xs-6 text-xs-right" [class.label--bold]="form.controls.show_se_pdf.value === 1">
                                        {{ 'Allowed' | translate }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-xs-6">
                        <label class="label--highlight">{{ 'Important' | translate }}</label>
                        <p class="text-muted">
                            {{ 'The invitation by letter allows users to generate PDFs that can be printed out and sent to patients by letter, instead of emails.' | translate }}
                        </p>
                    </div>
                </div>
                <div *ngIf="this.authService.isSuperAdmin && params['action'] !== 'read'" class="row">
                    <div class="form-group col-xs-6">
                        <div class="row">
                            <div class="label col-xs-12">{{ 'Diagnosis proposals' | translate }}</div>
                            <div class="col-xs-12">
                                <div class="row">
                                    <div class="col-xs-12">
                                        <input type="range" min="0" max="1" step="1" formControlName="show_proposal_diagnosis"/>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xs-6" [class.label--bold]="form.controls.show_proposal_diagnosis.value === 0">
                                        {{ 'Hide' | translate }}
                                    </div>
                                    <div class="col-xs-6 text-xs-right" [class.label--bold]="form.controls.show_proposal_diagnosis.value === 1">
                                        {{ 'Show' | translate }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-xs-6">
                        <label class="label--highlight">{{ 'Important' | translate }}</label>
                        <p class="text-muted">
                            {{ 'Diagnosis proposals can be displayed by the system under “Diagnoses”. This is intended to optionally offer users assistance in making a diagnosis.' | translate }}
                        </p>
                    </div>
                </div>
                <div *ngIf="params['action'] === 'read' && false" class="row">
                    <div class="form-group col-xs-4">
                        <label>{{ 'Login Type' | translate }}</label>
                        <input type="text" value="{{login_type ? ('Extended' | translate) : ('Simple' | translate)}}" disabled="true" />
                    </div>
                    <div class="form-group col-xs-4">
                        <label>{{ 'Clinic Security' | translate }}</label>
                        <input type="text" value="{{security ? ('Closed' | translate) : ('Open' | translate)}}" disabled="true" />
                    </div>
                </div>
                <div *ngIf="params['action'] !== 'read'" class="row">
                    <div class="form-group col-xs-6">
                        <div class="row">
                            <div class="label col-xs-12">{{ 'Login Type' | translate }}</div>
                            <div class="col-xs-12">
                                <div class="row">
                                    <div class="col-xs-12">
                                        <input type="range" min="0" max="1" step="1" formControlName="login_type"/>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xs-6" [class.label--bold]="form.controls.login_type.value === 0">
                                        {{ 'Simple' | translate }}
                                    </div>
                                    <div class="col-xs-6 text-xs-right" [class.label--bold]="form.controls.login_type.value === 1">
                                        {{ 'Extended' | translate }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-xs-6">
                        <label class="label--highlight">{{ 'Important' | translate }}</label>
                        <p class="text-muted">
                            {{ 'Simple login is per email address and password.' | translate }}
                            <br/> {{ 'Extended is two-leveled and adds a SMS verification code level.' | translate }}
                        </p>
                    </div>
                </div>
                <div *ngIf="params['action'] !== 'read' && false" class="row">
                    <div class="form-group col-xs-6">
                        <div class="row">
                            <label class="col-xs-5">{{ 'Clinic Security' | translate }}</label>
                            <div class="col-xs-7">
                                <div class="form-control-static">
                                    <input type="range" min="0" max="1" step="1" formControlName="security"/>
                                </div>
                                <div class="row">
                                    <div class="col-xs-6" [class.label--bold]="form.controls.security.value === 0">
                                        {{ 'Open' | translate }}
                                    </div>
                                    <div class="col-xs-6 text-xs-right" [class.label--bold]="form.controls.security.value === 1">
                                        {{ 'Closed' | translate }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-xs-6">
                        <label class="label--highlight">{{ 'Important' | translate }}</label>
                        <p class="text-muted">
                            {{ 'In an open clinic, doctors have access to all patients of the clinic.' | translate }}
                            <br/> {{ 'In a closed clinic.' | translate }}
                        </p>
                    </div>
                </div>
                <div *ngIf="this.authService.isSuperAdmin && params['action'] !== 'read'" class="row">
                    <div class="form-group col-xs-6">
                        <div class="row">
                            <div class="label col-xs-12">{{ 'Show different self-report success page' | translate }}</div>
                            <div class="col-xs-12">
                                <div class="row">
                                    <div class="col-xs-12">
                                        <input type="range" min="0" max="1" step="1" formControlName="show_different_sr_success_page" (change)="changeSRMethod()"/>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xs-6" [class.label--bold]="form.controls.show_different_sr_success_page.value === 0">
                                        {{ 'Hide' | translate }}
                                    </div>
                                    <div class="col-xs-6 text-xs-right" [class.label--bold]="form.controls.show_different_sr_success_page.value === 1">
                                        {{ 'Show' | translate }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-xs-6">
                        <mat-form-field class="ps-content" *ngIf="form.controls.show_different_sr_success_page.value === 1">
                            <input type="text" matInput [placeholder]="'SR success URL' | translate" formControlName="sr_success_url" required="required"/>
                            <small class="form-text text-danger" [hidden]="form.controls.sr_success_url.valid || (form.controls.sr_success_url.pristine && !formSubmitted)">
                                {{ 'SR success url is required.' | translate }}
                            </small>
                        </mat-form-field>
                    </div>
                </div>
                <div *ngIf="this.authService.isSuperAdmin && params['action'] !== 'read'" class="row">
                    <div class="form-group col-xs-6 mb-2">
                        <div class="row">
                            <div class="label col-xs-12">{{ 'External patient registration' | translate }}</div>
                            <div class="col-xs-12">
                                <div class="row">
                                    <div class="col-xs-12">
                                        <input type="range" min="0" max="1" step="1" formControlName="b2c_register"/>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xs-6" [class.label--bold]="form.controls.b2c_register.value === 0">
                                        {{ 'Not allowed' | translate }}
                                    </div>
                                    <div class="col-xs-6 text-xs-right" [class.label--bold]="form.controls.b2c_register.value === 1">
                                        {{ 'Allowed' | translate }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-xs-6" *ngIf="form.controls.b2c_register.value === 1">
                        <div class="row">
                            <div class="label col-xs-12">{{ 'Auto assign to transfer host' | translate }}</div>
                            <div class="col-xs-12">
                                <div class="row">
                                    <div class="col-xs-12">
                                        <input type="range" min="0" max="1" step="1" formControlName="auto_assign_to_transfer_host"/>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xs-6" [class.label--bold]="form.controls.auto_assign_to_transfer_host.value === 0">
                                        {{ 'No' | translate }}
                                    </div>
                                    <div class="col-xs-6 text-xs-right" [class.label--bold]="form.controls.auto_assign_to_transfer_host.value === 1">
                                        {{ 'Yes' | translate }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="this.authService.isSuperAdmin && params['action'] !== 'read'" class="row">
                    <div class="form-group col-xs-6" *ngIf="form.controls.b2c_register.value === 1">
                        <mat-form-field class="ps-content" >
                            <mat-select [placeholder]="'Register templates' | translate" formControlName="b2c_register_template_name">
                                <mat-option *ngFor="let template of registrationTemplates" [value]="template['value']">
                                    {{ template['name'] | translate }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="form-group col-xs-6" *ngIf="form.controls.b2c_register.value === 1">
                        <mat-form-field class="ps-content">
                            <input type="text" matInput [placeholder]="'Redirect URL' | translate" formControlName="b2c_redirect_url"/>
                            <small class="form-text text-danger" [hidden]="form.controls.b2c_redirect_url.valid || (form.controls.b2c_redirect_url.pristine && !formSubmitted)">
                                {{ 'SR success url is required.' | translate }}
                            </small>
                        </mat-form-field>
                    </div>
                </div>
                <div *ngIf="this.authService.isSuperAdmin && params['action'] !== 'read'" class="row">
                    <div #emergencyLink class="form-group col-xs-6" *ngIf="form.controls.b2c_register.value === 1">
                        <mat-form-field class="ps-content">
                            <input type="text" matInput [placeholder]="'Emergency Link' | translate" formControlName="emergency_link" (focus)="onInputFocused($event, emergencyLink, true)"/>
                        </mat-form-field>
                    </div>
                    <div #emergencyPhone class="form-group col-xs-6" *ngIf="form.controls.b2c_register.value === 1">
                        <mat-form-field class="ps-content">
                            <input type="text" matInput [placeholder]="'Emergency Phone' | translate" formControlName="emergency_phone" (focus)="onInputFocused($event, emergencyPhone, true)"/>
                        </mat-form-field>
                    </div>
                </div>
                <div *ngIf="this.authService.isSuperAdmin && params['action'] !== 'read'" class="row">
                    <div #srLimit class="form-group col-xs-6" *ngIf="form.controls.b2c_register.value === 1">
                        <mat-form-field class="ps-content">
                            <input type="text" matInput [placeholder]="'SR limit' | translate" formControlName="sr_limit" (focus)="onInputFocused($event, srLimit, true)"/>
                        </mat-form-field>
                    </div>
                    <div #srLimitTimeline class="form-group col-xs-6">
                        <mat-form-field class="ps-content" *ngIf="form.controls.b2c_register.value === 1">
                            <input type="text" matInput [placeholder]="'SR limit duration (days)' | translate" formControlName="sr_limit_timeline" (focus)="onInputFocused($event, srLimitTimeline, true)"/>
                        </mat-form-field>
                    </div>
                </div>
                <div *ngIf="this.authService.isSuperAdmin && params['action'] !== 'read'" class="row">
                    <div class="form-group col-xs-6">
                        <div class="row">
                            <div class="label col-xs-12">{{ 'Documents' | translate }}</div>
                            <div class="col-xs-12">
                                <div class="row">
                                    <div class="col-xs-12">
                                        <input type="range" min="0" max="1" step="1" formControlName="show_patient_document"/>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xs-6" [class.label--bold]="form.controls.show_patient_document.value === 0">
                                        {{ 'Hide' | translate }}
                                    </div>
                                    <div class="col-xs-6 text-xs-right" [class.label--bold]="form.controls.show_patient_document.value === 1">
                                        {{ 'Show' | translate }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-xs-6">
                        <div class="row">
                            <div class="label col-xs-12">{{ 'Info Video-Call' | translate }}</div>
                            <div class="col-xs-12">
                                <div class="row">
                                    <div class="col-xs-12">
                                        <input type="range" min="0" max="1" step="1" formControlName="show_patient_video_call"/>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xs-6" [class.label--bold]="form.controls.show_patient_video_call.value === 0">
                                        {{ 'Hide' | translate }}
                                    </div>
                                    <div class="col-xs-6 text-xs-right" [class.label--bold]="form.controls.show_patient_video_call.value === 1">
                                        {{ 'Show' | translate }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-12 text-xs-right">
                        <button type="button" class="btn btn-secondary" (click)="hideClinicEditModal()">
                            {{ 'Cancel' | translate }}
                        </button>
                        <button *ngIf="params['action'] !== 'read'" type="button" class="btn btn-primary" (click)="submit.emit()">
                            {{ 'Submit' | translate }}
                        </button>
                    </div>
                </div>
            </form>
        </ng-template>
    </div>
</div>