import {
    Component,
    ViewChild,
    ViewContainerRef,
    Input,
    ComponentFactoryResolver,
    OnChanges
} from '@angular/core';

@Component({
    selector: 'app-dynamic-view',
    template: `
        <div #dynamicView></div>
    `
})

export class DynamicViewComponent implements OnChanges {
    @Input() component: any;

    @ViewChild('dynamicView', { read: ViewContainerRef, static: true }) dynamicView;

    constructor(private service: ComponentFactoryResolver) { }

    renderComponent(component) {
        const factory = this.service.resolveComponentFactory(component);
        this.dynamicView.createComponent(factory);
    }

    ngOnChanges() {
        this.renderComponent(this.component);
    }
}
