import {Component, Input } from '@angular/core';

@Component({
    selector: 'confirm-modal-body',
    templateUrl: './confirm-modal-body.component.html',
})
export class ConfirmModalBodyComponent {
    @Input() responseMessage = '';
    @Input() params = {};
}
