<footer class="footer">
    <div class="container" *ngIf="!seAuthPage">
        <div class="row" *ngIf="isLoggedIn && !authService.isPatient">
            <div class="footer--right col-xs-12 col-md-6 pl-0 pr-0 text-xs-center text-md-right">
                <div class="footer--right--item pl-0 col-md-3 col-xs-12">
                    <a [routerLink]="'/terms-condition'">
                        {{ 'General terms and conditions' | translate }}
                    </a>
                </div>
                <div class="footer--right--item col-md-3 col-xs-12">
                    <a [routerLink]="'/privacy-statement'">
                        {{ 'Privacy statement' | translate }}
                    </a>
                </div>
                <div class="footer--right--item col-md-3 col-xs-12">
                    <a [routerLink]="'/legal-notice'">
                        {{ 'Legal Notice' | translate }}
                    </a>
                </div>
                <div class="footer--right--item pl-0 pr-0 col-md-3 col-xs-12">
                    <a [routerLink]="'/device-label'">
                        {{ 'Device Label' | translate }}
                    </a>
                </div>
            </div>

            <div class="copyright col-md-4 col-xs-12 footer--left pull-left exclude-translate text-xs-center text-md-left" *ngIf="isLoggedIn && !sePage">
                {{ releasedVersion }} &copy; {{ thisYear }} Klenico Health AG
            </div>
        </div>
        <div *ngIf="!isLoggedIn || authService.isPatient">
            <!-- SE page -->
            <div class="row alignment" *ngIf="sePage">
                <div class="footer--right col-xs-12 col-md-6 pl-0 pr-0 text-xs-center text-md-right">
                        <div class="footer--right--item pl-0 col-md-3 col-xs-12">
                            <a href="javascript:void(0)" (click)="openTermsConditionModal()">
                                {{ 'General terms and conditions' | translate }}
                            </a>
                        </div>
                        <div class="footer--right--item col-md-3 col-xs-12">
                            <a href="javascript:void(0)" (click)="openPrivacyStatementModal()">
                                {{ 'Privacy statement' | translate }}
                            </a>
                        </div>
                        <div class="footer--right--item col-md-3 col-xs-12">
                            <a href="javascript:void(0)" (click)="openLegalNoticeModal()">
                                {{ 'Legal Notice' | translate }}
                            </a>
                        </div>
                        <div class="footer--right--item pl-0 pr-0 col-md-3 col-xs-12">
                            <a href="javascript:void(0)" (click)="openDeviceLabelModal()">
                                {{ 'Device Label' | translate }}
                            </a>
                        </div>
                    </div>

                <div class="copyright col-md-4 col-xs-12 footer--left pull-left exclude-translate text-xs-center text-md-left">
                    {{ releasedVersion }} &copy; {{ thisYear }} Klenico Health AG
                </div>
            </div>
        </div>
    </div>
    <div class="container d-flex" *ngIf="seAuthPage">
        <div class="footer_klenico-text">
            <a href="https://klenico.com" target="_blank" rel="noopener" *ngIf="getLanguage == 'de'">
                klenico.com 
            </a>
            <a href="https://klenico.com/en/" target="_blank" rel="noopener" *ngIf="getLanguage == 'en'">
                klenico.com 
            </a>
        </div>
    </div>
    <app-dashboard-information #dashboardInformationComponent></app-dashboard-information>
</footer>