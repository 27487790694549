import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { Breadcrumb } from './breadcrumb.model';
import { BreadcrumbService } from './breadcrumb.service';
import { AuthService } from '../../core/auth.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent {

  breadcrumbs$: Observable<Breadcrumb[]>;
  @Input() firstNode?: {
    label : string,
    url : string
  };
  @Input() data?: {
      label : string,
      url : string
  };
  rootAdmin = {
    label : 'Users list',
    url : '/home'
  };
  rootDoctor = {
    label : 'Patient list',
    url : '/patients'
  };

  constructor(
      private readonly breadcrumbService: BreadcrumbService,
      private authService: AuthService) {
    this.breadcrumbs$ = breadcrumbService.breadcrumbs$;
  }
}
