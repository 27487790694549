<div *ngIf="!appService.isLoading" class="auth__form box-shadow">
    <div class="auth__wrapper">
        <div class="auth__inner">
            <div class="card auth__form__card">
                <div class="auth__header">
                    <a [routerLink]="['/home']" class="auth__header__brand sprite"></a>
                </div>
                <div class="card-block mat-form">
                    <div *ngIf="errorMessage" class="alert alert-danger" role="alert">
                        <span class="fas fa-exclamation-circle" aria-hidden="true"></span> {{ errorMessage | translate }}
                    </div>
                    <div>
                        <div *ngIf="successMessage" class="alert alert-success" role="alert">
                            <span class="fas fa-exclamation-circle" aria-hidden="true"></span> {{ successMessage | translate }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>