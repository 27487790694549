import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable()
export class AuthSelfEvaluationService {

    constructor(public http: HttpClient, @Inject('baseUrl') public baseUrl: string) {
    }

    login(code: string, language : any ) {
        let url = this.baseUrl + 'patients/self-evaluation/auth';

        let httpParams = new HttpParams()
            .append('code', code)
            .append('language', language)

        let body = httpParams.toString();

        let headerOptions = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        };

    const httpOptions = {
            headers: new HttpHeaders(headerOptions)
        }

        return this.http.post(url, body, httpOptions);
    }
}
