import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SlideSelectPatientComponent } from './slide-select-patient/slide-select-patient.component';
import { SlideCreateQuestionreComponent } from './slide-create-questionnaire/slide-create-questionnaire.component';
import { SlideVisualizationComponent } from './slide-visualization/slide-visualization.component';
import { SlidePackagesComponent } from './slide-packages/slide-packages.component';
import { SlideLongitudinalMeasurementComponent } from './slide-longitudinal-measurement/slide-longitudinal-measurement.component';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule
    ],
    declarations: [
        SlideSelectPatientComponent,
        SlideCreateQuestionreComponent,
        SlideVisualizationComponent,
        SlidePackagesComponent,
        SlideLongitudinalMeasurementComponent
    ],
    exports: [
        SlideSelectPatientComponent,
        SlideCreateQuestionreComponent,
        SlideVisualizationComponent,
        SlidePackagesComponent,
        SlideLongitudinalMeasurementComponent
    ],
    entryComponents: [
        SlideSelectPatientComponent,
        SlideCreateQuestionreComponent,
        SlideVisualizationComponent,
        SlidePackagesComponent,
        SlideLongitudinalMeasurementComponent
    ]
})

export class SlideDashboardInformationModule { }