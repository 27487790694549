// angular
import {Component, OnInit, Input, Output, EventEmitter, ElementRef, Inject} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// services
import { SelfEvaluationService } from '../self-evaluation.service';
import { AuthService } from '../../core/auth.service';

// others
import { SelectionComponent } from '../selection.component';
import { AppService } from '../../app.service';
import { TranslateService } from '@ngx-translate/core';
import {ENV} from "../../app.env";
import { CoreService } from '../../core/core.service';
import * as _ from 'lodash';

@Component({
    selector: 'self-evaluation-menu',
    templateUrl: 'self-evaluation-menu.component.html',
    styleUrls: ['self-evaluation-menu.component.scss']
})
export class SelfEvaluationMenuComponent  extends SelectionComponent implements OnInit {

    readonly contentHeaderNormal = [
        '',
        'Overview statements of current condition',
        'Detailed statements of current condition',
        'Rating of the statements',
        'Statement about past condition',
        'Statement about past condition'
    ];

    readonly contentHeaderRepeated = [
        '',
        'Previous agreed on statements',
        'Statements on current condition',
        'Detailed statements of current condition',
        'Rating of the statements',
        'Statement about past condition',
    ];

    readonly contentBodyNormal = [
        '',
        'Please think about the past 14 days. Choose everything that applies to you. Selected sentences can be deselected by clicking on them again.',
        'Please think about the past 14 days. Choose everything that applies to you. Selected sentences can be deselected by clicking on them again.',
        'Now, you can rate which sentences describe.',
        'The last part is about statements of your past, which don\'t impair you in the present anymore. Please click on the statements that apply to you.',
        'The last part is about statements of your past, which don\'t impair you in the present anymore. Please click on the statements that apply to you.'
    ];

    readonly contentBodyRepeated = [
        '',
        'Please think about the past 14 days. Choose everything that applies to you. Selected sentences can be deselected by clicking on them again.',
        'Please think about the past 14 days. Choose everything that applies to you. Selected sentences can be deselected by clicking on them again.',
        'Now, you can rate which sentences describe.',
        'The last part is about statements of your past, which don\'t impair you in the present anymore. Please click on the statements that apply to you.',
        'The last part is about statements of your past, which don\'t impair you in the present anymore. Please click on the statements that apply to you.'
    ];

    readonly contentBodyStudy = [
        '',
        'STUDY Please think about the past 14 days. Choose everything that applies to you. Selected sentences can be deselected by clicking on them again.',
        'STUDY Please think about the past 14 days. Choose everything that applies to you. Selected sentences can be deselected by clicking on them again.',
        'STUDY Now, you can rate which sentences describe.',
        'STUDY The last part is about statements of your past, which don\'t impair you in the present anymore. Please click on the statements that apply to you.',
        'STUDY The last part is about statements of your past, which don\'t impair you in the present anymore. Please click on the statements that apply to you.'
    ];

    contentHeader = this.contentHeaderNormal;
    contentBody = this.contentBodyNormal;
    clinic: any;
    languages =  [{
        id: 1,
        locale: "de_CH",
        code: "DE",
        name: 'German'
    }, {
        id: 4,
        locale: "en_GB",
        code: "EN",
        name: 'English'
    }];

    waitingTranslation = false;
    activeModal = '';
    thisYear ;
    releasedVersion =  ENV.buildVersion;

    @Input() step : number;
    @Output() closeMenu = new EventEmitter<any>();
    @Output() openLinks = new EventEmitter<any>();

    constructor(
        public elementRef: ElementRef,
        public service: SelfEvaluationService,
        public router: Router,
        public route: ActivatedRoute,
        public authService: AuthService,
        public appService: AppService,
        public coreService: CoreService,
        public translateService : TranslateService,
    ) {
        super(elementRef, service, router, route, authService, appService, coreService, translateService);
    }

    ngOnInit() {

        if (this.appService.isRepeatedSE) {
            this.contentHeader = _.cloneDeep(this.contentHeaderRepeated);
            this.contentBody = _.cloneDeep(this.contentBodyRepeated);
        }

        const currentTime = new Date();
        this.thisYear = currentTime.getFullYear();
        this.setSEType();

        this.appService.SEStep.subscribe( step => {
            if(localStorage.getItem('clinic')){
                this.clinic = JSON.parse(localStorage.getItem('clinic'));
            }
        });

        if (localStorage.getItem('languages')) {
            this.languages =  JSON.parse(localStorage.getItem('languages'));
        }
        if (localStorage.getItem('wglang')) {
            localStorage.removeItem('wglang');
        }
        let _self = this;
        this.appService.SEStep.subscribe(() => {
            if (this.appService.isRepeatedSE && this.appService.SEData.is_finish_first_repeated_step ) {
                this.contentHeader = _.cloneDeep(this.contentHeaderRepeated);
                this.contentBody = _.cloneDeep(this.contentBodyRepeated);
                this.contentHeader.splice(this.appService.repeatSteps.SELECTED_SMOKE_DETECTOR, 1);
                this.contentBody.splice(this.appService.repeatSteps.SELECTED_SMOKE_DETECTOR, 1);
            }
        });
    }

    // initWeglot(lang = this.appService.activeLanguage){
    //     this.appService.isLoading = true;
    //     let _self = this;
    //     if(!this.weglotKey[lang?.code?.toLowerCase()]){
    //         lang = _self.appService.getCurrentSystemLanguage();
    //     }
    //     if (lang && lang.locale) {
    //         //Init Weglot
    //         Weglot.initialize({
    //             api_key: this.weglotKey[lang?.code?.toLowerCase()],
    //             hide_switcher: false,
    //             getCurrentLang: true
    //         });
    //         _self.appService.activeLanguage = _self.appService.activeLanguage || lang;
    //         Weglot.on("initialized", function () {
    //             if(_self.appService.activeLanguage.locale !== localStorage.getItem('language')){
    //                 return _self.initWeglot(_self.appService.getCurrentSystemLanguage());
    //             }
    //             setTimeout(function(){
    //                 _self.setWeglot(_self.appService.activeLanguage);
    //                 _self.appService.isLoading = false;
    //             }, 500);
    //         });
    //     }
    //     else {
    //         this.appService.isLoading = false;
    //     }
    // }

    getLanguageCode(lang){
        const currentLang = _.find(this.languages, {locale: lang});
        return currentLang.code;
    }

    openModal(value) {
        this.openLinks.emit(value);
        this.activeModal = value;
    }

    // setWeglot(lang) {
    //     if(!Weglot.options.api_key){
    //         return this.initWeglot();
    //     }
    //     this.appService.isLoading = true;
    //     let _self = this;
    //     localStorage.setItem('wglang', lang.code.toLowerCase());
    //     // Default language of Weglot is de so switch both en_GB and de_CH to de
    //     Weglot.switchTo(lang.code.toLowerCase());
    //     _self.appService.isLoading = false;
    //     setTimeout(function () {
    //         _self.waitingTranslation = false;
    //     },3000);
    // }

    changeLanguage(lang){
        this.waitingTranslation = true;
        const currentSystemLanguage = this.appService.getCurrentSystemLanguage();
        if(_.find(this.languages, {locale: lang.locale}) && lang.locale !== currentSystemLanguage.locale){
            return this.switchSystemLang(this.appService.activeLanguage, lang);
        }
    }

    // Remove in future
    // getCurrentWeglotLanguage(){
    //     return localStorage.getItem('wglang') || this.appService.activeLanguage.code.toLocaleLowerCase();
    // }

    switchSystemLang(origin, destination){
        let _self = this;
        this.appService.isLoading = true;
        localStorage.setItem('language', destination.locale);
        // this.setWeglot(destination);
        this.appService.activeLanguage = destination;

        const data = {
            'questionnaire_id': this.appService.SEData?.patient_questionnaire_id,
            'language': destination.locale
        };
        this.service.changeLanguage(data).subscribe(value => {
            localStorage.removeItem('defaultLanguage');
            _self.appService.isLoading = false;
            this.translateService.use(destination.locale);
            location.reload();
        });
    }

    // switchWeglotLang(origin, destination){
    //     return this.setWeglot(destination);
    // }
}
