import { ViewChild, Directive } from '@angular/core';
import {ModalDirective} from "ngx-bootstrap/modal";

@Directive()
export class BaseDialogComponent {
    @ViewChild('modal', { static: true }) protected modal: ModalDirective;

    public params = {
        title: '',
        icon: '',
        content: '',
        description: '',
        submitText: '',
        cancelText: ''
    };

    public alertMessage = null;

    ngAfterViewInit() {
        // prevent users from clicking outside to close the modal since that does not set the url properly
        this.modal.config.backdrop = false;
        this.modal.config.ignoreBackdropClick = true;
        this.modal.config.keyboard = false;
        this.modal.show();
    }

    onCancelAction: Function = () => {
        return;
    }

    onSubmitAction: Function = () => {
        return;
    }
    hideModal() {
        this.modal.hide();
    }

    showAlert(data) {
        this.alertMessage = data;
    }

    setParams(params) {
        this.params = params;
    }

    cancel() {
        this.modal.hide();
        this.onCancelAction();
    }

    onCancel(onCancelAction: Function) {
        this.modal.hide();
        this.onCancelAction = onCancelAction;
    }

    onSubmit(onSubmitAction: Function) {
        this.modal.hide();
        this.onSubmitAction = onSubmitAction;
    }

    submit() {
        this.onSubmitAction();
    }

    get hasAlertMessage() {
        if (this.alertMessage) {
            return true;
        }
        return false;
    }

}
