<div [class]="'row ' + params['class']" *ngIf="!responseMessage">
    <div class="col-xs-12 modal-title">
        {{ params['title'] | translate }}
    </div>
    <div class="col-xs-12 modal-content">
        {{ params['content'] | translate }}
    </div>
    <div class="col-xs-12 modal-description" [innerHTML]="params['description'] | translate">
    </div>
</div>
<div class="row" *ngIf="responseMessage">
    <div class="col-xs-12 modal-title">
        {{ params['title'] | translate }}
    </div>
    <div class="col-xs-12 modal-content">
        {{ responseMessage | translate }}
    </div>
    <div class="col-xs-12 modal-description"></div>
</div>