<section class="auth__form">
    <div class="row auth__header">
        <div class="col-xs-6">
            <a [routerLink]="['/login']" class="sprite auth__header__brand"></a>
        </div>
        <div class="col-xs-6 text-right">
            <a href="https://klenico.com" target="_blank" rel="noopener" class="auth__header__link">
                {{ 'Go to website' | translate}}
                <i class="fas fa-arrow-right mr-l--xs"></i>
            </a>
        </div>
    </div>

    <div class="auth__slider auth__slider--mobile hidden-sm-up">
        <img src="/assets/img/pages/login{{getBackground()}}.webp" width="100%" height="100%" class="auth__slider__image" />
    </div>

    <div class="auth__wrapper">
        <div class="auth__inner">
            <div class="card auth__form__card">
                <div class="card-header auth__form__card__header">
                    <div class="row">
                        <div class="col-xs-12">
                            <h2 class="page-title">
                                {{ 'Forgot password?' | translate}}
                            </h2>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12">
                            <span class="font-se-paragraph">
                                {{ 'No problem. Just enter your email address below — we’ll send you a link to reset it.' | translate }}
                            </span>
                        </div>
                    </div>
                </div>

                <div class="card-block mat-form">
                    <div *ngIf="errorMessage" class="alert alert-danger" role="alert">
                        <span class="fas fa-exclamation-circle" aria-hidden="true"></span>{{ errorMessage | translate }}
                    </div>
                    <div *ngIf="successMessage" class="alert alert-success" role="alert">
                        <span class="fas fa-exclamation-circle" aria-hidden="true"></span>{{ successMessage | translate }}
                    </div>
                    <div class="form-group">
                        <mat-form-field class="ps-content">
                            <input type="email" matInput [placeholder]="'Email Address' | translate" [(ngModel)]="email" (keyDown.Enter)="onClickResetPassword()" (focus)="onInputFocused($event)" autocomplete="off" />
                        </mat-form-field>
                    </div>
                    <div class="form-group text-xs-center">
                        <button (click)="onClickResetPassword()" class="btn btn-primary btn-radius btn-dark" type="button">
                            {{ 'Recover your password' | translate }}
                        </button>
                    </div>
                </div>

                <div class="auth__form__card__footer mr-b--md">
                    <strong>{{ 'You want to register as Health Professional?' | translate }}</strong><br />
                    <span [innerHTML]="'Please contact us.' | translate | safeHtml"></span>
                </div>
            </div>
        </div>
    </div>

    <div class="row auth__footer">
        <div class="col-xs-8">
            <p class="copyright exclude-translate">
                {{ appService.releasedVersion }} &copy; {{ thisYear }} Klenico Health AG
            </p>
        </div>
        <div class="col-xs-4">
            <div class="footer__language">
                <div class="form-group mat-select-pill">
                    <mat-select class="mat-select-pill__select"
                            [value]="appService.activeLanguage.locale"
                            (selectionChange)="appService.setLanguage($event.value)">
                            <mat-option *ngFor="let language of appService.languages"
                                        [value]="language.locale">
                                {{language.name | translate}}
                            </mat-option>
                    </mat-select>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="auth__slider hidden-xs-down">
    <img src="/assets/img/pages/login{{getBackground()}}.webp" width="100%" height="100%" class="auth__slider__image" />
</section>