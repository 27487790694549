import { ViewChild, OnInit, ElementRef, Directive, OnDestroy } from '@angular/core';
import {ModalDirective} from "ngx-bootstrap/modal";
import { ActivatedRoute, Router } from '@angular/router';
import { SelfEvaluationService } from './self-evaluation.service';
import { AuthService } from '../core/auth.service';
import { AppService } from '../app.service';
import { SelfEvaluationConfirmModalComponent } from './shared/self-evaluation-confirm-modal/self-evaluation-confirm-modal.component';
import set = Reflect.set;
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { CoreService } from '../core/core.service';
import * as _ from 'lodash';

declare var window:any;

@Directive()
export class SelectionComponent implements OnInit, OnDestroy {
    itemsGroup = [];
    selectedIds = [];
    itemsRated = [];
    selectedFeelingIds = [];
    selectedSmokeIds = [];
    smokeItems = [];
    selectedCircumstanceIds = [];
    activePage = 1;
    groupSelectedCount = [];
    currentStep: number;
    responseMessage: string;
    isStudy = false;
    pageName = '';
    startTime: Date;
    questionnaireId;
    nextStep = false;
    handleHash: any;
    items : any;
    itemPerPage: number;
    MINUMUM_ITEMS: number;
    RATING_MIN = 2;
    RATING_MAX = 98;
    feelingItemHeight = 100;
    deviceInnerHeight: number;
    totalPage = 0;
    isAcute = 1;
    layer = 2;
    haveAnamnestic = true;

    public STEPS = {
        FEELING_SELECTION: 'acute-feeling',
        FEELING_SELECTION_2ND: 'acute-feeling-2nd',
        FEELING_SMOKE_DETECTOR: 'feeling-smoke-detector',
        ANAMESTIC_SMOKE_DETECTOR: 'anamestic-smoke-detector',
        ANAMESTIC_SELECTION: 'anamestic-feeling',
    };
    apiRoute: string;
    apiUpdateRoute: string;
    pageTypes = this.service.pageTypes;
    type = this.pageTypes.se.type;

    get isLoading() {
        return this.appService.isLoading;
    }

    set isLoading(isLoading) {
        this.appService.isLoading = isLoading;
    }
    token;
    lang = 'de_CH';
    subscription : Subscription;
    echo: any;

    @ViewChild('modal', { static: false }) modal: ModalDirective;
    @ViewChild('confirmModal', /* TODO: add static flag */ {}) confirmModal: SelfEvaluationConfirmModalComponent;

    constructor(
        public elementRef: ElementRef,
        public service: SelfEvaluationService,
        public router: Router,
        public route: ActivatedRoute,
        public authService: AuthService,
        public appService: AppService,
        public coreService: CoreService,
        public translateService: TranslateService
        ) {
        this.itemPerPage = this.service.itemPerPage;
        this.MINUMUM_ITEMS = this.service.MINUMUM_ITEMS;
    }

    ngOnInit() {
        this.isLoading = true;
        this.nextStep = false;
        this.lang = this.translateService.currentLang;

        if(this.appService.isMobile){
            this.deviceInnerHeight = window.innerHeight;
        }
        this.setSEType();

        this.route.params.subscribe(params => {
            this.token = params['token'];
            this.layer = params['layer'] || this.layer;

            // API route base on Step
            this.apiRoute = 'patient-questionnaire/' + this.pageTypes.se['route'] + '/' + this.pageTypes[this.appService.SEType]['smokeDetectorRoute'] + '/';
            this.apiUpdateRoute = 'patient-questionnaire/' + this.pageTypes.se['route'] + '/' + this.pageTypes[this.appService.SEType]['routePost'] + '/';

            return this.getSelectionList();
        });

        this.subscription = this.translateService.onLangChange.subscribe(value => {
            this.lang = value.lang;
            let data = {
                'questionnaire_id' : this.questionnaireId,
                'language' : value.lang
            };
            this.isLoading = true;
            this.service.changeLanguage(data).subscribe(language => {
                this.isLoading = false;
            });
        });

    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }

        if (this.echo) {
            this.echo.leaveChannel('Questionnaire.' + this.questionnaireId);
        }
    }

    setSEType(){
        if((!this.appService.SEType || this.appService.SEType === '') && this.coreService.activatedRouteSnapshot){
            this.type = this.coreService.activatedRouteSnapshot['data']['page'] || this.type;
            this.appService.SEType = this.type;
        }
    }

    getSelectionList(){
        this.apiRoute = this.apiRoute + this.token + '/' + this.isAcute + '/' +  this.layer + '/' + this.itemPerPage;
        // Load selection data
        this.service.getItems(this.apiRoute).subscribe((rs) => {
                if(rs['step'] === this.pageTypes[this.appService.SEType]['showFeedbackStep']){
                    return this.router.navigate([this.pageTypes[this.appService.SEType]['route'] + '/' + this.token]);
                }
                this.itemsGroup = rs['items'] || [];
                this.totalPage = rs['total_page'] || this.itemsGroup.length;

                this.activePage = this.getActivePage(rs['active_page']);
                this.haveAnamnestic = rs['is_have_ananmestic_step'];
                this.items = this.itemsGroup[this.activePage - 1];
                this.currentStep = rs['step'] || this.currentStep;
                this.appService.SEData.is_finish_first_repeated_step = rs['is_finish_first_repeated_step'];
                this.appService.setCurrentSeStep(this.currentStep);
                if(this.currentStep >= this.pageTypes.se.steps.FEELING_SELECTION){
                    this.apiUpdateRoute = 'sr-feelings/' + this.token + '/' + this.isAcute + '/' + this.itemPerPage + '/' + this.layer + '/';
                }

                this.getSelectedItemIds(rs['items']);
                this.isStudy = rs['is_study'];
                this.questionnaireId = rs['patient_questionnaire_id'];

                this.groupSelectedCount[this.activePage] = this.calculateSelectedItem(this.selectedIds);

                this.isLoading = false;
                this.scrollToTop();

                //2157
                this.listenPatientChange(this.questionnaireId, this.token);
            },
            error => {
                alert(this.apiRoute + ' ----- ' +JSON.stringify(error.message));
            });
    }

    getSelectedItemIds(data){
        const arrayItems = _.flattenDeep(data);
        const selectedItems = _.filter(arrayItems, function(item){
            return item && item.selected && item.selected > 0
        });
        this.selectedIds = _.map(selectedItems, 'id');
        this.smokeItems = _.filter(arrayItems, function(item){
            return 'scientific' in item;
        });
    }

    stepNavigate(step) {
        this.nextStep = true;
        if (step && step < 5) {
            return this.router.navigate([this.pageTypes[this.appService.SEType]['route'] + '/' + this.token + '/' + step]);
        }
        return this.router.navigate([this.pageTypes[this.appService.SEType]['route'] + '/' + this.token]);
    }

    selectQuestion(item, isSmoke = false) {
        let selectedIds = this.selectedIds;
        const id  = item.id;
        if (selectedIds.indexOf(id) !== -1) {
            selectedIds.splice(selectedIds.indexOf(id), 1);
            _.remove(this.itemsRated, function(feeling){
                return feeling.id === id
            });
            item['selected'] = 0;
            item['rating'] = 0;
            this.groupSelectedCount[this.activePage]--;
            return false;
        }

        if (!this.groupSelectedCount[this.activePage]) {
            this.groupSelectedCount[this.activePage] = 0;
        }

        this.groupSelectedCount[this.activePage]++;
        selectedIds.push(id);
        if(item.feeling_id){
            this.itemsRated.push(item);
        }
        if(!item['rating']){
            item['rating'] = (this.RATING_MIN + this.RATING_MAX) / 2;
        }

        let domId = 'mat-slider--feeling--' + item.feeling_id;
        let dom = document.getElementById(domId);
        if (dom) {
            dom.focus();
        }
        item['selected'] = 1;
    }

    openConfirm() {
        this.confirmModal.openConfirm();
    }

    hideModal() {
        this.confirmModal.hideModal();
    }

    continueLaterSubmit() {
        this.router.navigate([this.pageTypes[this.appService.SEType]['route'] + '/' + this.token + '/continue-later']);
    }

    backPreviousPage() {
        this.scrollToTop();
        this.activePage--;
        this.saveCountTime();
    }

    saveAndGoNextPage() {
        this.isLoading = true;
        if (this.activePage >= this.itemsGroup.length) {
            this.submitSelection(1);
        } else {
            this.scrollToTop();
            this.submitSelection();
            this.activePage++;
        }

        this.saveCountTime();
    }

    goNextStep() {
        this.stepNavigate(this.currentStep + 1);
    }

    submitSelection(goNextStep = 0, callback: any = false) {
        let selectedFeelings = {
            selected_feelings: this.selectedIds,
            is_step_finished: goNextStep,
            // next_acute_feeling_page: this.activePage,
            // next_anamestic_page: this.activePage,
            next_item : (this.itemPerPage * this.activePage) + 1
        };
        
        this.service.submit(this.apiRoute, selectedFeelings).subscribe((rs) => {

            if (rs['success'] && goNextStep) {
                this.stepNavigate(0);
            }
            this.isLoading = false;


            if (typeof callback === 'function') {
                callback();
            }
        });
    }

    initStartTime() {
        this.startTime = new Date();
    }

    saveCountTime() {
        if (this.startTime && this.questionnaireId) {
            let endTime = new Date();
            let time = endTime.getTime() - this.startTime.getTime();
            time = Math.floor(time / 1000);
            if (this.isStudy && time > 180) {
                time = 180;
            }
            if (time <= 0) {
                time = 1;
            }
            this.service.addSpentTime(this.questionnaireId, time).subscribe(rs => this.startTime = endTime);
            this.startTime = new Date();
        }
    }

    popupReload(e) {
        if(!e) e = window.event;
        //e.cancelBubble is supported by IE - this will kill the bubbling process.
        e.cancelBubble = true;
        e.returnValue = 'You sure you want to leave/refresh this page?';
        e.preventDefault();

        //e.stopPropagation works in Firefox.
        if (e.stopPropagation) {
            e.stopPropagation();
            e.preventDefault();
        }
    }

    preventBack(e){
        let keycode;
        if (window.event)
            keycode = window.event.keyCode;
        else if (e){
            keycode = e.which;
        }
        if ((e.which || e.keyCode) == 116 || (e.ctrlKey && keycode == 82) || (window.event.ctrlKey && keycode == 82)) {
            // Pressing F5 or Ctrl+R
            e.preventDefault();
        }
    }

    noBackPlease() {
        //Fix error on safari
        history.pushState(null, document.title, location.href);
        window.onpopstate = function () {
            history.pushState(null, document.title, location.href);
        };
    };

    notAllowBack(_hash){
        if(!this.nextStep){
            if (window.location.hash !== _hash) {
                window.location.hash = _hash;
            }
        }
        else{
            clearInterval(this.handleHash);
        }
    }

    allowBack(){
        let url = window.location.href.split('#')[0];
        window.location.href = url;
        return;
    }

    // Get maximum feeling items base on Mobile device
    // Currently not neccessary when Implement new UI #6278
    getMaximumnumber(){
        const deviceWidth = (window.innerWidth > 0) ? window.innerWidth : screen.width;
        // device Width > 576 : Tablet
        if(deviceWidth > 576){
            return this.itemPerPage;
        }
        let wrapperHeight =  this.appService.deviceInnerHeight > 0 ? this.appService.deviceInnerHeight : this.elementRef.nativeElement.querySelector('.wrapper__selfevaluation').offsetHeight;
        let progressBarHeight =  this.elementRef.nativeElement.querySelector('.se-progress-bar').offsetHeight;
        let headerTitleHeight =  this.elementRef.nativeElement.querySelector('.header-title').offsetHeight;
        let actionBtnHeight =  this.elementRef.nativeElement.querySelector('.wrapper__btns').offsetHeight;
        let itemPerScreen = (wrapperHeight - progressBarHeight - headerTitleHeight - actionBtnHeight) / this.feelingItemHeight;
        let itemPerPage = ( Math.floor(itemPerScreen) * 2 ) - 1;
        return itemPerPage < this.MINUMUM_ITEMS ? this.MINUMUM_ITEMS : itemPerPage; // Remove 1 items for browser footer on Iphone
    }

    scrollToTop() {
        var scrollStep = -window.scrollY / (500 / 15),
            scrollInterval = setInterval(function(){
            if ( window.scrollY != 0 ) {
                window.scrollBy( 0, scrollStep );
            }
            else clearInterval(scrollInterval); 
        },15);

        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
        document.getElementById("top-el").scrollIntoView();
        setTimeout(function(){
            this.deviceInnerHeight = window.innerHeight;
        }, 200);
    }

    getActivePage(activePage){
        if(activePage > this.itemsGroup.length){
            const emptyPage = this.totalPage - this.itemsGroup.length;
            activePage = activePage - emptyPage;
        }
        return activePage;
    }

    calculateSelectedItem(selectedIds) {
        if(!this.itemsGroup || this.itemsGroup.length === 0){
            return [];
        }
        let items = this.itemsGroup[this.activePage - 1];
        return items.filter( item => { return selectedIds?.indexOf(item.id) != -1 }).length;
    }

    listenPatientChange(id, token) {
        this.echo = this.appService.initEcho();
        const self = this;
        this.echo.channel('Questionnaire.' + id).listen('InactivePatient', function (data) {
            self.router.navigate([self.pageTypes[self.appService.SEType]['route'] + '/' + token]);
        });
    }

    onRatingChange($event, item){
        if(item.rating >= this.RATING_MIN && !item.selected){
            item.selected = 1;
            return this.selectQuestion(item);
        }
    }
}
