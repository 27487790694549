///<reference path="dialog.interface.ts"/>
import {
    Component, ViewContainerRef, ComponentFactoryResolver, ViewChild,
} from '@angular/core';

import {AlertComponent} from './alert/alert.component';
import {ConfirmComponent} from './confirm/confirm.component';

@Component({
    selector: 'dialog-component',
    templateUrl: './dialog.component.html'
})

export class DialogComponent {
    @ViewChild('body', { read: ViewContainerRef, static: false }) body;

    constructor(public componentFactoryResolver: ComponentFactoryResolver) {

    }

    openAlert(params = {}) {
        return this.createDialogComponent(AlertComponent, params);
    }

    openConfirm(params = {}) {
        return this.createDialogComponent(ConfirmComponent, params);
    }

    openComponent(component, params = {}) {
        return this.createDialogComponent(component, params);
    }

    createDialogComponent(component, params) {
        const factory = this.componentFactoryResolver.resolveComponentFactory(component);
        this.body.clear();
        const componentRef = this.body.createComponent(factory);
        const instance = componentRef.instance;
        instance.setParams(params);
        return instance;
    }
}
