<div class="multi-clinics" *ngIf="clinics.length">
    <div class="multi-clinics__wrapper">
        <div class="multi-clinics__tooltip"><span></span></div>
        <mat-form-field class="ps-content">
            <mat-select [placeholder]="'Institution/ Clinic' | translate" required="required">
                <mat-option value="null">
                    {{ 'Select Clinic' | translate }}
                </mat-option>
                <mat-option *ngFor="let clinic of clinics" [value]="clinic['id']">
                    {{ clinic['name'] }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>