import { Injectable } from '@angular/core';
import { SecureApi } from '../core/secure.api';

@Injectable()
export class UsersService {
    constructor(public secureApi: SecureApi) { }

    getAll(url: string) {
        return this.secureApi.get(url);
    }

    search(term: string, roleId: number, clinicId: number) {
        return this.secureApi.get('users/search/' + term + '?role_id=' + roleId + '&clinic_id=' + clinicId)['items'];
    }

    read(id = 0) {
        return this.secureApi.get('users/read/' + id);
    }

    inActiveDashboardInformation() {
        return this.secureApi.put('users/inactivate-dashboard-information', null, null);
    }

    saveSetting(key, value) {
        return this.secureApi.post('users/settings', {
            key:key,
            value: value
        }, false);
    }

    saveStatistics(key) {
        return this.secureApi.post('users/statistics', {
            key:key
        }, false);
    }
}