import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmModalBodyComponent } from './confirm-modal-body.component';

@NgModule({
    declarations: [ConfirmModalBodyComponent],
    exports: [
        ConfirmModalBodyComponent
    ],
    imports: [
        TranslateModule,
        CommonModule
    ],
    providers: [
    ]
})
export class ConfirmModalBodyModule { }
