<div class="self-evaluation-menu">
    <div class="row">
        <div class="col-xs-12">
            <a class="brand__logo-text">
                <img class="img-fluid" src="assets/img/pages/klenico-logo.svg">
            </a>
        </div>
    </div>
    <div class="row mr-t--md" *ngIf="appService.isRepeatedSE ? (step != 0 && step != 6 && step != 7) : (step != 0 && step != 5 && step != 6)">
        <ng-container *ngFor="let content of contentHeader ; let i = index">
            <div class="col-xs-12 mr-t--sm self-evaluation-menu-title" [class.hidden]= "i !== step">
                    {{ content | translate }}
            </div>
        </ng-container>
        <ng-container *ngFor="let content of contentBody ; let i = index">
            <div class="col-xs-12 mr-t--sm" [class.hidden]="step == 3 || i !== step">
                {{ content | translate }}
            </div>
        </ng-container>
        <div class="col-xs-12 mr-t--sm hidden-xs-down" [class.hidden]="step != 3">
            {{ 'Now, you can rate which sentences describe.' | translate }}
        </div>
        <div class="col-xs-12 mr-t--sm hidden-sm-up" [class.hidden]="step != 3">
            {{ 'MOBILE Now, you can rate which sentences describe.' | translate }}
        </div>
    </div>
    <div *ngIf="clinic" class="row mr-t--md">
        <div class="col-xs-6">
            <img *ngIf="clinic['logo']" src="{{clinic['logo']}}" class="icons--clinic img-fluid" />
        </div>
        <div class="col-xs-12">
            <span class="block exclude-translate">
                {{ clinic['name'] }}
            </span>
        </div>
        <div class="col-xs-12  mr-t--md" *ngIf="clinic['admin_email']">
            {{ 'Write to us' | translate }}
            <br/>
            <span class="info-bold exclude-translate">{{ clinic['admin_email'] }}</span>
        </div>
        <div class="col-xs-12  mr-t--md" *ngIf="clinic['phone']">
            {{ 'Call' | translate }}
            <br/>
            <span class="info-bold exclude-translate">{{ clinic['phone'] }}</span>
        </div>
    </div>
    <div class="self-evaluation-menu__bottom">
        <div class="row mr-b--sm color-primary self-evaluation-menu__bottom--footerlinks d-flex">
            <div class="col-xs-12">
                <span [class.active]="activeModal == 'privacy-statement'" (click)="openModal('privacy-statement')">
                    {{ 'Privacy statement' | translate }}
                </span>
                <span [class.active]="activeModal == 'terms-condition'" (click)="openModal('terms-condition')">
                    {{ 'General terms and conditions' | translate }}
                </span>
                <span [class.active]="activeModal == 'legal-notice'" (click)="openModal('legal-notice')">
                    {{ 'Legal Notice' | translate }}
                </span>

            </div>
        </div>
        <div class="row">
            <div class="col-xs-12 exclude-translate">
                {{ releasedVersion }} &copy; {{ thisYear }} Klenico Health AG
            </div>
        </div>
    </div>
</div>
