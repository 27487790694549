import { NgModule } from '@angular/core';
import { ModalModule } from 'ngx-bootstrap/modal';
import {PopoverModule} from 'ngx-bootstrap/popover';

import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BaseModule } from '../shared/base.module';
import { DialogModule } from '../dialog/dialog.module';
import { FeedbackRoutesModule } from './feedback.routes';
import { SelfEvaluationFeedbackComponent } from './self-evaluation-feedback/self-evaluation-feedback.component';
import { FeedbackService } from './feedback.service';
import { PassiveFeedbackComponent } from './passive-feedback/passive-feedback.component';

@NgModule({
    imports: [
        BaseModule,
        FeedbackRoutesModule,
        DialogModule,
        ModalModule.forRoot(),
        MatInputModule,
        MatSelectModule,
        PopoverModule.forRoot(),
        TooltipModule.forRoot(),
    ],
    exports: [],
    declarations: [
        SelfEvaluationFeedbackComponent,
        PassiveFeedbackComponent
    ],
    entryComponents: [
        PassiveFeedbackComponent
    ],
    providers: [
        FeedbackService
    ],
})

export class FeedbackModule {
}