<div class="stepper">
    <div class="stepper-container stepper-{{stepperType}}">
        <div class="stepper-{{stepperType}}-container" *ngFor="let step of steps; trackBy: trackByStep; let idx = index" 
        [style.width]="(stepperType === 'horizontal' && idx < steps.length - 1) ? (100 / (steps.length) + '%') : 'auto'"
            [ngClass]="{'last-step' : stepperType === 'horizontal' && idx === steps.length - 1}">
            <div class="step" [ngClass]="{'active': idx === currentStepIndex || idx < currentStepIndex }">
                <div class="step-heading" (click)="toggleListItems(idx)">
                    <div class="circle">{{ step.step }}</div>
                    <div class="title" *ngIf="isShowStepName && step.name">{{ step.name | translate }}</div>
                </div>
                <div class="line" *ngIf="idx < steps.length - 1"></div>
            </div>
            <div class="body" [ngClass]="{'collapse': idx === currentStepIndex }" id="collapse_{{idx}}" *ngIf="stepperType === 'vertical'">
                <div class="card card-body" *ngIf="step.template">{{step.template}}</div>
                <div class="text-xs-center btn-step-group">
                    <button *ngIf="step.backBtn && idx > 0" (click)="toggleListItems(idx - 1)" class="btn btn-default" name="previous" data-target="#collapse_idx"
                        aria-expanded="false" aria-controls="collapse_idx">
                        {{ 'Back' | translate }} 
                    </button>

                    <button *ngIf="step.nextBtn && idx < steps.length - 1" (click)="toggleListItems(idx + 1)" class="btn btn-primary" name="next"
                        data-target="#collapse_idx" aria-expanded="false" aria-controls="collapse_idx">
                        {{ 'Next' | translate }}
                    </button>

                    <button *ngIf="step.finishBtn && idx === steps.length - 1" (click)="stepDone()" class="btn btn-primary" name="finish" data-target="#collapse_idx"
                        aria-expanded="false" aria-controls="collapse_idx">
                        {{ 'Finish' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="steps">
        <div class="body" [ngClass]="{'collapse': steps[currentStepIndex].step === currentStepIndex }" id="collapse_{{currentStepIndex}}" *ngIf="stepperType === 'horizontal' && steps[currentStepIndex]">
            <div class="card card-body" *ngIf="steps[currentStepIndex].template">{{steps[currentStepIndex].template}}</div>
            <div class="text-xs-center btn-step-group">
                <button *ngIf="steps[currentStepIndex].backBtn && currentStepIndex > 0" (click)="toggleListItems(currentStepIndex - 1)" class="btn btn-default"
                    name="previous" data-target="#collapse_currentStepIndex" aria-expanded="false" aria-controls="collapse_currentStepIndex">
                    {{ 'Back' | translate }}
                </button>

                <button *ngIf="steps[currentStepIndex].nextBtn && currentStepIndex < steps.length - 1" (click)="toggleListItems(currentStepIndex + 1)"
                    class="btn btn-primary" name="next" data-target="#collapse_currentStepIndex" aria-expanded="false" aria-controls="collapse_currentStepIndex">
                    {{ 'Next' | translate }}
                </button>

                <button *ngIf="steps[currentStepIndex].finishBtn && currentStepIndex === steps.length - 1" (click)="stepDone()" class="btn btn-primary"
                    name="finish" data-target="#collapse_currentStepIndex" aria-expanded="false" aria-controls="collapse_currentStepIndex">
                    {{ 'Finish' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>