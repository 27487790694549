export class LanguageModel {
    id: number;
    code: string;
    locale: string;
    name: string;
    constructor(params: {
        id?: number,
        code?: string,
        locale?: string,
        name?: string
    } = {}) {
        this.id = params['id'] || NaN;
        this.code = params['code'] || '';
        this.locale = params['locale'] || '';
        this.name = params['name'] || '';
    }
}