import { Injectable } from '@angular/core';
import { SecureApi } from '../core/secure.api';
import { AuthService } from '../core/auth.service';

@Injectable()
export class FeedbackService {
    constructor(public secureApi: SecureApi, authService: AuthService) {
    }

    sendSRFeedback(body) {
        return this.secureApi.post('user-feedback/send-sr-complete-feedback', body, false);
    }

    sendPassiveFeedback(body) {
        return this.secureApi.post('user-feedback/send-passive-feedback', body, false);
    }
}