import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatMenuModule } from '@angular/material/menu';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatExpansionModule } from '@angular/material/expansion';

export const MATERIALS = [
    MatInputModule,
    MatSelectModule,
    MatSidenavModule,
    MatMenuModule,
    MatAutocompleteModule,
    MatExpansionModule,
    DragDropModule
];
