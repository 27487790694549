
import {finalize} from 'rxjs/operators';
import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { AuthService } from '../../core/auth.service';
import { AppService } from '../../app.service';
import { TranslateService } from '@ngx-translate/core';
import { DialogComponent } from '../../dialog/dialog.component';
import { ProfileComponent } from '../../profile/profile.component';
import { NgZone } from '@angular/core';
import {UserInterface} from "../../users/user.interface";
import { PassiveFeedbackComponent } from '../../feedback/passive-feedback/passive-feedback.component';

@Component({
    selector: 'app-header',
    changeDetection: ChangeDetectionStrategy.Default,
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    readonly MAX_NO_OF_NOTIFICATIONS = 10;
    readonly NOTIFICATION_INTERVAL = 1000 * 60 * 5;
    diPage = false;
    isLoggedIn = false;
    activeIndex = 1;
    totalNotifications: number = 0;
    languages: Object[];
    activeMenu = null;
    isDiagnosisInterview: boolean;
    ttemplate = 'For assistance or questions about this questionnaire, Write to us on <b class="info-bold">{{email}}</b> or Call <b class="info-bold">{{phone}}</b>';
    @ViewChild('dialog', { static: false }) dialog: DialogComponent;
    menus: Object[];
    user: UserInterface;
    activeRole: number;
    adminRole: number;
    relatedUsers= [];
    @Output() toggleSENavEvent = new EventEmitter<any>();

    constructor(public router: Router,
                public authService: AuthService,
                public translate: TranslateService,
                public appService: AppService,
                private ngZone: NgZone) {
        this.router.events
            .subscribe((event) => {
                if (event instanceof NavigationEnd) {

                    if (!this.menus) {
                        return;
                    }
                    this.menus.forEach((menu: any) => {
                        const isActive = this.router.url.search(menu.route) === 1;

                        if (isActive) {
                            this.activeMenu = menu;
                        }
                        if (!isActive && this.activeMenu && this.activeMenu['route'] == menu.route) {
                            this.activeMenu = null;
                        }
                    });

                }
            });
    }

    ngDoCheck() {
        this.isLoggedIn = this.authService.isLoggedIn;
        this.user = this.authService.user;
        this.menus = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user'))['menus'] : null;
        this.activeRole = this.user.role_id;
        this.adminRole = this.authService.ROLES.CLINIC_ADMIN;
        this.relatedUsers = (this.user['related_user'] && this.user['related_user'].length > 1) ? this.user['related_user'] : [];
    }

    ngOnInit() {

        this.authService.getDBLanguages().subscribe(
            response => {
                this.languages = response['languages'];
                this.appService.languages = response['languages'];
                localStorage.setItem('languages', JSON.stringify(response['languages']));
                window.scrollTo(window.scrollX, 0);
            }
        );
    }

    setLanguage(locale: string): void {
        this.appService.setLanguage(locale);
    }

    get avatar() {
        return localStorage.getItem('profile-avatar');
    }

    get isSR() {
        const res = this.router.url.search('self-evaluation');
        if (res !== -1) {
            return true;
        } else {
            return false;
        }
    }

    get isHideHeader() {
        const res = this.router.url.search(
            /feelings-rating|feelings-selection|smoke-detector|amnestic-feeling-selection|amnestic-smoke-detector|self-evaluation-feedback|start/
        );

        if (res !== -1) {
            return true;
        } else {
            return false;
        }
    }

    get isAuthSEPage() {
        const res = this.router.url.search(
            /start/
        );

        if (res !== -1) {
            return true;
        } else {
            return false;
        }
    }

    get clinic() {
        if (localStorage.getItem('clinic')) {
            return JSON.parse(localStorage.getItem('clinic'));
        }

        return false;
    }

    getActiveClass() {
        if (this.menus[0]) {
            return 'nav-link__icons--' + this.menus[0]['icon'];
        }
        return '';
    }

    trackMenu(index, menu) {
        return menu ? menu.route : undefined;
    }

    isActiveRoute(menu) {
        return this.activeMenu && menu.route === this.activeMenu.route;
    }

    goRoute(string) {

        const destination = string;

        //temporary disable origin : don't know purpose to have origin
        //string += '&origin=' + this.router.url.split('?')[0];

        string  = string.replace('{clinicId}', this.user['clinic_id']);

        // need to call reload() when the route destination and the current route are the same. Otherwise, the page won't be updated
        if (this.router.url.search('users') !== -1 && destination.search('users') !== -1) {
            window.location.href = string;
        } else {
            this.router.navigateByUrl(string);
        }
    }

    openNotification(notification: any) {
        this.removeNotification(notification);

        if (notification.data.params.redirect_url) {
            let currentUrl = notification.data.params.redirect_url;
            currentUrl += '&origin=' + this.router.url.split('?')[0];

            this.router.navigateByUrl(currentUrl);
        }
    }

    removeNotification(notification: any) {
        this.appService.markNotificationAsRead(notification.id);
    }

    get notifications() {
        return this.appService.notifications;
    }

    isActive(locale: string): string {
        const language = localStorage.getItem('language');

        if (language === null && locale === 'de_CH') {
            return 'active';
        }
        if (this.translate.currentLang === locale) {
            return 'active';
        } else {
            return '';
        }
    }

    openProfile() {
        this.dialog.openComponent(ProfileComponent, {});
    }

    showUserFeedbackModal() {
        // Send action to GA
        // this.appService.sendGA('Header Menu', 'Click Open Feedback', 'User : ' + this.user.email);
        this.appService.sendGA(this.appService.GAEvents.HEADER_MENU.name, this.appService.GAEvents.HEADER_MENU.event.feedback);
        this.dialog.openComponent(PassiveFeedbackComponent, {});
    }

    switchRole(role){
        if(role === this.activeRole){
            return false;
        }
        this.appService.isLoading = true;
        this.authService.switchRole().pipe(finalize(
            () => {
                this.appService.isLoading = false;
            })).subscribe(res => {
                if (res.hasOwnProperty('role_id') && res['role_id'] !== this.user.role_id) {
                    this.authService.saveToLocalStorage(res['email'], res);
                    this.router.navigate(['home']);
                    //reload to load all menu
                    setTimeout(function(){
                        return document.location.reload();
                    },100);
                }
            },
        error => {
                this.router.navigate(['login']);
            }
        );
    }

    toggleSENav() {
        this.toggleSENavEvent.emit();
    }
}
