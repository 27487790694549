
import {catchError} from 'rxjs/operators';
import { Injectable, Inject, Injector } from '@angular/core';
import { Router } from '@angular/router';



import { AuthService } from './auth.service';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { from } from 'rxjs';

@Injectable()
export class SecureApi {

    constructor(
        public injector: Injector,
        public http: HttpClient,
        public authService: AuthService,
        @Inject('baseUrl') public baseUrl: string
    ) { }

    get router(): Router {
        return this.injector.get(Router);
    }

    get(resource: string) {
        const url = this.baseUrl + resource;
        const options = this.getHeaderOptions(true);
        return this.http.get(url, options).pipe(catchError((error: any) => {
            error = this.errorHandle(error);
            throw error;
        }));
    }

    downloadFile(resource: string, body?: any) {
        const token = this.authService.getToken();
        const url = this.baseUrl + resource;
        const headerOptions = {
            'Authorization': 'Bearer ' + token,
        };
        const options = {
            headers: new HttpHeaders(headerOptions),
            responseType: 'blob' as 'text',
            observe: 'response' as 'body'
        };
        if (body) {
            return this.http.put(url, body, options).pipe(catchError((error: any) => {
                if (error?.error instanceof Blob) {
                  return from(Promise.resolve(error).then(async x => { throw new HttpErrorResponse({ error: JSON.parse(await x.error.text()), headers: x.headers, status: x.status, statusText: x.statusText, url: x.url ?? undefined })}));
                }
                error = this.errorHandle(error);
                throw error;
            }));
        }
        return this.http.get(url, options).pipe(catchError((error: any) => {
            error = this.errorHandle(error);
            throw error;
        }));
    }

    post(resource: string, body: any, extendOptions = true) {
        const url = this.baseUrl + resource;
        const options = this.getHeaderOptions(extendOptions);
        return this.http.post(url, body, options).pipe(catchError((error: any) => {
            error = this.errorHandle(error);
            throw error;
        }));
    }

    put(resource: string, body: string, extendOptions) {
        const url = this.baseUrl + resource;
        const options = this.getHeaderOptions(extendOptions);
        return this.http.put(url, body, options);
    }

    delete(resource: string, extendOptions?) {
        const url = this.baseUrl + resource;
        const options = this.getHeaderOptions(true);
        return this.http.delete(url, options);
    }

    changeStatus(url: string, action: string) {
        switch (action) {
            case 'activate':
                return this.get(url);
            case 'archive':
                return this.get(url);
            case 'dearchive':
                return this.get(url);
            case 'restore':
                return this.get(url);
            case 'delete':
                return this.delete(url);
            default:
                break;
        }
    }

    getHeaderOptions(postAsWwwForm: boolean) {
        const token = this.authService.getToken();
        let headerOptions;

        if (!postAsWwwForm) {
            headerOptions = {
                'Accept': 'application/json',
                'Access-Control-Allow-Credentials': 'true',
                'Access-Control-Max-Age': 3600,
                'Authorization': 'Bearer ' + token
            };
        } else {
            headerOptions = {
                'Accept': 'application/json',
                'Access-Control-Allow-Credentials': 'true',
                'Access-Control-Max-Age': 3600,
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            };
        }

        return {
            headers: new HttpHeaders(headerOptions)
        };

    }

    private errorHandle(error) {
        if (error.status === 401) {
            if (error.error.error === 'token_expired') {
                this.authService.refreshToken().subscribe(
                    response => {
                        const newToken = response['token'];
                        if (newToken) {
                            const user = JSON.parse(localStorage.getItem('user'));
                            if (user) {
                                user.token = newToken;
                                localStorage.setItem('user', JSON.stringify(user));
                                this.authService.loginCompleteEvent.next(true);
                            }
                        } else {
                            // If we don't get a new token, we are in trouble so logout.
                            return this.authService.logout();
                        }
                    },
                    errorResquest => {
                        // If there is an exception calling 'refreshToken', bad news so logout.
                        return this.authService.logout();
                    }
                );
            } else {
                this.router.navigate(['login'], {
                    queryParams: {
                        infoMessage: 'Your session has expired due to inactivity.'
                    }
                });
            }
        } else if (error.status === 403) {
            this.router.navigate(['login'], {
                queryParams: {
                    infoMessage: 'Your session has expired due to inactivity.'
                }
            });
        }
        return error;
    }
}
