<div bsModal #modal="bs-modal" class="modal fade self-evaluation-modal privacy" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <i class="fas fa-times close-btn" (click)="hideModal()"></i>
            <div class="modal-body">
                <div class="row self-evaluation-modal__header">
                    <div class="col-xs-11">
                        <span class="text-wrap-normal">{{content[locale]?.title}}</span>
                     </div>
                </div>
                <div class="row self-evaluation-modal__body" *ngIf="content[locale]">
                    <div class="col-sm-9" [innerHTML]="content[locale]?.content | safeHtml">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
