import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'searchArray',
    pure: true
})

export class SearchArrayPipe implements PipeTransform {
    transform(items: any[], searchText: string, fieldName?: string): any[] {
        if (!items || searchText === null) {
            return items;
        }

        const fieldNames = fieldName.split(',');

        searchText = searchText.toLowerCase();
        for (let key in items) {
            let matched = false;

            fieldNames.forEach((field) => {
                let text = field ? items[key][field] : items[key];
                if (text == null) {
                    text = '';
                }
                if (text.toLowerCase().indexOf(searchText.toLocaleLowerCase()) !== -1) {
                    matched = true;
                }
            });

            items[key]['hidden'] = !matched;
        }
        return items;
    }
}
