<div class="wrapper box-shadow">
    <div class="row">
        <div class="col-xs-10 offset-xs-1 content">
            <div class="content-404">
                <div class="text-block">
                    <div class="text-404">404</div>
                    <div class="text-page-not-found">
                        {{ 'PAGE NOT FOUND' | translate }}
                    </div>
                </div>
                <i class="fas fa-search"></i>
            </div>
            <div *ngIf="authService.isLoggedIn" class="error-msg">
                <div class="info-text" [innerHTML]="infoMsg | translate"></div>
                <div class="redirect-info">
                    <div class="sprite home-page" [routerLink]="['/home']">
                        <div>{{'Go to' | translate}}</div>
                        <div><b>{{'Home Page' | translate}}</b></div>
                    </div>
                    <div class="border"></div>
                    <div class="sprite support-page" [routerLink]="['/support']">
                        <div>{{'Go to' | translate}}</div>
                        <div><b>{{'Support' | translate}}</b></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>