import { Injectable } from '@angular/core';
import { SecureApi } from '../core/secure.api';
@Injectable()

export class SelfEvaluationService {
    isRedirectedFromAmnestic = false;
    showFeedback = false;
    haveAnanmesticStep = true;
    itemPerPage = 5;
    MINUMUM_ITEMS = 5;

    readonly pageTitle = {
        overview :  'Overview', //0
        thankyou : 'Thank you', //1
        feedbackSent : 'Your feedback has been sent', // 2
        linkDeactivated: 'This link is not active anymore', // rating
    };
    readonly contentHeaderNormal = {
        '0' :  'Use the following questionnaire to provide information about your perception', //0
        '1' : 'Overview statements of current condition', //1
        '2' : 'Detailed statements of current condition', // 2
        '3' : '', // rating
        '4' : 'Statement about past condition', // 4
    };
    readonly contentBodyNormal = {
        '0' : 'The following questionnaire is about your condition.', // 0
        '1' : 'In this first part you will be shown many different statements.',
        '2' : 'You have completed the first part, thank you.',
        '3' : '', // Rating removed
        '4' : 'Thank you. You have almost completed the survey.',
        '5' : 'Thank you very much for completing this survey.',
        '6' : 'Thank you for sharing your thoughts, we will try to integrate your feedback.'
    };
    readonly contentHeaderStudy = [
        'Use the following questionnaire to provide information about your perception',
        'Overview statements of current condition',
        'Detailed statements of current condition',
        '',
        'Statement about past condition',
    ];
    readonly contentBodyStudy = {
        '0' : 'STUDY The following questionnaire is about your condition.',
        '1' : 'STUDY In this first part you will be shown many different statements.',
        '2' : 'STUDY You have completed the first part, thank you.',
        '3' : '', // Rating removed
        '4' : 'Thank you. You have almost completed the survey.',
        '5' : 'Thank you very much for completing this survey.',
        '6' : 'Thank you for sharing your thoughts, we will try to integrate your feedback.'
    };

    readonly agreementAccordion = {
        header : [
            'Information about the protection of your data',
            'Information about the transfer of your data', //1
            'Information about the research results', // 2
            'Your rights', // 3
        ],
        content : [
            'Klenico Health AG receives your data exclusively in anonymous form',
            'Your anonymous data may be passed on to the affiliated company Klenico Health GmbH based in Germany', //1
            'If you are interested in the general results of our research, please feel free to contact us', // 2
            'Your consent is voluntary and is only valid for the current survey with the Klenico system.', // 3
        ],
    };

    readonly contentHeaderFeedback = [
        'Your feed back',
        'Very poor',
        'Poor',
        'Average',
        'Good',
        'Excellent'
    ];

    readonly contentBodyFeedback = [
        'How would you rate the questionnaire in general?',
        'Much was incomprehensible to me and the handling was unclear.',
        'Some parts of the questionnaire were difficult to understand or difficult to handle.',
        'The questionnaire was acceptable, but there is much room for improvement.',
        'The questionnaire was easy to answer and met my expectations.',
        'The questionnaire was very easy to answer and exceeded my expectations.'
    ];

    // Repeated Data
    readonly repeatedContentHeaderNormal = {
        '0' : 'Welcome back to the Klenico questionnaire', //0
        '1' : 'Previous agreed on statements', //1
        '1-additional' : 'Statements on current condition', //1 - 2
        '2' : 'Detailed statements of current condition', // 2
        '3' : '', // rating
        '4' : 'Statement about past condition', // 4
    };
    repeatedContentBodyNormal = {
        '0' : 'The following questionnaire is base on your previous answers', // 0
        '1' : 'First you will be shown all statements', // 1
        '1-additional' : 'Next you will have the chance to select statements that may now', // 1 - 2
        '2' : 'You have completed the first part, thank you.', // 2
        '3' : '', // rating
        '4' : 'Thank you. You have almost completed the survey.', // 4
        '5' : 'Thank you very much for completing this survey.', // 5
        '6' : 'Thank you for sharing your thoughts, we will try to integrate your feedback.' // 6
    };

    TYPES = {
        SE: 'se',
        REPEATED: 'repeatedSE'
    };
    pageTypes = {
        se: {
            route : 'self-evaluation',
            routeAnamPost: 'sr-smoke-detector',
            routePost: 'sr-smoke-detector',
            circumstanceRoute: 'sr-life-circumstance',
            smokeDetectorRoute: 'sr-ratings',
            type: 'se',
            contentHeader: this.contentHeaderNormal,
            contentBody: this.contentBodyNormal,
            smokeDetector: 4,
            showFeedbackStep: 5,
            steps : {
                OVERVIEW: 0,
                ADDITIONAL_STEP: 0,
                FEELING_SMOKE_DETECTOR: 1,
                FEELING_SELECTION: 2,
                ANAMESTIC_SELECTION: 4,
                FEEDBACK: 5,
                FINISH: 6
            }
        },
        repeatedSE:{
            route : 'repeat-self-evaluation',
            routeAnamPost: 'sr-smoke-detector',
            routePost: 'repeat-sr-smoke-detector',
            circumstanceRoute: 'sr-life-circumstance',
            smokeDetectorRoute: 'sr-ratings',
            contentHeader: this.repeatedContentHeaderNormal,
            contentBody: this.repeatedContentBodyNormal,
            type: 'repeatedSE',
            smokeDetector: 4,
            showFeedbackStep: 5,
            steps : {
                OVERVIEW: 0,
                SELECTED_SMOKE_DETECTOR: 1,
                ADDITIONAL_STEP: 1,
                NOT_SELECTED_SMOKE_DETECTOR: 1,
                FEELING_SELECTION: 2,
                ANAMESTIC_SELECTION: 4,
                FEEDBACK: 5,
                FINISH: 6
            }
        }
    };
    constructor(public secureApi: SecureApi) {
    }

    getItems(route) {
        return this.secureApi.get(route);
    }

    submit(route, data) {
        return this.secureApi.post(route, data, false);
    }

    updateResearchAgreementAnswer(data) {
        let route = `patient-questionnaire/research-agreement/update`
        return this.secureApi.put(route, data, false);
    }

    addSpentTime(questionnaireId, additionTime) {
        let url = `patient-questionnaire/add-spent-time/${questionnaireId}`;
        let body = {additionnal_time: additionTime};

        return this.secureApi.post(url, body, false);
    }

    updateSelfEvaluationFirstStep(tokenId, data) {
        let route = `patients/self-evaluation-start/${tokenId}`
        return this.secureApi.post(route, data , false);
    }

    sendSelfEvaluationPatientFeedback(data) {
        let route = `patient-questionnaire/patient-feedback`;
        return this.secureApi.post(route, data, false);
    }

    countPauseSelfEvaluation(data) {
        let route = `patient-questionnaire/count-pause`;
        return this.secureApi.post(route, data, false);
    }

    enterSelfEvaluation(token: any, itemPerPage, isMobile, data) {
        const url = 'patients/self-evaluation/' + token + '/' + itemPerPage + '/' + isMobile;
        return this.secureApi.post(url, data, false);
    }

    getCircumstance(token: any, itemPerPage, isMobile, data) {
        const url = 'sr-life-circumstance/' + token + '/' + itemPerPage + '/' + isMobile;
        return this.secureApi.post(url, data, false);
    }

    changeLanguage(data) {
        let route = `patient-questionnaire/change-language`
        return this.secureApi.post(route, data, false);
    }
}
