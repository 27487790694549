<div class="slide-select-patient">
    <div class="slide-container">
        <div class="block">
            <i class="fas fa-list"></i>
            <span>{{'Patients List' | translate}}</span>
        </div>
        <div class="block block-relationship">
            <span>{{'or' | translate}}</span>
        </div>

        <div class="block">
            <i class="fas fa-plus"></i>
            <span>{{'Create new patient' | translate}}</span>
        </div>
    </div>
</div>