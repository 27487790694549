
import {of as observableOf, Observable} from 'rxjs';
// angular
import {Component, OnInit, Input, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder} from '@angular/forms';

// others
import {BaseComponent} from '../shared/base.component';

// services
import {MaintenanceService} from './maintenance.service';
import {AuthService} from '../core/auth.service';
import {BaseService} from '../shared/base.service';
import {AppService} from '../app.service';
import {TinymceComponent} from "angular2-tinymce";

@Component({
    selector: 'maintenance-component',
    templateUrl: './maintenance.component.html',
    styleUrls: ['./maintenance.component.scss'],
    providers: [MaintenanceService, BaseService]
})
export class MaintenanceComponent extends BaseComponent implements OnInit {
    errorMsg: Observable<string>;
    infoMsg: string;
    url = 'settings';
    modalConfig: any;
    @Input() openModal: boolean;
    @ViewChild('tinymce', { static: false }) private tinymce: TinymceComponent;

    constructor(public authService: AuthService,
                public router: Router,
                public baseService: BaseService,
                public appService: AppService,
                public route: ActivatedRoute,
                public fb: FormBuilder,
                public maintenanceService: MaintenanceService) {
        super(authService, router, baseService, appService);
    }

    ngOnInit() {
        this.appService.isLoading = false;
        this.isLoading = true;
        this.route.queryParams.subscribe(params => {
            this.errorMsg = observableOf(params.message);
        });

        this.isLoading = false;
        this.infoMsg = this.authService.maintenanceMessage;
    }
}
