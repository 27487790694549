<div class="slide-create-questionaire">
    <div class="slide-container">
        <div class="block">
            <i class="fas fa-comment"></i>
            <span>{{'Invite for Self-Evaluation' | translate}}</span>
        </div>
        <div class="block block-relationship">
            <img src="assets/img/dashboard-informations/arrow-down.png">
            <p class="then">
                {{ 'then' | translate}}
            </p>
        </div>

        <div class="block">
            <i class="fas fa-comments"></i>
            <span>{{'Start Diagnosis Interview' | translate}}</span>
        </div>
    </div>
</div>