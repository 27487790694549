<div class="card-block sms_form">
    <div *ngIf="errorMessage" class="alert alert-danger" role="alert">
        <span class="fas fa-exclamation-circle" aria-hidden="true"></span>{{ errorMessage | translate }}
        <br>
        <div class="sms_form__resend" *ngIf="!isValidSmsCode">
            <button class="btn btn-secondary btn-sm" type="submit"
                    *ngIf="isResetPasswordStep"
                    [disabled]="isDisableResendSmsButton"
                    (click)="onResendSmsClick()">
                <i class="fas fa-undo"></i>{{ 'Send mTAN' | translate }} 1
            </button>
        </div>
    </div>
    <div *ngIf="infoMessage" class="alert alert-info" role="alert">
        <span class="fas fa-exclamation-circle" aria-hidden="true"></span>
        {{ infoMessage | translate }}
        <ng-container *ngIf="hiddenPhoneNumber != ''">
            ******** {{ hiddenPhoneNumber }}
        </ng-container>
    </div>
    <div *ngIf="successMessage" class="alert alert-success" role="alert">
        <span class="fas fa-exclamation-circle" aria-hidden="true"></span>{{ successMessage | translate }}
    </div>
    <ng-container *ngIf="!isVerifyByEmail">
        <p *ngIf="!isResetPasswordStep" class="form-group">
            {{ 'Please enter your mobile number for the two step verification process. To this mobile number sms-codes (mTAN) will be send to confirm your password.' | translate }}
        </p>
        <p *ngIf="isResetPasswordStep" class="form-group">
            {{ 'To complete this process, please enter now the security code sent to your initially registered mobile phone. If you have changed your phone number in the meantime, please contact your clinic administrator.' | translate }}
        </p>
        <form *ngIf="!isResetPasswordStep" class="sms_form__sub_form" [formGroup]="mobileForm"
              (ngSubmit)="onSubmitMobileNumber(mobileForm.value, mobileForm.valid)" novalidate>
            <div class="form-group">
                <mat-form-field class="ps-content">
                    <input formControlName="mobile" #mobileField type="tel" matInput [placeholder]="'Mobile number' | translate" autocomplete="off" />
                    <small class="form-text text-danger" [hidden]="(mobileForm.controls.mobile.valid || mobileSubmitted) || (mobileForm.controls.mobile.pristine && !mobileSubmitted)">
                        {{ 'The mobile phone number format is not valid.' | translate }}
                    </small>
                </mat-form-field>
            </div>
            <div class="row form-group">
                <div class="col-xs-6">
                    <button class="btn btn-primary btn-radius btn-dark" type="submit" [disabled]="isDisableSendSmsButton">
                        {{ 'Send mTAN' | translate }}
                    </button>
                </div>
            </div>
        </form>
    </ng-container>
    <ng-container *ngIf="isVerifyByEmail">
        <p class="form-group">
            {{ 'To complete this process, please enter now the security code sent to your email.' | translate }}
        </p>
    </ng-container>
    <form [formGroup]="smsCodeForm" (ngSubmit)="onSubmitSmsCode(smsCodeForm.value, smsCodeForm.valid)" novalidate class="mat-form">
        <div class="row form-group">
            <div class="col-xs-12">
                <mat-form-field class="ps-content">
                    <input formControlName="smsCode" #mTanCode type="text" matInput [placeholder]="'Verification number' | translate" [disabled]="(!isRequestedSms && !isResetPasswordStep)" autocomplete="off"/>
                    <small class="form-text text-danger" [hidden]="smsCodeForm.controls.smsCode.valid || smsCodeForm.controls.smsCode.disabled ||
                    (smsCodeForm.controls.smsCode.pristine && !smsCodeSubmitted)">
                        {{ 'Verification number is required.' | translate }}
                    </small>
                </mat-form-field>
            </div>
        </div>
        <div class="row form-group">
            <div class="col-xs-6 offset-xs-3">
                <button [disabled]="((isDisableVerifySmsButton && !isResetPasswordStep) || (isResetPasswordStep && isDisableVerifySmsButton)) && !isVerifyByEmail"
                        class="btn btn-primary btn-radius btn-dark" type="submit">
                    {{ 'Verify' | translate }}
                </button>
            </div>
        </div>
    </form>
</div>
