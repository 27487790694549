
import {finalize} from 'rxjs/operators';
import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../../core/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthComponent } from '../../../auth/auth.component';
import { AppService } from '../../../app.service';
import { MaintenanceService } from '../../../maintenance/maintenance.service';
import { SmsService } from '../../sms.service';

@Component({
    selector: 'sms-verification-component',
    templateUrl: './sms-verification.component.html',
    styleUrls: ['./sms-verification.component.scss'],
    providers: [MaintenanceService]
})

export class SmsVerificationComponent extends AuthComponent implements OnInit, OnChanges, AfterViewInit {

    form: FormGroup;
    submitted: boolean;
    isValidSmsCode: boolean;
    successMessage: string = '';
    errorMessage: string = '';
    infoMessage: string = '';
    smsCodeForm: FormGroup;
    isDisableResendSmsButton: boolean = false;
    latestURL = '';
    latestParams = {};
    @Input() smsMessage: string = '';
    @Input() userId: string = '';
    @Input() hiddenPhoneNumber: string = '';
    @ViewChild('mTanCode', { static: false }) mTanCode: ElementRef;

    // @Output() notifyIsValidSmsCode: EventEmitter<string> = new EventEmitter<string>();

    get isLoading() {
        return this.appService.isLoading;
    }

    set isLoading(isLoading) {
        this.appService.isLoading = isLoading;
    }

    constructor(public router: Router,
        public authService: AuthService,
        public translate: TranslateService,
        public appService: AppService,
        public smsService: SmsService,
        public route: ActivatedRoute,
        public fb: FormBuilder) {
        super(router, authService, appService, translate);

        this.createSmsCodeForm();
    }

    ngOnInit() {
        super.ngOnInit();

        this.route.queryParams.subscribe(params => {
            this.latestParams = params;
        });

         //  Integrate DEEPLINK after login successfully
         this.route.params.subscribe(params => {
            if (params['action'] && params['id']) {
                this.latestURL = params['action'] + '/' + params['id'];
            }
            if (params['page']) {
                this.latestURL = params['page'];
            }
        });

    }

    ngAfterViewInit() {
        this.mTanCode.nativeElement.focus();
    }

    /**
     * preload mobile number for mobile field
     * @param {SimpleChanges} changes
     */
    ngOnChanges(changes: SimpleChanges) {
        if (changes['userId'] && this.userId) {
            this.smsCodeForm.controls['userId'].setValue(this.userId);
        }
    }

    /**
     * Init Verify SMS code form
     */
    createSmsCodeForm() {
        this.smsCodeForm = this.fb.group({
            smsCode: ['', [<any>Validators.required]],
            userId: ['', [<any>Validators.required]],
        });
    }

    /**
     * Handle on submit sms code
     * @param smsCodeForm
     * @param isValid
     */
    onSubmitSmsCode(smsCodeForm, isValid) {
        this.submitted = true;

        if (!isValid) {
            return;
        }

        this.resetErrors();

        this.smsService.verifyLoginSmsCode(smsCodeForm).pipe(
            finalize(() => this.isLoading = false))
            .subscribe(response => {
                if (response['id']) {
                    this.successMessage = response['message'];
                    this.authService.saveToLocalStorage(response['email'], response);
                    this.appService.setLanguage(response['locale']);

                    // Validate dependencies clinics
                    if(response['clinics'].length > 1){
                        this.appService.multiClinics = response['clinics'];
                        return this.router.navigate(['switch-clinic'],
                            {
                                queryParams: this.latestParams
                            });
                    }

                    // Send event to GA
                    const GAEvent = this.latestURL ? this.appService.GAEvents.LOGIN.event.deeplink : this.appService.GAEvents.LOGIN.event.normal;
                    // Send event to GA : Redirect login with a deeplink
                    this.appService.sendGA(this.appService.GAEvents.LOGIN.name, GAEvent);

                    return this.router.navigate([this.latestURL],
                        {
                            queryParams: this.latestParams
                        });

                } else {
                    this.errorMessage = response['message'];
                    this.isValidSmsCode = false;
                }
            },
                error => {
                    this.resetErrors();
                    this.isValidSmsCode = false;
                    if (typeof error.error.error.smsCode !== 'undefined') {
                        this.errorMessage = error.error.error.smsCode[0]
                    } else {
                        this.isDisableResendSmsButton = true;

                        setTimeout(() => {
                            this.isDisableResendSmsButton = false;
                        }, 60000); // Disable button for 1 minute

                        this.errorMessage = error.error.error;
                    }
                });
    }

    /**
     * Handle resend sms code
     */
    onResendSmsClick() {
        if (this.isValidSmsCode || !this.userId) {
            return;
        }

        this.smsService.resendLoginSms(this.userId).pipe(
            finalize(() => this.isLoading = false))
            .subscribe(response => {
                this.successMessage = response['message'];

                this.errorMessage = '';

                this.smsCodeForm.controls.smsCode.markAsUntouched();
                this.smsCodeForm.controls.smsCode.setValue(null);
            },
                error => {
                    this.resetErrors();

                    if (error.error.error) {
                        this.errorMessage = error.error.error.phone_number[0];
                    }
                });
    }

    /**
     * Reset form message before submit
     */
    resetErrors() {
        if (this.errorMessage) {
            this.errorMessage = '';
        }

        if (this.infoMessage) {
            this.infoMessage = '';
        }

        if (this.successMessage) {
            this.successMessage = '';
        }
    }
}
