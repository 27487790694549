import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from '../shared/base.component';
import { AuthService } from '../core/auth.service';
import { BaseService } from '../shared/base.service';
import { AppService } from '../app.service';
import { StepWizard } from '../shared/stepper/step.interface';
import { Slide } from '../shared/slide/slide.interface';
import { SlideSelectPatientComponent } from './slides/slide-select-patient/slide-select-patient.component';
import { SlideCreateQuestionreComponent } from './slides/slide-create-questionnaire/slide-create-questionnaire.component';
import { SlideVisualizationComponent } from './slides/slide-visualization/slide-visualization.component';
import { SlidePackagesComponent } from './slides/slide-packages/slide-packages.component';
import { SlideLongitudinalMeasurementComponent } from './slides/slide-longitudinal-measurement/slide-longitudinal-measurement.component';

@Component({
    selector: 'app-dashboard-information',
    templateUrl: './dashboard-information.component.html',
    styleUrls: ['./dashboard-information.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class DashboardInformationComponent extends BaseComponent {
    public stepTypeWizard = 'horizontal';
    public stepsWizard: StepWizard[];
    public slides: Slide[];
    public slideIndex = 0;
    url = '';

    constructor(public authService: AuthService,
        public router: Router,
        public baseService: BaseService,
        public appService: AppService) {
        super(authService, router, baseService, appService);
    }

    openDashboardInformationModal(url?: string) {
        this.url = url ? url : '';

        const params = {
            action: 'open',
            url: this.url,
            title: 'This is how the Klenico symptom assessment works'
        };

        this.initForm(params);

        this.showModal();

        this.modal.onShown.subscribe(() => {
            this.stepsWizard = this.getStepsWizard();
            this.slides = this.getSlides();
        });
    }

    getStepsWizard() {
        return [{
            step: 1,
            name: 'Select a patient'
        }, {
            step: 2,
            name: 'Create a questionnaire'
        }, {
            step: 3,
            name: 'Visualization'
        }, {
            step: 4,
            name: 'Packages'
        }, {
            step: 5,
            name: 'Longitudinal measurement',
            finishBtn: true
        }];
    }

    getSlides() {
        return [{
            slide: 1,
            name: 'Select a patient',
            template: SlideSelectPatientComponent,
            class: 'slide-patient'
        }, {
            slide: 2,
            name: 'Create a questionnaire',
            template: SlideCreateQuestionreComponent
        }, {
            slide: 3,
            name: 'Visualization',
            template: SlideVisualizationComponent
        }, {
            slide: 4,
            name: 'Packages',
            template: SlidePackagesComponent
        }, {
            slide: 5,
            name: 'Longitudinal measurement',
            template: SlideLongitudinalMeasurementComponent,
            class: 'slide-measurement'
        }];
    }

    hideModal() {
        this.modal.hide();
        this.slideIndex = 0;
        /* remove query params added earlier. Otherwise, users will be redirected to the errors page
        after they have closed the dashboard info modal and refreshed the page */
        this.router.navigate([this.url]);
    }
}
