<carousel #appCarousel class="carousel-custom" [showIndicators]="isShowIndicator" [noPause]="noPauseSlides" [noWrap]="noWrapSlides"
    [interval]="intervalValue" [activeSlide]="currentSlideIndex" (activeSlideChange)="slideChange()" [ngClass]="{ 'hide-arrow-icon-prev': noPauseSlides === false && currentSlideIndex === 0 , 
        'hide-arrow-icon-next': slides && noPauseSlides === false && (currentSlideIndex === slides.length - 1) }">
    <slide *ngFor="let slide of slides; trackBy: trackBySlide; let idx = index" class="{{slide.class}}">
        <div class="slide-template">
            <app-dynamic-view [component]="slide.template"></app-dynamic-view>
        </div>

        <div class="carousel-caption" *ngIf="isShowCaption">
            <h4 class="slide-name" *ngIf="slide.name">{{slide.name | translate}}</h4>
        </div>
   
        <a class="left carousel-control control-prev" (click)="prevSlide()">
            <span aria-hidden="true" class="control-prev-icon">
                <i class="fas fa-chevron-left"></i>
            </span>
        </a>
        <a class="right carousel-control control-next" (click)="nextSlide()">
            <span aria-hidden="true" class="control-next-icon">
                <i class="fas fa-chevron-right"></i>
            </span>
        </a>
    </slide>
</carousel>