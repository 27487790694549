<div >
    <app-breadcrumb
            [firstNode]="{label: 'Users list', 'url': '/home'}">
    </app-breadcrumb>
    <div class="box-shadow">
        <div class="card card--box">
            <div class="card--box__header">
                <div class="card--box__header__title">
                    {{ 'Clinic Management' | translate }}
                </div>
                <div class="card--box__header--action text-xs-right">
                    <span class="card--box__header__action float-xs-right" *ngIf="isCreatable">
                        <button type="button" class="btn btn-link  card--box__header__action__button" (click)="exportClinic($event)">
                            <i class="fas fa-download"></i>
                            <span class="hidden-sm-down">{{ 'Retrieve Data' | translate}}</span>
                        </button>
                    </span>
                    <span class="card--box__header__action float-xs-right" *ngIf="isCreatable">
                        <button type="button" class="btn btn-link  card--box__header__action__button" (click)="openFormModal({action: 'create', title: 'Add Clinic', url: '/clinics'});">
                            <i class="fas fa-plus-circle"></i>
                            <span class="hidden-sm-down">{{ 'Add clinic' | translate}}</span>
                        </button>
                    </span>
                </div>
            </div>
            <div class="card-block card--box__block">
                <div class="row">
                    <div class="col-xs-10 col-xl-8">
                        <div class="sprite card--box__search">
                            <ng-template #searchList let-model="item" let-index="index">
                                {{ model.name }}
                            </ng-template>
                            <input [(ngModel)]="searchInfo.keyword" class="form-control card--box__search__input"
                                placeholder="{{ 'Search by name' | translate }}" (keyup.enter)="searchItems()" />
                        </div>
                    </div>
                    
                    <div *ngFor="let filter of asyncFilters | async" class="col-xs-3 col-xl-2">
                        <select class="form-control" [(ngModel)]="searchInfo[filter['key']]" (change)="setFilter($event, filter['key'])">
                            <option value="0">{{ filter['default'] | translate }}</option>
                            <option *ngFor="let element of filter['elements']" value="{{ element['id'] }}">{{ element['name'] | translate }}</option>
                        </select>
                    </div>

                    <div class="col-xs-2 offset-xs-5 col-xl-2 offset-xl-0">
                        <button type="button" (click)="searchItems()" class="btn btn-primary btn-block ">
                        <i class="fas fa-search"></i>
                        {{ 'Search' | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="table--grid">
            <div class="table--grid__body">
                <div class="row">
                    <!--table headers-->
                    <div class="col-xs-12">
                        <div class="row">
                            <div *ngFor="let column of tableColumns" class="{{ column[2] }}" class="{{ column[2] }}" (click)="sort(column[3]);" title="{{ ('Sort by' | translate) }} {{ (column[1] | translate) }}">
                                <span [ngClass]="{'sorted': sortIsActivated && sortedColumn === column[3], 'asc': order === 'asc', 'desc': order === 'desc'}">
                                    {{ column[1] | translate }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <!--table cells-->
                <div *ngFor="let clinic of items" class="row" [class.inactive]="!clinic.activated">
                    <!--group header button for table-->
                    <div class="col-xs-12" (click)="openFormModal({ action: 'read', id: clinic['id'], title: 'Clinic Details', activated: clinic['activated'], content: clinic['activated'] ? 'Are you sure you want to deactivate this clinic?' : 'Are you sure you want to activate this clinic?', description: clinic['name'], url: '/clinics' });">
                        <div class="row">
                            <div *ngFor="let column of tableColumns" class="{{ column[2] }}">
                                <ng-template [ngIf]="column[0] != 'country_name' && column[0] != 'customer_type_text'" [ngIfElse]="isTranslateColumn">
                                    {{ clinic[column[0]] }}
                                </ng-template>
                                <ng-template #isTranslateColumn>
                                    {{ clinic[column[0]] | translate }}
                                </ng-template>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-3 table--grid__actions table--grid__actions--hover">
                        <div class="btn-group dropdown" dropdown>
                            <button class="btn btn-link table--grid__actions__ellipsis dropdown-toggle" dropdownToggle>
                                <i class="fas fa-ellipsis-h table--grid__actions__ellipsis__icon"
                                    aria-hidden="true"></i>
                            </button>
                            <ul class="dropdown-menu dropdown-menu-right table--grid__actions__links">
                                <li class="dropdown-item" *ngIf="isUpdatable" (click)="openFormModal({action: 'update', id: clinic['id'], title: 'Update Clinic', url: 'clinics'});">
                                    <a class="btn btn-link table--grid__action-btn">
                                        <i class="fas fa-pencil-alt table--grid__actions__links__icon" aria-hidden="true"
                                        title="{{ 'Edit' | translate }}"
                                        attr.name="{{ 'Edit' | translate }}"></i>
                                    </a>
                                </li>
                                <li class="dropdown-item">
                                    <a class="btn btn-link table--grid__action-btn" [routerLink]="['/users']" [queryParams]="{clinic_id: clinic['id']}">
                                        <i class="fas fa-user table--grid__actions__links__icon" aria-hidden="true"
                                            title="{{ 'List Users' | translate }}"
                                            attr.name="{{ 'List Users' | translate }}"></i>
                                    </a>
                                </li>
                                <li class="dropdown-item">
                                    <a class="btn btn-link table--grid__action-btn" [routerLink]="['/activity-dashboard']" [queryParams]="{clinic_id: clinic['id']}">
                                        <i class="fas fa-chart-bar table--grid__actions__links__icon" aria-hidden="true"
                                            title="{{ 'Activity Dashboard' | translate }}"
                                            attr.name="{{ 'Activity Dashboard' | translate }}"></i>
                                    </a>
                                </li>
                                <li class="dropdown-item">
                                    <a class="btn btn-link table--grid__action-btn" [routerLink]="['/templates']" [queryParams]="{clinic_id: clinic['id']}">
                                        <i class="fas fa-file-alt table--grid__actions__links__icon" aria-hidden="true"
                                            title="{{ 'Edit template' | translate }}"
                                            attr.name="{{ 'Edit template' | translate }}"></i>
                                    </a>
                                </li>
                                <li class="dropdown-item hidden">
                                    <a class="btn btn-link table--grid__action-btn" [routerLink]="['/clinic-sr-config']" [queryParams]="{clinic_id: clinic['id']}">
                                        <i class="fas fa-clone table--grid__actions__links__icon" aria-hidden="true"
                                            title="{{ 'Choose Self Report' | translate }}"
                                            attr.name="{{ 'Choose Self Report' | translate }}"></i>
                                    </a>
                                </li>
                                <li class="dropdown-item hidden">
                                    <a class="btn btn-link table--grid__action-btn" [routerLink]="['/clinic-service-provider']" [queryParams]="{clinic_id: clinic['id']}">
                                        <i class="fas fa-book table--grid__actions__links__icon" aria-hidden="true"
                                            title="{{ 'Choose Service Provider' | translate }}"
                                            attr.name="{{ 'Choose Service Provider' | translate }}"></i>
                                    </a>
                                </li>
                                <li class="dropdown-item" *ngIf="isDeletable" (click)="deleteClinic(clinic)">
                                    <a class="btn btn-link table--grid__action-btn">
                                        <i class="far fa-trash-alt table--grid__actions__links__icon"
                                            title="{{ 'Delete' | translate }}"
                                            attr.name="{{ 'Delete' | translate }}" aria-hidden="true"></i>
                                    </a>
                                </li>
                                <li class="dropdown-item" *ngIf="isActivatable" (click)="openConfirmModal({
                                    url: 'clinics',
                                    id: clinic['id'],
                                    action: 'activate',
                                    title: clinic['activated'] ? 'Deactivate Clinic Account' : 'Activate Clinic Account',
                                    content:  clinic['activated'] ? 'Are you sure you want to deactivate this clinic?' : 'Are you sure you want to activate this clinic?',
                                    icon: (clinic.activated)?'fas fa-ban':'far fa-check-circle',
                                    description: clinic['name']
                                })">
                                    <a class="btn btn-link table--grid__action-btn" [ngClass]="{'table--grid__action-btn--lighter': !clinic.activated}">
                                        <i class="table--grid__actions__links__icon"
                                        [ngClass]="(clinic.activated)?'fas fa-ban':'far fa-check-circle'"
                                        aria-hidden="true"
                                        title="{{ clinic.activated ?  ('Deactivate' | translate) : ('Activate' | translate) }}"
                                        attr.name="{{ clinic.activated ?  ('Deactivate' | translate) : ('Activate' | translate) }}"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!--pagination-->
    <pagination-component *ngIf="!paginationHidden" [(page)]="currentPage" [total]="totalItems" [perPage]="itemsPerPage" [limit]="maxSize" boundaryNumbers="1" boundaryLinks="true" (pageChange)="paginate($event)" #pagination></pagination-component>

    <!-- Modal -->
    <div bsModal #modal="bs-modal" class="modal fade" [ngClass]="params['action'] === 'activate' || params['action'] === 'delete' ? 'modal-confirmation': ''" tabindex="-1" role="dialog" aria-hidden="true">

        <div class="modal-dialog">
            <clinics-modal-component
                [clinicUsers]="clinicUsers"
                [params]="params"
                [formLoading]="formLoading"
                [isUploading]="isUploading"
                [form]="form"
                [errorMessage]="errorMessage"
                [responseMessage]="responseMessage"
                [formSubmitted]="formSubmitted"
                [fullPath]="fullPath"
                [languages]="languages"
                [countries]="countries"
                [authService]="authService"
                [token]="token"
                [accessControl]="{
                    'isIndexable': isIndexable,
                    'isCreatable': isCreatable,
                    'isUpdatable': isUpdatable,
                    'isDeletable': isDeletable,
                    'isActivatable': isActivatable
                }"
                [helpRawData]="helpClinicProfile"
                (hideModal)="hideModal()"
                (submit)="submit(form.value, form.valid)"
                (openFormModal)="openFormModal({ action: 'update', id: params['id'], title: 'Edit Clinic', url: 'clinics' })"
                (activateConfirmationModal)="openConfirmModal({
                    url: 'clinics',
                    action: 'activate',
                    id: params['id'],
                    activated: params['activated'],
                    title: params['activated'] == 1 ? 'Deactivate Clinic Account' : 'Activate Clinic Account',
                    content: params['content'],
                    description: params['description'],
                    icon: params['activated'] == 1 ? 'fas fa-ban':'far fa-check-circle'
                })"
                (delete)="deleteClinic(params['clinic'])"
                (avatarUploading)="avatarUploading($event)"
                (logoUploaded)="logoUploaded($event)"
                (deleteLogoEmit)="deleteLogo($event)"
                >
            </clinics-modal-component>
        </div>
    </div>
    <!--close <div bsModal ...-->

    <div bsModal #modalDeleteClinic="bs-modal" class="modal fade modal-confirmation" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-confirmation-dialog modal-sm">
            <div class="modal-content">
                <div class="modal-body text-xs-center">
                    <div class="row">
                        <div class="col-xs-12">
                            <div class="modal-icon">
                                <i class="far fa-trash-alt" ng-class="icon" aria-hidden="true"></i>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 modal-title">
                            {{ 'Delete clinic confirmation header' | translate: {name: deletingName} }}
                        </div>
                        <div class="col-xs-12 modal-content">
                            {{ 'Are you sure you want to delete this clinic?' | translate }}
                            <br/>
                            <b>{{ 'To procced please enter the mTAN COde you just recieved.' | translate }} </b>
                        </div>
                    </div>
                    <div class="row form-group">
                        <form [formGroup]="smsCodeForm" novalidate class="mat-form">
                            <div class="col-xs-12">
                                <mat-form-field>
                                    <input formControlName="smsCode" #mTanCode type="text" matInput [placeholder]="'Verification number' | translate" autocomplete="off"/>
                                    <small class="form-text text-danger" [hidden]="smsCodeForm.controls.smsCode.valid || smsCodeForm.controls.smsCode.disabled ||
                                    (smsCodeForm.controls.smsCode.pristine && !smsCodeSubmitted)">
                                        {{ 'Verification number is required.' | translate }}
                                    </small>
                                </mat-form-field>
                            </div>
                        </form>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 text-xs-right">
                            <button type="button" class="btn btn-secondary " (click)="this.modalDeleteClinic.hide()">
                                <i class="fas fa-ban"></i>
                                {{ 'Cancel' | translate }}
                            </button>
                            <button type="button" class="btn btn-primary " (click)="submitDeleteClinic()">
                                <i class="fas fa-check"></i>
                                {{ 'OK' | translate }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<dialog-component #dialog></dialog-component>
<dialog-component #dialogLogo></dialog-component>
