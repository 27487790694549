
import {finalize} from 'rxjs/operators';
import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RequestActionService } from './request-action.service';
import { AuthService } from '../../core/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthComponent } from '../../auth/auth.component';
import { AppService } from '../../app.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';

@Component({
    selector: 'app-request-action',
    templateUrl: './request-action.component.html',
    styleUrls: ['../auth.component.scss']
})

/**
 * New account activation page / Reset password page
 */
export class RequestActionComponent extends AuthComponent implements OnInit, AfterViewInit {
    params: any;
    token: string;
    email: string;
    errorMessage: string;
    successMessage: string;
    infoMessage: string;
    form: FormGroup;

    get isLoading() {
        return this.appService.isLoading;
    }

    set isLoading(isLoading) {
        this.appService.isLoading = isLoading;
    }

    constructor(
        public router: Router,
        public authService: AuthService,
        public appService: AppService,
        public translate: TranslateService,
        public requestActionService: RequestActionService,
        public activatedRouter: ActivatedRoute,
        public fb: FormBuilder
        ) {
            super(router, authService, appService, translate);
        }

    ngOnInit() {
        localStorage.removeItem('user');
        super.ngOnInit();

        this.activatedRouter.params.subscribe(params => {
            this.params = params;
            if (params['token']) {
                this.token = params['token'];
            }
            if(params['action'] && params['id']){
                this.appService.isLoading = true;
                this.requestActionService
                    .verifyAccess(this.token, params['action'], params['id'])
                    .subscribe(response => {
                            this.appService.isLoading = false;
                            this.successMessage = response['message'];
                        },
                        error => {
                            this.appService.isLoading = false;
                            this.errorMessage = error.error.error;
                        });
            }
        });

    }

    ngAfterViewInit(): void {
    }

    verifyToken(){
        this.requestActionService
            .verifyToken(this.token)
            .subscribe(response => {
                    this.email = response['email'];
                },
                error => {
                    this.errorMessage = error.error.error;
                    this.router.navigate(['/login'], {
                        queryParams: {
                            infoMessage: error.error.error
                        }
                    });
                });
    }
}
