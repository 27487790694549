import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from '../../shared/base.component';
import { AuthService } from '../../core/auth.service';
import { BaseService } from '../../shared/base.service';
import { AppService } from '../../app.service';
import { TermsConditionService } from '../terms-condition.service';

@Component({
    selector: 'app-terms-condition-modal',
    templateUrl: './modal.component.html',
    encapsulation: ViewEncapsulation.None,
    providers: [TermsConditionService]
})

export class TermsConditionModalComponent extends BaseComponent {
    content = {};

    constructor(public authService: AuthService,
        public router: Router,
        public baseService: BaseService,
        public appService: AppService,
        public termsConditionService: TermsConditionService
        ) {
        super(authService, router, baseService, appService);
    }

    openModal() {
        const params = {
            action: 'open',
            url: '',
            title: 'General terms and conditions'
        };

        this.content = {};
        this.getTemplateContent(this.termsConditionService, params, this.content);
    }
}

