<div bsModal #modal="bs-modal" class="modal profile-edit-modal fade" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog profile">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">{{ 'Edit Account' | translate }}</h4>
            </div>
            <div class="modal-body">
                <button type="button" class="btn btn-link close" (click)="hideModal()" aria-label="Close">
                </button>
                <ng-template [ngIf]="!formLoading">
                    <form [formGroup]="form" novalidate (ngSubmit)="submit(form.value, form.valid)" class="mat-form">
                        <div *ngIf="errorMessage.length > 0" class="row form-group" role="alert">
                            <div class="col-sm-10">
                                <div class="alert alert-danger">
                                    <div *ngFor="let error of errorMessage">
                                        <i class="fas fa-exclamation-circle" aria-hidden="true"></i> {{ error | translate }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="responseMessage" class="row form-group" role="alert">
                            <div class="col-sm-10">
                                <div class="alert-success alert">
                                    <i class="fas fa-check" aria-hidden="true"></i> {{ responseMessage | translate }}
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-xs-2">
                                <div class="avatar {{isUploading ? 'loading-overlay' : ''}}">
                                    <i class="btn btn-link nav-link sprite icons--profile" *ngIf="!fullPath"></i>
                                    <div class="spinning-circle">
                                    </div>
                                    <img *ngIf="fullPath" src="{{ fullPath }}" class="icons--profile" />
                                    <i (click)="removeAvatar()" class="avatar__remove far fa-times-circle" *ngIf="fullPath"></i>
                                </div>
                            </div>
                            <div class="form-group col-xs-10">
                                <image-upload class="image-upload-extension" [headers]="{'Authorization': 'Bearer ' + token}" [max]="10" [maxFileSize]="2000000"
                                              [fileTooLargeMessage]="('Image too large!' | translate)" [extensions]="['jpg','jpeg','png']"
                                              [url]="authService.baseUrl + 'profile/upload/'" [buttonCaption]="('Select a picture to upload' | translate)"
                                              (uploadFinished)="avatarUploaded($event)" (uploadStateChanged)="avatarUploading($event)"
                                              [preview]="false" dropBoxMessage="">
                                </image-upload>
                                {{ 'Max file size: 2MB. Recommend format: jpg, jpeg and png' | translate }}
                            </div>
                        </div>
                        <div class="row">
                            <div #emailFG class="form-group col-xs-6">
                                <mat-form-field class="ps-content">
                                    <input type="email" matInput [placeholder]="'Email Address' | translate" required="required" formControlName="email" (focus)="onInputFocused($event, emailFG)"/>
                                    <small class="form-text text-danger" [hidden]="!form.controls.email.valid || (form.controls.email.pristine && !formSubmitted)">
                                        {{ 'Require this format: name@company.com' | translate }}
                                    </small>
                                </mat-form-field>
                            </div>
                            <div #officialNameFG class="form-group col-xs-6">
                                <mat-form-field class="ps-content">
                                    <input type="text" matInput [placeholder]="'Official Name' | translate" formControlName="official_name"  (focus)="onInputFocused($event, officialNameFG)"/>
                                    <small class="form-text text-danger" [hidden]="form.controls.official_name.valid || (form.controls.official_name.pristine && !formSubmitted)">
                                        {{ 'Require at least 3 characters.' | translate }}
                                    </small>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div #givenNameFG class="form-group col-xs-6">
                                <mat-form-field class="ps-content">
                                    <input type="text" matInput [placeholder]="'Given Name' | translate" required="required" formControlName="given_name"  (focus)="onInputFocused($event, givenNameFG)"/>
                                    <small class="form-text text-danger" [hidden]="form.controls.given_name.valid || (form.controls.given_name.pristine && !formSubmitted)">
                                        {{ 'Require at least 3 characters.' | translate }}
                                    </small>
                                </mat-form-field>
                            </div>
                            <div #lastNameFG class="form-group col-xs-6">
                                <mat-form-field class="ps-content">
                                    <input type="text" matInput [placeholder]="'Last Name' | translate" required="required" formControlName="surname"  (focus)="onInputFocused($event, lastNameFG)"/>
                                    <small class="form-text text-danger" [hidden]="form.controls.surname.valid || (form.controls.surname.pristine && !formSubmitted)">
                                        {{ 'Require at least 3 characters.' | translate }}
                                    </small>
                                </mat-form-field>
                            </div>
                        </div>
                        <div  class="row">
                            <div #passwordFG class="form-group col-xs-6">
                                <label>{{ 'Password' | translate }}</label>
                                <input type="password" class="form-control" autocomplete="new-password" placeholder="***" formControlName="password" (focus)="onInputFocused($event, passwordFG);isCorrectPassword = false" (blur)="isCorrectPassword = !form.controls.password.errors"/>
                                <div *ngIf="form.controls.password.value && !isCorrectPassword" class="form-text password">
                                    <small  [ngClass]="form.controls.password.hasError('minlength') ? 'text-danger' : 'text-success' ">
                                        {{ 'At least 8 characters long' | translate }}
                                    </small>
                                    <small  [ngClass]="form.controls.password.hasError('haveNumber') ? 'text-danger' : 'text-success' " >
                                        {{ 'At least 1 number' | translate }}
                                    </small>
                                    <small [ngClass]="form.controls.password.hasError('haveSpecialCharacter') ? 'text-danger' : 'text-success' ">
                                        {{ 'At least 1 special character' | translate }}
                                    </small>
                                </div>
                            </div>
                            <div #passwordConfirmationFG class="form-group col-xs-6">
                                <label>{{ 'Confirm Password' | translate }}</label>
                                <input type="password" autocomplete="new-password" class="form-control" [placeholder]="form.controls.password.value ? '' : '***'" formControlName="passwordConfirmation" (focus)="onInputFocused($event, passwordConfirmationFG)"/>
                            </div>
                        </div>
                        <div class="row">
                            <div #mobileNumFG class="form-group col-xs-6">
                                <div class="row">
                                    <div class="col-xs-8">
                                        <mat-form-field class="ps-content">
                                            <input type="text" matInput [placeholder]="'Mobile Phone Number' | translate" formControlName="mobile" (focus)="focusInMobileInput($event, mobileNumFG, true)"
                                                (focusout)="focusOutMobileInput()"/>
                                            <small [ngClass]="isFocusMobileInput ? 'text-normal' : 'text-danger'" [hidden]="form.controls.mobile.valid || (form.controls.mobile.pristine && !formSubmitted)">
                                                {{ 'Country code format e.g. "+41 234 56789"' | translate }}
                                            </small>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-xs-4">
                                        <button class="btn btn-primary btn-block btn--send-sms" (click)="sendTestSms()" type="button">{{ 'Test' | translate }}</button>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-xs-6">
                                <p>
                                    <i class="fas fa-info-circle" aria-hidden="true"></i>
                                    {{ verifyPhoneNumberMessage | translate }}
                                </p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group form-wrapper col-xs-6">
                                <div class="row">
                                    <div class="label col-xs-12">{{ 'Login Type' | translate }}</div>
                                    <div class="col-xs-12">
                                        <input type="radio" id="p-simple" [value]="0" formControlName="login_type" [checked]="form.controls.login_type.value === 0" />
                                        <label for="p-simple">{{ 'Simple' | translate }}</label>
                                        <br>
                                        <input type="radio" id="p-extended-p" [value]="1" formControlName="login_type" [checked]="form.controls.login_type.value === 1" />
                                        <label for="p-extended-p"> {{ 'Extended by phone' | translate }}</label>
                                        <br>
                                        <input type="radio" id="p-extended-e" [value]="2" formControlName="login_type" [checked]="form.controls.login_type.value === 2" />
                                        <label for="p-extended-e"> {{ 'Extended by e-mail' | translate }}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-xs-6">
                                <label class="label--highlight">{{ 'Important' | translate }}</label>
                                <p>
                                    {{ 'Simple login is per email address and password.' | translate }}
                                    <br/> {{ 'Extended is two-leveled and adds a SMS verification code level.' | translate }}
                                </p>
                            </div>
                        </div>
                        <div class="row" *ngIf="isShowEmailSetting">
                            <div class="form-group form-wrapper col-xs-6">
                                <div class="row">
                                    <div class="label col-xs-12">{{ 'Notification' | translate }}</div>
                                    <div class="col-xs-12">
                                        <br>
                                        <input type="radio" id="email-notification-y" formControlName="email_notification" value="1"   [checked]="form.controls.email_notification.value == 1"/>
                                        <label for="email-notification-y">{{ 'Deliver email notifications immediatly' | translate }}</label>
                                        <br>
                                        <input type="radio" id="email-notification-o" value="2" formControlName="email_notification"  [checked]="form.controls.email_notification.value == 2"/>
                                        <label for="email-notification-o" id="email-notification-o-label">{{ 'Deliver email notifications only on office hours (from 7:00pm to 7:00am and on weekends, notifications are hold back)' | translate }}</label>
                                        <br>
                                        <br>
                                        <input type="radio" id="email-notification-n" value="0" formControlName="email_notification"  [checked]="form.controls.email_notification.value == 0"/>
                                        <label for="email-notification-n">{{ 'Do not send email notifications' | translate }}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-xs-4" disabled>
                                <label>{{ 'Role' | translate }} *</label>
                                <mat-select class="form-control" formControlName="role_id">
                                    <mat-option *ngFor="let role of roles"
                                                [value]="role['id']"
                                                [hidden]="role['id'] === authService.ROLES.CLINIC_ADMIN && user?.is_patient_transfer_host">
                                        {{ role['name'] | translate }}
                                    </mat-option>
                                </mat-select>
                                <input type="text" class="form-control hidden-xs-up" formControlName="role_id">
                            </div>
                            <div class="form-group col-xs-2">
                                <div class="label">{{ roleLabel | translate }}</div>
                                <div class="pt-0">
                                    <mat-slide-toggle formControlName="have_admin_role">
                                    </mat-slide-toggle>
                                </div>
                            </div>
                            <div #careerFG class="form-group col-xs-6">
                                <mat-form-field class="ps-content">
                                    <input type="text" matInput [placeholder]="'Career' | translate" formControlName="career" (focus)="onInputFocused($event, careerFG, true)"/>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-xs-4">
                                <mat-form-field class="ps-content">
                                    <mat-select [placeholder]="'Institution/ Clinic' | translate"
                                                required="required" formControlName="clinic_id">
                                        <mat-option disabled value="null" *ngIf="user.clinic">
                                            {{ 'Select Clinic' | translate }}
                                        </mat-option>
                                        <mat-option *ngFor="let clinic of clinics" [value]="clinic['id']">
                                            {{ clinic['name'] }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <input type="text" class="form-control hidden-xs-up" formControlName="clinic_id">
                            </div>
                            <div class="col-xs-4">
                                <mat-form-field class="ps-content">
                                    <mat-select [placeholder]="'Language' | translate"
                                                required="required" formControlName="language_id">
                                        <mat-option *ngFor="let language of languages" [value]="language['id']">
                                            {{ language['name'] | translate }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <div class="col-xs-4" *ngIf="authService.isPoolClinic && authService.isDoctor">
                                <mat-form-field #cityTXT class="">
                                    <input type="text" matInput [placeholder]="'City' | translate" formControlName="city" (focus)="onInputFocused($event, cityTXT, true)"/>
                                </mat-form-field>
                            </div>
                        </div>

                        <ng-container *ngIf="authService.isPoolClinic && authService.isDoctor">
                            <div class="row">
                                <div class="col-xs-12">
                                    <div class="label label--highlight">{{ 'Signature' | translate }}</div>
                                    <div class="pt-0">
                                        <mat-slide-toggle formControlName="have_signature" (change)="enableSignature(form.controls['have_signature'].value)">
                                        </mat-slide-toggle>
                                    </div>
                                </div>
                            </div>

                            <div class="row mt-2" *ngIf="form.controls['have_signature'].value">
                                <div class="form-group col-xs-8">
                                    <image-upload class="image-upload-extension"
                                                  [max]="10" [maxFileSize]="2000000"
                                                  [fileTooLargeMessage]="('Image too large!' | translate)"
                                                  [extensions]="['jpg','jpeg','png']"
                                                  (change)="getBase64($event)"
                                                  [buttonCaption]="('Upload Signature' | translate)"
                                                  [preview]="false" dropBoxMessage="">
                                    </image-upload>
                                    {{ 'Max file size: 2MB. Recommend format: jpg, jpeg and png' | translate }}
                                </div>

                                <div class="col-xs-3 xs-offset-1" *ngIf="form?.controls['signature']">
                                    <div class="label">{{ 'Preview' | translate }}</div>
                                    <img class="w-100" [src]="form?.controls['signature']?.value" />
                                </div>
                            </div>

                            <div class="row" [class.hidden]="!form.controls['have_signature'].value">
                                <div class="col-xs-9">
                                    <div class="label">{{ 'or Sign below' | translate }}</div>
                                    <button type="button" class="btn btn-secondary pull-right mr-2" (click)="sig.clear();">
                                        {{ 'Clear' | translate }}
                                    </button>
                                    <canvas #canvas class="pull-left signature" width="600" height="360"></canvas>
                                </div>
                            </div>
                        </ng-container>

                        <div class="row">
                            <div class="col-xs-12 text-xs-right">
                                <button type="button" class="btn btn-secondary" (click)="hideModal()">
                                    {{ 'Cancel' | translate }}
                                </button>
                                <button type="submit" class="btn btn-primary">
                                    {{ 'Submit' | translate }}
                                </button>
                            </div>
                        </div>
                    </form>
                </ng-template>
            </div>
        </div>
    </div>
</div>
<dialog-component #dialogAvatar></dialog-component>