<header *ngIf="isLoggedIn && !isDiagnosisInterview && !authService.maintenanceMode && !isSR" class="header not-diagnosisInterview not-maintenanceMode role-{{activeRole}}">
    <div class="container">
        <div class="row header__row">
            <div class="col-xs-4 col-xl-3 logo">
                <a [routerLink]="['/home']" class="">
                    <div class="klenico-logo"></div>
                </a>
                <div class="btn-group dropdown dropdown--nav-link hidden-xl-up menu-icon" dropdown>
                    <button *ngIf="menus && menus.length" class="btn btn-link nav-link sprite nav-link__icons--menu dropdown-toggle" dropdownToggle [disabled]="disabled">
                    </button>
                    <div class="dropdown-menu dropdown-menu-left">
                        <a *ngFor="let menu of menus;trackBy: trackMenu" class="dropdown-item sprite nav-link__icons nav-link__icons--{{ menu.icon }}"
                           (click)="goRoute(menu.route)">
                            {{ menu.name | translate }}
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-xl-6">
                <!-- Not use-->
                <div class="navbar navbar-light hidden">
                    <ul class="nav navbar-nav float-xs-left">
                        <li *ngFor="let menu of menus;trackBy: trackMenu" class="nav-item">
                            <a class="nav-link sprite nav-link__icons nav-link__icons--{{ menu.icon }}" (click)="goRoute(menu.route)">
                                {{ menu.name | translate }}
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-xs-8 col-xl-3">
                <div class="navbar navbar-light">
                    <ul class="nav navbar-nav float-xs-right" *ngIf="activeRole !== authService.ROLES.INSURANCE_PARTNER; else InsurranceHeader">
                        <li class="nav-item">
                            <a class="btn btn-link" (click)="showUserFeedbackModal()">
                                <i class="far fa-comment-dots  dropdown--circle__icon"></i>
                            </a>
                        </li>
                        <li class="nav-item">
                            <div class="btn-group dropdown dropdown--circle user-select-none" dropdown #dropdownNotify="bs-dropdown" (mouseenter)="dropdownNotify.show()" (click)="dropdownNotify.show()" (mouseleave)="dropdownNotify.hide()">
                                <a class="btn btn-link nav-link dropdown-toggle--no-caret" dropdownToggle title="{{ 'Notifications' | translate }}">
                                    <i class="far fa-bell dropdown--circle__icon"></i>
                                    <ng-template [ngIf]="appService.unreadNotifications.length">
                                        <span class="tag">
                                            <em *ngIf="appService.unreadNotifications.length < MAX_NO_OF_NOTIFICATIONS; else greaterThan">
                                                {{ appService.unreadNotifications.length }}
                                            </em>
                                            <ng-template #greaterThan>
                                                {{ MAX_NO_OF_NOTIFICATIONS - 1 }}+
                                            </ng-template>
                                        </span>
                                    </ng-template>
                                </a>
                                <div class="dropdown-menu dropdown-menu-center">
                                    <notification-list></notification-list>
                                </div>
                            </div>
                        </li>
                        <li class="nav-item" *ngIf="activeRole !== authService.ROLES.PATIENT && activeRole !== authService.ROLES.COORDINATOR">
                            <a class="btn btn-link" (click)="appService.sendGA(appService.GAEvents.HEADER_MENU.name, appService.GAEvents.HEADER_MENU.event.activityDashboard)" [routerLink]="['/activity-dashboard']">
                                <i class="fal fa-chart-bar  dropdown--circle__icon"></i>
                            </a>
                        </li>
                        <li class="nav-item" *ngIf="activeRole !== authService.ROLES.PATIENT && activeRole !== authService.ROLES.COORDINATOR">
                            <a class="btn btn-link" (click)="appService.sendGA(appService.GAEvents.HEADER_MENU.name, appService.GAEvents.HEADER_MENU.event.support)" [routerLink]="['/support']">
                                <i class="fal fa-question-circle  dropdown--circle__icon"></i>
                            </a>
                        </li>
                        <li class="nav-item">
                            <div class="btn-group dropdown dropdown--profile" dropdown>
                                <button class="btn btn-link dropdown-toggle dropdown-toggle--profile" dropdownToggle [disabled]="false">
                                    <div class="dropdown-toggle--profile__image">
                                        <i *ngIf="!avatar" class="fas fa-user"></i>
                                        <img *ngIf="avatar" src="{{ avatar }}" [alt]="user.official_name"/>
                                    </div>
                                </button>
                                <div class="dropdown-menu dropdown-menu-right shadow" *dropdownMenu>
                                    <ng-container *ngIf="relatedUsers.length > 1">
                                        <span class="btn dropdown-item dropdown-item__role ellipsis pr-2" (click)="switchRole(relatedUsers[0].role_id)">{{ user.official_name }}<i *ngIf="activeRole !== adminRole" class="fas fa-check active-role"></i></span>
                                        <div class="dropdown-divider"></div>
                                        <span class="btn dropdown-item dropdown-item__role ellipsis pr-2" (click)="switchRole(relatedUsers[1].role_id)">{{ 'Administrator' | translate }}<i *ngIf="activeRole === adminRole" class="fas fa-check active-role"></i></span>
                                        <div class="dropdown-divider"></div>
                                    </ng-container>
                                    <span class="btn btn-link dropdown-item dropdown-item__second-level" (click)="openProfile()">{{ 'Edit Account' | translate }}</span>
                                    <a *ngIf="authService.multipleClinics.length > 1" class="dropdown-item dropdown-item__second-level" [routerLink]="['/switch-clinic']">{{ 'Change Clinic' | translate }}</a>
                                    <a class="dropdown-item dropdown-item__second-level" [routerLink]="['/logout']">{{ 'Logout' | translate }}</a>
                                    <span class="dropdown-item dropdown-item--languages dropdown-item__second-level">
                                        <a *ngFor="let language of languages" class="dropdown-item--languages__link" [ngClass]="isActive(language['locale'])" (click)="setLanguage(language['locale'])">
                                            {{ language['code'] | translate }} 
                                        </a>
                                    </span>
                                </div>
                            </div>
                        </li>
                    </ul>

                    <ng-template #InsurranceHeader>
                        <ul class="nav insurance-header navbar-nav float-xs-right">
                            <li class="nav-item nav-item__pc">
                                <a class="dropdown-item dropdown-item__url dropdown-item__url__pc" [routerLink]="['/home']" [routerLinkActive]="['active']">{{ 'Dashboard' | translate }}</a>
                            </li>
                            <li class="nav-item">
                                <a class="hidden dropdown-item dropdown-item__url dropdown-item__url__pc" [routerLink]="['/insurance-dashboard/tokens']" [routerLinkActive]="['active']">{{ 'Tokens' | translate }}</a>
                            </li>
                            <li class="nav-item nav-item__pc">
                                <a class="dropdown-item dropdown-item__url dropdown-item__url__pc" [routerLink]="['/logout']">{{ 'Logout' | translate }}</a>
                            </li>
                            <li class="nav-item nav-item__pc">
                                <div class="dropdown-item__language mat-select-pill">
                                    <mat-select  class="mat-select-pill__select" (selectionChange)="appService.setLanguage($event.value)" [value]="appService.activeLanguage.locale">
                                        <mat-option *ngFor="let language of languages"
                                                    [value]="language['locale']"
                                        >
                                            {{ language['name'] | translate }}
                                        </mat-option>
                                    </mat-select>
                                </div>
                            </li>
                            <li class="nav-item nav-item__mobile">
                                <div class="dropdown dropdown--profile" dropdown>
                                    <span class="dropdown-toggle" dropdownToggle [disabled]="false">
                                        <i class="fas fa-bars"></i>
                                    </span>
                                    <div class="dropdown-menu dropdown-menu-right shadow" *dropdownMenu>
                                        <span class="btn btn-link dropdown-item dropdown-item__url" [routerLinkActive]="['active']">
                                            <a class="dropdown-item p-0" [routerLink]="['/home']">{{ 'Dashboard' | translate }}</a>
                                        </span>
                                        <span class="btn btn-link dropdown-item">
                                            <a class="dropdown-item p-0" [routerLink]="['/logout']">{{ 'Logout' | translate }}</a>
                                        </span>
                                        <span class="dropdown-item dropdown-item--languages dropdown-item__second-level">
                                            <a *ngFor="let language of languages" class="dropdown-item--languages__link" [ngClass]="isActive(language['locale'])" (click)="setLanguage(language['locale'])">
                                                {{ language['code'] | translate }} 
                                            </a>
                                        </span>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</header>
<header *ngIf="isSR" class="header not-loggedIn is-patient"
        [class.header--self-evaluation]='appService.isSE'
        [ngClass]='{"hidden":isHideHeader}'>
    <div class="container" *ngIf="clinic">
        <div class="row header--self-evaluation__logo">
            <div class="col-sm-3 col-xs-6 pd-h--none">
                <a class="brand__logo-text">
                    <img class="img-fluid logo" src="assets/img/pages/klenico-logo.svg">
                </a>
            </div>
            <div *ngIf="!appService.isSE; else languageCode" class="col-sm-9 col-xs-6 navbar navbar-light">
                <ul class="nav navbar-nav float-xs-right">
                    <li class="nav-item">
                        <img *ngIf="clinic['logo']" src="{{clinic['logo']}}" class="icons--clinic" />
                        <div class="btn btn__info">
                            <span class="block">
                                <a class="exclude-translate">{{ clinic['name'] }}</a>
                            </span>
                            <span class="block exclude-translate" title="{{clinic['address']}}">{{ clinic['address'] | truncate }}</span>
                        </div>
                    </li>
                    <li class="nav-item nav-item--borderleft hidden-lg-down" *ngIf="clinic['admin_email'] && clinic['phone']">
                        <div class="btn btn__info info">
                            <span class="exclude-translate" [innerHTML]="ttemplate | translate: {email: this.clinic['admin_email'], phone: this.clinic['phone']}"></span>
                        </div>
                    </li>
                    <li class="nav-item hidden-xl-up">
                        <div class="btn-group dropdown dropdown--circle" dropdown *ngIf="clinic['admin_email'] && clinic['phone']">
                            <button class="btn btn-link nav-link dropdown-toggle dropdown-toggle--no-caret" dropdownToggle [disabled]="disabled">
                                <i class="fas fa-phone-square dropdown--circle__icon"></i>
                            </button>
                            <div class="dropdown-menu dropdown-menu-right">
                                <h6 class="dropdown-header text-small">{{ 'For assistance or questions about this questionnaire' | translate }}</h6>
                                <a class="dropdown-item">
                                    {{ 'Write to us' | translate }}
                                    <br/>
                                    <span class="dropdown--circle__link exclude-translate">{{ clinic['admin_email'] }}</span>
                                </a>
                                <a class="dropdown-item">
                                    {{ 'Call' | translate }}
                                    <br/>
                                    <span class="dropdown--circle__link exclude-translate">{{ clinic['phone'] }}</span>
                                </a>
                            </div>
                        </div>
                    </li>
                    <li class="nav-item" *ngIf="!appService.isSE">
                        <div class="btn-group dropdown dropdown--circle" dropdown>
                            <button class="btn btn-link nav-link dropdown-toggle dropdown-toggle--no-caret" dropdownToggle [disabled]="disabled">
                                <i class="fas fa-comment-alt dropdown--circle__icon"></i>
                            </button>
                            <div class="dropdown-menu dropdown-menu-right">
                                <h6 class="dropdown-header  text-small">{{ 'Language Selection' | translate }}</h6>
                                <span class="dropdown-item dropdown-item--languages">
                                    <a *ngFor="let language of languages" class="dropdown-item--languages__link exclude-translate" [ngClass]="isActive(language['locale'])" (click)="setLanguage(language['locale'])">
                                        {{ language['code'] | translate }}
                                    </a>
                                </span>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <ng-template #languageCode>
                <div class="col-xs-6 col-sm-3 offset-sm-6 pd-h--none" *ngIf="appService.activeLanguage?.code">
                    <div class="row">
                        <div class="col-sm-12 col-xs-12">
                            <div class="form-group header--self-evaluation__language">
                                <label for="">{{ appService.activeLanguage?.name | translate }}</label>
                                <mat-select class="header--self-evaluation__language__select"
                                            (selectionChange)="appService.changeLanguage($event.value)"
                                            [disabled]="appService.waitingTranslation">
                                    <mat-option *ngFor="let language of appService.languages"
                                                [class.mat-active]="appService.activeLanguage.code == language.code"
                                                [value]="language">
                                        {{language.name | translate}}
                                    </mat-option>
                                </mat-select>
                            </div>
                        </div>
                        <div class="col-sm-3 header--self-evaluation__nav hidden">
                            <i class="fas fa-bars" (click)="toggleSENav()"></i>
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
</header>
<header *ngIf="!isLoggedIn && isAuthSEPage" class="header not-loggedIn isAuthSEPage">
    <div class="container">
        <div class="row d-flex">
            <div class="col-xs-2">
                <a>
                    <div class="klenico-logo"></div>
                </a>
            </div>
            <div class="col-xs-3 col-sm-1 offset-xs-6 offset-sm-4 se-auth-language">
                <div class="form-group">
                    <mat-form-field class="select" appearance="none">
                        <mat-select  (selectionChange)="appService.setLanguage($event.value)" [value]="appService.activeLanguage.locale">
                            <mat-option *ngFor="let language of languages" [value]="language.locale">
                                {{ language.code }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>
</header>
<dialog-component #dialog></dialog-component>