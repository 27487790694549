import {Component, OnInit, ViewEncapsulation, Inject, ViewChild, HostListener} from '@angular/core';
import {
    Router,
    NavigationEnd,
    RoutesRecognized,
    ActivatedRoute,
    NavigationStart
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CoreService, Breadcrumb } from './core/core.service';
import { AppService } from './app.service';
import { AuthService } from './core/auth.service';
import { DashboardInformationComponent } from './dashboard-information/dashboard-information.component';
import { filter } from 'rxjs/operators';
import { TermsConditionModalComponent } from './terms-condition/modal/modal.component';
import { PrivacyStatementModalComponent } from './privacy-statement/modal/modal.component';
import { LegalNoticeModalComponent } from './legal-notice/modal/modal.component';
import {HttpClient} from '@angular/common/http';
import {ENV} from './app.env';
import {DOCUMENT} from '@angular/common';
import {setTheme} from 'ngx-bootstrap/utils';
import { MultiClinicsComponent } from './shared/multi-clinics/multi-clinics.component';
import { DeviceLabelModalComponent } from './device-label/modal/modal.component';
import * as _ from 'lodash';
/*
 * App Component
 * Top Level Component
 */

declare var window;
@Component({
    selector: 'app',
    encapsulation: ViewEncapsulation.None,
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
    @ViewChild('dashboardInformationComponent', { static: false }) dashboardInformationComponent: DashboardInformationComponent;
    @ViewChild('termsConditionModalComponent', { static: false }) termsConditionModalComponent: TermsConditionModalComponent;
    @ViewChild('privacyStatementModalComponent', { static: false }) privacyStatementModalComponent: PrivacyStatementModalComponent;
    @ViewChild('legalNoticeModalComponent', { static: false }) legalNoticeModalComponent: LegalNoticeModalComponent;
    @ViewChild('deviceLabelModalComponent', { static: false }) deviceLabelModalComponent: DeviceLabelModalComponent;
    @ViewChild('multiClinicsComponent', { static: false }) multiClinicsComponent: MultiClinicsComponent;

    currentPage = '';
    currentSEStep = 0;
    state: any;
    openedNavSE = false;
    sub: any;
    isHideFooter: boolean;
    isHideHeader: boolean;

    constructor(
        @Inject('defaultLanguage') public defaultLanguage: string,
        public translate: TranslateService,
        public coreService: CoreService,
        public appService: AppService,
        public router: Router,
        public route: ActivatedRoute,
        private authService: AuthService,
        public http: HttpClient,
        @Inject('googleId') private googleId,
        @Inject(DOCUMENT) private document: Document,
    ) {
        this.isLoading = true;
        // set default System language
        const systemLanguage = this.appService.getCurrentSystemLanguage();
        setTheme('bs4');
        if (systemLanguage != null) {
            translate.use(systemLanguage['locale']);
        } else {
            localStorage.setItem('language', this.defaultLanguage);
            localStorage.setItem('defaultLanguage', 'true');
            translate.use(this.defaultLanguage);
        }

        // google tag manager
        const navEndEvent = router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        );

        navEndEvent.subscribe((event: NavigationEnd) => {
            if (googleId) {
                window.gtag('config', googleId, {
                    'page_path': event.urlAfterRedirects
                });
            }
        });

        const navStartEvent = router.events.pipe(
            filter(event => event instanceof NavigationStart)
        );

        navStartEvent.subscribe((event: NavigationStart) => {
            if (event.url.indexOf('/me') >= 0) {
                this.appService.isMePage = true;
            }
        });

    }

    @HostListener('window:orientationchange', ['$event'])
    onOrientationChange(event) {
        if (window.orientation == 0 || window.orientation == 180) {
            this.appService.isPortrait = true;
        }
        else {
            this.appService.isPortrait = false;
        }
    }

    get isLiveQuestionnairePage() {
        return this.router.url.indexOf('live-questionnaire') != -1;
    }

    ngOnInit() {
        if (('performance' in window) && performance.navigation.type === 1) {
            localStorage.setItem('reloaded', JSON.stringify(true));
        }
        this.router.events.subscribe(event => {
            if (event instanceof RoutesRecognized) {
                let route = event.state.root.firstChild;
                this.appService.isRepeatedSE = route.data.page === 'repeatedSE';
                this.appService.isSE = (route.data.page === 'se' || route.data.page === 'repeatedSE');
                if (this.appService.hideFooterPages.indexOf(route.data.page) >= 0) {
                    this.isHideFooter =  true;
                    this.isHideHeader =  true;
                }
                else {
                    this.isHideFooter =  false;
                    this.isHideHeader =  false;
                }
            }
        });
        // Check new Version
        // Check new version each timer
        this.checkNewVersion();

        const _self = this;
        setTimeout(function () {
            _self.appService.SEStep.subscribe( step => {
                _self.currentSEStep = step;
            });
            const active = JSON.parse(localStorage.getItem('reloaded'));
            const openSE = JSON.parse(localStorage.getItem('openSE'));
            const user = JSON.parse(localStorage.getItem('user'));
            const meClinicFolder =localStorage.getItem('meClinicFolder');

            // Keep login when redirect from me sr
            if (meClinicFolder && user && user['role_id'] === _self.authService.ROLES.PATIENT) {
                return;
            }

            if (!active && !openSE && user) {
                localStorage.removeItem('user');
                if(_self.state) {
                    _self.router.navigateByUrl(`login${_self.state}`);
                }
                else{
                    _self.router.navigate(['/login']);
                }
            }
        }, 1000);
    }

    autoLogout() {
        if (this.authService.isLoggedIn) {
            this.authService.logout();
            this.router.navigate(['/login'], {
                queryParams: {
                    infoMessage: 'Your session has expired due to inactivity.'
                }
            });
        }
        localStorage.removeItem('active_time');
    }

    get isLoading() {
        return this.appService.isLoading;
    }

    set isLoading(isLoading) {
        this.appService.isLoading = isLoading;
    }

    createTitle(routesCollection: Breadcrumb[]) {
        const titles = routesCollection.filter((route) => route.displayName);

        if (!titles.length) {
            return '';
        }

        const routeTitle = titles.reduce((prev, curr) => {
            return `${curr.displayName}`;
        }, '');
        return `${routeTitle}`;
    }

    openDashboardInformationModal() {
        this.dashboardInformationComponent.openDashboardInformationModal(this.router.url);
    }

    openLinks($event) {
        switch ($event) {
            case 'terms-condition':
                this.termsConditionModalComponent.openModal();
                break;
            case 'privacy-statement':
                this.privacyStatementModalComponent.openModal();
                break;
            case 'legal-notice':
                this.legalNoticeModalComponent.openModal();
                break;
            case 'device-label':
                this.deviceLabelModalComponent.openModal();
                break;
            default:
                break;
        }
    }

    // Inside the controller
    isBoolean(value) {
        return typeof value === 'boolean';
    }

    /*
    When SW is stable
    Remove this checkNewVersion() function
  */

    checkNewVersion() {
        const d = new Date();
        const version = d.getTime();
        const _self = this;

        // Always auto Check new Version don't need to wait response (sometimes response will have error and make this service don't work anymore)
        // Check new version each timer
        setTimeout(function () {
            _self.checkNewVersion();
        }, 30000);
        this.http.get('./assets/files/build-version.json?v=' + version)
            .subscribe(data => {
                const APIVersion = data['version'];
                const RELEASEDATE = data['releaseDate'];
                localStorage.setItem('release-date', RELEASEDATE);
                const FEVersion = ENV.buildVersion;

                if (APIVersion && APIVersion != FEVersion) {
                    this.appService.setIsNewVersionAvailable(true);
                    localStorage.setItem('build-version', FEVersion);
                    location.reload();
                    this.appService.setIsNewVersionAvailable(false);
                }
            });
    }

    toggleSENav(){
        this.openedNavSE = !this.openedNavSE;
        if(this.openedNavSE){
            return this.document.body.classList.add("disable-scroll");
        }
        return this.document.body.classList.remove("disable-scroll");
    }
}
