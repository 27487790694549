
import {finalize} from 'rxjs/operators';
import { Component, DoCheck, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { ProfileService } from './profile.service';
import { AuthService } from '../core/auth.service';
import { BaseService } from '../shared/base.service';
import { BaseComponent } from '../shared/base.component';
import { ProfileModel } from './profile.model';
import { ProfileInterface } from './profile.interface';
import { AppService } from '../app.service';
import { DialogComponent } from '../dialog/dialog.component';
import { RoleModel } from '../users/user.model';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import SignaturePad from 'signature_pad';

@Component({
    selector: 'profile-component',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss'],
    providers: [ProfileService, BaseService]
})

export class ProfileComponent extends BaseComponent implements OnInit, DoCheck {
    @ViewChild('dialogAvatar', { static: false }) dialogAvatar: DialogComponent;
    @ViewChild('canvas', { static: false }) canvas: ElementRef;

    roles: Object[] = [];
    clinics: Object[] = [];
    password: string;
    passwordConfirmation: string;
    verifyPhoneNumberMessage: string;
    isFocusMobileInput: boolean = false;
    isAllowSwitchLoginType: boolean = false;
    isCorrectPassword: boolean = false;
    user: ProfileModel;
    avatarModal: any;
    relatedUsers: Object[] = [];
    additionalRole = 0;
    roleLabel : string = '' ;
    regexSpecialCharacter = '[\x20-\x2F\x3A-\x40\x5B-\x60\x7b-\x7e\xA0-\xA3\xA5\xA7\xA9-\xB3\xB5-\xB7\xB9-\xBB\xBF-\xFF\u20AC\u0160\u0161\u017D\u017E\u0152\u0153\u0178]'; // ISO 8859-15
    className = 'profile-edit-modal';
    ROLE = new RoleModel();
    subcription: Subscription;
    languages = [];
    sig: SignaturePad;
    signatureByImage = false;

    // define what table columns to display [column key, label, css class]
    readonly tableColumns = [
        ['surname', 'Last Name', 'col-xs-2 col-lg-2'],
        ['given_name', 'Given Name', 'col-xs-2 col-lg-1'],
        ['email', 'Email Address', 'col-xs-2 col-sm-5 col-sm-3 col-lg-3'],
        ['role_name', 'Role', 'col-xs-2 col-sm-3 col-lg-2 hidden-sm-down'],
        ['created_at', 'Addition Date', 'col-xs-2 hidden-sm-down col-lg-2']
    ];

    constructor(public authService: AuthService,
                public router: Router,
                public baseService: BaseService,
                public appService: AppService,
                public profileService: ProfileService,
                public fb: FormBuilder,
                public location: Location) {
        super(authService, router, baseService, appService);
    }

    /**
     * Init profile form
     */
    ngOnInit() {
        super.ngOnInit();

        this.searchInfo = new ProfileModel();
        this.languages = JSON.parse(localStorage.getItem('languages'));

        this.url = 'profile';
        this.token = this.authService.user['token'];
        this.verifyPhoneNumberMessage = 'We strongly recommend that you test your mobile number by clicking on the Test button. All verification codes will be send to that mobile number.';

        this.openFormModal();
    }

    ngDoCheck() {
        if (!this.authService.isLoggedIn) {
            this.modal.hide();
        }
    }
    /**
     * Open form modal
     */
    openFormModal() {
        this.appService.isLoading = true;
        this.profileService.read(this.authService.user['id']).pipe(
            finalize(() => this.appService.isLoading = false))
            .subscribe(response => {
                    this.user = response['user'] || {};
                    // remove Insurance Partner Role for admin and SAdmin #6575
                    let roles = response['roles'].length ? response['roles'] : [this.user.role];
                    this.roles = roles;
                    if (this.user.role_id === this.authService.ROLES.CLINIC_ADMIN || this.user.role_id === this.authService.ROLES.SUPER_ADMIN) {
                        const _self = this;
                        this.roles = _.filter(roles, function (role) {
                            return role['id'] !== _self.authService.ROLES.INSURANCE_PARTNER
                        });
                    }

                    this.clinics = response['clinics'];
                    this.relatedUsers = response['related_users'];
                    // display only the roles that the logged-in user can select

                    let form = {
                        'email': '',
                        'official_name': '',
                        'given_name': '',
                        'surname': '',
                        'mobile': '',
                        'role_id': '',
                        'clinic_id': '',
                        'login_type': '',
                        'career': '',
                        'avatar': '',
                        'email_notification': '',
                        'language_id': '',
                        'have_signature': true,
                        'signature': '',
                        'city': '',
                        'have_admin_role' : response['related_users'].length > 1 || this.authService.isSuperAdmin
                    };
                    Object.keys(form).forEach(key => {
                        form[key] = this.user[key] || '';
                    });

                    form['password'] = null;
                    form['passwordConfirmation'] = null;

                    if (this.form != null) {
                        this.form.reset(form);
                    } else {
                        this.form = this.fb.group(form);
                        this.form.controls['email'].setValidators([<any>Validators.required,
                            <any>Validators.pattern('[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}')]);
                        this.form.controls['official_name'].setValidators([<any>Validators.minLength(3)]);
                        this.form.controls['given_name'].setValidators([<any>Validators.required, <any>Validators.minLength(3)]);
                        this.form.controls['surname'].setValidators([<any>Validators.required, <any>Validators.minLength(3)]);
                        this.form.controls['mobile'].setValidators([<any>Validators.required, <any>Validators.pattern('\\+{1}[0-9\\s]+')]);
                        this.form.controls['password'].setValidators([Validators.minLength(8),this.appService.regexpValidator('\\d','haveNumber'),this.appService.regexpValidator(this.regexSpecialCharacter,'haveSpecialCharacter')]);
                    }

                    // Don't allow to edit these field
                    this.form.controls['role_id'].disable();
                    this.form.controls['clinic_id'].disable();
                    this.form.controls['email'].disable();

                    // Login type of user will depend on Clinic #150 -- Change on #6588
                    this.form.controls['login_type'].disable();
                    this.form.controls['login_type'].setValue(this.user.login_type);

                    this.form.controls['email'].disable();

                    // #6588 
                    if (this.user.clinic) {
                        this.form.controls['login_type'].enable();
                    }

                    this.fullPath = form['avatar'];

                    // listen to password change. If password change -> clear password confirmation
                    this.sub = this.form.get('password').valueChanges.subscribe(data => {
                        if (this.form.controls['passwordConfirmation'].value == null) {
                            return;
                        }
                        this.form.controls['passwordConfirmation'].setValue(null);
                    });

                    this.showAdditionRole();
                    this.showModal();

                    // Only apply this feature after modal showed
                    if((this.form?.controls['signature'] && this.form?.controls['signature'].value != "")){
                        this.form.controls['have_signature'].setValue(true);
                        let modal = this;
                        setTimeout(function(){
                            modal.enableSignature(true);
                        },200);
                    }
                    else{
                        this.form.controls['have_signature'].setValue(false);
                    }

                    //Watch when change role
                    this.subcription = this.form.controls['role_id'].valueChanges.subscribe(selectedRole => {
                        this.enableSelectAdminRole(selectedRole);
                    });
                }
            );
    }

    enableSelectAdminRole(roleId) {
        const value = this.form.controls['have_admin_role'].value;
        if (roleId === this.authService.ROLES.SUPER_ADMIN) {
            this.form.controls['clinic_id'].disable();
            this.form.controls['clinic_id'].setValue(0);
            this.form.controls['have_admin_role'].disable();
            this.form.controls['have_admin_role'].setValue(true);
        }
        else 
        {
            if (roleId !== this.authService.ROLES.CLINIC_ADMIN) {
                this.form.controls['have_admin_role'].enable();
                this.form.controls['have_admin_role'].setValue(value);
                if (roleId === this.authService.ROLES.COORDINATOR) {
                    this.form.controls['have_admin_role'].setValue(false);
                    this.form.controls['have_admin_role'].disable();
                }
            } else {
                this.form.controls['have_admin_role'].setValue(true);
                this.form.controls['have_admin_role'].disable();
            }
            this.form.controls['clinic_id'].enable();
            this.form.controls['clinic_id'].setValidators([<any>Validators.required, <any>Validators.min(1)]);
        }

        if (this.authService.user.role_id !== this.authService.ROLES.SUPER_ADMIN) {
            this.form.controls['clinic_id'].disable();
        }

        if(roleId === this.authService.ROLES.ASSISTANT_PLUS) {
            this.form.controls['clinic_id'].disable();
            this.clinics.forEach(clinic => {
                if (clinic['is_default']) {
                    this.form.controls['clinic_id'].setValue(clinic['id']);
                }
            });
        }
    }

    /**
     * Submit form
     * @param {ProfileInterface} model
     * @param {boolean} isValid
     */
    submit(model: any, isValid: boolean): void {
        this.resetErrors();

        this.responseMessage = null;

        // this ensures validation message will only be displayed when necessary
        this.formSubmitted = true;

        // only when the form is valid and its values have been changed
        if (!isValid) {
            return;
        }

        this.isLoading = true;
        model.avatar = this.avatar;

        if (model.password) {
            model.password = model.password.replace(/\+/gi, '%2B');
            model.passwordConfirmation = model.passwordConfirmation.replace(/\+/gi, '%2B');
        }

        if(!model.have_signature ){
            model.signature = "";
        }

        this.baseService.submit(this.url, this.authService.user['id'], model).subscribe(response => {
                this.items = response['items'];
                if (response['message'] != null) {
                    this.responseMessage = response['message'];
                }

                let newRoles = response['related_users'];

                this.isLoading = false;
                this.formSubmitted = false;

                this.profileService.setProfile(response['user']);
                this.authService.getAvatar(response['user']);

                let component = this;
                const hasAdminRole = component.relatedUsers.length > 1 || component.authService.isClinicAdmin || component.authService.isSuperAdmin ? true : false;
                let updatedRole = false;
                if((model.role_id && !_.find(this.relatedUsers, { role_id : model.role_id})) ||
                    (model.have_admin_role && hasAdminRole !== model.have_admin_role) || newRoles.length != component.relatedUsers.length){
                    updatedRole = true;
                }

                setTimeout(function () {
                    component.hideModal();
                    // Auto logoff if Role changed
                    if(updatedRole){
                        component.authService.logout();
                        component.router.navigate(['/login'], {
                            queryParams: {
                                infoMessage: 'Your session has expired due to inactivity.'
                            }
                        });
                    }
                }, 2000);
            },
            error => {
                this.isLoading = false;
                this.errorMessage = this.appService.parseErrors(error);
            });

    }

    /**
     * Hide modal
     */
    hideModal() {
        this.modalIsClosed = true;
        this.isLoading = false;
        this.responseMessage = null;
        this.subcription.unsubscribe();
        this.modal.hide();
    }

    /**
     * Set params
     * @param params
     */
    setParams(params) {
        this.params = params;
    }

    /**
     * Send test sms
     */
    sendTestSms() {
        let mobileNumber = this.form.controls['mobile'].value;

        if (!mobileNumber) {
            this.form.controls['mobile'].markAsDirty();
            return;
        }

        this.profileService.sendTestSms(this.authService.user['id'], mobileNumber).subscribe(
            response => {
                this.verifyPhoneNumberMessage = response['message'];
            },
            error => {
                this.errorMessage = this.appService.parseErrors(error);
            });
    }

    /**
     * Handle on focus mobile for update the color of help text
     */
    focusInMobileInput(event, form, isBottomField: boolean) {
        this.isFocusMobileInput = true;
        this.onInputFocused(event, form, this.className, isBottomField);
    }

    /**
     * Handle on focus mobile for update the color of help text
     */
    focusOutMobileInput() {
        this.isFocusMobileInput = false;
    }

    changeNotificationSetting() {
        this.user.email_notification = !this.user.email_notification;
    }

    get notificationSettingText() {
        return (this.user.email_notification === 1 || this.user.email_notification === true) ? 'Email notifications activated' : 'Email notifications deactivated';
    }

    get isShowEmailSetting() {
        if (this.user.role_id >= 3) {
            return true;
        }
        return false;
    }

    removeAvatar() {
        let params = {
            url: 'profile/deleteAvatar',
            id: this.user['id'],
            action: 'delete',
            title: 'Remove image',
            content: 'Are you sure you want to remove this image?',
            icon: 'far fa-trash-alt'
        };

        const dialog = this.dialogAvatar.openConfirm(params);
        this.isLoading = false;

        dialog.onSubmit(() => {
            this.isLoading = true;

            const url = (params['component'] ? params['component'] : params['url']) + '/' + params['id'];

            this.baseService.changeStatus(url, params['action'])
                .subscribe(
                    response => {
                        const message = response['message'];
                        this.isLoading = false;
                        this.fullPath = '';
                        this.user['avatar'] = '';
                        if (message) {
                            dialog.showAlert(message);
                        } else {
                            this.avatarModal.hideModal();
                        }
                        this.profileService.setProfile({'avatar': ''});
                        localStorage.setItem('profile-avatar', '');
                    },
                    error => {
                        dialog.showAlert(error.error.error);
                        this.isLoading = false;
                    }
                );
        });

        this.avatarModal = dialog;

        dialog.onCancel(() => {
            dialog.hideModal();
        });
    }

    showAdditionRole() {
        let user = this.authService.user;
        this.roleLabel = 'Admin rights';
        let profile = this;
        let additionalRole: any;
        let hasAdminRole = this.relatedUsers.length > 1 || user.role_id === this.ROLE.ADMIN  || user.role_id === this.ROLE.SUPER_ADMIN ? true : false;

        this.relatedUsers.forEach( item => {
            if(item['role_id'] !== profile.ROLE.ADMIN) {
                additionalRole = item;
                return;
            }
        });
        if(user.role_id === this.ROLE.ADMIN  || user.role_id === this.ROLE.SUPER_ADMIN) {
            hasAdminRole = true;
            this.form.controls['role_id'].enable();
            this.form.controls['have_admin_role'].enable();
        } else{
            this.form.controls['role_id'].disable();
            this.form.controls['have_admin_role'].disable();
        }
        if(this.relatedUsers.length < 2){
            this.form.controls['have_admin_role'].disable();
        }
        this.form.controls['have_admin_role'].setValue(hasAdminRole);
        this.additionalRole = additionalRole?.role_id;
        this.form.controls['role_id'].setValue(additionalRole && additionalRole.role_id ? additionalRole.role_id : user.role_id);
    }

    enableSignature(enable = false){
        if(!enable || !this.canvas){
            return false;
        }
        this.sig = new SignaturePad(this.canvas.nativeElement);
        this.sig.addEventListener("endStroke", () => {
            return this.setSignature();
        }, { once: false });
    }

    clearSig() {
        this.sig.clear();
    }

    getBase64(event) {
        let me = this;
        let file = event.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            return me.setSignature(reader.result);
        };
        reader.onerror = function (error) {
            me.signatureByImage = false;
        };
    }

    setSignature(data = null){
        const sign = data ? data : this.sig.toDataURL();
        this.form.controls['signature'].setValue(sign);
        this.signatureByImage = false;
    }
}
