import { catchError } from 'rxjs/operators';
import { Inject, Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable, Subject, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()

export class AuthService {
    constructor(public http: HttpClient,
                public router: Router,
                public jwtHelper: JwtHelperService,
                @Inject('baseUrl') public baseUrl: string,
                @Inject('defaultLanguage') public defaultLanguage: string) {

        this.formattedPermissions();
    }

    loginCompleteEvent = new Subject();
    logoutCompleteEvent = new Subject();

    public ROLES = {
        SUPER_ADMIN: 1,
        CLINIC_ADMIN: 2,
        DOCTOR: 3,
        ASSISTANT: 4,
        MPA: 5,
        PATIENT: 6,
        COORDINATOR: 7,
        B2C_ADMIN: 8,
        ASSISTANT_PLUS: 9,
        INSURANCE_PARTNER: 10
    };

    EMPLOYEE_ROLES = [
        this.ROLES.MPA,
        this.ROLES.DOCTOR,
        this.ROLES.ASSISTANT,
        this.ROLES.COORDINATOR,
        this.ROLES.ASSISTANT_PLUS
    ];

    public LOGIN_TYPE = {
        SIMPLE: 0,
        EXTENDED: 1
    };

    public NOTICE_TYPE = {
        ARCHIVE: 'archive',
    };

    maintenanceMode = false;
    patientMeMode = false;
    maintenanceMessage: string;

    permissions: any[] = [];
    showMessage = {
        archiveQuestionnaire: true
    };

    /**
     * Check login (token) only
     * @returns {boolean}
     */
    get isLoggedIn() {
        return !this.jwtHelper.isTokenExpired(this.getToken());
    }

    /**
     * Check login and redirect
     * @returns {boolean}
     */
    redirectIfNotLogin() {
        if (this.isLoggedIn) {
            return true;
        }

        this.router.navigate(['login'], {
            queryParams: {
                infoMessage: 'Your session has expired due to inactivity.'
            }
        });

        return false;
    }

    get user() {
        if (!localStorage.getItem('user')) {
            return false;
        }

        return JSON.parse(localStorage.getItem('user'));
    }

    get clinic() {
        if (!localStorage.getItem('clinic')) {
            return false;
        }

        return JSON.parse(localStorage.getItem('clinic'));
    }

    set clinic(clinic) {
        localStorage.setItem('clinic', JSON.stringify(clinic));
    }

    get isPoolClinic() {
        if (!this.clinic) {
            return false;
        }

        return this.clinic['is_default'] !== 0;
    }

    get isTokenExpired() {
        return this.jwtHelper.isTokenExpired(this.getToken());
    }

    get isDoctor() {
        if (!localStorage.getItem('user')) {
            return false;
        }

        return (this.user['role_id'] === this.ROLES.DOCTOR);
    }

    get isMPA() {
        if (!localStorage.getItem('user')) {
            return false;
        }

        return (this.user['role_id'] === this.ROLES.MPA);
    }

    get isAssistant() {
        if (!localStorage.getItem('user')) {
            return false;
        }

        return (this.user['role_id'] === this.ROLES.ASSISTANT);
    }

    get isSuperAdmin() {
        if (!localStorage.getItem('user')) {
            return false;
        }

        return (this.user['role_id'] === this.ROLES.SUPER_ADMIN);
    }

    get isB2CAdmin() {
        if (!localStorage.getItem('user')) {
            return false;
        }

        return (this.user['role_id'] === this.ROLES.B2C_ADMIN);
    }

    get isClinicAdmin() {
        if (!localStorage.getItem('user')) {
            return false;
        }

        return (this.user['role_id'] === this.ROLES.CLINIC_ADMIN);
    }

    get isCoordinator() {
        if (!localStorage.getItem('user')) {
            return false;
        }

        return (this.user['role_id'] === this.ROLES.COORDINATOR);
    }

    get isAssistantPlus() {
        if (!localStorage.getItem('user')) {
            return false;
        }

        return (this.user['role_id'] === this.ROLES.ASSISTANT_PLUS);
    }

    get isPatient() {
        if (!localStorage.getItem('user')) {
            return false;
        }

        return (this.user['role_id'] === this.ROLES.PATIENT);
    }

    get patientId() {
        if (this.isPatient && this.user) {
            return parseInt(this.user['patient_id']);
        }
        return 0;
    }

    get multipleClinics() {
        if (!localStorage.getItem('user')) {
            return false;
        }

        return this.user['clinics'];
    }

    isExtendedLoginClinic() {
        if (this.user['role_id'] === this.ROLES.SUPER_ADMIN) {
            return true;
        }

        return parseInt(this.user['clinic'].login_type) === this.LOGIN_TYPE.EXTENDED;
    }

    onLoginComplete(): Observable<any> {
        return this.loginCompleteEvent.asObservable();
    }

    onLogoutComplete(): Observable<any> {
        return this.logoutCompleteEvent.asObservable();
    }

    saveToLocalStorage(email: string, response: any) {
        localStorage.setItem('user', JSON.stringify(response));

        this.formattedPermissions();

        this.loginCompleteEvent.next(true);

        this.getAvatar(response);

        // Validate dependencies clinics
        if (response['clinic']) {
            this.clinic = response['clinic'];
        }
    }

    logout() {
        let language = localStorage.getItem('language');
        if (language == null) {
            language = this.defaultLanguage;
        }

        localStorage.setItem('language', language);
        localStorage.removeItem('user');
        localStorage.removeItem('clinic');
        this.logoutCompleteEvent.next(true);
    }

    formattedPermissions() {
        const permissions = this.user ? this.user['permissions'] : false;
        if (!permissions) {
            return;
        }

        for (const permission of permissions) {
            const permissionComp = permission.split('.')[0];
            const permissionAction = permission.split('.')[1];

            if (typeof this.permissions[permissionComp] === 'undefined') {
                this.permissions[permissionComp] = [];
            }

            this.permissions[permissionComp].push(permissionAction);
        }
    }

    getAvatar(newProfile) {
        const url = this.baseUrl + 'profile/get-avatar/' + newProfile.id;
        const options = this.getHeaderOptions();
        this.http.get(url, options).subscribe(
            response => {
                localStorage.setItem('profile-avatar', response['fullPath']);
            }
        );
    }

    getHeaderOptions() {
        const token = this.getToken();

        const headerOptions = {
            'Accept': 'application/json',
            'Access-Control-Allow-Credentials': 'true',
            'Access-Control-Max-Age': '3600',
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        };

        return {
            headers: new HttpHeaders(headerOptions)
        };
    }

    getToken() {
        return localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user'))['token'] : null;
    }

    refreshToken(): Observable<any> {
        const url = this.baseUrl + 'oauth/refresh-token';
        const options = this.getHeaderOptions();
        return this.http.post(url, {}, options).pipe(catchError((error: any) => {
            if (error.status === 401 || error.status === 403) {
                this.router.navigate(['login'], {
                    queryParams: {
                        infoMessage: 'Your session has expired due to inactivity.'
                    }
                });
            }
            return throwError(error.error);
        }));
    }

    getDBLanguages() {
        const url = this.baseUrl + 'languages/get-list';
        return this.http.get(url);
    }

    isAuthorised(currentPermission: string) {
        const currentComp = currentPermission.split('.')[0];
        const currentAction = currentPermission.split('.')[1];

        for (const permissionAction of this.permissions[currentComp]) {
            if (currentAction === permissionAction) {
                return true;
            }
        }
        return false;
    }

    isShowNotice(type: string) {
        switch (type) {
            case 'archive':
                return this.user['show_confirm_archive_self_report_modal'] ? true : false;
                break;
            case 'delete':
                break;
            case 'restore':
                break;
        }
    }

    enterSelfEvaluation(token: any, itemPerPage, isMobile) {
        const url = this.baseUrl + 'patients/self-evaluation/' + token + '/' + itemPerPage + '/' + isMobile;

        const headerOptions = {
            'Content-Type': 'application/x-www-form-urlencoded'
        };

        const httpOptions = {
            headers: new HttpHeaders(headerOptions)
        };

        return this.http.get(url, httpOptions);
    }

    switchRole() {
        const url = this.baseUrl + 'oauth/switch-role';
        const options = this.getHeaderOptions();
        return this.http.post(url, {}, options)
    }

    setData(attr, value) {
        this.user[attr] = value;
        if (this?.showMessage[attr] !== undefined) {
            this.showMessage['attr'] = value;
        }
        localStorage.setItem('user', JSON.stringify(this.user));
    }

    setUserLanguage(language: object){
        let user = JSON.parse(localStorage.getItem('user'));
        user['language'] = language;
        user['language_id'] = language['id'];
        user['locale'] = language['locale'];
        localStorage.setItem('user', JSON.stringify(user));
    }
}
