import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ENV } from '../../app.env';

@Injectable()
export class LoginService {

    constructor(public http: HttpClient, @Inject('baseUrl') public baseUrl: string) {
    }

    login(email: string, password: string) {
        let url = this.baseUrl + 'oauth/token';

        let httpParams = new HttpParams()
            .append('email', email.replace(/\+/gi, '%2B'))
            .append('password', password.replace(/\+/gi, '%2B'))
            .append('version', ENV.buildVersion);

        let body = httpParams.toString();

        let headerOptions = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        };

        const httpOptions = {
            headers: new HttpHeaders(headerOptions)
        }

        return this.http.post(url, body, httpOptions);
    }
}
