import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterRole'
})

export class FilterRolePipe implements PipeTransform {
    transform(userCollection: any[], roleId: number): any[] {
        if (!userCollection) return [];

        if (!roleId) return userCollection;

        return userCollection.filter(user => {
            return user.role_id == roleId;
        });
    }
}
