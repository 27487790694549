
import {of as observableOf,  Observable ,  Subscription } from 'rxjs';

import {finalize} from 'rxjs/operators';
// angular
import { OnInit, ViewChild, Directive } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
// 3rd parties
import { ModalDirective } from 'ngx-bootstrap/modal';
// services
import { AuthService } from '../core/auth.service';
import { BaseService } from './base.service';
// others
import { ENV } from '../app.env';
import { AppService } from '../app.service';
import { DialogComponent } from '../dialog/dialog.component';
import { LanguageModel, TemplateContent } from '../models';

@Directive()
export class BaseComponent implements OnInit {
    @ViewChild('dialog',  { static: false}) dialog: DialogComponent;
    // for addition/update/details modal
    @ViewChild('modal',  {static: false}) modal: ModalDirective;

    items: Object[] = [];
    clinicName: string;
    clinicCity: string;
    clinicActivation: boolean;
    languages: Object[] = [];
    countries: Object[] = [];
    totalPeople = 0;
    profileId: number;
    errorMessage: string[] = [];
    errorData: any;
    responseMessage: string;
    token: string;
    // store the path of each component e.g. 'users', 'clinics',...
    url: string;
    // Variables for upload logo
    fullPath: string;
    isUploading = false;
    avatar: string;
    // check if items are changed to update the items
    isUpdated = true;

    // handle app loading icon
    get isLoading() {
        return this.appService.isLoading;
    }

    set isLoading(isLoading) {
        this.appService.isLoading = isLoading;
    }

    modalIsClosed = false;
    form: FormGroup;
    externalForm: FormGroup;
    formLoading = true;
    externalFormLoading = true;
    formSubmitted: boolean;
    sub: Subscription;
    modalHidingSub: Subscription;
    // query parameters
    params: Object = {};
    // will be removed after integrate changes into clinics,profiles,...
    formAction: string;

    // for sorting
    sortedColumn = 'id';
    orderField: any;
    order = 'asc';
    sortIsActivated = false;

    // for filtering
    filters: any = [];
    asyncFilters: Observable<any>;

    // for pagination
    totalItems: number;
    itemsPerPage: number = ENV.itemsPerPage;
    currentPage: number = ENV.currentPage;
    readonly maxSize = ENV.maxSize;
    paginationHidden = false;

    // store the current page before opening the modal since the current page will be set back to 1 after closing the modal.
    page: number;

    // for search
    searchResults: Observable<any>;
    searchInfo: any;

    // access control
    isIndexable: boolean;
    isCreatable: boolean;
    isUpdatable: boolean;
    isActivatable: boolean;
    isDeletable: boolean;

    constructor(public authService: AuthService,
        public router: Router,
        public baseService: BaseService,
        public appService: AppService) {
        this.authService = authService;
        this.router = router;
        this.baseService = baseService;
    }

    ngOnInit() {
        this.profileId = this.authService.user['id'];
    }

    /**
     * Redirect using auth.service
     * @returns {boolean}
     */
    // get isLoggedIn() {
    //     return this.authService.redirectIfNotLogin();
    // }

    // handle table header clicks to perform sorting
    sort(column: string, isUpdated = true, callApi = true) {
        // display the sort icon
        if (!this.sortIsActivated) {
            this.sortIsActivated = true;
        }
        if (this.sortedColumn === column) {
            if (this.order === 'asc') {
                this.order = 'desc';
                this.orderField = ['-' + column];
            } else {
                this.order = 'asc';
                this.orderField = [column];
            }
        } else {
            if (this.order === 'desc') {
                this.order = 'asc';
            }
            this.orderField = [column];
        }

        this.sortedColumn = column;
        this.isUpdated = isUpdated;
        if (!callApi) {
            return;
        }
        this.getItems();
    }

    resetErrors() {
        if (this.errorMessage.length > 0) {
            this.errorMessage = [];
        }
    }

    paginate(event: any, isUpdated = true): void {
        this.currentPage = event;
        this.isUpdated = isUpdated;
        this.getItems();
    }

    // Handle search filter changes
    setFilter(event, filterType: string) {
        this.currentPage = 1;
        this.searchInfo[filterType] = event?.target?.value || event;
        this.isUpdated = true;
        this.getItems();
    }

    // handle search button clicks
    searchItems(): void {
        this.currentPage = 1;
        this.isUpdated = true;
        this.getItems();
    }

    // display modal
    showModal(): void {
        // prevent users from clicking outside to close the modal since that does not set the url correctly
        this.modal.config.backdrop = false;
        this.modal.config.ignoreBackdropClick = true;
        this.modal.config.keyboard = false;
        // this ensures the form is displayed only when the data is ready
        if (this.params['action'] !== 'delete' && this.params['action'] !== 'activate') {
            // if the form opened belongs to the component different from the component where it's opened
            if (this.params['component'] != null) {
                this.externalFormLoading = false;
            } else {
                this.formLoading = false;
            }
        }
        // hide the app loading icon
        this.isLoading = false;
        this.isUpdated = false;
        this.resetErrors();
        this.modal.show();
    }

    // hide modal when clicking Cancel
    hideModal(isUpdated?: boolean) {
        this.modal.hide();

        // unsubscribe subscription to avoid memory leaks & prevent this.modal.onHidden() being called repeatedly
        if (typeof this.modalHidingSub !== 'undefined') {
            this.modalHidingSub.unsubscribe();
        }

        this.modalHidingSub = this.modal.onHidden.subscribe(() => {
            this.modalIsClosed = true;
            this.isLoading = false;
            this.formSubmitted = false;
            this.responseMessage = null;
            this.fullPath = '';

            if (isUpdated) {
                this.isUpdated = isUpdated;
                this.getItems();
            }

            // unsubscribe subscription to avoid memory leaks & prevent undesired behaviours such as disabled form fields getting enabled
            if (typeof this.sub !== 'undefined') {
                this.sub.unsubscribe();
            }
        });
    }

    initForm(params: Object, navigate = true): void {
        this.params = params;
        // store the current page to be used later since this.currentPage will be set to 1 when opening the modal
        this.page = this.currentPage;
        let queryParams = {};

        for (const param in this.params) {
            if (param !== 'url' && this.params.hasOwnProperty(param)) {
                queryParams[param] = this.params[param];
            }
        }

        // reset errors
        this.resetErrors();

        if (navigate) {
            // Notice: For popup navitation from the search on StartPage
            this.router.navigate([this.params['url']], { queryParams: queryParams });
        }

        // display app loading icon
        this.isLoading = true;
    }

    getItems() {
        let pageNum = 0;
        if (this.modalIsClosed) {
            this.modalIsClosed = false;
            pageNum = this.page;
        } else {
            pageNum = this.currentPage;
        }

        const sortFilter = 'sort-' + this.sortedColumn + '--order-' + this.order;
        if (this.searchInfo.keyword !== '' || this.filters != null) {
            const filters = this.setFilters(sortFilter);
            this.search(filters, pageNum);
        } else {
            this.search(sortFilter, pageNum);
        }
    }

    setFilters(sortFilter: string): string {
        let filters = '';
        if (this.searchInfo.keyword !== '') {
            filters += 'keyword-' + this.searchInfo.keyword;

            for (const filter of this.filters) {
                if (this.searchInfo[filter['key']] != null) {
                    filters += '--' + filter['key'] + '-' + this.searchInfo[filter['key']];
                }
            }

        } else if (this.filters != null && this.filters.length > 0) {
            let isFirstFilter = true;
            for (const filter of this.filters) {
                if (isFirstFilter) {
                    isFirstFilter = false;
                } else {
                    filters += '--';
                }
                if (this.searchInfo[filter['key']] != null) {
                    filters += filter['key'] + '-' + this.searchInfo[filter['key']];
                }
            }

        }
        filters += '--' + sortFilter;

        return filters;
    }

    search(filters: string, page: number) {
        // set url query parameters
        this.router.navigate([this.url], {
            queryParams: {
                filters: filters,
                page: page,
                items: this.itemsPerPage
            }
        });

        if (!this.isUpdated && this.items) {
            return;
        } else {
            this.isUpdated = false;
        }

        this.isLoading = true;
        // get users from backend
        this.baseService.getItems(this.url, page, this.itemsPerPage, this.sortedColumn, this.order, this.searchInfo)
            .subscribe(data => {
                this.setData(data);
            });
    }

    setData(data: any): void {
        this.items = data.items;
        if (data.filters != null) {
            this.filters = data.filters;
            this.asyncFilters = observableOf(data.filters);
        }
        this.totalItems = data.total;
        this.totalPeople = data.total_people;
        this.clinicName = data.clinic_name;
        this.clinicCity = data.clinic_city;
        this.clinicActivation = data.clinic_activation;
        // if users are empty, hide the page numbers below the users list to prevent page redirection
        this.paginationHidden = this.items.length === 0;
        this.isLoading = false;
    }

    // user addition/update handler: send user info to DB
    submit(model: any, isValid: boolean, url?: string, isUpdated?: boolean): void {
        if (url == null) {
            url = this.url;
        }

        this.resetErrors();
        // this ensures validation messages will only be displayed when necessary
        this.formSubmitted = true;
        // only when the form is valid and its values have been changed
        if (isValid) {
            this.isLoading = true;

            this.baseService.submit(url, this.params['id'], model, this.currentPage, this.itemsPerPage, this.sortedColumn,
                this.order, this.searchInfo).pipe(
                finalize(() => {
                    this.isLoading = false;
                }))
                .subscribe(
                    response => {
                        this.handleResponse(response);
                        // hide addition modal
                        if (isUpdated) {
                            this.hideModal();
                        } else {
                            this.hideModal(isUpdated);
                        }
                    },
                    error => {
                        this.isLoading = false;
                        this.errorMessage = this.appService.parseErrors(error);
                        this.errorData = error?.error?.data || '';
                    }
                );
        }
    }

    handleResponse(response: any): void {
        if (this.params['component'] == null) {
            this.setData(response);
            this.getItems();
        } else {
            this.isUpdated = true;
        }

        if (response['message']) {
            this.responseMessage = response['message'];
            return;
        }
    }

    avatarUploading($event) {
        if ($event) {
            this.isUploading = true;
        }
    }

    avatarUploaded($event) {
        const response = $event.serverResponse.response.body;
        this.fullPath = response.fullPath;
        this.avatar = response.fullPath;
        this.responseMessage = response.message;
        this.isUploading = false;
    }

    logoUploaded($event) {
        let body = {};
        if ($event.serverResponse.response.body) {
            body = $event.serverResponse.response.body;
        } else if ($event.serverResponse.response._body) {
            body = JSON.parse($event.serverResponse.response._body);
        }
        const response = body;
        this.fullPath = response['fullPath'];
        this.responseMessage = response['message'];
        this.isUploading = false;
    }

    openConfirmModal(params: Object) {
        this.initForm(params);

        if (this.modal && this.modal.isShown) {
            this.hideModal();
        }
        if(!this.dialog){
            return;
        }

        this.isLoading = false;
        const dialog = this.dialog.openConfirm(params);

        dialog.onSubmit(() => {
            const url = (params['component'] ? params['component'] : params['url']) + '/' + params['action'] + '/' + params['id'];
            this.isLoading = true;

            this.baseService.changeStatus(url, params['action'])
                .subscribe(
                    response => {
                        if (this.params['component'] && this.params['action'] === 'delete') {
                            this.navigateRoute();
                        } else {
                            this.isUpdated = true;
                            this.getItems();
                        }

                        const message = response['message'];
                        if (message) {
                            dialog.showAlert(message);
                        }
                    },
                    error => {
                        dialog.showAlert(error.error.error);
                        this.isLoading = false;
                    }
                );
        });

        dialog.onCancel(() => {
            if (this.params['redirectUrl']) {
                return this.router.navigate([this.params['redirectUrl']]);
            }

            if (this.modal && this.modal.isShown) {
                this.hideModal();
            }

            this.getItems();
        });
    }

    navigateRoute() {
        this.router.navigate([this.params['component']]);
    }

    get locale() {
        return this.appService.getLanguage();
    }

    getTemplateContent(service, params, content) {
        // get content from api
        service.getContent().subscribe(res => {
            const templateResponse = res['template'];
            templateResponse.forEach(template => {
                const languageObj = new LanguageModel(template['language']);
                const templateContentObj = new TemplateContent({ title: String(template['title']), content: String(template['content']) });
                Object.defineProperty(content,
                    languageObj.locale,
                    {
                        value: templateContentObj
                    }
                );
            });
            this.isLoading = false;
        });

        this.initForm(params, false);

        this.showModal();
        this.isLoading = true;
    }

    onInputFocused(event, formGroup: any, modalClassName, isBottomField?: boolean) {
        let uagent = navigator.userAgent.toLowerCase();
        if (!(/safari/.test(uagent) && !/chrome/.test(uagent))) {
            // wait for the virtual keyboard to scroll up so that the scroll height can be updated correctly
            setTimeout(() => {
                if (isBottomField) {
                    document.body.scroll({
                        top: document.body.scrollHeight,
                        left: 0,
                        behavior: 'smooth'
                    });
                } else {
                    document.body.scroll({
                        top: 0,
                        left: 0,
                        behavior: 'smooth'
                    });
                }

                let modalEl = document.getElementsByClassName(modalClassName)[0] as HTMLElement;
                modalEl.scroll({
                    top: formGroup.offsetTop,
                    left: 0,
                    behavior: 'smooth'
                })
            }, 2000);
        }
    }
}
