import { Pipe, PipeTransform , Sanitizer, SecurityContext} from '@angular/core';
import { ConsoleService } from '@ng-select/ng-select/lib/console.service';

@Pipe({name: 'replace'})
export class ReplacePipe implements PipeTransform {

    transform(value: string, regexValue: string, replaceValue: string): any {
        const regex = new RegExp(regexValue, 'g');
        return value.replace(regex, replaceValue);
    }
}
