import { Component, Input, ViewEncapsulation, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { StepWizard } from './step.interface';

@Component({
    selector: 'app-stepper-wizard',
    templateUrl: './stepper.component.html',
    styleUrls: ['./stepper.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class StepperWizardComponent {
    public currentStepIndex = 0;

    @Input() stepperType = 'vertical';
    @Input() steps: StepWizard[];
    @Input() set selectedIndex(selectedIndex: number) {
        this.currentStepIndex = selectedIndex;
    }
    @Input() isShowStepName = false;

    @Output() selectedIndexChange = new EventEmitter<number>();
    @Output() done = new EventEmitter<any>();

    trackByStep(index, step) {
        return step.step || index;
    }

    toggleListItems(index) {
        this.currentStepIndex = index;
        this.selectedIndexChange.emit(this.currentStepIndex);
    }

    stepDone() {
        this.done.emit();
    }
}

