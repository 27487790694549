import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'orderBy', pure: true})

export class OrderByPipe implements PipeTransform {
    static locale = 'de';
    static _orderByComparator(a: any, b: any): number {

        if ((isNaN(parseFloat(a)) || !isFinite(a)) || (isNaN(parseFloat(b)) || !isFinite(b))) {
            if (!a) {
                return -1;
            }

            if (!b) {
                return 1;
            }

            if (!a.length && b.length) {
                return -1;
            }

            if (!a.length && !b.length) {
                return 0;
            }

            if (a.length && !b.length) {
                return 1;
            }
            // is not a number, lowercase the string to compare
            // if (a.toLowerCase() < b.toLowerCase()) {
            //     return -1;
            // }
            // if (a.toLowerCase() > b.toLowerCase()) {
            //     return 1;
            // }
            return a.localeCompare(b, this.locale, { sensitivity: 'accent' });

        } else {
            // parse strings as numbers to compare
            if (parseFloat(a) < parseFloat(b)) {
                return -1;
            }

            if (parseFloat(a) > parseFloat(b)) {
                return 1;
            }
        }
        return 0;
    }

    transform(input: any, [config = '+'], locale: string): any {
        if (!Array.isArray(input)) {
            return input;
        }

        if (!input) {
            return input;
        }

        OrderByPipe.locale = locale.slice(0, 2) || 'de';

        if (!Array.isArray(config) || (Array.isArray(config) && config.length === 1)) {
            const propertyToCheck: string = !Array.isArray(config) ? config : config[0];
            const desc = propertyToCheck.substr(0, 1) === '-';

            if (!propertyToCheck || propertyToCheck === '-' || propertyToCheck === '+') {
                // sort on array's object
                if (!desc) {
                    return input.sort();
                }
                return input.sort().reverse;
            } else {
                // sort on array's particular property
                const property: string = propertyToCheck.substr(0, 1) === '+' || propertyToCheck.substr(0, 1) === '-'
                    ? propertyToCheck.substr(1)
                    : propertyToCheck;

                return input.sort(function (a: any, b: any) {
                    return !desc
                        ? OrderByPipe._orderByComparator(a[property], b[property])
                        : -OrderByPipe._orderByComparator(a[property], b[property]);
                });
            }
        }
    }
}
