import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from '../../shared/base.component';
import { AuthService } from '../../core/auth.service';
import { BaseService } from '../../shared/base.service';
import { AppService } from '../../app.service';
import { DeviceLabelService } from '../device-label.service';
import { ENV } from '../../app.env';
import { LanguageModel, TemplateContent } from '../../models';

@Component({
    selector: 'app-device-label-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['../device-label.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [DeviceLabelService]
})

export class DeviceLabelModalComponent extends BaseComponent {
    content = {};

    constructor(public authService: AuthService,
        public router: Router,
        public baseService: BaseService,
        public appService: AppService,
        public service: DeviceLabelService
        ) {
        super(authService, router, baseService, appService);
    }

    openModal() {
        let releaseDate = ENV.releaseDate;
        let releaseVersion = ENV.buildVersion;

        this.appService.isLoading = true;
        this.service.getContent().subscribe(res => {
            const response = res['template'];
            response.forEach(template => {
                template.content = template.content
                .replace('{{release_version}}', releaseVersion)
                .replace('{{release_date}}', releaseDate);

                const language = new LanguageModel(template['language']);
                const templateContent = new TemplateContent({ title: String(template['title']), content: String(template['content']) });
                this.appService.isLoading = false;
                Object.defineProperty(this.content,
                    language.locale,
                    {
                        value: templateContent
                    }
                );
            });

            this.appService.isLoading = false;
            this.showModal();
        });
    }

}

