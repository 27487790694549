import { Injectable, Inject } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';

@Injectable()
export class PasswordService {

    constructor(public http: HttpClient, @Inject('baseUrl') public baseUrl: string) { }

    password(email: string) {
        let url = this.baseUrl + 'oauth/password';
        let body = 'email=' + email.replace(/\+/gi, '%2B');
        let headerOptions = {
            'Content-Type': 'application/x-www-form-urlencoded'
        };

        const httpOptions = {
            headers: new HttpHeaders(headerOptions)
        };

        return this.http.post(url, body, httpOptions);
    }
}
