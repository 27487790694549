import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'dateDiffTranslate', pure: false
})
export class DateDiffTranslatePipe implements PipeTransform {
    constructor(public translate: TranslateService) {
    }

    transform(value: any, args?: any): any {
        if (!value) {
            return '';
        }

        let data = value.split(' ');

        if (data.length === 1) {
            return this.translate.instant(value);
        }

        let time = data.shift();
        let needTranslate = data.join(' ');

        return this.translate.instant(needTranslate, {X: time});
    }

}
