<section class="auth__form" *ngIf="!userId ||  (userId && isVerifySMS)">
    <div class="row auth__header">
        <div class="col-xs-6">
            <a [routerLink]="['/login']" class="sprite auth__header__brand"></a>
        </div>
        <div class="col-xs-6 text-right">
            <a href="https://klenico.com" target="_blank" rel="noopener" class="auth__header__link">
                {{ 'Go to website' | translate}}
                <i class="fas fa-arrow-right mr-l--xs"></i>
            </a>
        </div>
    </div>

    <div class="auth__slider auth__slider--mobile hidden-sm-up">
        <img src="/assets/img/pages/login{{getBackground()}}.webp" width="100%" height="100%" class="auth__slider__image" />
    </div>

    <div class="auth__wrapper">
        <div class="auth__inner">
            <div class="card auth__form__card">
                <div class="card-header auth__form__card__header">
                    <div class="row">
                        <div class="col-xs-12">
                            <h2 class="page-title">
                                {{ 'Login' | translate}}
                            </h2>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12">
                            <span class="font-se-paragraph">{{ 'Are you already our client as Health Professional or as affected person? Please use this login to sign in.' | translate}}</span>
                        </div>
                    </div>
                </div>
                <div class="card-block" *ngIf="!isVerifySMS">
                    <div *ngIf="showDemoLink" class="alert alert-info" role="alert">
                        <span class="fas fa-exclamation-circle" aria-hidden="true"></span>{{ 'You can login here as soon as you have a user account. To register please arrange a product demo' | translate }} 
                        <a class=" auth__demo-button" href="https://outlook.office365.com/book/KlenicoDemoTerminvereinbaren1@klenico.com">{{'here' | translate}}</a>
                    </div>
                    <div *ngIf="errorMessage" class="alert alert-danger" role="alert">
                        <span class="fas fa-exclamation-circle" aria-hidden="true"></span>{{ errorMessage | translate }}
                    </div>
                    <div *ngIf="infoMessage" class="alert alert-info" role="alert">
                        <span class="fas fa-exclamation-circle" aria-hidden="true"></span>{{ infoMessage | translate }}
                    </div>
                    <div *ngIf="successMessage" class="alert alert-success" role="alert">
                        <span class="fas fa-exclamation-circle" aria-hidden="true"></span>{{ successMessage | translate }}
                    </div>
                    <form [formGroup]="form" novalidate (ngSubmit)="login(form.value, form.valid)" class="mat-form">
                        <div class="form-group">
                            <mat-form-field class="ps-content">
                                <input type="email" matInput [placeholder]="'Email Address' | translate" formControlName="email" autocomplete="on"/>
                                <small [hidden]="form.controls.email.valid
                                                || !form.controls.email.touched
                                                || (form.controls.email.pristine && !submitted)"
                                        class="form-text text-danger">
                                    {{ 'Email is required.' | translate }}
                                </small>
                            </mat-form-field>
                        </div>
                        <div class="row form-group">
                            <div class="col-xs-12">
                                <mat-form-field class="ps-content">
                                    <input type="password" autocomplete="on" matInput [placeholder]="'Password' | translate" formControlName="password" class="jtext"/>
                                    <small [hidden]="form.controls.password.valid
                                                || !form.controls.email.touched
                                                || (form.controls.password.pristine && !submitted)"
                                        class="form-text text-danger">
                                        {{ 'Password is required.' | translate }}
                                    </small>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="form-group text-xs-center d-flex align-items-center">
                            <div class="col-xs-7 text-xs-right">
                                <a class="color-dark-blue" [routerLink]="['/password']">{{ 'Forgot password?' | translate }}</a>
                            </div>
                            <div class="pull-right">
                                <button class="btn btn-primary btn-radius btn-dark" type="submit">
                                    {{ 'Login' | translate }}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                <div *ngIf="isVerifySMS">
                    <sms-verification-component
                            [smsMessage]="smsMessage"
                            [hiddenPhoneNumber]="hiddenPhoneNumber"
                            [userId]="userId"></sms-verification-component>
                </div>

                <div class="auth__form__card__footer mr-b--md">
                    <strong>{{ 'You want to register as Health Professional?' | translate }}</strong><br />
                    <span [innerHTML]="'Please contact us.' | translate | safeHtml"></span>
                </div>
            </div>
        </div>
    </div>

    <div class="row auth__footer">
        <div class="col-xs-8">
            <p class="copyright exclude-translate">
                {{ appService.releasedVersion }} &copy; {{ thisYear }} Klenico Health AG
            </p>
        </div>
        <div class="col-xs-4">
            <div class="footer__language">
                <div class="form-group mat-select-pill">
                    <mat-select class="mat-select-pill__select"
                            [value]="appService.activeLanguage.locale"
                            (selectionChange)="appService.setLanguage($event.value)">
                            <mat-option *ngFor="let language of appService.languages"
                                        [value]="language.locale">
                                {{language.name | translate}}
                            </mat-option>
                    </mat-select>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="auth__slider hidden-xs-down">
    <img src="/assets/img/pages/login{{getBackground()}}.webp" width="100%" height="100%" class="auth__slider__image" />
</section>

<dialog-component #dialog></dialog-component>