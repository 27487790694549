
import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {finalize} from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../../core/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../../../app.service';
import { MaintenanceService } from '../../../maintenance/maintenance.service';
import { SmsService } from '../../sms.service';
import { SmsVerificationComponent } from '../../login/sms-verification/sms-verification.component';

@Component({
    selector: 'mobile-verification-component',
    templateUrl: './mobile-verification.component.html',
    styleUrls: ['./mobile-verification.component.scss'],
    providers: [
        MaintenanceService
    ]
})

export class MobileVerificationComponent extends SmsVerificationComponent implements OnInit, OnChanges, AfterViewInit {

    form: FormGroup;
    mobileSubmitted: boolean = false;
    smsCodeSubmitted: boolean;
    successMessage: string = '';
    errorMessage: string = '';
    infoMessage: string = '';
    mobileForm: FormGroup;
    smsCodeForm: FormGroup;
    isRequestedSms: boolean = false;
    isValidSmsCode: boolean = false;
    isDisableVerifySmsButton: boolean = false;
    isDisableSendSmsButton: boolean = false;
    isDisableResendSmsButton: boolean = false;
    hiddenPhoneNumber: any = '';

    @Input() isIdentityOk: boolean = false;
    @Input() userEmail: string = '';
    @Input() userMobileNumber: any;
    @Input() enteredPassword: string;
    @Input() resetPasswordToken: string;
    @Input() isResetPasswordStep: boolean = false;
    @Input() isVerifyByEmail: boolean = false;

    @ViewChild('mobileField', { static: false }) mobileField: ElementRef;
    @ViewChild('mTanCode', { static: false }) mTanCode: ElementRef;

    get isLoading() {
        return this.appService.isLoading;
    }

    set isLoading(isLoading) {
        this.appService.isLoading = isLoading;
    }

    constructor(public router: Router,
                public authService: AuthService,
                public translate: TranslateService,
                public appService: AppService,
                public smsService: SmsService,
                public route: ActivatedRoute,
                public fb: FormBuilder) {
        super(router, authService, translate, appService, smsService, route, fb);

        if (!this.isResetPasswordStep) {
            this.createMobileForm();
        }

        this.createSmsCodeForm();
    }

    ngOnInit() {
        super.ngOnInit();

        if (this.isVerifyByEmail) {
            this.smsCodeForm.get('smsCode').enable();
            this.isDisableVerifySmsButton = false;
        }
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            if (this.mobileField) {
                this.mobileField.nativeElement.focus();
            }
        }, 50);
    }

    /**
     * preload mobile number for mobile field
     * @param {SimpleChanges} changes
     */
    ngOnChanges(changes: SimpleChanges) {
        if (changes['userMobileNumber'] && this.userMobileNumber) {
            this.mobileForm.controls['mobile'].setValue(this.userMobileNumber);
        }

        if (changes['enteredPassword'] && this.enteredPassword) {
            this.smsCodeForm.controls['enteredPassword'].setValue(this.enteredPassword);
        }

        if (changes['resetPasswordToken'] && this.resetPasswordToken) {
            this.smsCodeForm.controls['resetPasswordToken'].setValue(this.resetPasswordToken);
        }

        if (changes['isResetPasswordStep']) {
            if (!this.isResetPasswordStep) {
                this.smsCodeForm.get('smsCode').disable();
                this.isDisableVerifySmsButton = true;

                return;
            }

            if (!this.isVerifyByEmail) {
                this.hiddenPhoneNumber = this.userMobileNumber.slice(-3);
                this.infoMessage = 'We just send out the mTAN to following number:';
            } else {
                this.infoMessage = 'We have just sent out the mTAN to your e-mail.';
            }
        }
    }

    /**
     * Init Entering phone number form
     */
    createMobileForm() {
        this.mobileForm = this.fb.group({
            mobile: ['', [<any>Validators.required, Validators.pattern('\\+{1}[0-9\\s]+')]],
        });

        this.mobileForm.reset({mobile: this.userMobileNumber});
    }

    /**
     * Init Verify SMS code form
     */
    createSmsCodeForm() {
        this.smsCodeForm = this.fb.group({
            smsCode: ['', [<any>Validators.required]],
            enteredPassword: ['', [<any>Validators.required]],
            resetPasswordToken: ['', [<any>Validators.required]]
        });
    }

    /**
     * Handle on submit mobile number
     * @param mobileNumber
     * @param isValid
     */
    onSubmitMobileNumber(mobileNumber, isValid) {
        this.mobileSubmitted = true;

        if (!isValid) {
            return;
        }

        this.resetErrors();

        this.smsService.requestToSendSms(mobileNumber).pipe(
            finalize(() => this.isLoading = false))
            .subscribe(response => {
                this.isRequestedSms = response['isSent'];

                    if (!this.isRequestedSms) {
                        this.errorMessage = response['message'];
                    } else {
                        this.infoMessage = response['message'];
                        this.smsCodeForm.get('smsCode').enable();
                        this.isDisableVerifySmsButton = false;
                        this.mTanCode.nativeElement.focus();

                        this.isDisableSendSmsButton = true;
                        this.mobileForm.get('mobile').disable();
                    }
                },
                error => {
                    this.resetErrors();

                    if (typeof error.error.error.smsCode !== 'undefined') {
                        this.errorMessage = error.error.error.phone_number[0];
                    } else {
                        this.isDisableSendSmsButton = true;
                        this.isDisableVerifySmsButton = true;
                        this.smsCodeForm.get('smsCode').disable();
                        this.infoMessage = '';
                        this.mobileForm.get('mobile').disable();

                        setTimeout(() => {
                            this.isDisableSendSmsButton = false;
                            this.isDisableVerifySmsButton = false;
                            this.smsCodeForm.get('smsCode').enable();
                            this.mobileForm.get('mobile').enable();

                            this.resetErrors();
                        }, this.DISABLE_TIMEOUT); // Disable button for 1 minute

                        this.errorMessage = error.error.error;
                    }
                });
    }

    onSubmitSendEmailNumber() {
        this.smsService.requestToSendSms(this.userEmail).pipe(
            finalize(() => this.isLoading = false))
            .subscribe(response => {
                this.isRequestedSms = response['isSent'];

                if (!this.isRequestedSms) {
                    this.errorMessage = response['message'];
                } else {
                    this.infoMessage = response['message'];
                    this.smsCodeForm.get('smsCode').enable();
                    this.isDisableVerifySmsButton = false;
                    this.mTanCode.nativeElement.focus();
                    this.isDisableSendSmsButton = true;
                }
            },
            error => {
                this.resetErrors();

                if (typeof error.error.error.smsCode !== 'undefined') {
                    this.errorMessage = error.error.error.phone_number[0];
                } else {
                    this.isDisableSendSmsButton = true;
                    this.isDisableVerifySmsButton = true;
                    this.smsCodeForm.get('smsCode').disable();
                    this.infoMessage = '';

                    setTimeout(() => {
                        this.isDisableSendSmsButton = false;
                        this.isDisableVerifySmsButton = false;
                        this.smsCodeForm.get('smsCode').enable();
                        this.resetErrors();
                    }, this.DISABLE_TIMEOUT); // Disable button for 1 minute

                    this.errorMessage = error.error.error;
                }
            });
    }

    /**
     * Handle on submit sms code
     * @param smsCode
     * @param isValid
     */
    onSubmitSmsCode(smsCode, isValid) {
        this.smsCodeSubmitted = true;

        if (!isValid) {
            return;
        }

        if (!this.smsCodeForm.controls.enteredPassword.value) {
            return;
        }

        if (!this.smsCodeForm.controls.resetPasswordToken.value) {
            return;
        }

        this.resetErrors();

        const password = this.smsCodeForm.controls.enteredPassword.value;
        const token = this.smsCodeForm.controls.resetPasswordToken.value;
        const phoneNumber = this.mobileForm.controls.mobile.value;
        this.isLoading = true;

        this.smsService.verifySmsCode(smsCode, phoneNumber, password, token).pipe(
            finalize(() => this.isLoading = false))
            .subscribe(response => {
                    this.isValidSmsCode = response['isValid'];

                    if (this.isValidSmsCode) {
                        this.successMessage = response['message'];

                        this.smsCodeForm.get('smsCode').disable();
                        this.isDisableVerifySmsButton = true;
                        // disable anothere element

                        setTimeout(() => {
                            this.router.navigate(['login']);
                        }, this.NAVIGATE_TIMEOUT);
                    } else {
                        this.errorMessage = response['message'];
                    }
                },
                error => {
                    this.resetErrors();

                    if (typeof error.error.error.smsCode !== 'undefined') {
                        this.errorMessage = error.error.error.smsCode[0];
                    } else {
                        this.isDisableSendSmsButton = true;
                        this.isDisableVerifySmsButton = true;
                        this.isDisableResendSmsButton = true;
                        this.smsCodeForm.get('smsCode').disable();

                        setTimeout(() => {
                            this.isDisableSendSmsButton = false;
                            this.isDisableVerifySmsButton = false;
                            this.isDisableResendSmsButton = false;
                            this.smsCodeForm.get('smsCode').enable();

                            this.resetErrors();
                        }, this.DISABLE_TIMEOUT); // Disable button for 1 minute

                        this.errorMessage = error.error.error;
                    }
                });
    }

    /**
     * Handle resend sms code
     */
    onResendSmsClick() {
        if (this.isValidSmsCode) {
            return;
        }

        this.smsService.resendLoginSms(this.userEmail).pipe(
            finalize(() => this.isLoading = false))
            .subscribe(response => {
                    this.successMessage = response['message'];
                    this.errorMessage = '';

                    this.smsCodeForm.controls.smsCode.markAsPristine();
                    this.smsCodeSubmitted = false;
                    this.smsCodeForm.controls.smsCode.setValue(null);
                },
                error => {
                    this.resetErrors();
                    if (error.error && error.error.error) {
                        this.errorMessage = error.error.error.phone_number[0];
                    }
                });
    }
}
