import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
// modules
import { AuthModule } from './auth/auth.module';
import { ErrorHandler, NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule } from '@angular/router';
/*
 * Platform and Environment providers/directives/pipes
 */
import { ENV_PROVIDERS } from '../environments/environment';
import { ROUTES } from './app.routes';
// App is our top level component
import { AppComponent } from './app.component';
import { ErrorsComponent } from './errors';
/*
 * Core services
 */
import { AppService } from './app.service';
import { AuthService } from './core/auth.service';
import { AuthGuard } from './core/auth.guard';
import { AuthUnguard } from './core/auth.unguard';
import { SecureApi } from './core/secure.api';

import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';

// pipes
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BaseModule } from './shared/base.module';
import { ImageUploadModule } from 'angular2-image-upload';
import { ProfileComponent } from './profile/profile.component';
import { CoreService } from './core/core.service';
import { MaintenanceModule } from './maintenance/maintenance.module';
import { MaintenanceService } from './maintenance/maintenance.service';
import { AuthMaintenance } from './core/auth.maintenance';
import { DialogModule } from './dialog/dialog.module';
import {ModalModule} from "ngx-bootstrap/modal";
import {PopoverModule} from "ngx-bootstrap/popover";
import { PatientService } from './patient/patient.service';
import { NotificationModule } from './shared/notification/notification.module';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { MATERIALS } from './app.materials';
import { UsersModule } from './users/users.module';
import { RequestInterceptorService } from './core/request-interceptor.service';
import { CustomErrorHandle } from './error.handle';
import { DirectiveModule } from './shared/directives/directive.module';
import { SelfEvaluationMenuComponent } from './self-evaluation/self-evaluation-menu/self-evaluation-menu.component';
import { SelfEvaluationService } from './self-evaluation/self-evaluation.service';
import {JWT_OPTIONS, JwtHelperService} from "@auth0/angular-jwt";
import { FeedbackModule } from './feedback/feedback.module';
import { UsersService } from './users/users.service';
import { BreadcrumbModule } from './shared/breadcrumb/breadcrumb.module';
import { SwitchClinicService } from './home/switch-clinic/switch-clinic.service';
import { ClinicsService } from './clinics/clinics.service';
import { HighchartsChartModule } from 'highcharts-angular';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

/**
 * `AppModule` is the main entry point into Angular2's bootstraping process
 */
@NgModule({
    bootstrap: [AppComponent],
    declarations: [
        AppComponent,
        ErrorsComponent,
        ProfileComponent,
        HeaderComponent,
        FooterComponent,
        SelfEvaluationMenuComponent
    ],
    imports: [ // import Angular's modules
        MATERIALS,
        BaseModule,
        BrowserAnimationsModule,
        BreadcrumbModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        RouterModule.forRoot(ROUTES, {useHash: false, preloadingStrategy: PreloadAllModules, enableTracing: false}),
        AuthModule,
        DialogModule,
        PopoverModule,
        BsDropdownModule.forRoot(),
        ModalModule.forRoot(),
        ImageUploadModule.forRoot(),
        PopoverModule.forRoot(),
        MaintenanceModule,
        NotificationModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        UsersModule,
        MatSlideToggleModule,
        FeedbackModule,
        HighchartsChartModule
    ],
    providers: [ // expose our Services and Providers into Angular's dependency injection
        ENV_PROVIDERS,
        AppService,
        HttpClient,
        AuthService,
        CoreService,
        MaintenanceService,
        AuthGuard,
        AuthUnguard,
        AuthMaintenance,
        SecureApi,
        PatientService,
        SelfEvaluationService,
        UsersService,
        ClinicsService,
        SwitchClinicService,
        {provide: ErrorHandler, useClass: CustomErrorHandle},
        { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
        JwtHelperService,
        { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptorService, multi: true }
    ]
})

export class AppModule {
}
