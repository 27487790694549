import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { BlockTypes, MarkTypes, richTextResolver } from '@storyblok/richtext';


@Pipe({
    name: 'storyblokImage'
})

export class StoryBlokImagePipe implements PipeTransform {

    constructor(
        private sanitizer: DomSanitizer,
        private translate: TranslateService
    ) {

    }

    transform(imageData: any, cssClass: string = ''): SafeHtml {
        // Validate the input data
        if (!imageData || !imageData.filename) {
            return '';
        }

        // Extract the image properties from the Storyblok response
        const src = imageData.filename;
        const alt = imageData.alt || '';
        const title = imageData.title || '';

        // Generate the HTML for the image element
        const imgHtml = `<img src="${src}" alt="${alt}" title="${title}" class="${cssClass}">`;

        // Sanitize the HTML
        return this.sanitizer.bypassSecurityTrustHtml(imgHtml);
    }

    removeFullSlugPrefix(href) {
        let locale = this.translate.currentLang.split('_')[0];
        const withoutLocale = href.replace(`${locale}/`, "");
        const removeN = withoutLocale.startsWith("/") ? 2 : 1;
        const segments = withoutLocale.split("/").slice(removeN);
        return `/me/${segments.join("/")}`;
    }
}
