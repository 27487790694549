// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.
import { LocationStrategy, PathLocationStrategy } from '@angular/common';

const baseUrls = {
    'localhost':
        {
            url: 'http://localhost:8000',
            production: false,
            googleId: '',
            webSocketUrl : 'localhost',
            webSocketKey : 'd96beeec9eba9f8e24ae',
            weglotKey : {
                de : 'wg_945a0d61fd8dc1c59e9b1bcd93b793ee9',
                en : 'wg_701ecc72cb2351324025be106e0c29396',
            },
            mouseFlow: '',
            konektoDomain : '',
            storyBlokUrl: 'https://api.storyblok.com/v2/cdn',
            storyBlokToken: 'jEa8e5MOYqxolnYFAliIkwtt',
            storyBlokSpaceId: '328096'
        },
    'klenico.quickbird.local':
        {
            url: 'https://api.klenico.quickbird.local',
            production: true,
            googleId: '',
            webSocketUrl : 'ws.klenico.quickbird.local',
            webSocketKey : 'd96beeec9eba9f8e24ae',
            weglotKey : {
                de : 'wg_945a0d61fd8dc1c59e9b1bcd93b793ee9',
                en : 'wg_701ecc72cb2351324025be106e0c29396',
            },
            mouseFlow: '',
            konektoDomain : '',
            storyBlokUrl: 'https://api.storyblok.com/v2/cdn',
            storyBlokToken: 'jEa8e5MOYqxolnYFAliIkwtt',
            storyBlokSpaceId: '328096'
        },
    'dev.klenico.quickbirdmedical.com':
        {
            url: 'https://api.dev.klenico.quickbirdmedical.com',
            production: true,
            googleId: '',
            webSocketUrl : 'api.dev.klenico.quickbirdmedical.com',
            webSocketKey : 'd96beeec9eba9f8e24ae',
            weglotKey : {
                de : 'wg_945a0d61fd8dc1c59e9b1bcd93b793ee9',
                en : 'wg_701ecc72cb2351324025be106e0c29396',
            },
            mouseFlow: '',
            konektoDomain : 'https://me.dev.klenico.quickbirdmedical.com',
            storyBlokUrl: 'https://api.storyblok.com/v2/cdn',
            storyBlokToken: 'jEa8e5MOYqxolnYFAliIkwtt',
            storyBlokSpaceId: '328096'
        },
    'wwwtest.klenico.net':
        {
            url: 'https://api.wwwtest.klenico.net',
            production: true,
            googleId: '',
            webSocketUrl : 'ws.wwwtest.klenico.net',
            webSocketKey : 'd96beeec9eba9f8e24ae',
            weglotKey : {
                de : 'wg_945a0d61fd8dc1c59e9b1bcd93b793ee9',
                en : 'wg_701ecc72cb2351324025be106e0c29396',
            },
            mouseFlow: '',
            konektoDomain : 'https://stag.klenico.me',
            storyBlokUrl: 'https://api.storyblok.com/v2/cdn',
            storyBlokToken: '9WYPSYuZhps56JrgbMiEswtt',
            storyBlokSpaceId: '305302'
        },
    'www.klenico.net':
        {
            url: 'https://api.klenico.net',
            production: true,
            googleId: 'UA-135033520-2',
            webSocketUrl : 'ws.klenico.net',
            webSocketKey : 'd96beeec9eba9f8e24ae',
            weglotKey : {
                de : 'wg_945a0d61fd8dc1c59e9b1bcd93b793ee9',
                en : 'wg_701ecc72cb2351324025be106e0c29396',
            },
            mouseFlow: '//cdn.mouseflow.com/projects/692d0a8b-1b4a-4eea-9fe1-d1007047756d.js',
            konektoDomain : 'https://klenico.me',
            storyBlokUrl: 'https://api.storyblok.com/v2/cdn',
            storyBlokToken: 'abZj87lb6Mp5Z85GYx1hUwtt',
            storyBlokSpaceId: '327541'
        },
    'klenico.net':
        {
            url: 'https://api.klenico.net',
            production: true,
            googleId: 'UA-135033520-2',
            webSocketUrl : 'ws.klenico.net',
            webSocketKey : 'd96beeec9eba9f8e24ae',
            weglotKey : {
                de : 'wg_945a0d61fd8dc1c59e9b1bcd93b793ee9',
                en : 'wg_701ecc72cb2351324025be106e0c29396',
            },
            mouseFlow: '//cdn.mouseflow.com/projects/692d0a8b-1b4a-4eea-9fe1-d1007047756d.js',
            konektoDomain : 'https://klenico.me',
            storyBlokUrl: 'https://api.storyblok.com/v2/cdn',
            storyBlokToken: 'abZj87lb6Mp5Z85GYx1hUwtt',
            storyBlokSpaceId: '327541'
        }
};

const currentVersion = 'v1';

let hostname = window.location.hostname;

if (!(hostname in baseUrls)) {
    hostname = 'localhost';
}

// export const baseUrl = baseUrls['klenico.bbv-demo.ch'].url + '/' + currentVersion + '/';
export const baseUrl = baseUrls[hostname].url + '/' + currentVersion + '/';
export const baseSocketUrl = baseUrls[hostname].webSocketUrl;
export const baseSocketKey = baseUrls[hostname].webSocketKey;
export const weglotKey = baseUrls[hostname].weglotKey;
export const storyBlokAPI = baseUrls[hostname].storyBlokUrl;

const PROVIDERS: any[] = [
    // common env directives
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    { provide: 'defaultLanguage', useValue: 'de_CH' },
    { provide: 'baseUrl', useValue: baseUrl },
    { provide: 'baseSocketUrl', useValue: baseSocketUrl },
    { provide: 'baseSocketKey', useValue: baseSocketKey },
    { provide: 'weglotKey', useValue: weglotKey },
    { provide: 'googleId', useValue: baseUrls[hostname].googleId },
    { provide: 'mouseFlow', useValue: baseUrls[hostname].mouseFlow },
    { provide: 'konektoDomain', useValue: baseUrls[hostname].konektoDomain },
    { provide: 'storyBlokAPI', useValue: baseUrls[hostname].storyBlokUrl },
    { provide: 'storyBlokToken', useValue: baseUrls[hostname].storyBlokToken },
    { provide: 'storyBlokSpaceId', useValue: baseUrls[hostname].storyBlokSpaceId },
    { provide: 'reviewProposalStatisticKey', useValue: 'reviewed_proposal_diagnosis' },
];

export const ENV_PROVIDERS = [
    ...PROVIDERS
];

export const environment = {
    production: baseUrls[hostname].production
};
