import {NgModule} from '@angular/core';
import {DialogComponent} from './dialog.component';
import {AlertComponent} from './alert/alert.component';
import {ModalModule} from "ngx-bootstrap/modal";
import {CommonModule} from '@angular/common';
import {ConfirmComponent} from './confirm/confirm.component';
import {TranslateModule} from '@ngx-translate/core';
import { ConfirmModalBodyModule } from '../shared/confirm-modal-body/confirm-modal-body.module';

@NgModule({
    imports: [
        ModalModule.forRoot(),
        TranslateModule,
        CommonModule,
        ConfirmModalBodyModule
    ],
    exports: [DialogComponent, AlertComponent, ConfirmComponent],
    declarations: [DialogComponent, AlertComponent, ConfirmComponent],
    entryComponents: [DialogComponent, AlertComponent, ConfirmComponent]
})
export class DialogModule {
}
