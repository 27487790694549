
import {finalize} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { SecureApi } from '../core/secure.api';
import { AppService } from '../app.service';
import { AuthService } from '../core/auth.service';
import * as moment from 'moment';
import { EventEmitter } from 'events';
import { Subject } from 'rxjs';

@Injectable()
export class PatientService {
    patientId: number;

    nextDiagnosisOrder = 1;

    selectedDiagnoses: Object[];
    currentlySelectedOrder: number;

    diagnoses: Object[];
    newDiagnoses: Object[] = [];

    disableDiagnoses: any;

    questionnaireIsArchived = false;

    latestDiagnosisCreationDate: string;

    displaySelectedDiagnosisPlaceholder = false;
    latestMapId = 0;
    proposalData : any;
    enableProposal : boolean = false;
    symptomMapAction = new Subject();
    activeTab = 1;

    ACTION = {
        'edit_mode' : 'Edit mode',
        'open_diagnosis' : 'Open diagnosis',
        'cv_map' : 'Open CV',
    };
    ACCESSSTATUS = {
        'NOACCESS' : 0,
        'PENDING' : 1,
        'DENIED' : -1,
        'GRANTED' : 2,
    };
    PATIENTTABS = {
        OVERVIEW : 1,
        SR : 2,
        CV : 3,
        PROPOSALS : 4,
        DOCUMENTS: 5,
        THERAPY: 6,
        SUICIDALITY: 7,
    };

    get language() {
        return this.appService.getLanguage();
    }
    constructor(public secureApi: SecureApi, public appService: AppService, private authService: AuthService) {
    }

    helpTemplates: Object = {};
    getHelpTemplateTranslation(name) {
        return this.helpTemplates &&
            this.helpTemplates[name] &&
            this.helpTemplates[name][this.language] &&
            this.helpTemplates[name][this.language]['content'];
    }

    generateArray(obj) {
        return Object.keys(obj).map((key) => {
            return obj[key];
        });
    }

    generateSelectedDiagnoses() {
        this.nextDiagnosisOrder = this.selectedDiagnoses.length > 0 ? this.selectedDiagnoses.length + 1 : 1;

        let diagnosisArray = this.generateArray(this.diagnoses);

        // set categories & subcategories of diagnoses
        for (let item of this.selectedDiagnoses) {
            if (item['level'] === 3) {
                for (let category of diagnosisArray) {
                    for (let level1Category of category.children) {
                        if (level1Category.id === item['parent_id']) {
                            item['category'] = category;
                            let childArray = this.generateArray(level1Category.children);
                            item['categoryLastChild'] = childArray[childArray.length - 1]['code'];

                            for (let level2Category of category.children) {
                                if (level2Category.id === item['parent_id']) {
                                    item['subcategory'] = level2Category;
                                }
                            }
                            break;
                        }
                    }
                }
            } else if (item['level'] === 2) {
                for (let category of diagnosisArray) {
                    if (category.id === item['parent_id']) {
                        item['category'] = category;

                        let childArray = this.generateArray(category.children);
                        item['categoryLastChild'] = childArray ? childArray[childArray.length - 1]['code'] : item['code'];
                        break;
                    }
                }
            }
        }

        let sortedDiagnoses = this.selectedDiagnoses.slice();
        this.latestDiagnosisCreationDate = null;
        if (sortedDiagnoses.length === 0) return;

        sortedDiagnoses.sort((a, b) => {
            return <any>new Date(b['pivot']['created_at']) - <any>new Date(a['pivot']['created_at']);
        });

        this.latestDiagnosisCreationDate = sortedDiagnoses[0]['pivot']['created_at'];
    }

    addDiagnosis(item: Object) {
        // selected diagnoses must be unique
        let isDuplicate = false;
        for (const selectedItem of this.selectedDiagnoses) {
            if (selectedItem['id'] === item['id']) {
                isDuplicate = true;
                break;
            }
        }

        if (isDuplicate) {
            return;
        }

        const selectedItems = this.formatSubmittedDiagnoses();

        const submittedItem = {
            id: item['id'],
            order: this.nextDiagnosisOrder,
            user_id: this.authService.user.id,
            created_at: moment().format('DD.MM.YYYY')
        };


        // change diagnosis instead of adding a new one
        let changeDiagnosis = false;
        for (const selectedItem of selectedItems) {
            if (selectedItem['order'] === this.currentlySelectedOrder) {
                changeDiagnosis = true;
                selectedItem['id'] = submittedItem.id;
                selectedItem['user_id'] = submittedItem.user_id;
                selectedItem['created_at'] = submittedItem.created_at;
                break;
            }
        }

        if (changeDiagnosis) {
            this.submitDiagnoses(selectedItems, item);
        } else {

            this.nextDiagnosisOrder++;

            selectedItems.push(submittedItem);

            this.displaySelectedDiagnosisPlaceholder = false;

            this.submitDiagnoses(selectedItems, item);
        }
    }

    deleteDiagnosis(item: Object) {
        let selectedItems = this.formatSubmittedDiagnoses();

        let deletedIndex: number;
        let nextOrder: number;

        for (let index in selectedItems) {
            if (selectedItems[index]['id'] === item['id']) {
                deletedIndex = +index;
                nextOrder = selectedItems[deletedIndex - 1] ? selectedItems[deletedIndex - 1]['order'] + 1 : 1;
                break;
            }
        }

        // update the orders of the remaining selected diagnoses
        for (let index in selectedItems) {
            if (+index > deletedIndex && selectedItems.hasOwnProperty(index)) {
                selectedItems[index]['order'] = nextOrder++;
            }
        }

        // update the current order
        this.nextDiagnosisOrder = this.selectedDiagnoses.length - 1;

        selectedItems.splice(deletedIndex, 1);

        this.submitDiagnoses(selectedItems, item, true);
    }

    formatSubmittedDiagnoses(): Object[] {
        const selectedItems = [];

        for (const selectedItem of this.selectedDiagnoses) {
            selectedItems.push({
                id: selectedItem['id'],
                order: selectedItem['pivot'].order,
                user_id: selectedItem['pivot'].user_id,
                created_at: moment(selectedItem['pivot']['created_at']).format('DD.MM.YYYY')
            });
        }

        return selectedItems;
    }

    submitDiagnoses(selectedItems: Object[], item?: Object, isDelete?: boolean, isLoading = true) {
        this.appService.isLoading = isLoading;

        this.submit(this.patientId, selectedItems).pipe(
            finalize(() => this.appService.isLoading = false))
            .subscribe(res => {
                this.selectedDiagnoses = res['selectedDiagnosis'];
                this.proposalData = res['proposal_diagnosis'];
                let isProposal = false;
                if (item && !isDelete) {
                    this.newDiagnoses[item['id']] = item['id'];
                    isProposal = item['is_proposal'];
                }

                this.generateSelectedDiagnoses();
                this.appService.publish('diagnoses', { selectedDiagnosesAreUpdated: true, isDelete: isDelete, isProposal: isProposal });
                this.appService.publish('patient', { selectedDiagnosesAreUpdated: true, items: res['selectedDiagnosis'] });
                this.appService.publish('updateDiagnosis', { items: res['proposal_diagnosis'] });
            },
            error => {
                this.appService.publish('updateDiagnosis', { error: true, errorMessage : error.error.error });
            }
        );
    }

    submit(patientId: number, diagnoses: Object[]) {
        // add url query parameters
        let body = '';

        for (const item of diagnoses) {
            body += '&diagnosis[' + item['id'] + '][order]=' + item['order'];
            body += '&diagnosis[' + item['id'] + '][user_id]=' + item['user_id'];
            body += '&diagnosis[' + item['id'] + '][created_at]=' + item['created_at'];
        }

        // remove the unnecessary ampersand symbol
        body = body.slice(1);

        return this.secureApi.post('patient/diagnosis/update/' + patientId, body, true);
    }

    // after drag & drop
    updateOrdersOfSelectedDiagnoses(className: string, isLoading = true) {
        const diagnosisEls = document.querySelectorAll(className);

        const selectedItems = this.formatSubmittedDiagnoses();
        const submittedItems = [];

        // update the diagnosis orders
        let order = 1;
        for (let i = 0; i < diagnosisEls.length; i++) {
            for (const selectedItem of selectedItems) {
                if (+diagnosisEls[i].getAttribute('id') === selectedItem['id']) {
                    selectedItem['order'] = order++;
                    submittedItems.push(selectedItem);
                    break;
                }
            }
        }

        // save to API
        this.submitDiagnoses(submittedItems, null, null, isLoading);
    }

    onDiagnosisSelected(item: Object) {
        this.currentlySelectedOrder = item['pivot']['order'];

        this.displaySelectedDiagnosisPlaceholder = false;

        // show the selected diagnosis in the diagnoses
        let openedCategoryId: number;
        let openedSubcategoryId: number;
        const openedDiagnosisId = item['id'];

        if (item['level'] === 2) {
            openedCategoryId = item['parent_id'];
        } else if (item['level'] === 3) {
            openedSubcategoryId = item['parent_id'];

            // get the category id of the diagnosis
            let categoryIsFound = false;
            const diagnoses = this.generateArray(this.diagnoses);

            for (const category of diagnoses) {
                const subcategories = this.generateArray(category['children']);
                for (const subcategory of subcategories) {
                    if (subcategory.id === openedSubcategoryId) {
                        openedCategoryId = category['id'];
                        categoryIsFound = true;
                        break;
                    }
                }

                if (categoryIsFound) {
                    break;
                }
            }
        }

        this.appService.publish('diagnoses', { selectedDiagnosis: [openedCategoryId, openedSubcategoryId, openedDiagnosisId, item['level']] });
    }

    getGraphicComparision(currentId, compareId) {
        const locale = this.language || 'en_GB';
        return this.secureApi.get('graphic-comparison/' +  currentId + '/' + compareId + '/' + locale);
    }

    /**
     * @param videoId
     * @param videoForm
     * @param videoModel
     * @returns {Observable<any>}
     */
    upload(patientId, pdf) {
        if (!pdf) {
            return;
        }

        return this.secureApi.post('patient/' + patientId + '/upload', pdf, false);
    }

    getBookingEmailTemplate(patientId) {
        return this.secureApi.get('patients/booking-email-template/' + patientId);
    }

    sendEmail(patientId, data){
        return this.secureApi.post('patients/booking-email/' + patientId,  data, false);
    }

    getFilters(patientId) {
        return this.secureApi.get('patient/' + patientId + '/service-provider/filter');
    }

    generatePreview(patientId, data) {
        return this.secureApi.post('patient/' + patientId + '/generate-docs', data, false);
    }

    generateReport(patientId, data) {
        return this.secureApi.post('patient/' + patientId + '/service-provider/export-docs', data, false);
    }

    getServiceProviders(patientId, countryIds, insuranceIDs, group = 0) {
        let body = {
            country_ids: countryIds,
            insurance_ids: insuranceIDs,
            group_therapy: group
        };

        return this.secureApi.post('patient/' + patientId + '/service-provider/list', body, false);
    }


    getCallInfo(patientId){
        return this.secureApi.get('patient/' + patientId + '/patient-calling-status/index');
    }

    addSuicidal(patientId, body){
        return this.secureApi.post('patient/' + patientId + '/document-of-suicidality/add', body, false);
    }

    addCallInfo(patientId, body){
        return this.secureApi.post('patient/' + patientId + '/patient-calling-status/add', body, false);
    }

    requestPermissionHealthData(patient) {
        return this.secureApi.post('patient/' + patient.id + '/request-permission', {}, false);
    }
}
