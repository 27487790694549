import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SelfEvaluationFeedbackComponent } from './self-evaluation-feedback/self-evaluation-feedback.component';

const routes: Routes = [
    {
        path: 'self-report-feedback',
        component: SelfEvaluationFeedbackComponent, canActivate: [],
        data: {
            breadcrumb: 'Login',
            class: 'auth-page',
            admin: false
        }
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class FeedbackRoutesModule { }
