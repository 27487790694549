<div class="wrapper wrapper__selfevaluation">
    <div class="row container">
        <div class="col-xs-12 col-md-3 offset-md-1">
            <a class="selfevaluation__icon" href="/login">
                <i class="selfevaluation__icon--fa end wrapper__introduction__icon--completed"></i>
            </a>
        </div>
    </div>
    <div class="row header-title">
        <div class="col-sm-12">
            <h1 class="wrapper__title font-se-title" [class.hidden]="showMessage">{{ 'Thank you' | translate}}</h1>
            <h1 class="wrapper__title font-se-title" [class.hidden]="!showMessage">{{ 'Your feedback has been sent' | translate }}</h1>
        </div>
    </div>
    <div class="row wrapper__introduction">
        <div class="col-xs-12 col-sm-8">
            <div *ngIf="!showMessage">
                {{ 'Your rating has been saved.' | translate }}
            </div>
            <div *ngIf="showMessage">
                {{ 'Thank you for sharing your thoughts, we will try to integrate your feedback.' | translate }}
            </div>
        </div>
    </div>
    <div class="row self-report-feedback" *ngIf="!showMessage">
        <div class="col-xs-12 col-md-8">
            <div class="self-report-feedback__area">
                <div class="row">
                    <div class="col-xs-12">
                        <div *ngFor="let item of scoredList" class="rating-item" [ngClass]="(scored == item ) ? 'active':''" (click)="scored = item">
                            {{ item }}
                        </div>
                    </div>
                </div>   
                <div class="row self-report-feedback__label">
                    <div class="col-xs-6">
                        <span class="pull-left">{{ 'Not likely' | translate }}</span>
                    </div>
                    <div class="col-xs-6">
                        <span class="pull-right">{{ 'Very likely' | translate }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xs-12 col-md-8 mr-t--md" [class.hidden]="scored == 0">
            <span>{{ 'Tell us a bit more how you came to your decision.' | translate }}</span>
            <textarea id="patient-comment" maxlength="300" placeholder="{{ 'My comment' | translate }}" class="form-control comment-area mr-t--sm" [(ngModel)]="comment" rows="3"></textarea>
        </div>
    </div>      
    <div class="row wrapper__btns wrapper__send-feedback-btn" *ngIf="!showMessage">
        <div class="col-xs-12 slide-navigator">
            <button (click)="submit()" type="submit" class="btn btn-primary badge-pill pd-v--sm btn-xs-full">
                <i class="fas fa-clock"></i>
                {{ 'Send feedback' | translate }}
            </button>
        </div>
    </div>
</div>