<div bsModal #modal="bs-modal" class="modal fade modal-confirmation " tabindex="-1" role="dialog" aria-hidden="true">
    <div class='modal-dialog modal-confirmation-dialog ' [ngClass]="params['modalSize'] || 'modal-sm'">
        <div class="modal-content">
            <div class="modal-body text-xs-center">
                <div class="row">
                    <div class="col-xs-12">
                        <div class="modal-icon" [ngClass]="{'modal-icon--red': params['warning'] }">
                            <i class="{{params.icon}}" ng-class="icon" aria-hidden="true"></i>
                        </div>
                    </div>
                </div>
                <confirm-modal-body [responseMessage]="alertMessage" [params]="params"></confirm-modal-body>
                <div class="row">
                    <div class="col-xs-12 text-xs-right">
                        <button *ngIf="!alertMessage" type="button" class="btn btn-secondary" (click)="cancel()">
                            {{ (params['cancelText'] || 'Cancel') | translate }}
                        </button>
                        <button *ngIf="alertMessage" type="button" class="btn btn-secondary" (click)="cancel()">
                            {{ 'Close' | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
