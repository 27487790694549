<div >
    <a class="btn btn-link btn-icon btn-icon--left btn-back hidden"
       *ngIf="isClinicView && isClinicActivatable && clinicName != null && clinicCity != null"
       [routerLink]="['/clinics']">
        {{ 'back' | translate }}
    </a>
    <app-breadcrumb
            [firstNode]="{label: 'Users list', 'url': '/home'}">
    </app-breadcrumb>

    <div class="box-shadow">
        <div class="card card--box">
            <div class="card--box__header">
                <div class="card--box__header--info">
                    <div *ngIf="isClinicView" class="card--box__header__title">
                        <h6>{{ 'User Management' | translate }}</h6>
                        <h2>
                            {{ clinicName | translate }}, <span>{{ clinicCity | translate }}</span>
                        </h2>
                    </div>
                    <div *ngIf="!isClinicView" class="card--box__header__title">
                        {{ 'User List' | translate }}
                    </div>
                </div>
                <div class="text-xs-right">
                    <span class="card--box__header__action" *ngIf="isCreatable">
                        <button type="button" class="btn btn-primary btn-block pl-3 pr-3"
                                (click)="openFormModal({ action: 'create', title: 'Add User', url: url })">
                            <i class="fas fa-plus-circle"></i>
                            {{ 'Add User' | translate }}
                        </button>
                    </span>
                </div>
            </div>
        </div>
        <div class="table--grid users-component">
            <div class="table--grid__header">
                <div *ngIf="authService.isSuperAdmin && !isClinicView"
                    class="table--grid__header-tab" [class.active]="activeListUser == authService.ROLES.SUPER_ADMIN"
                    (click)="switchUserTypeTab(authService.ROLES.SUPER_ADMIN)">
                   <div>{{ 'Super Admin' | translate }} ({{ (items | filterRole: authService.ROLES.SUPER_ADMIN).length }})</div>
                   <ng-template [ngIf]="activeListUser == authService.ROLES.SUPER_ADMIN">
                       <input class="form-control" placeholder="{{ 'Search' | translate }}"
                              [(ngModel)]="searchInfo.keyword">
                       <i *ngIf="searchInfo.keyword" class="fas fa-times" (click)="clearSearch()"></i>
                   </ng-template>
               </div>
                <div class="table--grid__header-tab" [class.active]="activeListUser == authService.ROLES.CLINIC_ADMIN"
                     (click)="switchUserTypeTab(authService.ROLES.CLINIC_ADMIN)">
                    <div>{{ 'Administrator' | translate }} ({{ (items | filterRole: authService.ROLES.CLINIC_ADMIN).length }})</div>
                    <ng-template [ngIf]="activeListUser == authService.ROLES.CLINIC_ADMIN">
                        <input class="form-control" placeholder="{{ 'Search' | translate }}"
                               [(ngModel)]="searchInfo.keyword">
                        <i *ngIf="searchInfo.keyword" class="fas fa-times" (click)="clearSearch()"></i>
                    </ng-template>
                </div>
                <div class="table--grid__header-tab" [class.active]="activeListUser == authService.ROLES.DOCTOR"
                     (click)="switchUserTypeTab(authService.ROLES.DOCTOR)">
                    <div>{{ 'Doctor' | translate }} ({{ (items | filterRole: authService.ROLES.DOCTOR).length }})</div>
                    <ng-template [ngIf]="activeListUser == authService.ROLES.DOCTOR">
                        <input class="form-control" placeholder="{{ 'Search' | translate }}"
                               [(ngModel)]="searchInfo.keyword">
                        <i *ngIf="searchInfo.keyword" class="fas fa-times" (click)="clearSearch()"></i>
                    </ng-template>
                </div>
                <div class="table--grid__header-tab" [class.active]="activeListUser == authService.ROLES.MPA"
                     (click)="switchUserTypeTab(authService.ROLES.MPA)">
                    <div>{{ 'MPA' | translate }} ({{ (items | filterRole: authService.ROLES.MPA).length }})</div>
                    <ng-template [ngIf]="activeListUser == authService.ROLES.MPA">
                        <input class="form-control" placeholder="{{ 'Search' | translate }}"
                               [(ngModel)]="searchInfo.keyword">
                        <i *ngIf="searchInfo.keyword" class="fas fa-times" (click)="clearSearch()"></i>
                    </ng-template>
                </div>
                <div class="table--grid__header-tab" [class.active]="activeListUser == authService.ROLES.ASSISTANT"
                     (click)="switchUserTypeTab(authService.ROLES.ASSISTANT)">
                    <div>{{ 'Clinic secretary' | translate }} ({{ (items | filterRole: authService.ROLES.ASSISTANT).length }})</div>
                    <ng-template [ngIf]="activeListUser == authService.ROLES.ASSISTANT">
                        <input class="form-control" placeholder="{{ 'Search' | translate }}"
                               [(ngModel)]="searchInfo.keyword">
                        <i *ngIf="searchInfo.keyword" class="fas fa-times" (click)="clearSearch()"></i>
                    </ng-template>
                </div>
                <div *ngIf="authService.isSuperAdmin"
                     class="table--grid__header-tab" [class.active]="activeListUser == authService.ROLES.COORDINATOR"
                     (click)="switchUserTypeTab(authService.ROLES.COORDINATOR)">
                    <div>{{ 'Coordinator' | translate }} ({{ (items | filterRole: authService.ROLES.COORDINATOR).length }})</div>
                    <ng-template [ngIf]="activeListUser == authService.ROLES.COORDINATOR">
                        <input class="form-control" placeholder="{{ 'Search' | translate }}"
                               [(ngModel)]="searchInfo.keyword">
                        <i *ngIf="searchInfo.keyword" class="fas fa-times" (click)="clearSearch()"></i>
                    </ng-template>
                </div>
                <div *ngIf="authService.isSuperAdmin"
                        class="table--grid__header-tab" [class.active]="activeListUser == authService.ROLES.B2C_ADMIN"
                        (click)="switchUserTypeTab(authService.ROLES.B2C_ADMIN)">
                    <div>{{ 'B2C Admin' | translate }} ({{ (items | filterRole: authService.ROLES.B2C_ADMIN).length }})</div>
                    <ng-template [ngIf]="activeListUser == authService.ROLES.B2C_ADMIN">
                        <input class="form-control" placeholder="{{ 'Search' | translate }}"
                                [(ngModel)]="searchInfo.keyword">
                        <i *ngIf="searchInfo.keyword" class="fas fa-times" (click)="clearSearch()"></i>
                    </ng-template>
                </div>
                <div *ngIf="(authService.isSuperAdmin && !isClinicView) || (isClinicView && clinic && (clinic['is_default'] || clinic['b2c_register']))"
                     class="table--grid__header-tab" [class.active]="activeListUser == authService.ROLES.ASSISTANT_PLUS"
                     (click)="switchUserTypeTab(authService.ROLES.ASSISTANT_PLUS)">
                    <div>{{ 'Reception Plus' | translate }} ({{ (items | filterRole: authService.ROLES.ASSISTANT_PLUS).length }})</div>
                    <ng-template [ngIf]="activeListUser == authService.ROLES.ASSISTANT_PLUS">
                        <input class="form-control" placeholder="{{ 'Search' | translate }}"
                               [(ngModel)]="searchInfo.keyword">
                        <i *ngIf="searchInfo.keyword" class="fas fa-times" (click)="clearSearch()"></i>
                    </ng-template>
                </div>
                <div *ngIf="(authService.isSuperAdmin && !isClinicView) || (isClinicView && clinic && !clinic['is_default'] && clinic['b2c_register'])"
                     class="table--grid__header-tab" [class.active]="activeListUser == authService.ROLES.INSURANCE_PARTNER"
                     (click)="switchUserTypeTab(authService.ROLES.INSURANCE_PARTNER)">
                    <div>{{ 'Insurance Partner' | translate }} ({{ (items | filterRole: authService.ROLES.INSURANCE_PARTNER).length }})</div>
                    <ng-template [ngIf]="activeListUser == authService.ROLES.INSURANCE_PARTNER">
                        <input class="form-control" placeholder="{{ 'Search' | translate }}"
                               [(ngModel)]="searchInfo.keyword">
                        <i *ngIf="searchInfo.keyword" class="fas fa-times" (click)="clearSearch()"></i>
                    </ng-template>
                </div>
            </div>
            <div class="table--grid__body users-component__list">
                <div class="row">
                    <!--table headers-->
                    <div class="col-xs-11 col-lg-10 users-component__list__column">
                        <div class="row">
                            <div *ngFor="let column of tableColumns"
                                 (click)="sortUsers(column[0]);"
                                 class="{{ column[2] }}"
                                 title="{{ ('Sort by' | translate) }} {{ (column[1] | translate) }}">
                                <span [ngClass]="{'sorted': sortIsActivated && (sortedColumn === column[0]
                                                || (sortedColumn === 'created_at_sort' && column[0] === 'created_at')),
                                                'asc': order === 'asc', 'desc': order === 'desc'}">
                                    {{ column[1] | translate }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <!--table cells-->
                <div *ngFor="let user of items
                        | filterRole: activeListUser
                        | orderBy : orderField:appService.getLanguage()
                        | filterText: searchInfo.keyword:'official_name,email'
                        | slice : ((currentPage-1)*itemsPerPage):(currentPage*itemsPerPage)"
                     class="row" [class.inactive]="!user.activated">
                    <div class="col-xs-11 col-lg-10"
                         (click)="openFormModal({ action: 'read', id: user['id'], title: 'User Details', activated: user['activated'], content: user['activated'] ? 'Are you sure you want to deactivate this user account?' : 'Are you sure you want to activate this user account?', description: user['email'], 'role-id': user['role_id'], url: '/users' });">
                        <div class="row">
                            <div *ngFor="let column of tableColumns" class="{{ column[2] }}">
                                <span *ngIf="column[0] !== 'role_name'">{{ user[column[0]] }}</span>
                                <span *ngIf="column[0] === 'role_name'">{{ user[column[0]] | translate }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-1 col-lg-2 table--grid__actions table--grid__actions--hover"
                         *ngIf="(userRoleId === 1) || (userRoleId === 2 && user['role_id'] > 1 && user['role_id'] <= 5)">
                        <div class="btn-group dropdown" dropdown>
                            <button class="btn btn-link table--grid__actions__ellipsis dropdown-toggle" dropdownToggle>
                                <i class="fas fa-ellipsis-h table--grid__actions__ellipsis__icon"
                                   aria-hidden="true"></i>
                            </button>
                            <ul [hidden]="user['email'] === authService.user['email']" class="dropdown-menu dropdown-menu-right table--grid__actions__links">
                                <li class="dropdown-item" *ngIf="!user.is_activated_by_email"
                                    (click)="openResendEmailModal({
                                                url: 'users',
                                                id: user['id'],
                                                activated: user['activated'],
                                                content: 'Do you really want to resend the activation email?',
                                                description: user['email'],
                                                icon: 'fas fa-envelope',
                                                submitText: 'Send'
                                            })">
                                    <a class="btn btn-link table--grid__action-btn">
                                        <i class="fas fa-envelope table--grid__actions__links__icon"
                                            aria-hidden="true"
                                            attr.name="{{ 'Resend' | translate }}"
                                            title="{{ 'Resend' | translate }}"></i>
                                    </a>
                                </li>
                                <li class="dropdown-item" *ngIf="isUpdatable"
                                    (click)="openFormModal({ action: 'update', id: user['id'], title: 'Edit User', url: '/home' });">
                                    <a class="btn btn-link table--grid__action-btn">
                                        <i class="fas fa-pencil-alt table--grid__actions__links__icon"
                                           aria-hidden="true"
                                           attr.name="{{ 'Edit' | translate }}"
                                           title="{{ 'Edit' | translate }}"></i>
                                    </a>
                                </li>
                                <li class="dropdown-item" *ngIf="isDeletable"
                                    (click)="openConfirmModal({
                                                    url: 'users',
                                                    action: 'delete',
                                                    id: user['id'],
                                                    activated: user['activated'],
                                                    title: 'Delete User Account',
                                                    content: 'Are you sure you want to delete this user account?',
                                                    description: user['email'],
                                                    icon: 'far fa-trash-alt'
                                                })">
                                    <a class="btn btn-link table--grid__action-btn">
                                        <i class="far fa-trash-alt table--grid__actions__links__icon"
                                           attr.name="{{ 'Delete' | translate }}" aria-hidden="true"
                                           title="{{ 'Delete' | translate }}" aria-hidden="true"></i>
                                    </a>
                                </li>
                                <li class="dropdown-item" *ngIf="isActivatable"
                                    (click)="openConfirmModal({
                                                    url: 'users',
                                                    action: 'activate',
                                                    id: user['id'],
                                                    activated: user['activated'],
                                                    title: user['activated'] ? 'Deactivate User Account' : 'Activate User Account',
                                                    content: user['activated'] ? 'Are you sure you want to deactivate this user account?' : 'Are you sure you want to activate this user account?',
                                                    description: user['email'],
                                                    icon: (user.activated)?'fas fa-ban':'far fa-check-circle'
                                                })">
                                    <a class="btn btn-link table--grid__action-btn"
                                       [ngClass]="{'table--grid__action-btn--lighter': !user.activated}">
                                        <i class="table--grid__actions__links__icon"
                                           [ngClass]="(user.activated)?'fas fa-ban':'far fa-check-circle'"
                                           aria-hidden="true"
                                           attr.name="{{ user.activated ?  ('Deactivate' | translate) : ('Activate' | translate) }}"
                                           title="{{ user.activated ?  ('Deactivate' | translate) : ('Activate' | translate) }}"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--pagination-->
    <pagination-component *ngIf="!paginationHidden" [(page)]="currentPage"
                          [total]="(items | filterRole: activeListUser).length" [perPage]="itemsPerPage"
                          [limit]="maxSize" boundaryNumbers="1" boundaryLinks="true" (pageChange)="paginate($event)"
                          #pagination></pagination-component>
    <!-- Modal -->
    <div bsModal #modal="bs-modal" class="modal fade"
         [ngClass]="params['action'] === 'activate' || params['action'] === 'delete' ? 'modal-confirmation': ''"
         tabindex="-1" role="dialog" aria-hidden="true">
        <div *ngIf="params['component'] == null"
             class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">{{ params['title'] | translate }}</h4>
                </div>
                <div class="modal-body">
                    <button type="button" class="btn btn-link close" (click)="hideModal()" aria-label="Close">
                    </button>
                    <div *ngIf="params['action'] === 'read'
                                    && ((userRoleId === 1) || (userRoleId === 2 && params['role-id'] > '1' && params['role-id'] <= '5'))
                                    && isEditable
                    ">
                        <ul class="list-inline">
                            <li class="list-inline-item" *ngIf="isResendEmailable">
                                <button class="btn btn-link"
                                    (click)="openResendEmailModal({
                                        url: 'users',
                                        id: params['id'],
                                        activated: params['activated'],
                                        content: 'Do you really want to resend the activation email?',
                                        description: params['description'],
                                        icon: 'fas fa-envelope',
                                        submitText: 'Send'
                                    })">
                                    <i class="fas fa-envelope text-primary" aria-hidden="true"></i>
                                    <span>{{ 'Resend' | translate }}</span>
                                </button>
                            </li>
                            <li class="list-inline-item" *ngIf="isUpdatable">
                                <button class="btn btn-link"
                                        (click)="openFormModal({ action: 'update', id: params['id'], title: 'Edit User', url: '/users' });">
                                    <i class="fas fa-pencil-alt text-primary" aria-hidden="true"></i>
                                    <span>{{ 'Edit' | translate }}</span>
                                </button>
                            </li>
                            <li class="list-inline-item" *ngIf="isActivatable">
                                <button class="btn btn-link"
                                        (click)="openConfirmModal({
                                            url: 'users',
                                            action: 'activate',
                                            id: params['id'],
                                            activated: params['activated'],
                                            title: params['activated'] === 1 ? 'Deactivate User Account' : 'Activate User Account',
                                            content: params['content'],
                                            description: params['description'],
                                            icon: params['activated'] == 1 ? 'fas fa-ban':'far fa-check-circle'
                                        })">
                                    <i [ngClass]="(params['activated'] == 1) ? 'fas fa-ban text-primary': 'far fa-check-circle text-primary'"></i>
                                    <span>{{ params['activated'] === 1 ? ('Deactivate' | translate) : ('Activate' | translate) }}</span>
                                </button>
                            </li>
                            <li class="list-inline-item" *ngIf="isDeletable">
                                <button class="btn btn-link"
                                        (click)="openConfirmModal({
                                            url: 'users',
                                            action: 'delete',
                                            id: params['id'],
                                            title: 'Delete User Account',
                                            content: 'Are you sure you want to delete this user account?',
                                            description: params['description'],
                                            icon: 'far fa-trash-alt'
                                        });">
                                    <i class="far fa-trash-alt text-primary" aria-hidden="true"></i>
                                    <span>{{ 'Delete' | translate }}</span>
                                </button>
                            </li>
                        </ul>
                        <hr/>
                    </div>
                    <ng-template [ngIf]="!formLoading">
                        <form [formGroup]="form" novalidate (ngSubmit)="submit(form.value, form.valid)"
                              class="mat-form"  #formDirective="ngForm">
                            <div *ngIf="errorMessage.length > 0" class="row form-group" role="alert">
                                <div class="col-md-10">
                                    <div class="alert alert-danger">
                                        <div *ngFor="let error of errorMessage">
                                            <i class="fas fa-exclamation-circle" aria-hidden="true"></i>
                                            {{ error | translate }}
                                        </div>
                                        <span *ngIf="errorData && errorData != ''">{{ errorData }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-xs-6">
                                    <mat-form-field class="ps-content">
                                        <input type="email" matInput [placeholder]="'Email Address' | translate"
                                               required="required" formControlName="email"/>
                                        <small class="form-text text-danger"
                                               [hidden]="form.controls.email.valid || ((form.controls.email.pristine && !formSubmitted) || form.controls.email.disabled)">
                                            {{ 'Require this format: name@company.com' | translate }}
                                        </small>
                                    </mat-form-field>
                                </div>
                                <div class="form-group col-xs-6">
                                    <mat-form-field class="ps-content">
                                        <input type="text" matInput [placeholder]="'Official Name' | translate"
                                               formControlName="official_name"/>
                                        <small class="form-text text-danger"
                                               [hidden]="form.controls.official_name.valid || (form.controls.official_name.pristine && !formSubmitted)">
                                            {{ 'Require at least 3 characters.' | translate }}
                                        </small>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-xs-4">
                                    <mat-form-field class="ps-content">
                                        <input type="text" matInput [placeholder]="'Given Name' | translate"
                                               required="required" formControlName="given_name"/>
                                        <small class="form-text text-danger"
                                               [hidden]="form.controls.given_name.valid || (form.controls.given_name.pristine && !formSubmitted)">
                                            {{ 'Require at least 3 characters.' | translate }}
                                        </small>
                                    </mat-form-field>
                                </div>
                                <div class="form-group col-xs-4">
                                    <mat-form-field class="ps-content">
                                        <input type="text" matInput [placeholder]="'Last Name' | translate"
                                               required="required" formControlName="surname"/>
                                        <small class="form-text text-danger"
                                               [hidden]="form.controls.surname.valid || (form.controls.surname.pristine && !formSubmitted)">
                                            {{ 'Require at least 3 characters.' | translate }}
                                        </small>
                                    </mat-form-field>
                                </div>
                                <div class="form-group col-xs-4">
                                    <mat-form-field class="ps-content">
                                        <input type="tel" matInput [placeholder]="'Mobile Phone Number' | translate"
                                               formControlName="mobile" (focus)="focusInMobileInput()"
                                               (focusout)="focusOutMobileInput()"/>
                                        <small [ngClass]="isFocusMobileInput ? 'text-normal' : 'text-danger'"
                                               [hidden]="form.controls.mobile.valid || (form.controls.mobile.pristine && !formSubmitted)">
                                            {{ 'Country code format e.g. "+41 234 56789"' | translate }}
                                        </small>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-xs-4">
                                    <mat-form-field class="ps-content">
                                        <mat-select [placeholder]="'Role' | translate" required="required"
                                                    formControlName="role_id">
                                            <mat-option *ngFor="let role of roles"
                                                        [disabled]="role['id'] == authService.ROLES.INSURANCE_PARTNER
                                                                    && (!authService.isSuperAdmin || (clinic && (clinic['is_default'] || !clinic['b2c_register'])))"
                                                        [value]="role['id']">
                                                {{ role['name'] | translate }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="form-group col-xs-4">
                                    <div class="label">{{ 'Admin rights' | translate }}</div>
                                    <div class="pt-1">
                                        <mat-slide-toggle formControlName="have_admin_role"
                                                          [class.disabled]="params['action'] === 'read'">
                                        </mat-slide-toggle>
                                    </div>
                                </div>
                                <div class="form-group col-xs-4">
                                    <mat-form-field class="ps-content">
                                        <input type="text" matInput [placeholder]="'Career' | translate"
                                               formControlName="career"/>
                                    </mat-form-field>
                                </div>
                                <div class="form-group col-xs-12">
                                    <div class="row">
                                        <div class="col-xs-4">
                                            <mat-form-field class="ps-content">
                                                <mat-select [placeholder]="'Institution/ Clinic' | translate"
                                                            required="required" formControlName="clinic_id">
                                                    <mat-option value="null" disabled>
                                                        {{ 'Select Clinic' | translate }}
                                                    </mat-option>
                                                    <mat-option *ngFor="let clinic of clinics" [value]="clinic['id']"
                                                                [disabled]="(form.controls['role_id'].value === authService.ROLES.INSURANCE_PARTNER && B2CClinics.indexOf(clinic) < 0) || 
                                                                (form.controls['role_id'].value === authService.ROLES.ASSISTANT_PLUS && B2CClinics.indexOf(clinic) < 0 && !clinic['is_default'])">
                                                        {{ clinic['name'] }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-xs-4 mt-2" *ngIf="params['action'] !== 'read' && authService.isSuperAdmin">
                                            <button type="button"
                                                    class="btn btn-primary"
                                                    [disabled]="!authService.isSuperAdmin && !authService.isClinicAdmin"
                                                    (click)="addClinicRole(form.controls['clinic_id'].value, form.controls['role_id'].value, form.controls['have_admin_role'].value)">
                                                {{ 'Add Role' | translate }}
                                            </button>
                                        </div>
                                        <div class="col-xs-4">
                                            <mat-form-field class="ps-content">
                                                <mat-select [placeholder]="'Language' | translate"
                                                            required="required" formControlName="language_id">
                                                    <mat-option *ngFor="let language of languages" [value]="language['id']">
                                                        {{ language['name'] | translate }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-xs-10 roles" *ngIf="authService.isSuperAdmin">
                                    <div class="row roles--title" *ngIf="clinicRoles.length">
                                        <div class="col-xs-4">
                                            {{ 'Clinic' | translate }}
                                        </div>
                                        <div class="col-xs-3">
                                            {{ 'Role' | translate }}
                                        </div>
                                        <div class="col-xs-2 roles--btn">
                                            {{ 'Admin rights' | translate }}
                                        </div>
                                        <div class="col-xs-2 roles--btn">
                                            {{ 'Delete' | translate }}
                                        </div>
                                    </div>
                                    <div [class.roles--list]="clinicRoles.length > 1" *ngIf="clinicRoles.length">
                                        <perfect-scrollbar #psClinicRoles>
                                            <ng-container *ngFor="let role of clinicRoles">
                                                <div *ngIf="clinicRoles && clinicRoles.length && clinicById(role['clinic_id'], role['role_id'])"
                                                     class="row"
                                                     [class.hidden]="role['role_id'] === authService.ROLES.CLINIC_ADMIN && have2ndRole(role)">
                                                    <div class="col-xs-4">
                                                        {{clinicById(role['clinic_id'], role['role_id'])['name'] }}
                                                    </div>
                                                    <div class="col-xs-3">
                                                        {{ roleById(role['role_id'])['name'] | translate}}
                                                    </div>
                                                    <div class="col-xs-2 roles--btn">
                                                        <mat-slide-toggle
                                                                [class.disabled]="params['action'] === 'read'
                                                                || haveSuperAdminRole()
                                                                || role['role_id'] === authService.ROLES.CLINIC_ADMIN
                                                                || role['role_id'] === authService.ROLES.INSURANCE_PARTNER
                                                                || role['role_id'] === authService.ROLES.B2C_ADMIN"
                                                                (change)="haveAdminRole(role) ? removeClinicRole(role['clinic_id'], authService.ROLES.CLINIC_ADMIN) : addClinicRole(role['clinic_id'],role['role_id'], true)"
                                                                [checked]="(haveAdminRole(role) || haveSuperAdminRole()) && !isB2CAdmin(role['role_id'])">
                                                        </mat-slide-toggle>
                                                    </div>
                                                    <div class="col-xs-2 roles--btn">
                                                        <i class="fal fa-trash-alt"
                                                           [class.disabled]="params['action'] === 'read'"
                                                           (click)="removeClinicRole(role['clinic_id'])"></i>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </perfect-scrollbar>
                                    </div>
                                </div>
                                <div class="form-group col-xs-6" *ngIf="this.authService.isClinicAdmin || this.authService.isSuperAdmin">
                                    <div class="row">
                                        <div class="label col-xs-12">{{ 'Login Type' | translate }}</div>
                                        <div class="col-xs-12">
                                            <input type="radio" id="simple" [value]="0" formControlName="login_type" [checked]="form.controls.login_type.value === 0" />
                                            <label for="simple">{{ 'Simple' | translate }}</label>
                                            <br>
                                            <input type="radio" id="extended-p" [value]="1" formControlName="login_type" [checked]="form.controls.login_type.value === 1" />
                                            <label for="extended-p"> {{ 'Extended by phone' | translate }}</label>
                                            <br>
                                            <input type="radio" id="extended-e" [value]="2" formControlName="login_type" [checked]="form.controls.login_type.value === 2" />
                                            <label for="extended-e"> {{ 'Extended by e-mail' | translate }}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-xs-6" *ngIf="this.authService.isClinicAdmin || this.authService.isSuperAdmin">
                                    <label class="label--highlight">{{ 'Important' | translate }}</label>
                                    <p class="text-muted">
                                        {{ 'Simple login is per email address and password.' | translate }}
                                        <br/>
                                        {{ 'Extended is two-leveled and adds a SMS verification code level.' | translate }}
                                    </p>
                                </div>
                                <div class="col-xs-12 text-xs-right">
                                    <button type="button" class="btn btn-secondary" (click)="hideModal()">
                                        {{ 'Cancel' | translate }}
                                    </button>
                                    <button *ngIf="params['action'] !== 'read'"
                                            type="submit"
                                            [disabled]="clinicRoles.length < 1"
                                            class="btn btn-primary save">
                                        {{ 'Submit' | translate }}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </ng-template>
                </div>
            </div>
        </div>
        <div *ngIf="params['component'] === 'clinics'" class="modal-dialog">
            <clinics-modal-component
                    [params]="params"
                    [formLoading]="externalFormLoading"
                    [isUploading]="isUploading"
                    [form]="externalForm"
                    [errorMessage]="errorMessage"
                    [fullPath]="fullPath"
                    [languages]="languages"
                    [countries]="countries"
                    [clinicUsers]="clinicUsers"
                    [authService]="authService"
                    [helpRawData]="helpClinicProfile"
                    [formSubmitted]="formSubmitted"
                    [token]="token"
                    (hideModal)="hideModal()"
                    (submit)="formSubmitted=true;submit(externalForm.value, externalForm.valid, 'clinics')"
                    (avatarUploading)="avatarUploading($event)"
                    (logoUploaded)="logoUploaded($event)"
                    (deleteLogoEmit)="deleteLogo($event)"></clinics-modal-component>
        </div>
    </div>
</div>
<dialog-component #dialog></dialog-component>
<dialog-component #dialogLogo></dialog-component>
