import { Component, OnInit, ChangeDetectionStrategy, DoCheck, Output, EventEmitter } from '@angular/core';
import { AuthService } from '../../core/auth.service';
import { AppService } from '../../app.service';
import { Router, RoutesRecognized } from '@angular/router';
import {ENV} from "../../app.env";

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    changeDetection: ChangeDetectionStrategy.Default,
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, DoCheck {
    @Output() openIntroductionModal = new EventEmitter<any>();
    @Output() openLinks = new EventEmitter<any>();

    isLoggedIn: boolean;
    thisYear: number;
    private releasedVersion: string;
    isDoctor: boolean;
    currentPage: string;
    constructor(
        public authService: AuthService,
        public appService: AppService,
        public router: Router
    ) {
        this.router.events.subscribe((routeEvent: RoutesRecognized) => {
            if (!(routeEvent instanceof RoutesRecognized)) {
                return;
            }

            let route = routeEvent.state.root;
            this.currentPage = '';
            while (route.children.length) {
                route = route.firstChild;
                if (!route.routeConfig.path) {
                    continue;
                }

                if (!route.data['page']) {
                    continue;
                }

                this.currentPage = route.data['page'];
            }
        });
    }
    get sePage() {
        return this.currentPage === 'se' || this.currentPage === 'repeatedSE';
    }

    get seAuthPage() {
        return this.currentPage === 'auth-se';
    }

    ngOnInit() {
        this.releasedVersion = ENV.buildVersion;
        this.isLoggedIn = false;
        const currentTime = new Date();
        this.thisYear = currentTime.getFullYear();
        this.isDoctor = false;
    }
    ngDoCheck() {
        this.isLoggedIn = this.authService.isLoggedIn;
        this.isDoctor = this.authService.isDoctor;
    }

    openDashboardInformationModal() {
        this.openIntroductionModal.emit();
    }

    get getLanguage() {
        const selectedLang = this.appService.getLanguage();
        const languages = JSON.parse(localStorage.getItem('languages'));
        const language = languages.find(item => item.locale === selectedLang);

        if (!language) {
            return 'de';
        }
        return language.code.toLowerCase();
    }

    openTermsConditionModal() {
        this.openLinks.emit('terms-condition');
    }

    openPrivacyStatementModal() {
        this.openLinks.emit('privacy-statement');
    }

    openLegalNoticeModal() {
        this.openLinks.emit('legal-notice');
    }

    openDeviceLabelModal() {
        this.openLinks.emit('device-label');
    }
}
