import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { BlockTypes, MarkTypes, richTextResolver } from '@storyblok/richtext';


@Pipe({
    name: 'storyblokButton'
})

export class StoryBlokButtonPipe implements PipeTransform {

    constructor(
        private _sanitizer: DomSanitizer,
        private translate: TranslateService
    ) {

    }

    transform(buttonData: any): SafeHtml {
        // Check if buttonData is valid
        if (!buttonData || buttonData.component !== 'button') {
            return '';
        }

        // Extract button properties
        const url = buttonData.link?.cached_url ? this.formatLink(buttonData.link?.cached_url) : '#';
        const text = buttonData.text || 'Click here';
        const target = buttonData.link?.target || '_self';

        // Create the HTML string for the button
        const buttonHtml = `<a href="${url}" target="${target}" class="btn">${text}</a>`;

        // Sanitize the HTML to prevent security risks
        return this._sanitizer.bypassSecurityTrustHtml(buttonHtml);
    }

    formatLink(url: string) {
        let link = url.split('/');
        if (link[0] == '') {
            link[0] = 'me';
        }

        return link.join('/');
    }
}
