
import {of as observableOf,  Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppService } from '../app.service';
import { AuthService } from '../core/auth.service';

@Component({
    selector: 'errors-component',
    templateUrl: './errors.component.html',
    styleUrls: ['./errors.component.scss'],
})
export class ErrorsComponent implements OnInit {
    errorMsg: Observable<string>;
    infoMsg = 'It seems you have <span class="info-bold">no permission</span> to view this page or <span class="info-bold">the URL is wrong</span>.';

    constructor(public route: ActivatedRoute,
                public appService: AppService,
                public authService: AuthService) {
    }

    ngOnInit() {
        this.appService.isLoading = false;
        this.route.queryParams.subscribe(params => {
            this.errorMsg = observableOf(params.message);
        });
    }
}
