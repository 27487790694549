import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { AuthService } from './auth.service';

@Injectable()
export class AuthUnguard implements CanActivate {
    constructor(public authService: AuthService,
                public router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (!this.authService.isLoggedIn) {
            return true;
        }

        if(this.authService.isPatient){
            this.router.navigate(['me/dashboard']);
            return false;
        }
        this.router.navigate(['home']);
        return false;
    }
}
