
import {finalize} from 'rxjs/operators';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService } from './login.service';
import { AuthService } from '../../core/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthComponent } from '../../auth/auth.component';
import { Login } from './login.interface';
import { AppService } from '../../app.service';
import { MaintenanceService } from '../../maintenance/maintenance.service';
import { DialogComponent } from '../../dialog/dialog.component';
import { ENV } from '../../app.env';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['../auth.component.scss'],
    providers: [MaintenanceService]
})

export class LoginComponent extends AuthComponent implements OnInit {
    form: FormGroup;
    submitted: boolean;
    successMessage = '';
    errorMessage = '';
    infoMessage = '';
    smsMessage = '';
    hiddenPhoneNumber = '';
    checkMaintenance: 0;
    userId: 0;
    isVerifySMS = false;
    lastURL = '';
    lastParams = {};
    loginData: any;
    showDemoLink = false;

    @ViewChild(DialogComponent, {static: false}) dialog: DialogComponent;

    get isLoading() {
        return this.appService.isLoading;
    }

    set isLoading(isLoading) {
        this.appService.isLoading = isLoading;
    }

    constructor(
        public router: Router,
        public authService: AuthService,
        public translate: TranslateService,
        public appService: AppService,
        public loginService: LoginService,
        public route: ActivatedRoute,
        public maintenanceService: MaintenanceService,
        public fb: FormBuilder,
        @Inject('konektoDomain') public konektoDomain : string,
    ) {
        super(router, authService, appService, translate);
    }

    ngOnInit() {
        super.ngOnInit();
        const currentTime = new Date();
        this.thisYear = currentTime.getFullYear();
        let accessingNumber = this.getBackground();
        this.appService.isMePage = false;
        if(accessingNumber > 2){
            accessingNumber = 1;
        }
        else {
            accessingNumber++;
        }
        this.setBackground(accessingNumber);

        if (!this.route.snapshot.data['admin']) {
            this.checkMaintenanceMode();
        }

        this.form = this.fb.group({
            email: [localStorage.getItem('email'), [<any>Validators.required, <any>Validators.email]],
            password: ['', [<any>Validators.required]]
        });

        this.route.queryParams.subscribe(params => {
            this.lastParams = params;
            this.infoMessage = params['infoMessage'];
            if(params['email']){
                this.form.controls.email.setValue(params['email']);
            }
        });

        //  Integrate DEEPLINK after login successfully
        this.route.params.subscribe(params => {
            if (params['action'] && params['id']) {
                this.lastURL = params['action'] + '/' + params['id'];
            }
            if (params['page']) {
                this.lastURL = params['page'];
            }
        });

        this.isLoading = false;
    }

    checkMaintenanceMode() {
        this.maintenanceService.read().subscribe(
            response => {
                this.checkMaintenance = response['maintain'].value;
                if (this.checkMaintenance) {
                    this.authService.maintenanceMode = true;
                    this.authService.maintenanceMessage = response['maintain'].text;
                    this.router.navigate(['maintenance'], {
                        queryParams: {},
                        skipLocationChange: true
                    });
                }
            }
        );
    }

    /**
     * @param {Login} loginForm
     * @param {boolean} isValid
     */
    login(loginForm: Login, isValid: boolean) {
        this.submitted = true;

        if (!isValid) {
            return;
        }

        this.errorMessage = '';
        this.isLoading = true;
        this.showDemoLink = false;

        this.loginService.login(loginForm.email, loginForm.password).pipe(
            finalize(() => {
                localStorage.removeItem('email');
                this.isLoading = false;
            }))
            .subscribe(response => {

                this.isVerifySMS = response['hasToValidateSms'];
                localStorage.setItem('reloaded', JSON.stringify(true));
                localStorage.setItem('openSE', JSON.stringify(false));

                this.appService.publish('user_login', null);
                this.userId = response['id'] || response['userId'];
                this.loginData = response;

                // Remove in future after code handover
                // if (response['b2c_redirect_url'] && this.konektoDomain != '') {
                //     window.location.href = this.konektoDomain + response['b2c_redirect_url'];
                //     return;
                // }

                if (!this.isVerifySMS) {
                    this.doLogin(response);
                }

                this.smsMessage = response['smsMessage'];
                this.hiddenPhoneNumber = response['userHiddenPhoneNumber'];
            },
                error => {
                    this.errorMessage = error.error.error;
                    this.showDemoLink = true;
                    this.infoMessage = '';
                });
    }

    /**
     * Handle sms verification result
     *
     * @param invalidSmsCodeMessage
     */
    onNotifyIsValidSmsCode(invalidSmsCodeMessage): void {
        this.isVerifySMS = false;
        this.errorMessage = invalidSmsCodeMessage;
    }
}
