import { Injectable } from '@angular/core';
import { SecureApi } from '../core/secure.api';

@Injectable()
export class ClinicsService {
    constructor(public secureApi: SecureApi) { }

    search(term: string, countryId: number) {
        return this.secureApi.get('clinics/search/' + term + '?country_id=' + countryId)['items'];
    }

    read(id = 0) {
        return this.secureApi.get('clinics/read/' + id);
    }

    getAll() {
        return this.secureApi.get('clinics/get-all');
    }

    getAllB2C() {
        return this.secureApi.get('clinics/get-all-b2c');
    }

    deleteClinic(params) {
        return this.secureApi.post('clinic/delete/' + params['clinicId'], params, false);
    }

    sendDeleteClinicCode(params) {
        return this.secureApi.post('clinic/sms-to-delete', params, false);
    }
}