import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from '../../shared/base.component';
import { AuthService } from '../../core/auth.service';
import { BaseService } from '../../shared/base.service';
import { AppService } from '../../app.service';
import { PrivacyStatementService } from '../privacy-statement.service';

@Component({
    selector: 'app-privacy-statement-modal',
    templateUrl: './modal.component.html',
    encapsulation: ViewEncapsulation.None,
    providers: [PrivacyStatementService]
})

export class PrivacyStatementModalComponent extends BaseComponent {
    content = {};

    constructor(public authService: AuthService,
        public router: Router,
        public baseService: BaseService,
        public appService: AppService,
        public service: PrivacyStatementService
        ) {
        super(authService, router, baseService, appService);
    }

    openModal() {
        const params = {
            action: 'open',
            url: '',
            title: 'Privacy statement'
        };

        this.content = {};
        this.getTemplateContent(this.service, params, this.content);
    }

}

