import { Component, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import {ModalDirective} from "ngx-bootstrap/modal";

@Component({
    selector: 'self-evaluation-confirm-modal-component',
    templateUrl: './self-evaluation-confirm-modal.component.html',
    styleUrls: ['self-evaluation-confirm-modal.component.scss']
})
export class SelfEvaluationConfirmModalComponent {
    @Input() isStudy: boolean;
    @Input() isIntroducePage : boolean;
    @Input() step : any;
    @Output() confirmContinueLater = new EventEmitter<any>();


    @ViewChild('modal', { static: false }) modal: ModalDirective;


    openConfirm() {
        this.modal.show();
    }

    hideModal() {
        this.modal.hide();
    }

    confirm() {
        this.confirmContinueLater.emit();
    }
    
}
