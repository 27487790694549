import { Injectable } from '@angular/core';
import {HttpParams, HttpClient, HttpHeaders} from '@angular/common/http';
import { SecureApi } from '../core/secure.api';
import { GhQueryEncoder } from '../core/ghqueryencoder';

@Injectable()

export class BaseService {

    /**
     * @param {SecureApi} secureApi
     */
    constructor(public secureApi: SecureApi) {
    }

    /**
     * @param {number} userId
     * @param {number} currentPage
     * @param {number} itemsPerPage
     * @param {string} sortedColumn
     * @param {string} order
     * @param searchInfo
     * @returns {Observable<Response>}
     */
    delete(userId: number, currentPage: number, itemsPerPage: number, sortedColumn: string, order: string, searchInfo: any) {
        let url = 'users/delete/' + userId + '?';
        url = this.setFilters(url, currentPage, itemsPerPage, sortedColumn, order, searchInfo);
        return this.secureApi.delete(url);
    }

    /**
     * @param {string} body
     * @param {number} currentPage
     * @param {number} itemsPerPage
     * @param {string} sortedColumn
     * @param {string} order
     * @param searchInfo
     * @returns {string}
     */
    setFilters(body: string, currentPage: number, itemsPerPage: number, sortedColumn: string, order: string,
               searchInfo: any): string {
        let sortFilter = 'sort-' + sortedColumn + '--order-' + order;
        let queryParams = '&page=' + currentPage + '&items=' + itemsPerPage;
        body += 'filters=';

        let isFirstFilter = true;
        for (let filter in searchInfo) {
            if (searchInfo.hasOwnProperty(filter) && searchInfo[filter]) {
                if (isFirstFilter) {
                    isFirstFilter = false;
                } else {
                    body += '--';
                }
                body += filter + '-' + searchInfo[filter];
            }
        }

        if (!isFirstFilter) {
            body += '--';
        }

        return body + sortFilter + queryParams;
    }

    /**
     * @param {string} currentUrl
     * @param {number} currentPage
     * @param {number} itemsPerPage
     * @param {string} sortedColumn
     * @param {string} order
     * @param searchInfo
     * @returns {Observable<any>}
     */
    getItems(currentUrl: string, currentPage: number, itemsPerPage: number, sortedColumn: string, order: string, searchInfo?: any) {
        let url = currentUrl + '/index?filters=';
        let sortFilter = 'sort-' + sortedColumn + '--order-' + order;
        let queryParams = '&page=' + currentPage + '&items=' + itemsPerPage;
        // add url query parameters based on the availability of the searches
        if (searchInfo) {
            let isFirstFilter = true;
            for (let key in searchInfo) {
                if (searchInfo.hasOwnProperty(key) && searchInfo[key]) {
                    if (isFirstFilter) {
                        isFirstFilter = false;
                    } else {
                        url += '--';
                    }
                    url += key + '-' + searchInfo[key];
                }
            }
            url += '--' + sortFilter + queryParams;
        } else {
            url += sortFilter + queryParams;
        }

        // return users from backend in json format
        return this.secureApi.get(url);
    }

    /**
     * Submit reactive form
     *
     * @param {string} url
     * @param {number} id
     * @param model
     * @param {number} currentPage
     * @param {number} itemsPerPage
     * @param {string} sortedColumn
     * @param {string} order
     * @param searchInfo
     * @returns {Observable<Response>}
     */
    submit(url: string, id: number, model: any, currentPage?: number, itemsPerPage?: number, sortedColumn?: string,
           order?: string, searchInfo?: any) {
        // add url query parameters
        let body = '';

        let firstParam = true;
        if (id != null) {
            body = 'id=' + id;
            firstParam = false;
        }

        let paramBuilder = new URLSearchParams();

        for (let column in model) {
            if (model.hasOwnProperty(column) && model[column] !== null) {
                paramBuilder.append(column, Array.isArray(model[column]) ? JSON.stringify(model[column]) : model[column]);
            }
        }

        let modelProperties = paramBuilder.toString();
        if (firstParam) {
            body += modelProperties;
        } else {
            body += '&' + modelProperties;
        }

        body += '&';
        if (currentPage != null && itemsPerPage != null && sortedColumn != null && order != null && searchInfo != null) {
            body = this.setFilters(body, currentPage, itemsPerPage, sortedColumn, order, searchInfo);
        }

        if (id != null) {
            return this.secureApi.put(url + '/update/' + id, body, true);
        }

        return this.secureApi.post(url + '/create', body, true);
    }

    /**
     * @param {string} url
     * @param {string} action
     * @returns {Observable<any> | Observable<Response>}
     */
    changeStatus(url: string, action: string) {
        return this.secureApi.changeStatus(url, action);
    }
}
