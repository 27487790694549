import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { SecureApi } from '../../core/secure.api';

@Injectable()
export class SwitchClinicService {

    constructor(
        public secureApi: SecureApi
    ) {
    }

    switchClinic(clinicId: number) {
        return this.secureApi.get('oauth/user-roles?clinic_id=' + clinicId);
    }
}
