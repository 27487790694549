import { NgModule } from '@angular/core';
import { BaseModule } from '../shared/base.module';
import { UsersRoutesModule } from './users.routes';
import { UsersComponent } from './users.component';
import { ClinicsModalModule } from '../clinics/clinics-modal/clinics-modal.module';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from '../shared/pagination/pagination.module';
import { DialogModule } from '../dialog/dialog.module';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { MATERIALS } from '../app.materials';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {TooltipModule} from "ngx-bootstrap/tooltip";
import { BreadcrumbModule } from '../shared/breadcrumb/breadcrumb.module';
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
};
@NgModule({
    imports: [
        MATERIALS,
        BaseModule,
        UsersRoutesModule,
        ClinicsModalModule,
        TypeaheadModule.forRoot(),
        ModalModule.forRoot(),
        BsDropdownModule.forRoot(),
        TooltipModule.forRoot(),
        PaginationModule,
        DialogModule,
        MatSlideToggleModule,
        PerfectScrollbarModule,
        BreadcrumbModule
    ],
    declarations: [
        UsersComponent
    ],
    exports: [
        UsersComponent
    ],
    providers: [
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        }
    ]
})
export class UsersModule {
}
