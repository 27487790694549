import { Pipe, PipeTransform , Sanitizer, SecurityContext} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ConsoleService } from '@ng-select/ng-select/lib/console.service';

@Pipe({name: 'boldSpan'})
export class BoldSpanPipe implements PipeTransform {

    constructor(
        private sanitizer: DomSanitizer 
    ) {}

    transform(value: string, regex): any {
        if (!value || !regex) {
            return value;
        }
        return this.sanitize(this.replace(value, regex));
    }

    replace(str, regex) {
        let words = regex.match(/[^ ]+/g);
        words.forEach(item => {
            str = str.replace(new RegExp(`(${item})`, 'gi'), '<b>$1</b>');
        })
        return str;
    }

    sanitize(str) {
        return this.sanitizer.sanitize(SecurityContext.HTML, str);
    }
}
