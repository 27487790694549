<div bsModal #modal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog quicklink">
        <div class="modal-content">
            <div class="modal-header text-xs-center">
                <h4 class="modal-title">
                    <span>
                        {{ params['title'] | translate }}
                    </span>
                </h4>
            </div>
            <div class="modal-body modal-dashboard-information">
                <button type="button" class="btn btn-link close" (click)="hideModal()" aria-label="Close"></button>

                <div class="modal-dashboard-information__container">
                    <div class="title-container text-xs-center">
                        <div class="circle">{{ slideIndex + 1 }}</div>
                        <div class="title">{{ stepsWizard ? (stepsWizard[slideIndex].name | translate) : ''}}</div>
                    </div>
                    <div class="slide-information-container">
                        <app-slide [slides]="slides" [isShowIndicator]="false" [noPauseSlides]="false" [(activeSlideIndex)]="slideIndex">
                        </app-slide>
                    </div>
                    <div class="slide-information-detail text-xs-center">
                        <div *ngIf="slideIndex === 0">
                            <p>{{'Select a patient from the patient list' | translate }}</p>
                            <p>{{'or' | translate }}</p>
                            <p>{{'create a new patient.' | translate }}</p>
                        </div>
                        <div *ngIf="slideIndex === 1">
                            <p>{{'A questionnaire consists of 2 steps:' | translate }}</p>
                            <p>{{'The Self Evaluation forms the basis of the Diagnostic Interview.' | translate }}</p>
                        </div>
                        <div *ngIf="slideIndex === 2">
                            <p>{{'Self Evaluation and Diagnostic Interview are each visualized in the form of a separate Symptom Map' | translate }}</p>
                        </div>
                        <div *ngIf="slideIndex === 3">
                            <p>{{'Self Evaluation and Diagnostic Interview Symptom Map together form a "Package".' | translate }}</p>
                            <p>{{'For each "Package" a diagnosis (with additional diagnoses) is made.' | translate }}</p>
                        </div>
                        <div *ngIf="slideIndex === 4">
                            <p>{{'During the course of a treatment several "packages" can be created.' | translate }}</p>
                            <p>{{'Before a new "Package" can be started, the previous one must be completed (archived).' | translate }}</p>
                        </div>
                    </div>
                    <div class="stepper-information-container">
                        <app-stepper-wizard [stepperType]="'horizontal'" [steps]="stepsWizard" [(selectedIndex)]="slideIndex" (done)="hideModal()">
                        </app-stepper-wizard>
                    </div>
                </div>
            </div>
        </div>
    </div>