<div *ngIf="!this.appService.notificationLoaded; else notificationLoaded" class="notification-box border-box">
    <div class="notification-box__loading">
        {{ 'Loading' | translate }}...
    </div>
</div>

<ng-template #notificationLoaded>
    <div class="notification-box border-box">

        <div class="notification-box__header">
            <div *ngIf="this.appService.notifications.length == 0" class="media notification-box__media notification-box__media--empty">
                <div class="media-left notification-box__media-left">
                    <i aria-hidden="true" class="fas fa-ban"></i>
                </div>
                <div class="media-body notification-box__media-body">
                    {{ 'No Notifications' | translate }}
                </div>
            </div>

            <div *ngIf="this.appService.notifications.length">
                <ng-template ngFor let-i="index" let-notificationItem let-last="last" [ngForOf]="this.appService.notifications | slice:0:MIN_NO_OF_NOTIFICATIONS">
                    <notification-item [notificationItem]="notificationItem" (notificationClicked)="handleClinkedNotification($event)"></notification-item>
                </ng-template>
            </div>

        </div>

        <div class="notification-box__footer" *ngIf="appService.notifications.length">
            <a (click)="appService.sendGA(appService.GAEvents.HEADER_MENU.name, appService.GAEvents.HEADER_MENU.event.notification)" [routerLink]="['/notifications/' + appService.notifications[0]['id']]" class="media notification-box__media">
                <div class="media-left notification-box__media-left">
                    <i class="fas fa-long-arrow-alt-right notification-box__all-icon"></i>
                </div>
                <div class="media-body notification-box__media-body">
                    {{ 'All Notifications' | translate }}
                </div>
            </a>
        </div>
    </div>
</ng-template>
