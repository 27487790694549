import {Component, Input, ViewChild } from '@angular/core';
import { PopoverService } from './popover.service';
import {PopoverDirective} from "ngx-bootstrap/popover";

@Component({
    selector: 'app-popover',
    templateUrl: './popover.component.html',
    styleUrls: ['./popover.component.scss'],
    exportAs: 'Popover',
})
export class PopoverComponent {
    @Input() popoverContent = '';
    @Input() templateType = '';
    @Input() containerClass: string;
    @Input() placement = 'bottom';
    @Input() adaptivePosition = true;
    @Input() iconClass = '';
    @Input() fontSize = '85%';
    @Input() margin = '';
    @Input() container = null;
    @Input() attachBody = false;
    @Input() isFlex = false;
    @Input() styles : any;
    @ViewChild('pop', { static: true }) pop: PopoverDirective;

    constructor(public popoverService: PopoverService) {

    }
}
