import { Injectable } from '@angular/core';
import { SecureApi } from '../../core/secure.api';
import { AppService } from '../../app.service';

@Injectable()
export class PopoverService {

    helpContent = {};

    get language() {
        return this.appService.getLanguage();
    }

    constructor(public secureApi: SecureApi, public appService: AppService) {
    }

    getHelpContent(type) {
        return this.secureApi.get('help/' + type);
    }

    setHelpContent(rawContent) {
        this.helpContent = this.setHelpTemplates(rawContent);
    }

    getHelpTemplateTranslation(name) {
        return this.helpContent &&
            this.helpContent[name] &&
            this.helpContent[name][this.language] &&
            this.helpContent[name][this.language]['content'];
    }
    
    private setHelpTemplates(helpTemplates) {
        let helpTemplateObj = {};
        if (helpTemplates instanceof Array) {
            helpTemplates.forEach(template => {
                let templateTranslations = template['translations'] || [];
                let newTranslation = {};
                templateTranslations.forEach(translation => {
                    let locale = translation && translation['language'] && translation['language']['locale'];
                    let content = translation && translation['content'];
                    if (locale) {
                        newTranslation[locale] = {content};
                    }
                })
                let templateName = template && template['name'];
                helpTemplateObj[templateName] = newTranslation;
            });
        }

        return helpTemplateObj;
    }

    getHelpNotificationContent() {
        return this.secureApi.get('help/notification');
    }
}
