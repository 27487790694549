import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Location } from '@angular/common';
import { FeedbackService } from '../feedback.service';
import { BaseService } from '../../shared/base.service';
import { BaseComponent } from '../../shared/base.component';
import { AuthService } from '../../core/auth.service';
import { AppService } from '../../app.service';
import { DialogComponent } from '../../dialog/dialog.component';
import { TranslateService } from '@ngx-translate/core';


declare var FRUBIL;


@Component({
  selector: 'app-feedback',
  templateUrl: './passive-feedback.component.html',
  styleUrls: ['./passive-feedback.component.scss'],
  providers: [FeedbackService, BaseService]
})

export class PassiveFeedbackComponent extends BaseComponent implements OnInit {

    @ViewChild('dialog', { static: false }) dialog: DialogComponent;
    form: FormGroup;
    
    constructor(
      public authService: AuthService,
      public router: Router,
      public baseService: BaseService,
      public appService: AppService,
      public feedbackService: FeedbackService,
      public fb: FormBuilder,
      public translateService : TranslateService,
      public location: Location
      ) { 
      super(authService, router, baseService, appService);
    }
    ngOnInit(): void {
        super.ngOnInit();
        
        this.form = this.fb.group({
            comment: [''],
        });

        setTimeout ( () => {
            this.showModal();
        }, 500);
    }
    ngDoCheck() {

        if (!this.authService.isLoggedIn) {
            this.modal.hide();
        }
    }

    submit(form: any, isValid: boolean) {

        let body = {
            'comment' : form.comment.replace(/\r\n|\r|\n/g,"<br/>"),
            'url' : window.location.href,
            'device' : FRUBIL ? FRUBIL.device.class_code : 'unknow',
            'os':  FRUBIL ? FRUBIL.client.os : 'unknow',
            'browser' : FRUBIL ? FRUBIL.client.name : 'unknow',
            'version' : FRUBIL ? FRUBIL.client.version : 'unknow',
            'locale' : this.translateService.currentLang
        }

        this.isLoading = true;
        this.feedbackService.sendPassiveFeedback(body).subscribe( response => {
            this.hideModal();
        });
    }

    /**
     * Hide modal
     */
    hideModal() {
        this.modalIsClosed = true;
        this.isLoading = false;
        this.responseMessage = null;
        this.modal.hide();
    }

    /**
     * Set params
     * @param params
     */
    setParams(params) {
        this.params = params;
    }

}