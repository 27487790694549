<div [class]="'app-wrapper ' + coreService?.className" [class.overlay]="openedNavSE" *ngIf="appService && !appService.isMePage">
    <ng-container *ngIf="!appService.isSE">
        <app-header [class.hidden]="isHideHeader" class="block" (toggleSENavEvent)="toggleSENav()"></app-header>
        <main class="main">
            <div class="spinner-wrapper" [hidden]="!isLoading">
                <div class="spinner">
                    <div class="spinner__loading"><i></i><i></i></div>
                </div>
            </div>
            <div  [class.container]="coreService.className !== 'fluid'" [class.container-fluid]="coreService.className === 'fluid'" [class.app-loading]="isLoading" [class.live-questionnaire]="isLiveQuestionnairePage">
                <router-outlet></router-outlet>
                <router-outlet name='popup'></router-outlet>
            </div>
        </main>
    </ng-container>
    <ng-container *ngIf="appService.isSE">
        <mat-drawer-container (backdropClick)="toggleSENav()">
            <mat-drawer-content>
                <app-header [class.hidden]="isHideHeader" class="block" (toggleSENavEvent)="toggleSENav()"></app-header>
                <main class="main">
                    <div class="spinner-wrapper" [hidden]="!isLoading">
                        <div class="spinner">
                            <div class="spinner__loading"><i></i><i></i></div>
                        </div>
                    </div>
                    <div [class.container]="coreService.className !== 'fluid'" [class.container-fluid]="coreService.className === 'fluid'" [class.app-loading]="isLoading">
                        <router-outlet></router-outlet>
                        <router-outlet name='popup'></router-outlet>
                    </div>
                </main>
    
            </mat-drawer-content>
            <mat-drawer #sidenav mode="over" [(opened)]="openedNavSE" position="end" class="mat-sidenav--selfevaluation">
                <div class="selfevaluation__nav" *ngIf="openedNavSE">
                    <i class="fas fa-times close-btn" (click)="toggleSENav()"></i>
                </div>
                <self-evaluation-menu [step]="currentSEStep" (closeMenu)="openedNavSE = false" (openLinks)="openLinks($event)"></self-evaluation-menu>
            </mat-drawer>
        </mat-drawer-container>
    </ng-container>

    <div class="rotate-device" [class.hidden]="(appService.isPortrait && appService.isMobile) || (!appService.isPortrait && !appService.isMobile)">
        <div class="img-rotate"></div>
        <div class="text-rotate">{{ 'Please rotate your device' | translate }}</div>
    </div>
</div>

<div class='app-wrapper me-landing-page' *ngIf="appService.isMePage">
    <main class="main">
        <div class="spinner-wrapper" [hidden]="!isLoading">
            <div class="spinner">
                <div class="spinner__loading"><i></i><i></i></div>
            </div>
        </div>
        <div [class.app-loading]="isLoading">
            <router-outlet></router-outlet>
            <router-outlet name='popup'></router-outlet>
        </div>
    </main>
</div>

<!-- <router-outlet name='me'></router-outlet> -->
<app-footer [class.hidden]="isHideFooter" (openLinks)="openLinks($event)" (openIntroductionModal)="openDashboardInformationModal()"></app-footer>
<app-dashboard-information #dashboardInformationComponent></app-dashboard-information>
<app-terms-condition-modal #termsConditionModalComponent></app-terms-condition-modal>
<app-privacy-statement-modal #privacyStatementModalComponent></app-privacy-statement-modal>
<app-legal-notice-modal #legalNoticeModalComponent></app-legal-notice-modal>
<app-device-label-modal #deviceLabelModalComponent></app-device-label-modal>
    
<video #videoElement id="pip-video" controls controlsList="nodownload"
        src="" width="0"
        height="0"
        appNoRightClick>
    <track src="">
</video>
