import {Component} from '@angular/core';
import {BaseDialogComponent} from '../base.dialog.component';
import {DialogInterface} from '../dialog.interface';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent extends BaseDialogComponent implements DialogInterface {


}
