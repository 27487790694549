import { Injectable } from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import { AuthService } from './auth.service';
import { MaintenanceService } from '../maintenance/maintenance.service';

@Injectable()

export class AuthGuard implements CanActivate {
    constructor(public authService: AuthService,
                public maintenanceService: MaintenanceService,
                public route: ActivatedRoute,
                public router: Router) {
    }

    params: string;

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentPath = route.url[0].path;
        const currentAction = route.queryParams['action'];
        const rootPage = state.root.firstChild;
        let isKlenicoPage = rootPage?.data?.page === 'klenico-me';

        if (!this.authService.isLoggedIn) {
            if(state.url && isKlenicoPage) {
                this.router.navigateByUrl(`me/login`);
                return true;
            }
            if(state.url && state.url != '/home') {
                this.router.navigateByUrl(`login${state.url}`);
            }
            else {
                this.router.navigate(['login']);
            }
            return true;
        }

        if (currentPath === 'logout') {
            return true;
        }

        if (this.checkMaintenanceMode()) {
            return false;
        }

        // check if the logged in user is allowed to access a particular route by matching the paths & actions.
        const permissions = JSON.parse(localStorage.getItem('user'))['permissions'];
        if (!permissions) {
            return false;
        }

        for (const permission of permissions) {
            const path = permission.split('.')[0];
            const action = permission.split('.')[1];

            if (currentPath === path) {
                if (typeof currentAction !== 'undefined') {
                    if (currentAction === action) {
                        return true;
                    }
                } else {
                    if (action === 'index' || (path === 'profile' && action === 'read') || path === 'home') {
                        return true;
                    }

                    this.router.navigate(['/errors'], {
                        queryParams: {
                            message: 'You do not have sufficient permissions to access this page'
                        }
                    });
                    return false;
                }
            }
        }
        this.router.navigate(['/errors'], {
            queryParams: {
                message: 'You do not have sufficient permissions to access this page'
            }
        });

        return true;
    }

    checkMaintenanceMode() {
        if (this.authService.isSuperAdmin) {
            return false;
        }

        this.maintenanceService.read().subscribe(
            response => {
                if (response['maintain'].value) {
                    this.authService.maintenanceMode = true;
                    this.authService.maintenanceMessage = response['maintain'].text;
                    localStorage.clear();
                    return true;
                }
            }
        );
    }
}
