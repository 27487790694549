import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../core/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../app.service';
import { DialogComponent } from '../dialog/dialog.component';

@Component({
    selector: 'app-auth',
    templateUrl: './auth.component.html'
})

export class AuthComponent implements OnInit {
    authService: AuthService;
    appService: AppService;
    router: Router;
    translate: TranslateService;
    errorMessage: string;
    languages: Object[];
    clinicInfo: Object;
    successMessage : string;
    lastURL = '';
    lastParams = {};

    @ViewChild(DialogComponent, {static: false}) dialog: DialogComponent;

    // Time disable the button
    readonly DISABLE_TIMEOUT = 60000; // 1 minute

    // Time delay before redirect to login page (#671)
    readonly NAVIGATE_TIMEOUT = 4000;

    thisYear: number;

    constructor(router: Router,
                authService: AuthService,
                appService: AppService,
                translate: TranslateService) {
        this.router = router;
        this.authService = authService;
        this.appService = appService;
        this.translate = translate;
    }

    ngOnInit() {
        this.appService.isLoading = false;
        this.authService.getDBLanguages().subscribe(
            response => {
                this.languages = response['languages'];
                localStorage.setItem('languages', JSON.stringify(response['languages']));
                localStorage.setItem('language', this.translate.currentLang);
            },
            error => {
                this.errorMessage = error.error.error || 'Unable to get languages';
            }
        );
    }

    setLanguage(locale: string): void {
       this.appService.setLanguage(locale);
    }

    getBackground(): number{
        const number = parseInt(localStorage.getItem('accessingNumber')) || 1;
        return number;
    }

    setBackground(number): void{
        localStorage.setItem('accessingNumber', number);
    }

    get isLoggedIn() {
        return this.authService.isLoggedIn;
    }

    isActive(locale: string): string {
        const language = localStorage.getItem('language');

        if (language === locale || this.translate.currentLang === locale) {
            return '--active';
        }

        if (language == null && this.translate.currentLang == null && locale === 'de_CH') {
            return '--active';
        }

        return '';
    }

    doLogin(response) {
        this.successMessage = response['message'];
        this.authService.saveToLocalStorage(response['email'], response);
        this.appService.setLanguage(response['locale']);
        const isShowReleaseNote = (response['show_release_note'] && response['release_note'] && response['release_note']['description']);
        const releaseNote = isShowReleaseNote ? response['release_note'] : null;
        const notification = {
            isShow: isShowReleaseNote,
            notification: releaseNote,
            urlParam: this.lastURL,
            lastParams: this.lastParams,
        };

        const isShowDowntime = (response['show_downtime_notification'] && response['downtime_notification_text']);
        const downtimeNotification = {
            isShow: isShowDowntime,
            notification: {
                title: 'Downtime notification',
                description: response['downtime_notification_text'] || 'Downtime description',
            },
            urlParam: this.lastURL,
            lastParams: this.lastParams,
        };
        // Validate dependencies clinics
        if(response['clinics'].length > 1){
            this.appService.multiClinics = response['clinics'];
            notification['urlParam'] = 'switch-clinic';
            downtimeNotification['urlParam'] = 'switch-clinic';
            return this.showNotification(isShowReleaseNote ? notification : downtimeNotification, isShowDowntime && isShowReleaseNote ? downtimeNotification : null);
        }

        // Send event to GA
        const GAEvent = this.lastURL ? this.appService.GAEvents.LOGIN.event.deeplink : this.appService.GAEvents.LOGIN.event.normal;
        // Send event to GA : Redirect login with a deeplink
        this.appService.sendGA(this.appService.GAEvents.LOGIN.name, GAEvent);
        return this.showNotification(isShowReleaseNote ? notification : downtimeNotification, isShowDowntime && isShowReleaseNote ? downtimeNotification : null);
    }

    showNotification(data, nextNotification = null){
        const notification = data['notification'] || [];
        if (data['isShow']) {
            const dialog = this.dialog.openAlert({
                icon: 'fal fa-bell-on release-notes--icon',
                title: notification['version'] ||  notification['title'] || 'Information',
                description: notification['description'] || 'description',
                class: 'notification-release',
                modalSize: 'modal-md',
                cancelText: 'OK',
                warning: false
            });
            dialog.onCancel(() => {
                if(nextNotification){
                    return this.showNotification(nextNotification, false);
                }
                return this.router.navigate([data['urlParam']],
                    {
                        queryParams: data['params']
                    });
            });
        }
        else{
            return this.router.navigate([data['urlParam']],
                {
                    queryParams: data['params']
                })
        }
    }
}
