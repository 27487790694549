import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationComponent } from './notification.component';
import { NotificationItemComponent } from './notification-item/notification-item.component';
import { BaseModule } from '../base.module';
import { RouterModule } from '@angular/router';
import { PerfectScrollbarConfigInterface, PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
    , useBothWheelAxes: true
};

@NgModule({
    imports: [
        CommonModule,
        BaseModule,
        RouterModule
    ],
    declarations: [
        NotificationComponent,
        NotificationItemComponent
    ],
    exports: [
        NotificationComponent,
        NotificationItemComponent
    ],
    providers: [
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        }
    ]
})

export class NotificationModule {

}
