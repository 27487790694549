<div bsModal #modal="bs-modal" class="modal user-feedback-modal fade" tabindex="-1" role="dialog" aria-hidden="false">
    <div class="modal-dialog">
        <ng-container *ngIf="!formLoading">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">{{ 'Feedback welcome!' | translate }}</h4>
                    <button type="button" class="btn btn-link fal fa-times close" (click)="hideModal()" aria-label="Close"></button>
                </div>
                <div class="modal-body patient-form mat-form">
                    <form [formGroup]="form" class="mat-form" novalidate>
                        <div class="row">
                            <div class="col-xs-12 text-xs-left">
                                <span>{{ 'Do you have any questions or suggestions for improvement, something is not working as desired? We appreciate your feedback!' | translate}}</span>
                            </div>
                        </div>
                        <div class="row mr-t--md">
                            <div class="col-xs-12 text-xs-left">
                                <span>{{ 'Message' | translate }}</span>
                                <textarea id="patient-comment" maxlength="300" placeholder="{{ 'My message' | translate }}" rows="10" class="w-100 comment-textarea" formControlName="comment"></textarea>
                            </div>
                        </div>
                        <div class="row mr-t--sm">
                            <div class="col-xs-12 text-xs-left">
                                <button type="button" class="btn btn-primary" (click)="submit(form.value, form.valid, true)">
                                    {{ 'Send feedback' | translate }}
                                </button>
                                <button type="button" class="btn btn-secondary badge-pill" (click)="hideModal()">
                                    {{ 'Cancel' | translate }}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </ng-container>
    </div>
</div>
<dialog-component #dialog></dialog-component>