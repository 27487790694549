import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from '../../shared/base.component';
import { AuthService } from '../../core/auth.service';
import { BaseService } from '../../shared/base.service';
import { AppService } from '../../app.service';
import { LegalNoticeService } from '../legal-notice.service';

@Component({
    selector: 'app-legal-notice-modal',
    templateUrl: './modal.component.html',
    encapsulation: ViewEncapsulation.None,
    providers: [LegalNoticeService]
})

export class LegalNoticeModalComponent extends BaseComponent {
    content = {};

    constructor(public authService: AuthService,
        public router: Router,
        public baseService: BaseService,
        public appService: AppService,
        public service: LegalNoticeService
        ) {
        super(authService, router, baseService, appService);
    }

    openModal() {
        const params = {
            action: 'open',
            url: '',
            title: 'Legal Notice'
        };

        this.content = {};

        this.getTemplateContent(this.service, params, this.content);
    }

}

