import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { SlideCustomComponent } from './slide.component';
import { DynamicViewModule } from '../dynamic-view/dynamic-view.module';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        CarouselModule.forRoot(),
        DynamicViewModule
    ],
    exports: [
        SlideCustomComponent
    ],
    declarations: [
        SlideCustomComponent
    ]
})

export class SlideCustomModule { }