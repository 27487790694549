import { Injectable } from '@angular/core';
import { SecureApi } from '../core/secure.api';
import { AuthService } from '../core/auth.service';

@Injectable()
export class ProfileService {
    constructor(public secureApi: SecureApi, authService: AuthService) {
    }

    read(id: number) {
        return this.secureApi.get('profile/read/' + id);
    }

    setProfile(response: any) {
        let currentUser = JSON.parse(localStorage.getItem('user'));

        for (let key in response) {
            if (response.hasOwnProperty(key)) {
                currentUser[key] = response[key];
            }
        }

        localStorage.setItem('user', JSON.stringify(currentUser));
    }

    /**
     * Send test SMS after user click test.
     *
     * @param {number} id
     * @returns {Observable<any>}
     */
    sendTestSms(id: number, mobileNumber) {
        return this.secureApi.post('profile/sms/' + id, {
            phoneNumber: mobileNumber
        }, false);
    }
}
