export class UserModel {
    keyword   = '';
    role_id   = 0;
    clinic_id = 0;
}
export class RoleModel {
    SUPER_ADMIN = 1;
    ADMIN = 2;
    DOCTOR = 3;
    ASSISTANT = 4;
    MPA = 5;
}