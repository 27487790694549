import {
    Component,
    ViewEncapsulation,
    ChangeDetectionStrategy,
    Input,
    Output,
    EventEmitter,
    ViewChild
} from '@angular/core';

import { Slide } from './slide.interface';
import {CarouselComponent} from "ngx-bootstrap/carousel";

@Component({
    selector: 'app-slide',
    templateUrl: './slide.component.html',
    styleUrls: ['./slide.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class SlideCustomComponent{
    public currentSlideIndex = 0;

    @ViewChild('appCarousel', { static: false }) appCarousel: CarouselComponent;

    @Input() isShowIndicator = true;
    @Input() noPauseSlides = false;
    @Input() noWrapSlides = false;
    @Input() intervalValue: any = false; // Auto slide if set intervalValue is number
    @Input() isShowCaption = false;
    @Input() slides: Slide[];

    @Input() set activeSlideIndex(activeSlideIndex: number) {
        this.currentSlideIndex = activeSlideIndex;
    }

    @Output() activeSlideIndexChange = new EventEmitter<number>();

    trackBySlide(index, slide) {
        return slide.slide || index;
    }

    slideChange() {
        this.currentSlideIndex = this.appCarousel.activeSlide;
        this.activeSlideIndexChange.emit(this.currentSlideIndex);
    }

    prevSlide() {
        this.appCarousel.previousSlide();
    }

    nextSlide() {
        this.appCarousel.nextSlide();
    }
}
