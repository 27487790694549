import { RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { PasswordComponent } from './forgot-password/password.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { AuthGuard } from '../core/auth.guard';
import { AuthUnguard } from '../core/auth.unguard';
import { MobileVerificationComponent } from './change-password/mobile-verification/mobile-verification.component';
import { AuthSelfEvaluationComponent } from './self-evaluation/auth-self-evaluation.component';
import { RequestActionComponent } from './request-action/request-action.component';
import { VerifyLoginTokenComponent } from './verify-login-token/verify-login-token.component';

export const AuthRoutesModule = RouterModule.forChild([
    {
        path: 'login',
        component: LoginComponent, canActivate: [AuthUnguard],
        data: {
            breadcrumb: 'Login',
            page: 'login',
            class: 'auth-page',
            admin: false
        }
    },
    {
        path: 'login/:action/:id',
        component: LoginComponent, canActivate: [AuthUnguard],
        data: {
            breadcrumb: 'Login',
            page: 'login',
            class: 'auth-page',
            admin: false
        }
    },
    {
        path: 'login/:page',
        component: LoginComponent, canActivate: [AuthUnguard],
        data: {
            breadcrumb: 'Login',
            page: 'login',
            class: 'auth-page',
            admin: false
        }
    },
    {
        path: 'verify',
        component: MobileVerificationComponent, // @todo: do we still need this path.
        canActivate: [AuthUnguard],
        data: {
            breadcrumb: 'Verify SMS Code',
            class: 'auth-page',
            page: 'login',
            admin: false
        }
    },
    {
        path: 'admin',
        component: LoginComponent, canActivate: [AuthUnguard],
        data: {
            breadcrumb: 'Login',
            class: 'auth-page',
            admin: true
        }
    },
    {
        path: 'logout',
        component: LogoutComponent, canActivate: [AuthGuard],
        data: {
            breadcrumb: 'Logout',
            class: 'auth-page'
        }
    },
    {
        path: 'password',
        component: PasswordComponent, canActivate: [AuthUnguard],
        data: {
            breadcrumb: 'Password Change',
            page: 'login',
            class: 'auth-page'
        }
    },
    {
        path: 'password/reset/:token',
        component: ChangePasswordComponent, canActivate: [],
        data: {
            breadcrumb: 'Password Reset',
            page: 'login',
            class: 'auth-page'
        }
    },
    {
        path: 'start',
        component: AuthSelfEvaluationComponent, canActivate: [],
        data: {
            breadcrumb: 'Auth Self-Evaluation',
            class: 'auth-se',
            page: 'auth-se'
        }
    },
    {
        path: 'auth/request-access/:id/:action/:token',
        component: RequestActionComponent, canActivate: [],
        data: {
            breadcrumb: 'Verify access to health data',
            class: 'auth-page',
            page: 'login',
        }
    },
    {
        path: 'auth/token/:id/:token',
        component: VerifyLoginTokenComponent, canActivate: [],
        data: {
            breadcrumb: 'Verify access login after redirect',
            class: 'auth-page'
        }
    }
]);
