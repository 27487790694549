export class NotificationItemModel {

    private _id: any;
    private _title: string = '';
    private _redirectUrl: string = '';
    private _userName: string = '';
    private _icon: string = '';
    private _iconBackgroundColor: string = '';
    private _createdAt: string = '';
    // private _isActive: boolean = false;
    private _respondedAt: string = '';
    private _readAt: any;
    private _type: string = '';
    private _doctorName: string = '';
    private _doctorEmail: string = '';
    private _doctorMobile: string = '';
    private _clinicName: string = '';
    private _transferPatientId: number = 0;
    private _params: any;
    private _template: string = '';
    private _redirectBtnLabel: string = '';
    private _rawCreatedAt: string = '';
    private _assignmentNote: string = '';
    private _cancel: boolean = false;
    private _disabled: boolean = false;

    constructor(notificationItem) {
        if (!notificationItem) {
            return;
        }

        this._id = notificationItem.id;
        this._title = notificationItem.params.subject;
        this._redirectUrl = notificationItem.params && notificationItem.params.redirect_url;
        this._userName = (notificationItem.params && (notificationItem.params.patient_id || notificationItem.params.user_id || notificationItem.params.sender));
        this._icon = notificationItem.params.icon;
        this._iconBackgroundColor = notificationItem.params.style;
        this._createdAt = notificationItem.txt_created_at;
        this._rawCreatedAt = notificationItem.created_at;
        this._readAt = notificationItem.read_at || null;
        this._type = notificationItem.type;
        this._params = notificationItem.params;
        this._template = notificationItem.template;
        this._doctorName = notificationItem.params && notificationItem.params.executed_by;
        this._doctorEmail = notificationItem.params && notificationItem.params.doctor_email;
        this._doctorMobile = notificationItem.params && notificationItem.params.doctor_mobile;
        this._clinicName = notificationItem.params && notificationItem.params.clinic_id;
        this._respondedAt = notificationItem.params && notificationItem.params.responded_at;
        this._transferPatientId = notificationItem.params && notificationItem.params.transfer_patient_id;
        this._redirectBtnLabel = notificationItem.params && notificationItem.params.redirect_btn_label;
        this._assignmentNote = notificationItem.params && notificationItem.params.assignment_note;
        this._cancel = notificationItem.isCancelled;
        this._disabled = notificationItem.disabled;
    }

    get id(): string {
        return this._id;
    }

    get title(): string {
        return this._title;
    }

    get redirectUrl(): string {
        return this._redirectUrl;
    }

    get userName(): string {
        return this._userName;
    }

    get params(): string {
        return this._params;
    }

    get hasDefaultAction(): boolean {
        return (this._redirectUrl !== '');
    }

    get displayTime(): string {
        return this._createdAt;
    }

    get rawCreatedAt(): string {
        return this._createdAt;
    }

    get readAt(): any {
        return this._readAt;
    }

    get hasTempRead(): boolean {
        return typeof this._readAt === 'number';
    }

    get hasRead(): boolean {
        return this._readAt != null;
    }

    get isActive(): boolean {
        return (this._respondedAt === '');
    }

    get isCancel(): boolean {
        return this._cancel;
    }

    get respondedAt(): string {
        return this._respondedAt;
    }

    get template(): string {
        return this._template;
    }

    get isTransferRequest(): boolean {
        return (this._type === 'TransferRequestReceived');
    }

    get isWelcomeNotification(): boolean {
        return (this._type === 'UserWelcome');
    }

    get isTransferRequestReceived(): boolean {
        return this._type === 'TransferRequestReceived';
    }

    get isAccepted(): boolean {
        return this.isTransferRequestReceived
                && this.redirectUrl != ''
                && this.respondedAt != '';
    }

    get isDelinced(): boolean {
        return this.isTransferRequestReceived
                && this.redirectUrl == ''
                && this.respondedAt != '';
    }

    markAsRead() {
        if (this._readAt != null) {
            return;
        }

        this._readAt = Date.now();
    }

    get isTransferType(): boolean {
        return (this._type === 'TransferRequestReceived');
    }

    get isDisabled(): boolean {
        return this._disabled;
    }

    set disabled(disable) {
        this._disabled = disable;
    }

    get isAssignmentType(): boolean {
        return (this._type === 'PatientAssignmentSent');
    }

    get defaultActionLabel(): string {
        return this._redirectBtnLabel;
    }

    get cssClass(): string {
        let cssClass = '';

        const backgrounds = {
            'blue': 'text-primary',
            'red': 'text-danger',
            'yellow': 'text-warning',
            'grey': 'text-muted',
            'default': 'text-primary'
        };

        const icons = {
            'info': 'fas fa-info-circle',
            'exclamation': 'fas fa-exclamation-circle',
            'default': 'fas fa-info-circle'
        };

        cssClass += backgrounds[this._iconBackgroundColor] || backgrounds['default'];
        cssClass += ' ';
        cssClass += icons[this._icon] || icons['default'];

        if (this.isAccepted) {
            return 'text-secondary fas fa-check-circle' ;
        }

        if (this.isDelinced) {
            return 'text-danger fas fa-times-circle' ;
        }
        if (this.isDisabled) {
            return 'text-muted fal fa-ban' ;
        }


        return cssClass;
    }

    get doctorName(): string {
        return this._doctorName;
    }

    get doctorEmail(): string {
        return this._doctorEmail;
    }

    get doctorPhone(): string {
        return this._doctorMobile;
    }

    get clinicName(): string {
        return this._clinicName;
    }

    get transferPatientId(): number {
        return this._transferPatientId;
    }

    get assignmentNote(): string {
        return this._assignmentNote || '';
    }

    get isClickable(): boolean {
        // transferred
        if (this.params['transferred']) {
            return false;
        }
        // archived
        if (this.params['archived']) {
            return false;
        }
        // deleted
        if (this.params['deleted']) {
            return false;
        }
        return true;
    }

    get msgContent(): string {
        if (this.params['transferred']) {
            if (this.params['transferred_by'] === 'you') {
                return 'Action not possible: <strong>{{patient_id}}</strong> has been transfered to <strong>{{clinic_name}}</strong> on {{date}} by you.';
            }
            return 'Action not possible: <strong>{{patient_id}}</strong> has been transfered to <strong>{{clinic_name}}</strong> on {{date}} by <strong>{{executed_by}}</strong>.';
        }
        // archived
        if (this.params['archived']) {
            if (this.params['archived_by'] === 'you') {
                return 'Action not possible: <strong>{{patient_id}}</strong> has been archived on <strong>{{date}}</strong> by you.';
            }
            return 'Action not possible: <strong>{{patient_id}}</strong> has been archived on <strong>{{date}}</strong> by <strong>{{executed_by}}</strong>.';
        }
        // deleted
        if (this.params['deleted']) {
            if (this.params['deleted_by'] === 'you') {
                return 'Action not possible: <strong>{{patient_id}}</strong> has been deleted on <strong>{{date}}</strong> by you.';
            }
            return 'Action not possible: <strong>{{patient_id}}</strong> has been deleted on <strong>{{date}}</strong> by <strong>{{executed_by}}</strong>.';
        }
        return '';
    }

    get msgParams(): any {
        if (this.params['transferred']) {
            return {
                'patient_id': this.params['patient_id'],
                'date': this.params['transferred_at'],
                'clinic_name': this.params['clinic_name'],
                'executed_by': this.params['transferred_by']
            };
        }
        // archived
        if (this.params['archived']) {
            return {
                'patient_id': this.params['patient_id'],
                'date': this.params['archived_at'],
                'executed_by': this.params['archived_by'],
            };
        }
        // deleted
        if (this.params['deleted']) {
            return {
                'patient_id': this.params['patient_id'],
                'date': this.params['deleted_at'],
                'executed_by': this.params['deleted_by'],
            };
        }
        return {};
    }

    updateParams(key, value) {
        if (this._params[key]) {
            this._params[key] = value;
        }
    }

    backupParam(key) {
        if (!this._params[key + '_original']) {
            this._params[key + '_original'] = this._params[key];
        }
    }

    get isUserProfileLink() {
        const userTypes = [
            'LoginFailed',
            'UserActivated',
            'UserCreated',
            'UserDeactivated',
            'UserFinishedResetPassword',
            'UserRequestResetPassword'
        ];
        if (userTypes.indexOf(this._type) > -1) {
            return true;
        }
        return false;
    }

}
