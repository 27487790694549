<section class="auth__form">
    <div class="auth__wrapper">
        <div class="auth__inner">
            <div class="card auth__form__card">
                <div class="auth__header">
                    <span class="sprite auth__header__brand"></span>
                </div>
                <div class="card-block">
                    <div *ngIf="infoMessage" class="alert alert-info" role="alert">
                        <span class="fas fa-exclamation-circle" aria-hidden="true"></span>{{ infoMessage | translate }}
                    </div>
                    <div *ngIf="successMessage" class="alert alert-success" role="alert">
                        <span class="fas fa-exclamation-circle" aria-hidden="true"></span>{{ successMessage | translate }}
                    </div>
                    <div class="multi-clinics__wrapper">
                        <div class="multi-clinics__tooltip"><span></span></div>
                        <mat-form-field class="ps-content">
                            <mat-select [placeholder]="'Select Clinic' | translate" required="required" [(value)]="clinicId" [(ngModel)]="clinicId">
                                <mat-option *ngFor="let item of appService.multiClinics" [value]="item['id']">
                                    {{ item['name'] }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <button type="submit" class="btn btn-primary pull-right"
                                (click)=loginToClinic()>
                            {{ 'OK' | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
