<ng-container *ngIf="!data; else specific">
  <ul *ngIf="(breadcrumbs$ | async)?.length > 1 || (firstNode.label && (breadcrumbs$ | async)?.length > 0)">
    <li *ngIf="firstNode && firstNode.label && !authService.isDoctor && !authService.isMPA && !authService.isAssistant && !authService.isCoordinator && !authService.isAssistantPlus">
      <a *ngIf="rootAdmin.url" [href]="rootAdmin.url">{{ rootAdmin.label | translate }}</a>
    </li>
    <li *ngIf="firstNode && firstNode.label && (authService.isDoctor || authService.isMPA || authService.isAssistant || authService.isCoordinator || authService.isAssistantPlus)">
      <a *ngIf="rootDoctor.url" [href]="rootDoctor.url">{{ rootDoctor.label | translate }}</a>
    </li>
    <li *ngFor="let breadcrumb of (breadcrumbs$ | async); last as isLast">
      <span *ngIf="isLast">{{ breadcrumb.label | translate }}</span>
      <a *ngIf="!isLast" [href]="breadcrumb.url">{{ breadcrumb.label | translate }}</a>
    </li>
  </ul>
</ng-container>

<ng-template #specific>
  <ul class="specific">
    <li *ngFor="let item of data">
      <a *ngIf="item.url && item.url !== ''" [href]="item.url">{{ item.label | translate }}</a>
      <span *ngIf="!item.url || item.url === ''">{{ item.label | translate }}</span>
    </li>
  </ul>
</ng-template>
