import { Injectable, EventEmitter } from '@angular/core';
import { Router, RoutesRecognized, ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { local } from 'd3';

export class Breadcrumb {
    displayName: string;
    terminal: boolean;
    url: string;
}

@Injectable()
export class CoreService {
    currentRouteName = '';
    className = '';
    activatedRouteSnapshot: any;
    onBreadcrumbChange: EventEmitter<Breadcrumb[]> = new EventEmitter<Breadcrumb[]>(false);
    breadcrumbs: Breadcrumb[];

    constructor(
        public router: Router,
        public activatedRoute: ActivatedRoute,
        public translateService: TranslateService
    ) {
        this.router.events.subscribe((routeEvent: RoutesRecognized) => {
            if (routeEvent instanceof NavigationEnd) {
                // Traverse the active route tree
                let snapshot = this.activatedRoute.snapshot;
                let activated = this.activatedRoute.firstChild;
                if (activated != null) {
                    while (activated != null) {
                        snapshot = activated.snapshot;
                        activated = activated.firstChild;
                    }
                }
                const isTouchDevice = ('ontouchstart' in document.documentElement || navigator.maxTouchPoints > 0 || navigator.maxTouchPoints > 0) ? true : false;
                this.activatedRouteSnapshot = snapshot;
                let className = '';

                if (snapshot.data['class']) {
                    className = snapshot.data['class'];
                }

                if (isTouchDevice) {
                    className = className + ' ' + 'touch-device';
                }

                this.setClass(className);


                if (snapshot.data.name) {
                    this.setRouteName(snapshot.data.name);
                } else {
                    this.setRouteName('');
                }
            }

            if (!(routeEvent instanceof RoutesRecognized)) {
                return;
            }

            let route = routeEvent.state.root;
            let url = '';

            this.breadcrumbs = [];

            while (route.children.length) {
                route = route.firstChild;
                if (!route.routeConfig.path) {
                    continue;
                }

                url += `/${this.createUrl(route)}`;

                if (!route.data['breadcrumb']) {
                    continue;
                }

                this.breadcrumbs.push(this.createBreadcrumb(route, url));
            }

            this.onBreadcrumbChange.emit(this.breadcrumbs);
        });
    }

    setRouteName(routeName: string) {
        this.currentRouteName = routeName;
    }
    setClass(className: string) {
        this.className = !className ? '' : className;
    }
    removeClass(className: string) {
        if (className) {
            this.className = this.className.replace(className, '');
        }
    }
    changeBreadcrumb(route: ActivatedRouteSnapshot, name: string) {
        if (!this.breadcrumbs) {
            return;
        }

        const rootUrl = this.createRootUrl(route);
        const breadcrumb = this.breadcrumbs.find(bc => bc.url === rootUrl);

        breadcrumb.displayName = name;

        this.onBreadcrumbChange.emit(this.breadcrumbs);
    }

    createBreadcrumb(route: ActivatedRouteSnapshot, url: string): Breadcrumb {
        return {
            displayName: route.data['breadcrumb'],
            terminal: route.children.length === 0 || !route.firstChild.routeConfig.path,
            url: url
        };
    }

    createUrl(route: ActivatedRouteSnapshot) {
        return route.url.map(s => s.toString()).join('/');
    }
    get isHideHeader() {
        const res = window.location.href.search(
            /feelings-rating|feelings-selection|smoke-detector|amnestic-feeling-selection|amnestic-smoke-detector|login|errors|password/
        );
        if (res !== -1) {
            return true;
        } else {
            return false;
        }
    }
    createRootUrl(route: ActivatedRouteSnapshot) {
        let url = '';
        let next = route.root;

        while (next.firstChild !== route) {
            next = next.firstChild;
            if (!next.routeConfig.path) {
                continue;
            }

            url += `/${this.createUrl(next)}`;
        }

        url += `/${this.createUrl(route)}`;

        return url;
    }

    formatCurrency(amount, currency , maximumFractionDigits = 2) {
        let lang = this.translateService.currentLang;
        let locale = lang.replace('_', '-');
        if (locale === 'de-CH') {
            locale = 'de-DE';
        }
        
        return new Intl.NumberFormat(locale, {
            style: "currency",
            currency,
            maximumFractionDigits,
        }).format(Number(amount));
    }
}
